import React, { useState, useEffect } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AttributeGrid from "./AttributeGrid";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";

const AttributeMapping = (props) => {
  const [objectTypeOptions, setObjectTypeOptions] = useState([]);
  const [selectedObjectType, setSelectedObjectType] = useState("");
  const [allDistinctAttributes, setallDistinctAttributes] = useState([]);
  const [notAssignedAttributes, setNotAssignedAttributes] = useState([]);
  const [attributeMappings, setAttributeMappings] = useState([]);
  const [allAttributeMappings, setAllAttributeMappings] = useState([]);
  const [newAttributeName, setNewAttributeName] = useState("");
  const [updatedAttributeMap, setUpdatedAttributeMap] = useState([]);
  const [hasChanges, setHasChanges] = useState(false);
  const [deleteChanges, setDeleteChanges] = useState(false);
  const [originalAttributeMap, setOriginalAttributeMap] = useState({});

  useEffect(() => {
    axios
      .get("admin/types", {
        headers: {
          "auth-token": localStorage.getItem("auth"),
          database: props.user.mainDatabase,
          "user-Id": localStorage.getItem("userId"),
        },
      })
      .then((response) => {
        setObjectTypeOptions(response.data.content);
      })
      .catch((error) => {
        console.error("Failed to fetch object types:", error);
      });
  }, []);

  useEffect(() => {
    if (selectedObjectType) {
      fetchAttributes(selectedObjectType);
      fetchAllAttributes();
      findNotAssignedAttributes(allDistinctAttributes, attributeMappings);
    }
  }, [selectedObjectType, hasChanges]);

  useEffect(() => {
    const isAttributeMappingChanged =
      JSON.stringify(attributeMappings) !== JSON.stringify(updatedAttributeMap);
    setHasChanges(isAttributeMappingChanged);
  }, [attributeMappings, updatedAttributeMap]);

  useEffect(() => {
    fetchAllAttributes();
  }, []);

  useEffect(() => {
    let isAttributeMappingChanged =
      JSON.stringify(attributeMappings) === JSON.stringify(updatedAttributeMap);
    if (!isAttributeMappingChanged && deleteChanges) {
      saveChanges();
    }

    setDeleteChanges(false);
  }, [updatedAttributeMap]);

  const fetchAllAttributes = () => {
    axios
      .get("admin/attributeMap", {
        headers: {
          "auth-token": localStorage.getItem("auth"),
          database: props.user.mainDatabase,
          "user-Id": localStorage.getItem("userId"),
        },
      })
      .then((response) => {
        const responseData = response.data;
        if (
          responseData &&
          responseData.success &&
          responseData.content &&
          Array.isArray(responseData.content)
        ) {
          const originalMap = {};
          responseData.content.forEach((mapping) => {
            originalMap[mapping.type] = mapping.assignedAttributes.map(
              (attr, index) => ({
                ...attr,
                id: `${attr.attribute}-${index}`,
              })
            );
          });

          setOriginalAttributeMap(originalMap);
          setAllAttributeMappings(responseData.content); // Save all types and their attributes
          // get all distinct attribute for dropdown
          setallDistinctAttributes(
            getAllDistinctAttributes(responseData.content)
          );
        } else {
          setOriginalAttributeMap({});
          setAllAttributeMappings([]); // Clear allAttributeMappings if no data is received
        }
      })
      .catch((error) => {
        console.error("Failed to fetch attributes:", error);
        setOriginalAttributeMap({});
        setAllAttributeMappings([]); // Clear allAttributeMappings on error
      });
  };

  const fetchAttributes = (objectType) => {
    axios
      .get("admin/attributeMap", {
        headers: {
          "auth-token": localStorage.getItem("auth"),
          database: props.user.mainDatabase,
          "user-Id": localStorage.getItem("userId"),
        },
      })
      .then((response) => {
        const responseData = response.data;
        if (
          responseData &&
          responseData.success &&
          responseData.content &&
          Array.isArray(responseData.content)
        ) {
          const selectedAttributes = responseData.content.find(
            (item) => item.type === objectType
          );

          if (
            selectedAttributes &&
            selectedAttributes.assignedAttributes &&
            Array.isArray(selectedAttributes.assignedAttributes)
          ) {
            const attributesWithIds = selectedAttributes.assignedAttributes.map(
              (attr, index) => ({
                ...attr,
                id: `${attr.attribute}-${index}`,
              })
            );

            setAttributeMappings(attributesWithIds);

            setUpdatedAttributeMap((prevMap) => {
              const updatedMapCopy = [...prevMap];
              const index = updatedMapCopy.findIndex(
                (item) => item.type === objectType
              );

              if (index !== -1) {
                updatedMapCopy[index].assignedAttributes = attributesWithIds;
              } else {
                updatedMapCopy.push({
                  type: objectType,
                  assignedAttributes: attributesWithIds,
                });
              }

              return updatedMapCopy;
            });
          } else {
            setAttributeMappings([]);
          }
        } else {
          setAttributeMappings([]);
        }
      })
      .catch((error) => {
        console.error("Failed to fetch attributes:", error);
        setAttributeMappings([]);
      });
  };

  const handleObjectTypeSelect = (objectType) => {
    setSelectedObjectType(objectType);

    const typeExistsInUpdatedMap = updatedAttributeMap.some(
      (item) => item.type === objectType
    );

    if (typeExistsInUpdatedMap) {
      const selectedAttributes = updatedAttributeMap.find(
        (item) => item.type === objectType
      );
      if (selectedAttributes) {
        setAttributeMappings(selectedAttributes.assignedAttributes);
      }
    } else {
      setAttributeMappings(originalAttributeMap[objectType] || []);
    }
  };

  const handleAttributeEdit = (updatedAttributes) => {
    setAttributeMappings(updatedAttributes);

    setUpdatedAttributeMap((prevMap) => {
      const updatedMapCopy = [...prevMap];
      const index = updatedMapCopy.findIndex(
        (item) => item.type === selectedObjectType
      );

      if (index !== -1) {
        updatedMapCopy[index].assignedAttributes = updatedAttributes;
      } else {
        updatedMapCopy.push({
          type: selectedObjectType,
          assignedAttributes: updatedAttributes,
        });
      }

      return updatedMapCopy;
    });
  };

  // ...

  const handleDeleteAttribute = (id) => {
    const updatedAttributes = attributeMappings.filter(
      (attr) => attr.id !== id
    );
    setAttributeMappings(updatedAttributes);

    setUpdatedAttributeMap((prevMap) => {
      const updatedMapCopy = [...prevMap];
      const index = updatedMapCopy.findIndex(
        (item) => item.type === selectedObjectType
      );

      if (index !== -1) {
        updatedMapCopy[index].assignedAttributes = updatedAttributes;
      } else {
        updatedMapCopy.push({
          type: selectedObjectType,
          assignedAttributes: updatedAttributes,
        });
      }

      return updatedMapCopy;
    });
  };

  const handleAddNewAttribute = (attribute) => {
    setNewAttributeName(attribute);

    // if (!newAttributeName.trim()) {
    //   toast.error("Attribute is added");
    //   return;
    // }

    // const attributeExists = attributeMappings.some(
    //   (attr) =>
    //     attr.attribute.toLowerCase() === newAttributeName.trim().toLowerCase()
    // );

    // if (attributeExists) {
    //   toast.error("Attribute already exists in the list.");
    //   return;
    // }

    const newAttribute = {
      id: `temp-${Date.now()}`,
      attribute,
      whitelist: false,
      show: false,
    };

    setAttributeMappings((prevRows) => [...prevRows, newAttribute]);
    setNewAttributeName("");

    setUpdatedAttributeMap((prevMap) => {
      const updatedMapCopy = [...prevMap];
      const index = updatedMapCopy.findIndex(
        (item) => item.type === selectedObjectType
      );

      if (index !== -1) {
        updatedMapCopy[index].assignedAttributes.push(newAttribute);
      } else {
        updatedMapCopy.push({
          type: selectedObjectType,
          assignedAttributes: [...attributeMappings, newAttribute],
        });
      }

      return updatedMapCopy;
    });

    toast.success("Attribute added successfully!");
  };

  const saveChanges = () => {
    if (!hasChanges) {
      toast.info("No changes to save.");
      return;
    }

    // Create a copy of updatedAttributeMap and remove the "id" and "show" fields from each attribute
    const updatedMapWithoutIdsAndShow = updatedAttributeMap.map((mapping) => ({
      ...mapping,
      assignedAttributes: mapping.assignedAttributes.map((attr) => ({
        attribute: attr.attribute,
        whitelist: attr.whitelist,
      })),
    }));

    // Merge updatedMapWithoutIdsAndShow into allAttributeMappings, including unchanged types
    const mergedAttributeMap = allAttributeMappings.map((existingMapping) => {
      const updatedMapping = updatedMapWithoutIdsAndShow.find(
        (updated) => updated.type === existingMapping.type
      );

      return updatedMapping
        ? {
            ...existingMapping,
            assignedAttributes: updatedMapping.assignedAttributes,
          }
        : existingMapping; // Keep existing mapping if not updated
    });

    // Add any new types and their attributes that were added by the user
    for (const updatedMapping of updatedMapWithoutIdsAndShow) {
      if (
        !mergedAttributeMap.some(
          (existing) => existing.type === updatedMapping.type
        )
      ) {
        // If a type was not found in mergedAttributeMap, add it
        mergedAttributeMap.push(updatedMapping);
      }
    }

    axios
      .post("admin/attributeMap", mergedAttributeMap, {
        headers: {
         "auth-token": localStorage.getItem("auth"),
          database: props.user.mainDatabase,
          "user-Id": localStorage.getItem("userId"),
        },
      })
      .then((response) => {
        toast.success("Changes saved successfully!");

        // Update the allAttributeMappings state with the mergedAttributeMap
        setAllAttributeMappings(mergedAttributeMap);

        // Reset the hasChanges state
        setHasChanges(false);
      })
      .catch((error) => {
        console.error("Failed to save changes:", error);
        toast.error(`Failed to save changes. Please try again later.`);
      });
  };
  const handleChange = async (e) => {
    await setNewAttributeName(e.target.value);
    await handleAddNewAttribute(e.target.value);
    saveChanges();
  };

  const getAllDistinctAttributes = (jsonObj) => {
    const attributesSet = new Set(); // Use a Set to store distinct attributes
    // Check if the input object has the "assignedAttributes" property

    // Iterate through the "assignedAttributes" array
    jsonObj.map((item) => {
      if (item && item.assignedAttributes) {
        item.assignedAttributes.map((attr) => {
          if (attr && attr.attribute) {
            attributesSet.add(attr.attribute);
          }
        });
      }
    });

    return attributesSet;
  };

  const findNotAssignedAttributes = (allSet, jsonObj) => {
    const assignnedSet = new Set();
    // Iterate through the "assignedAttributes" array
    jsonObj.map((item) => {
      if (item && item.attribute) {
        assignnedSet.add(item.attribute);
      }
    });
    const result = new Set(allSet);
    for (const item of assignnedSet) {
      result.delete(item);
    }
    setNotAssignedAttributes(result);
  };

  return (
    <div
      className="container"
      style={{
        display: "flex",
        flexFlow: "wrap",
        flexDirection: "row",
        justifyContent: "space-between",
      }}
    >
      <div className="item">
        <FormControl style={{ width: "30vw" }}>
          <InputLabel> Select an Object Type</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            label="Select an Object Type"
            value={selectedObjectType}
            onChange={(e) => handleObjectTypeSelect(e.target.value)}
          >
            {Array.from(objectTypeOptions).map((type, index) => {
              return (
                <MenuItem key={type} value={type} tabindex={index}>
                  {type}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </div>

      {selectedObjectType && (
        <>
          <div className="item">
            <FormControl
              style={{
                width: "30vw",
                textAlign: "right",
                marginBottom: "25px",
              }}
            >
              <InputLabel>Add Attribute</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Add Arttrbute"
                value={newAttributeName}
                onChange={handleChange}
              >
                {Array.from(notAssignedAttributes).map((attr, index) => {
                  return (
                    <MenuItem key={index} value={attr} tabindex={index}>
                      {attr}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            {/* -----------------------------------------------------old logic */}
            {/* <TextField
                label="New Attribute"
                value={newAttributeName}
                onChange={(e) => setNewAttributeName(e.target.value)}
                variant="outlined"
                size="small"
              /> */}
            {/* -----------------------------------------------------old logic */}
            {/* <Button
              variant="contained"
              onClick={handleAddNewAttribute}
              style={{ backgroundColor: "#007bff", color: "#fff" }}
            >
              Add Attribute
            </Button> */}
            {/* </div> */}
            {/* //----------------------------------------------------------------------------------------------- */}

            {/* //----------------------------------------------------------------------------------------------- */}
          </div>

          <div className="break" style={{ flexBasis: "100%", height: 0 }}>
            <AttributeGrid
              attributes={attributeMappings}
              onRowEdit={handleAttributeEdit}
              onDeleteAttribute={handleDeleteAttribute}
              onGridChange={(hasChanges) => setHasChanges(hasChanges)}
              setDeleteChanges={setDeleteChanges}
            />
          </div>
        </>
      )}

      <ToastContainer />
    </div>
  );
};
export default AttributeMapping;
