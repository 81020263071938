import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import UserList from "./UserList";
import AttributeMapping from "./attribute/src/AttributeMapping";
import { getAllUsers } from "../../../actions";
import AdvanceSearchFilter from "./AdvanceSearchFilter";
import ReleaseNotesUpdate from "./ReleaseNotesUpdate";
import UploadBPMN from "./UploadBPMN";
import { useLocation, useHistory } from "react-router";
import Organisations from "./Organisations";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));

export default function AdminTabPanel(props) {
  const history = useHistory();

  const auth = localStorage.getItem("auth");
  const [userDataObject, setUserDataObject] = useState("");

  const [userListApiError, setUserListApiError] = useState("");

  const location = useLocation();

  const [alertBox, setAlertBox] = useState({
    show: true,
    variant: "success",
    msg: "User Data",
  });

  const resetAlertBox = () => {
    setAlertBox({
      show: true,
      variant: "success",
      msg: "User Data",
    });
  };
  useEffect(() => {
    let isMounted = true;
  
    const fetchUsers = async () => {
      if (props.userRole === "admin") {
        try {
          const res = await getAllUsers();
          if (res.success) {
            setUserDataObject(res.content);
            setAlertBox({
              show: true,
              variant: "primary",
              msg: "Users fetched successfully",
            });
          } else {
            setAlertBox({
              show: true,
              variant: "danger",
              msg: "Unable to get the user list",
            });
          }
          setTimeout(resetAlertBox, 4000);
        } catch (error) {
          console.error(error);
          setAlertBox({
            show: true,
            variant: "danger",
            msg: "An error occurred while fetching users.",
          });
        }
      }
  
      return () => {
        isMounted = false;
      };
    };
  
    fetchUsers();
  
  }, [props.userRole, getAllUsers]); // Include dependencies here

  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const newTabRoute = location.pathname.split("/")[2];
  // when user go to specific link open the specific TAB
  useEffect(() => {
    switch (newTabRoute) {
      case "upload-bpmn":
        setValue(1);
        break;
      case "attribute-mapping":
        setValue(2);
        break;
      case "advance-search":
        setValue(3);
        break;
      case "update-releasenotes":
        setValue(4);
        break;
      case "organisations":
        setValue(5);
        break;
      default:
        setValue(0);
        break;
    }
  }, [newTabRoute]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    switch (newValue) {
      case 0:
        history.push("/admin");
        break;
      case 1:
        history.push("/admin/upload-bpmn");
        break;
      case 2:
        history.push("/admin/attribute-mapping");
        break;
      case 3:
        history.push("/admin/advance-search");
        break;
      case 4:
        history.push("/admin/update-releasenotes");
        break;
      case 5:
          history.push("/admin/organisations");
          break;
      default:
        break;
    }
  };

  return (
    <div className="container-fluid nav_bg my-3 ">
      <div className="col-10 mx-auto">
        <div className={classes.root}>
          <AppBar position="static">
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="simple tabs example"
            >
              <Tab label="User" {...a11yProps(0)} />
              {/*<Tab label="Import Scope Items" {...a11yProps(1)} />*/}
              {/*<Tab label="Attributes" {...a11yProps(2)} />*/}
              <Tab label="Upload BPMN" {...a11yProps(1)} />
              <Tab label="Attribute Mapping" {...a11yProps(2)} />
              <Tab label="Advance Search" {...a11yProps(3)} />
              <Tab label="Release Notes" {...a11yProps(4)} />
              <Tab label="Organisations" {...a11yProps(5)} />

              {/* <Tab label="Domain" {...a11yProps(1)} /> */}
            </Tabs>
          </AppBar>
          <TabPanel value={value} index={0}>
            <UserList
              userRole={props.userRole}
              userDataObject={userDataObject}
              userListApiError={userListApiError}
              alertBox={alertBox}
            />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <UploadBPMN />
          </TabPanel>

          <TabPanel value={value} index={2}>
            <AttributeMapping
            // userRole={props.userRole}
            // domainDataObject={domainDataObject}
            // domainListApiError={domainListApiError}
            {...props}
            />
          </TabPanel>
          <TabPanel value={value} index={3}>
            <AdvanceSearchFilter />
          </TabPanel>
          <TabPanel value={value} index={4}>
            <ReleaseNotesUpdate />
          </TabPanel>
          <TabPanel value={value} index={5}>
            <Organisations />
          </TabPanel>
        </div>
      </div>
    </div>
  );
}
