import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Tabs,
  Tab,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { getReleaseNotes, getContentReleaseNotes } from "../actions";
import { DataGrid, GridRow } from "@mui/x-data-grid";
import { yellow } from "@mui/material/colors";
import Box from "@mui/material/Box";
import clsx from "clsx";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#000",
    color: theme.palette.common.white,
    fontSize: "16px",
    fontWeight: "bold",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: "14px",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:hover": {
    backgroundColor: theme.palette.action.selected,
  },
}));

const TableWrapper = styled("div")({
  padding: "20px",
  margin: "20px",
  boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
  borderRadius: "10px",
});

const CustomRow = (props) => {
  // function CustomRow(props) {
  const { className, index, ...other } = props;

  return (
    <GridRow
      index={index}
      className={clsx(className, index % 2 === 0 ? "odd" : "even")}
      {...other}
    />
  );
};

const ReleaseNotes = () => {
  const [releaseNotes, setReleaseNotes] = useState([]);
  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState([]);
  const [releaseNotesCon, setReleaseNotesCon] = useState([]);
  const [columnsCon, setColumnsCon] = useState([
    // "id",
    // "Name",
    // "Release Notes",
    // "Type",
    // "Release Dates",
  ]);
  const [rowsCon, setRowsCon] = useState([]);
  const [tabValue, setTabValue] = useState(0);

  useEffect(() => {
    async function fetchData() {
      const data = await getReleaseNotes();
      setReleaseNotes(data);
      const columnsFeatures = [
        {
          field: "title",
          headerName: "Title",
          minWidth: 220,
          flex: 0.5,
        },
        {
          field: "description",
          headerAlign: "center",
          headerName: "Description",
          minWidth: 600,
          flex: 1,
        },
        { field: "category", headerName: "Category", minWidth: 100, flex: 0.1 },
        { field: "type", headerName: "Type", minWidth: 100, flex: 0.1 },
        { field: "version", headerName: "Version", minWidth: 100, flex: 0.1 },
        {
          field: "releaseDate",
          headerName: "Release Date",
          minWidth: 120,
          align: "right",
          headerAlign: "right",
          flex: 0.1,
        },
      ];
      setColumns(columnsFeatures);
      const rowsWithIdsFaetures = data[1].slice(1).map((row, rowIndex) => ({
        id: rowIndex + 1,
        title: row[0],
        description: row[1],
        category: row[2],
        type: row[3],
        version: row[4],
        releaseDate: row[5],
      }));

      // setRows(data[1]);
      setRows(rowsWithIdsFaetures);

      //ARIS content release notes
      const dataC = await getContentReleaseNotes();
      var dataCon = dataC.replace(/(\r\n|\n|\r)/gm, ""); //remove new lines un
      if (dataCon != "empty") {
        const dataArisContent = JSON.parse(dataCon);
        // const dataArisContent = dataCon.split();

        setReleaseNotesCon(dataArisContent);
        // setColumnsCon(dataArisContent[0]);
        // setColumnsCon("id", "Name", "Release Notes", "Type", "Release Dates");
        const rowsWithIds = dataArisContent.slice(1).map((row, rowIndex) => ({
          id: rowIndex + 1,
          name: row[0],
          releaseNotes: row[1],
          type: row[2],
          releaseDate: row[3],
        }));

        setRowsCon(rowsWithIds);
        const columns = [
          {
            field: "name",
            headerName: "Name",
            minWidth: 220,
            flex: 0.5,
          },
          {
            field: "releaseNotes",
            headerAlign: "center",
            headerName: "Release Notes",
            minWidth: 600,
            flex: 1,
          },
          { field: "type", headerName: "Type", minWidth: 140, flex: 0.1 },
          {
            field: "releaseDate",
            headerName: "Release Date",
            minWidth: 180,
            align: "right",
            headerAlign: "right",
            flex: 0.1,
          },
        ];
        setColumnsCon(columns);
      }
    }

    fetchData();
  }, []);

  useEffect(() => {
    const elements = document.querySelectorAll(".w-100");
    elements.forEach((element) => {
      element.style.maxWidth = "100vw";
    });
  }, []);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const styles = {
    div: {
      padding: "20px",
      backgroundColor: "#f2f2f2",
      borderRadius: "10px",
      boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
      margin: "auto",
    },
    h1: {
      fontSize: "24px",
      color: "#333",
      marginBottom: "16px",
    },
    p: {
      padding: "10px",
      fontSize: "16px",
      lineHeight: "1.5",
      color: "#555",
    },
  };

  return (
    <div style={styles.div}>
      <div>
        <h1 style={styles.h1}>Release Notes and New Features</h1>
        <p style={styles.p}>
          The Business Flows Browser has recently rolled out a new update that
          brings a slew of enhancements and features designed to optimize user
          experience and workflow efficiency. The update introduces a revamped
          UI, providing a more intuitive layout that allows users to easily
          navigate through complex business processes. Additionally,
          drag-and-drop functionality has been added, giving users the ability
          to effortlessly rearrange workflow elements. The release also
          addresses several performance issues, resulting in faster load times
          and smoother transitions between tasks. These improvements are part of
          our ongoing commitment to deliver a tool that not only meets but
          exceeds the requirements of today's fast-paced business environments.
          Make sure to check out the detailed release notes for a comprehensive
          list of all the new features and improvements.
        </p>
        <Tabs value={tabValue} onChange={handleTabChange} centered>
          <Tab label="Features" />
          <Tab label="Content" />
          {/* Add more tabs as needed */}
        </Tabs>
        {/* Features tab */}
        <div style={{ maxWidth: "100vw" }}>
          <TabPanel value={tabValue} index={0}>
            {/* <TableWrapper>
            <TableContainer component={Paper}>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    {columns?.map((col, index) => (
                      <StyledTableCell align="center" key={index}>
                        {col}
                      </StyledTableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows?.map((row, index) => (
                    <StyledTableRow key={index}>
                      {row.map((cell, i) => (
                        <StyledTableCell key={i}>{cell}</StyledTableCell>
                      ))}
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </TableWrapper> */}
            <TableWrapper>
              <TableContainer component={Paper}>
                <div className="css-11" style={{ margin: 0, padding: 0 }}>
                  <Box
                    sx={{
                      width: 1,
                      "& .odd": {
                        bgcolor: "#ccc",
                      },
                    }}
                  >
                    <DataGrid
                      rows={rows}
                      columns={columns}
                      components={{ Row: CustomRow }}
                      getRowHeight={() => "auto"}
                      sx={{
                        boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
                        borderRadius: "10px",
                        "& .MuiDataGrid-cell:hover": {
                          background: "darkgray",
                          // whiteSpace: "normal !important",
                          // wordWrap: " break-word !important",
                        },
                        "& .MuiDataGrid-columnHeaderTitle": {
                          whiteSpace: "normal",
                          lineHeight: "normal",
                          color: "white",
                          fontWeight: 700,
                        },
                        "& .MuiDataGrid-columnHeader": {
                          background: "#000",
                          color: "white",
                          fontWeight: 700,
                        },
                        "& .MuiDataGrid-columnHeaders": {
                          maxHeight: "168px !important",
                          color: "white",
                          fontWeight: 700,
                        },
                        "& .MuiDataGrid-sortIcon": {
                          opacity: 1,
                          color: "white",
                        },
                        "& .MuiDataGrid-menuIconButton": {
                          opacity: 1,
                          color: "white",
                        },
                        "& .MuiDataGrid-cellContent": {
                          maxWidth: "600px !important",
                        },
                      }}
                    />
                  </Box>
                </div>
              </TableContainer>
            </TableWrapper>
          </TabPanel>
          <TabPanel value={tabValue} index={1}>
            {/* ARIS content tab */}
            <TableWrapper>
              <TableContainer component={Paper}>
                <div className="css-11" style={{ margin: 0, padding: 0 }}>
                  <Box
                    sx={{
                      width: 1,
                      "& .odd": {
                        bgcolor: "#ccc",
                      },
                    }}
                  >
                    <DataGrid
                      autoHeight={true}
                      rows={rowsCon}
                      columns={columnsCon}
                      components={{ Row: CustomRow }}
                      getRowHeight={() => "auto"}
                      sx={{
                        boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
                        borderRadius: "10px",
                        "& .MuiDataGrid-cell:hover": {
                          background: "darkgray",
                          // whiteSpace: "normal !important",
                          // wordWrap: " break-word !important",
                        },
                        "& .MuiDataGrid-columnHeaderTitle": {
                          whiteSpace: "normal",
                          lineHeight: "normal",
                          color: "white",
                          fontWeight: 700,
                        },
                        "& .MuiDataGrid-columnHeader": {
                          background: "#000",
                          color: "white",
                          fontWeight: 700,
                        },
                        "& .MuiDataGrid-columnHeaders": {
                          maxHeight: "168px !important",
                          color: "white",
                          fontWeight: 700,
                        },
                        "& .MuiDataGrid-sortIcon": {
                          opacity: 1,
                          color: "white",
                        },
                        "& .MuiDataGrid-menuIconButton": {
                          opacity: 1,
                          color: "white",
                        },
                        "& .MuiDataGrid-cellContent": {
                          maxWidth: "600px !important",
                        },
                      }}
                    />
                  </Box>
                </div>
              </TableContainer>
            </TableWrapper>
          </TabPanel>
        </div>
        {/* Add more TabPanel components as needed */}
      </div>
    </div>
  );
};

// A simple TabPanel component to conditionally render content based on the active tab
const TabPanel = ({ children, value, index }) => (
  <div hidden={value !== index} style={{ marginTop: "20px" }}>
    {value === index && <div>{children}</div>}
  </div>
);

export default ReleaseNotes;
