import React, { useEffect, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { getProcessStructure } from "../../actions";
import { getProcessOccurences, getProcessRelationships } from "../../actions";
import waterMarkimg from "../../images/WaterMark.svg";
import { quickCheckAccess, verifyAccess } from "../../utils/accessMapFunctions";
import { getProcessAttributesByPartialName } from "../../actions";

export default function ClassicModel(props) {
  const location = useLocation();
  const history = useHistory();
  const GUID = location.pathname.split("/")[3];

  const [structure, setStructure] = useState("");

  useEffect(async () => {
    const structure = await getProcessStructure(GUID);

    setStructure(structure.content);
    return () => {};
  }, [location]);

  return (
    <div
      className="disableSelection"
      id="container"
      style={{
        border: "2px solid #000000",
        height: props.height,
        width: props.width,
        margin: props.margin,
        overflow: "scroll",
        backgroundSize: "contain",
        backgroundImage:
          props.user.subscription === "trial"
            ? "url(" + waterMarkimg + ")"
            : "none",
        backgroundSize: "contain",
      }}
    >
      <h2
        className="objectName"
        style={{ color: "#222", fontWeight: "bold", margin: "3vh" }}
      >
        {structure?.main?.name || "Loading..."}
      </h2>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          rowGap: "8px",
          height: "100%",
        }}
      >
        {structure?.assignments?.map((element) => {
          function mouseOut(e) {
            e.currentTarget.style.outline = "0px";
          }
          function mouseOver(e) {
            e.currentTarget.style.outline = "2px dotted  #3e83fa";
          }
          async function onSingleClickModel(e) {
            /*  const attribute = {
              name: element.name || "",
              description: element.Description || "",
              type: element?.Type || "",
            };
            props.setPropertiesAttribute(attribute); */

            let attributes = await getProcessAttributesByPartialName(
              element.name
            );
            props.setAttributes(attributes);

            //----------------- occurance for classic model-------------------------------

            let occs = await getProcessOccurences(element.GUID);
            props.updateOccurences(occs);

            //----------------- Relationships for classic model-------------------------------

            let rels = await getProcessRelationships(element.GUID);
            props.updateRelationships(rels);
          }

          return (
            <div
              key={element.GUID}
              onMouseEnter={mouseOver}
              onMouseLeave={mouseOut}
              onClick={onSingleClickModel}
              onDoubleClick={() => {
                if (
                  quickCheckAccess(element?.inTrial, props.user.subscription) &&
                  element?.hasAssignment === true
                )
                  history.push(`${props.path}/model/${element.GUID}`);
              }}
              style={{
                fill: "white",
                fillOpacity: "0.95",
                border: "2px solid #000000",
                minWidth: "70%",
                maxWidth: "70%",
                minHeight: "45px",
                maxHeight: "6%",
                textAlign: "center",
                cursor: "pointer",
                borderRadius: 10,
                alignItems: "center",
                position: "relative",
                display: "flex",
                // minWidth: '50%',
                textOverflow: "ellipsis",
                backgroundColor: "white",
              }}
            >
              <div
                className="noselect"
                style={{
                  margin: "auto",
                  fontFamily: "sans-serif!important",
                  alignItems: "center",
                  fontSize: "12px",
                  verticalAlign: "middle",
                  textAlign: "center",
                  overflow: "hidden",
                  whiteSpace: "break-spaces",
                }}
              >
                {" "}
                {element.name}{" "}
              </div>

              {/* Green BOX + */}
              {quickCheckAccess(element?.inTrial, props.user.subscription) &&
              element?.hasAssignment === true ? (
                <div
                  style={{
                    position: "absolute",
                    top: -8,
                    left: -15,
                    borderRadius: 3,
                    height: "20px",
                    width: "20px",
                    border: "2px solid #52b414",
                    textAlign: "center",
                    backgroundColor: "white",
                    margin: "auto",
                    fontFamily: "sans-serif",
                    alignItems: "center",
                    fontSize: "12px",
                    verticalAlign: "middle",
                    textAlign: "center",
                    color: "#52b414",
                  }}
                >
                  {" "}
                  +
                </div>
              ) : null}
            </div>
          );
        })}
      </div>
    </div>
  );
}
