import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Common from "./Common";
import slider1 from "../images/Business Flows Illustration.jpg";
import slider2 from "../images/bpExperts_domain.jpg";
import slider3 from "../images/Pyramid.jpg";
import { Alert, Dropdown } from "react-bootstrap";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { requestProlongation, connectDb, connectInitialDb,getRootGUID } from "../actions";

const Home = (props) => {
  const history = useHistory();
  const [firstRender, setFirstRender] = useState(true);
  const [open, setOpen] = useState(false); // Snackbar state
  const [rootGUID, setRootGUID] = useState(null);
  const [content, setContent] = useState({
    severity: "success",
    msg: "successfully changed!",
  }); // Alert message
  const [alertBox, setAlertBox] = useState({
    show: false,
    variant: "warning",
    msg: "",
  });

  useEffect(async () => {
    if (firstRender) {
      handleFirstRender();
      setFirstRender(false);
    }
    const rootGUID = await getRootGUID();
    setRootGUID(rootGUID);
  }, [firstRender]);

  const handleFirstRender = () => {
    if (!props.user.id) {
      alert("No signed-in user, logging out..");
      localStorage.clear();
      history.push("/login");
      return;
    }

    const database = localStorage.getItem("db");
    if (database && database !== props.user.mainDatabase) {
      props.setUser({ ...props.user, mainDatabase: database });
      history.push(`/home/${database}`);
    }
  };

  const handleClose = (event, reason) => {
    if (reason !== "clickaway") {
      setOpen(false);
    }
  };

  const getCurrentDate = () => {
    const date = new Date();
    return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
  };

  const diffDays = (date, otherDate) =>
    Math.ceil(
      Math.abs(new Date(date) - new Date(otherDate)) / (1000 * 60 * 60 * 24)
    );

  const resetAlertBox = () => {
    setAlertBox({
      show: false,
      variant: "warning",
      msg: "",
    });
  };

  const accountExtensionRequest = async () => {
    const res = await requestProlongation(props.user.email);
    setAlertBox({
      show: true,
      variant: res.success ? "warning" : "danger",
      msg: res.success
        ? res.msg
        : "Internal server problem, please try again later",
    });
    setTimeout(resetAlertBox, 4000);
  };

  const isSafari =
    /Safari/.test(navigator.userAgent) &&
    /Apple Computer/.test(navigator.vendor);

  const browserWarning = isSafari && (
    <div
      className="alert alert-danger alert-dismissible fade show col-10"
      role="alert"
    >
      <p className="mb-0" style={{ textAlign: "center" }}>
        Right now, you are using the Apple Safari browser. For a better
        experience, kindly use Google Chrome or Mozilla Firefox.
      </p>
    </div>
  );

  const subscriptionAlert = (
    <div
      className="alert alert-dark alert-dismissible fade show col-10"
      role="alert"
    >
      <p style={{ textAlign: "center" }}>
        {props?.user?.subscription === "trial" ? (
          <>
            Hello,&nbsp;{props?.user?.firstName}&nbsp;{props?.user?.lastName}.
            You have
            <strong>
              {" "}
              {diffDays(props?.user?.expirationDate, getCurrentDate())}{" "}
            </strong>
            days until your {props?.user?.subscription} subscription expires.
            For extension, please request to&nbsp;
            <button
              className="badge badge-dark"
              type="submit"
              onClick={accountExtensionRequest}
            >
              &nbsp;Extend Account&nbsp;
            </button>
          </>
        ) : (
          <>
            Hello,&nbsp;{props?.user?.firstName}&nbsp;{props?.user?.lastName}.
            Welcome to Business flows
            <strong> {props?.user?.subscription} </strong>edition.
          </>
        )}
      </p>
      {props?.user?.subscription !== "ultimatePlus" && (
        <p className="mb-0" style={{ textAlign: "center" }}>
          For the subscription upgrade, kindly&nbsp;
          <button className="badge badge-dark">
            <a
              className="text-light bg-dark"
              href="mailto:sales@bpexperts.de?subject=Business Flows Browser Request for Update Subscription"
            >
              &nbsp;Contact us&nbsp;
            </a>
          </button>
        </p>
      )}
      <button
        type="button"
        className="close"
        data-dismiss="alert"
        aria-label="Close"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
  );

  const handleDatabaseSelection = async (db) => {
    props.setUser({ ...props.user, mainDatabase: db });
    history.push(`/home/${db}`);
    if (db === "auradb") {
      localStorage.setItem("db", "neo4j");
      const response = await connectDb();
      if (response.success) {
        // alert(response.msg + "----->" + db);
        setContent({
          severity: response.success ? "success" : "error",
          msg: response.msg,
        });
      }
    } else {
      localStorage.setItem("db", db);
      const response = await connectInitialDb();
      if (response.success) {
        // alert(response.msg);
      }
    }

    history.go(0);
  };

  return (
    <>
      <div className="d-flex flex-column align-items-center" style={{zIndex:1}}>
        {browserWarning}
        {subscriptionAlert}
        <Dropdown>
          <Dropdown.Toggle variant="success" id="dropdown-basic">
            {props.user.mainDatabase || "Select Database"}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {props.user.databases.map((database) => (
              <Dropdown.Item
                key={database}
                onClick={() => handleDatabaseSelection(database)}
              >
                {database}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
        <Alert
          className="alert alert-info alert-dismissible col-10 fade in"
          align="center"
          variant={alertBox.variant}
          show={alertBox.show}
        >
          {alertBox.msg}
        </Alert>
      </div>
      <Snackbar open={open} autoHideDuration={3000} onClose={handleClose}>
        <MuiAlert
          onClose={handleClose}
          severity={content.severity}
          elevation={6}
          variant="filled"
        >
          {content.msg}
        </MuiAlert>
      </Snackbar>
      <div className="d-flex flex-column align-items-center justify-content-start">
        <Common
          name="Boost your business transformation with"
          visitBF={`${props.path}/model/${props.e2eMap.GUID}`}
          visitRE={`${props.path}/object/${rootGUID}`}
          visitAS={`${props.path}/advance-search`}
          visitChatBot={props.path}
          btnameBF="Access Business Flows"
          btnameRE="Relationship Explorer"
          btnamechatBot="Business Flows Assistant"
          btnameAdvanceSearch="Advance Search"
          slider1={slider1}
          slider2={slider2}
          slider3={slider3}
          {...props}
        />
      </div>
    </>
  );
};

export default Home;
