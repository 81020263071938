import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <>
      <footer className="container-fluid nav_bg  footerFix">
        <div className="row" style={{ flex: 1, backgroundColor: "#fff" }}>
          <div className="col-10 mx-auto">
            <hr />
            <div style={{ color: "#000", fontWeight: "700" }}>
              &copy; {new Date().getFullYear()} bpExperts
            </div>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.bpexperts.de/impressum"
            >
              Impressum
            </a>
            &nbsp;| &nbsp;
            <a href="mailto:sales@bpexperts.de?subject=Business Flows Browser">
              Contact Us
            </a>
            &nbsp;| &nbsp;
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.bpexperts.de/gtc"
            >
              Terms and Conditions
            </a>
            &nbsp;| &nbsp;
            <Link to="/release-notes"> Release Notes and New Features </Link>
            &nbsp;| &nbsp;
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.bpexperts.de/privacy-statement"
            >
              Privacy Statement
            </a>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
