const libraryDump = {
  BPMN_GUID: "ea0738b1-4853-11ec-0d64-0201f3c99a7b",
  Type: "Functional Process Map",
  _type: "Library",
  GUID: "ea0738b1-4853-11ec-0d64-0201f3c99a7b",
  name: "Functional Process Map",
  _id: 1185,
  cluster_of: [
    {
      BPMN_GUID: "5a18229f-4c7c-4879-8210-fc5c94682d39",
      Type: "Process Cluster",
      _type: "Cluster",
      name: "D. Source",
      GUID: "916f6021-2569-11e9-2ddf-001bc640cd9b",
      group_of: [
        {
          BPMN_GUID: "94a1c761-8ddc-11e5-0a66-001bc640cd9b",
          Type: "Process Group",
          Description:
            "This process group covers all procurement activities which have an operational focus. This includes the creation of purchase requisitions, creation, approval and issue of purchase orders and the monitoring of the purchase orders.",
          _type: "Group",
          name: "Procurement",
          GUID: "94a1c761-8ddc-11e5-0a66-001bc640cd9b",
          _id: 2156,
          process_of: [
            {
              BPMN_GUID: "NULL",
              Description:
                "In this process the requestor uses a vendor portal system for placing purchasing requests. The same tool is used also for approving the placed requests.    ",
              _type: "Process",
              GUID: "aa7edcec-9fc3-11e8-2ddf-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "Requestor places purchase request via a vendor portal, which typically has an interface to the ERP system.The materials from the portal are previously defined by procurement, their terms agreed with vendors and rules control, which items are available for choice for a particular requestor. Required approval workflow is also managed through the catalogue system.",
              Outcome:
                "Purchase request is placed in the vendor portal and approved.",
              name: "Create and approve purchase request via vendor portal",
              _id: 286,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "An official purchase order (PO) has to be created from a purchase requisition (PR) to be able to order materials and services.       ",
              _type: "Process",
              GUID: "de59c987-7efd-11e5-0a66-001bc640cd9b",
              Advisory_Notes:
                " ***set-up of possible conversions must be defined. Conversion options are single, multiple or partial ***purchasing strategies for vendors need to be considered (e.g. fair share, contingents)  ***special agreements with vendors need to be considered (e.g. FTL/FCL only, min/max quantities, multiple products to be bundled) ",
              Type: "Business Process",
              Scope:
                "The PO is created with reference to a specific PR. Additional data not provided within the PR has to be maintained.",
              Outcome: "A PO is generated.",
              name: "Convert purchase requisition to purchase order",
              _id: 1193,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "A purchase order is an external document and issued by a procuring organization to a supplier indicating types, quantities, and agreed prices for products or services.    In procurement via catalogue scenario all items available for purchasing and included into catalogue are controlled by procurement. Therefore no separate approval of purchase order at a later stage is required. Nevertheless purchase order must be checked before the issue on absence of trade compliance blocks.",
              _type: "Process",
              GUID: "7d8dc859-e8c6-11e4-0a66-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "After global trade checks have been performed for a particular purchase order, it is checked if any blocks have been set and the purchase order is issued to vendor.",
              Outcome: "Purchase order is issued.",
              name: "Issue purchase order",
              _id: 1217,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "A purchase requisition (PR) acts as the trigger for the purchasing department to perform the required purchase of direct materials. Therefore, the purchasing department needs to check who can deliver the material and if the supplier can meet the necessary purchasing conditions. Often the supply network for continuously required direct materials is defined and already considered by the planning tool. Thus, the processing can be automated.              ",
              _type: "Process",
              GUID: "26aa6cf5-7f07-11e5-0a66-001bc640cd9b",
              Advisory_Notes:
                "Even if APO is used for supply chain planning, quite often the ERP MRP run will finally generate the PRs on a local (=plant) level. However, PRs can also be generated and transferred to the ERP by the APO.",
              Type: "Business Process",
              Scope:
                "Processing the PR checks if the purchase order can be fulfilled. Delivery conditions, payment methods, and potential suppliers who can deliver the material, are checked. Once the purchasing department has all relevant information, the conversion of the PR into a purchase order (PO) can be triggered.",
              Outcome: "The PR is ready to be converted to a PO.",
              name: "Process purchase requisition for direct materials",
              _id: 1210,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "The purchase order (PO) for VOI stock is created with information received from the vendor regarding replenishment. This PO is a formal request to the vendor for the products and initiates the official purchasing process. Information from the vendor notification is incorporated into the PO.",
              _type: "Process",
              GUID: "1b7433ce-9fd9-11e8-2ddf-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "Once the purchaser notifies that a specified quantity of stock is needed, the PO is created based on the information provided. The PO is issued to the vendor, who then ships out the goods.",
              Outcome:
                "The purchase order complies with the applicable trade regulations and the goods can be purchased and imported.",
              name: "Create and issue purchase order for VOI",
              _id: 289,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "The approval process for purchase requisition (PR) for one-time services confirms that the specific service can be purchased by the requester and performed by the identified service provider. Moreover, the duration of the service to be performed and other related terms and conditions are verified.  ",
              _type: "Process",
              GUID: "fbfd3e95-7f05-11e5-0a66-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "The purchasing department verifies that the service can be purchased by the requester and that all necessary information regarding the service is recorded, including type and duration of service as well as vendor.   ",
              Outcome:
                "The PR for one-time services is ready to be converted into a purchase order (PO).",
              name: "Approve purchase requisition for one-time services",
              _id: 296,
              Business_Rules:
                "It needs to be clarified what will happen if the PR is not converted into a PO because:   ***the price for the service exceeds the budget   ***the purchase of services is not required     It is necessary to define what services a department is allowed to purchase.",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "By using the Ariba network all documents required are exchanged electronically. These documents can be: ***Purchase requisition  ***Purchase order ***Order confirmation ***Advanced shipping notification ***Goods receipt ***Invoice",
              _type: "Process",
              GUID: "a30b1b18-972c-11e9-0cfc-001bc641ce4f",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "After the supplier receives the PO via the Ariba network, the order confirmation can be created, if required. The confirmation data is transferred to the procuring organization and the PO is updated.  To inform the procuring organization about  a pending delivery and details on e.g. the schedule and physical characteristics of the shipment, the supplier creates an Advanced Shipping Notification (ASN). Also the ASN details are exchanged via the Ariba network and the the PO is updated accordingly.  At any time, the procuring company is able to monitor the suppliers updates via the the network. ",
              Outcome:
                "PO is enriched with order confirmation data and ASN details. ",
              name: "Exchange electronic documents via Ariba network",
              _id: 291,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "When purchasing subcontracting services, a requisition must be sent to the purchasing department that checks to see which subcontractor can manufacture the required products under the corresponding purchasing conditions.        ",
              _type: "Process",
              GUID: "de59c96c-7efd-11e5-0a66-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "Potential subcontractors that can manufacture the required products under desired conditions are checked. Once the purchasing department confirms that all relevant information is available for the fulfilment of the order, the purchase requisition (PR) is ready to be converted to a purchase order (PO). ",
              Outcome: "The PR is ready to be converted to a PO.",
              name: "Process subcontracting purchase requisition",
              _id: 1200,
              Business_Rules:
                "Rules must be defined regarding how materials can be provided to fulfil a specific order.",
              Prerequisite:
                "Pre-requisites:  - There must be predefined contracts with subcontractors. - A list of subcontractors and the specific services they provide must be available",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "Before returnable packaging can be sent back to the vendor, the vendor must provide confirmation that the materials will be picked-up upon delivery on a specified date. The information is captured and processed to update the existing data pertaining to the return.   ",
              _type: "Process",
              GUID: "9595686f-7f0c-11e5-0a66-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "After information has been sent to the vendor regarding the return of the packaging units, the vendor acknowledges the return and confirms when and where the packaging materials will be picked-up. The information from the vendor updates the existing data on the return of the specified packaging units. This triggers the logistics process.",
              Outcome:
                "All relevant information regarding the pick-up is available and the returnable packaging can be sent to the vendor",
              name: "Receive and process returnable packaging pick-up information",
              _id: 287,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "The purchase requisition (PR) for services is a notification that informs the purchasing department that additional services, e.g. for a specific project, are required.",
              _type: "Process",
              GUID: "529c6282-7f06-11e5-0a66-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "It must be determined where external resources are needed and what availability is required to perform the service. The requisition is triggered by the user department. The PR for services can contain a set of service specifications with details of the necessary service or group of services. It can also be very basic with a superficial provision for unplanned services. This can be the case for hiring external employees. In this case, the extent and the precise nature of the service are not fully determined yet.",
              Outcome:
                "The PR includes all required information:   ***specific value (max. price)   ***services with certain skills   ***time frame   ***reason, stating why a service needs to be purchased  ",
              name: "Create purchase requisition for standard services",
              _id: 1235,
              Business_Rules:
                "The reasons, stating why purchasing a service is necessary to achieve a certain task, must be clearly defined.   ***How are the services purchased e.g. via a bidders platform?   ***What if the price of the service exceeds the budget e.g. of a project?   ***How is the internal hiring process started?   ***How is the bid procedure performed?   ***How were the quotations compared?  ",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "In a cross-company replenishment scenario, no purchase orders are used. Instead a stock transport order (STO) is used to depict the fact that goods delivery is expected from another legal entity of the group.     ",
              _type: "Process",
              GUID: "fbef921c-e8d4-11e4-0a66-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "An STO is created (converted from an STR), which documents the fact that materials will be received from a cross-company vendor. The kind and quantity of materials to be transferred and received are specified in the transport order, as well as the specified storage location and the point in time for planned goods receipt. Pricing follows the same logic as for purchasing from external vendors.",
              Outcome:
                "An STO is created triggering and documenting the expected inventory transfer.",
              name: "Create stock transport order",
              _id: 1230,
              Business_Rules:
                "Approval requirements for stock transfer orders need to be defined.",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "Advance procurement must be carried out at the very beginning of the project execution. In this process ProMan is used to control the activity. Sometimes long lead time procurement is triggered before the order is confirmed. Although whenever possible a supplier will avoid ordering project-specific materials beforehand, in some situations it is required.     ",
              _type: "Process",
              GUID: "ab66384a-7e4e-11e5-0a66-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "The data for needed material (of long lead time material) is checked, and whether its planned demand will be met. With the release of this activity the demand for (long lead time) items are created for the MRP run.",
              Outcome:
                "A purchase order is created for the procurement of the required materials.",
              name: "Perform advance procurement",
              _id: 201,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "After a pre-defined submission deadline for quotations the best supplier quotes are identified according to the companys requirements. Several checks support the selection, e.g. ***Check for formal completeness and correctness ***Technical/functional prerequisites ***Commercial check",
              _type: "Process",
              GUID: "4efe97e0-974b-11e9-0cfc-001bc641ce4f",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope: "",
              Outcome: "",
              name: "Award supplier quote",
              _id: 285,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "When creating the purchase order (PO) for purchases based on a contractual agreement with the supplier, the agreed terms, conditions and prices stated in the supplier contract are applied to the order.      ",
              _type: "Process",
              GUID: "2164f397-7f0b-11e5-0a66-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "The PO is created with information from the purchase requisition as well as details regarding the terms and conditions specified in the supplier contract. The PO is then forwarded for approval.",
              Outcome:
                "A PO is generated. This is an official document that - once it has been approved - can be forwarded to the supplier.",
              name: "Convert purchase requisition for contract into purchase order",
              _id: 1207,
              Business_Rules:
                "The set-up of possible conversions must be defined.    Conversion options are:   ***single   ***multiple   ***partial  ",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "After a demand for materials is identified, a purchase requisition (PR) is created to capture the details of the demand. The PR is an internal document and its details include e.g. the quantity and timeframe for the required material.  The approved PR then is the trigger for the procurement department to perform the purchase of the required goods.              ",
              _type: "Process",
              GUID: "31aa9d57-b9cd-11e9-0cfc-001bc641ce4f",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "The creation of a PR takes place in SAP Ariba. After submitting the the PR for approval, an automated transfer of the document will be send to SAP S4/HANA and therefore be created also in the ERP system with status In Release. Simultaneously, the approver can check if all relevant information is included in the PR and approve the document.  In S4/HANA, all information and progress of the PR can be monitored at any point in time.",
              Outcome: "The approved PR is ready to be converted into a PO. ",
              name: "Create and approve purchase requisition (Ariba)",
              _id: 288,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "In this process the requestor uses an electronic catalogue system/vendor portal for placing purchasing requests. The same tool is used also for approving the placed requests.    ",
              _type: "Process",
              GUID: "bdc1eaf3-7f03-11e5-0a66-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "Requestor places purchase request in an electronic catalogue system/vendor portal, which is not part of ERP. The materials included into the catalogue/vendor portal are previously defined by procurement, their terms agreed with vendors and rules control, which items are available for choice for a particular requestor. Required approval workflow is also managed through the catalogue system/vendor portal.",
              Outcome:
                "Purchase request is placed in the catalogue/vendor portal and approved.",
              name: "Create and approve purchase request via catalogue/vendor portal",
              _id: 1222,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "The purchase order (PO) for VMI stock is created with information received from the vendor regarding replenishment. This PO is a formal request to the vendor for the products and initiates the official purchasing process. Information from the vendor notification is incorporated into the PO.    ",
              _type: "Process",
              GUID: "fcf412ef-7f0b-11e5-0a66-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "Once the vendor notifies that a specified quantity of stock is needed, the PO is created based on the information provided. The PO is issued to the vendor, who then ships out the goods.",
              Outcome: "The PO for VMI stock is sent to the vendor.",
              name: "Create and issue purchase order for VMI stock",
              _id: 284,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "A purchase requisition (PR) has to be created from the purchase request with additional information of the purchase. ",
              _type: "Process",
              GUID: "564ad661-7f04-11e5-0a66-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "The PR can be derived from an individual demand and includes basic information of the type of indirect materials and which quantity is needed.    This could be:   ***material   ***quantity    ***delivery date   ***estimated price   ***vendor (if known)   ***procurement type (e.g. K for cost center)  ",
              Outcome:
                "A PR with the necessary details is created and ready for the approval workflow through the purchasing department.",
              name: "Create purchase requisition for indirect materials",
              _id: 292,
              Business_Rules:
                "It needs to be ensured that the information on the PR is complete and up to date. This includes for example:   ***When the PR was created   ***Why the PR is required   ",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "Request for Quotation (RFQ) is used to enquiry in market to obtain quotations from prospective suppliers. RFQ can be created with reference to a Purchase Requisition, Outline Agreement (Contract) and another RfQ. RFQs are sent later to SAP Ariba Sourcing, where the bidders and suppliers can place their bids.",
              _type: "Process",
              GUID: "4efe97d7-974b-11e9-0cfc-001bc641ce4f",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope: "",
              Outcome: "",
              name: "Create Request for Quotation",
              _id: 290,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "Replenishment scenarios are based on a set of agreements between the individual legal entities of a group, which are reflected in the supply chain set-up and related master data. The processing of the stock requisition (STR) for replenishment involves taking information on material requirements and mapping them to a predefined supplying entity. This forms the basis for delivery scheduling.",
              _type: "Process",
              GUID: "81702a09-7eff-11e5-0a66-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "When the PR is received, the purchasing department records the required amount of materials based on planning data and records which predefined group vendor will supply the materials. The STR is then reviewed and approved accordingly. ",
              Outcome:
                "The STR is approved and forwarded for further processing. ",
              name: "Process stock transport requisition",
              _id: 1226,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "Once issued to vendor the purchase order represents a firm commitment. Therefore, it must be approved before the issue and then transferred to vendor.       ",
              _type: "Process",
              GUID: "de59c972-7efd-11e5-0a66-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "Before committing to the actual purchase of materials or services, an authorised person does a final check if the purchase order is correct and approves it. The PO will then be sent to the vendor.",
              Outcome: "The purchase order is issued.",
              name: "Approve and issue purchase order",
              _id: 1189,
              Business_Rules: "Approval rules and limits have to be defined.",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "Monitoring purchase order (PO) process ensures - through communication with the vendor - that the correct material is sent and can be/is delivered on time, and in full quantity.     ",
              _type: "Process",
              GUID: "dfa9a1e2-7efd-11e5-0a66-001bc640cd9b",
              Advisory_Notes:
                "The monitoring scope and necessary details must be defined depending on material market availability and importance for the supply chain (e.g. monitoring for deficit components might be stricter then for widely available materials). Rules for sending reminders to vendor (timing etc.) must be defined",
              Type: "Business Process",
              Scope:
                "After the PO has been sent to the vendor, it needs to be tracked. The vendor hereby sends an order acknowledgement to confirm the receipt of the PO. Furthermore, the delivery date is tracked and, in case of imported material, the estimated time of arrival (ETA) as well as the estimated time of sailing is provided by the vendor. In addition, the timely provision of quality certificate is monitored. In case of delays or missing information on the vendors side, reminders and dunning letters can be issued.",
              Outcome:
                "Purchase order status and delivery of goods, documents and additional information has been monitored.",
              name: "Monitor purchase order",
              _id: 1191,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "Processing the purchase order with returnable packaging information ensures that the cost, quantity and other conditions related to the returnable packaging material are recorded in the purchase order (PO).     ",
              _type: "Process",
              GUID: "ba511803-7f0c-11e5-0a66-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "When returnable packaging is received from a vendor along with products, information about the quantity and value of the returnable packaging, as well as return conditions and the vendor to which the packaging is to be returned must be captured in the PO and forwarded for further processing.",
              Outcome:
                "Returnable packaging information is recorded in the PO.",
              name: "Process purchase order with returnable packaging",
              _id: 283,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "Purchase requisition (PR) is created in the ERP based on data from the purchase request within the catalogue system or vendor portal.",
              _type: "Process",
              GUID: "af3b5880-514a-11e5-0a66-001bc640cd9b",
              Advisory_Notes:
                "***Items need to be included into catalogue and controlled by procurement, which means, they contain all information necessary to make the purchase (vendor information, product information etc.).   ***Purchase request can be directly converted to a purchase requisition in the ERP system without any manual intervention only if catalogue content is maintained correctly.",
              Type: "Business Process",
              Scope:
                "Information from the purchase request in the catalogue system or vendor portal is transferred to the ERP system in order to create a corresponding purchase requisition (PR) there. During this process the existence of the requested materials within the ERP is checked.",
              Outcome:
                "Purchase requisition is created and can be processed in ERP.",
              name: "Convert purchase request to purchase requisition",
              _id: 1219,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "When returnable packaging material sent by a vendor is at the storage location of the purchasing entity, it is necessary to track the packaging units in order to:   ***know when the packaging must be sent back to the vendor,   ***know what units are at which storage location,   ***record when packaging was received and when it was sent out.      ",
              _type: "Process",
              GUID: "95956860-7f0c-11e5-0a66-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "After the receipt of packaging units has been captured, all relevant information concerning the packaging is collected and recorded. With this information the movement of the packaging is tracked until it is sent back to the vendor. ",
              Outcome:
                "Returnable distribution packaging is being tracked and can be located if needed.",
              name: "Track packaging units",
              _id: 295,
              Business_Rules:
                "***The decision must be made regarding what information is required for tracking.   ***It is necessary to define what movements of the packaging will be tracked e.g. only movement to/from vendor, movement between storage locations owned by the purchasing entity.   ",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "Once terms for purchasing of one-time service have been agreed, a purchase requisition (PR) must be created to capture information about the procured service in the ERP system.   ",
              _type: "Process",
              GUID: "fbfd3e93-7f05-11e5-0a66-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "Since the purchased service is not regularly purchased, its details are not contained in the ERP system and cant be included into the PR. Therefore the purchase requisition must have reference to a statement of work, previously negotiated vendor proposal or other documents stating content and terms of the service.",
              Outcome:
                "PR for services with necessary details is created and ready for the approval workflow.",
              name: "Create purchase requisition for one-time services",
              _id: 293,
              Business_Rules: "",
              Prerequisite:
                "Pre-requisites: request for proposal process has been performed if required, vendor proposal has been received, its terms negotiated and approved by purchasing department.",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "An official purchase order (PO) has to be created from a purchase requisition (PR) to be able to order materials. It is a formal request or instruction from a purchasing organization to a vendor to supply or provide a certain quantity of materials at or by a certain point in time",
              _type: "Process",
              GUID: "31aa9d59-b9cd-11e9-0cfc-001bc641ce4f",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "Upon approval of a PR, a PO is created based on it. An automated notification on the creation of the PO in SAP Ariba is then sent to the S4/HANA system. Based on this message the PO is also created in the ERP system and a PO number is generated. This information is sent back to Ariba where the PO number is added to the document in Ariba. The updated PO is ready to be issued to the supplier via the Ariba network. ",
              Outcome: "The PO is issued. ",
              name: "Create purchase order (Ariba)",
              _id: 294,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "A purchase requisition (PR) has to be created with detailed information about the asset to be purchased.",
              _type: "Process",
              GUID: "22f82391-94bc-11e8-2ddf-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "The PR can be derived from an individual demand and includes basic information of the type of asset and which quantity is needed. This could be:   ***material   ***quantity   ***delivery date   ***estimated price   ***vendor (if known)   ***procurement type (e.g. K for cost center) ***information about the related cost object.",
              Outcome:
                "PR has been created for an asset and can be handed over for approval.",
              name: "Create purchase requisition for asset",
              _id: 1244,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "A purchase requisition (PR)  is created after a sales order has been confirmed. The purchasing department must check which vendor can supply the goods. The goods are delivered directly to the customer, therefore it is necessary to clarify the terms of delivery with the vendor.        ",
              _type: "Process",
              GUID: "78ced096-7f02-11e5-0a66-001bc640cd9b",
              Advisory_Notes:
                "Purchasing products directly for a sales order is used in a variety of business models. It can be a one time opportunity, a frequently applied model, or even a type of outsourced inventory in a JIT-like set-up. Therefore, the interaction between the sales department, the purchasing department, and the vendor in order to clarify availability and delivery dates might differ. In a opportunity sale, it might even be required to identify a source of supply before any commitment to the customer is given.",
              Type: "Business Process",
              Scope:
                "If not already specified by master data, the purchasing department checks vendors to determine which one can supply the products required to fulfil the order.",
              Outcome: "The PR is created.",
              name: "Process purchase requisition for sales orders",
              _id: 1188,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "Before the created purchase requisition (PR) can be processed further, it needs to pass an approval workflow to ensure, that no un-necessary purchases are made.  ",
              _type: "Process",
              GUID: "564ad663-7f04-11e5-0a66-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "Typically approval of a purchase requisiton involves two stages - budget approval and content approval. Budget approval confirms, that there are sufficient funds for the intended purchase and lies within responsibility of the budget owner for the requesting department. Content approval confirms the necessity of the purchase and is performed by subject matter expert (e.g. IT manager for IT related spending).",
              Outcome:
                "Purchase requisition has been approved and can be further processed by purchasing department.",
              name: "Approve purchase requisition",
              _id: 1245,
              Business_Rules:
                "To eliminate possibility of maverick spending, rules must be established regulating approvals requirements (e.g. spending exceeding set amount require a higher instance approval). To reduce unnecessary approval workload rules must be established on when which approvals are required (e.g. only spending exceeding predefined amounts require extra approval).",
              Prerequisite: "",
            },
          ],
        },
        {
          BPMN_GUID: "c8986421-8ddc-11e5-0a66-001bc640cd9b",
          Type: "Process Group",
          Description:
            "This process group covers all processes in the field of supplier relationship management starting from performance evaluation up to development measurements, that are taken to improve the performance. Supplier selection is covered in a separate group.",
          _type: "Group",
          name: "Supplier Relationship Management",
          GUID: "c8986421-8ddc-11e5-0a66-001bc640cd9b",
          _id: 2155,
          process_of: [
            {
              BPMN_GUID: "NULL",
              Description:
                "Through evaluation, the organization can qualify suppliers to ensure that their capabilities have been verified. The result of the evaluation forms the basis for the negotiation process with the supplier.    It is possible to assign different weights to the individual criterias. The vendors overall score is calculated taking into account the weighted scores awarded for each of the main criteria. ",
              _type: "Process",
              GUID: "915d6e93-7f0d-11e5-0a66-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "The data, statements, records, operations and performances of a supplier are maintained in the system and examined in order to verify the suppliers validity and support the decision on whether or not to enter into a business relation with the supplier.    The audit can include criteria such as:   ***checking that the supplier is capable of producing the product or providing the service with adequate quality standards   ***verifying that the supplier is conforming to required environmental regulations and international standards   ***checking if the information available about the supplier is comprehensive enough to begin the negotiation process    ",
              Outcome: " Negotiations with identified supplier can begin.",
              name: "Evaluate vendor responses",
              _id: 301,
              Business_Rules:
                "It is necessary to establish a framework for the audit i.e.    ***What information will be used?   ***How will the audit be operationalised?   ***Who will perform the audit?   ***How will the results be communicated and used in the selection of suppliers?  ",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "After quality results of all inbound goods are defined and tracked, the information regarding the vendor shall be updated to ensure a constant quality for the delivered products or goods.",
              _type: "Process",
              GUID: "fabfdc69-e86b-11e4-0a66-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "The process is triggered by the final decision of the usage of the delivered goods.  The result shall have an influence on sourcing considerations and any further negotiations with the supplier, as well as should be used to commonly further develop the vendors quality management system.",
              Outcome:
                "A track record on quality related data and KPIs is established.",
              name: "Update quality information in vendor record",
              _id: 21,
              Business_Rules: "",
              Prerequisite: "",
            },
          ],
        },
        {
          BPMN_GUID: "8728aea1-8ddc-11e5-0a66-001bc640cd9b",
          Type: "Process Group",
          Description:
            "This process group covers all processes involved in sourcing including identification and qualification of suppliers as well as contract and price negotiations.",
          _type: "Group",
          name: "Sourcing",
          GUID: "8728aea1-8ddc-11e5-0a66-001bc640cd9b",
          _id: 2154,
          process_of: [
            {
              BPMN_GUID: "NULL",
              Description:
                "The final decision regarding which supplier will provide the organization with the requested products or services has to be made in order to initiate collaboration with the supplier and confirm the new source of products or services.   ",
              _type: "Process",
              GUID: "915d6eb7-7f0d-11e5-0a66-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "Based upon the negotiations with potential suppliers, an assessment is carried out to determine which one is most suitable to fulfill the requests for products or services. A supplier is selected and informed about the decision. ",
              Outcome:
                "The supplier that will provide a specified product or service is chosen and the contracting process can begin.",
              name: "Evaluate vendors and make final decision",
              _id: 311,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "Gathering information regarding products and services required by the organization is a key first step of the purchasing process. Through this, details on the specific product and service needs of the various department within the organization are communicated and documented accordingly.     ",
              _type: "Process",
              GUID: "915d6e8a-7f0d-11e5-0a66-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "Before a potential supplier can be identified, details regarding the specific products or services needed are collected and recorded. The purchasing department ensures that all departments communicate their requirements accurately and comprehensively. The information is captured in a reliant format for further processing. ",
              Outcome:
                "Comprehensive information on products and services required by the various departments is available.",
              name: "Gather product/service information",
              _id: 308,
              Business_Rules:
                "It is necessary to define:   ***What information is required on the products and services   ***How the information is collected and stored  ",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "Requests For Quotation (RFQ) are suited for goods or services that are as standardized as possible.   A formal request for proposal (RFQ) is sent to potential suppliers. It contains a description of all relevant parameters of the intended purchase, such as:   ***Part or service descriptions/specifications   ***Quantities/Volumes   ***Description or drawings   ***Quality levels or personnel skills, training level or competencies   ***Delivery requirements   ***Term of contract   ***Terms and conditions   ***Other value added requirements or terms   ***Draft contract   As the request is centered around a standard offering the most important dimension for ranking suppliers will be the price per item or per unit of service, with other dimensions playing a secondary role.  ",
              _type: "Process",
              GUID: "915d6e90-7f0d-11e5-0a66-001bc640cd9b",
              Advisory_Notes:
                "RFQs are typically used as supporting documentation for sealed bids (either single-round or multi-round) and may be a logical pre-cursor to an electronic reverse auction.",
              Type: "Business Process",
              Scope:
                "An RfQ is created and issued to potential vendors. The vendor responses to the RfQ are collected.  ",
              Outcome: "Vendor responses to RfQ is available.",
              name: "Request quotation from vendors",
              _id: 309,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "Before purchasing goods under a contractual agreement with the supplier, it is necessary to define the conditions and set the terms under which the goods will be provided. The purchasing department must understand exactly what products (e.g. goods or services) need to be purchased and in what quantity, as well as select the supplier and prepare the contracting details with the supplier.      ",
              _type: "Process",
              GUID: "ec357bb3-7e11-11e5-0a66-001bc640cd9b",
              Advisory_Notes:
                "Typically at the end of the negotiations the  vendor master data and other master data is initiated if  required.",
              Type: "Business Process",
              Scope:
                "Based on the products and quantity that needs to be purchased, a suitable supplier is selected. Specific terms and conditions related to the purchase are negotiated and a contract document prepared.",
              Outcome: "The contract with the supplier is approved.",
              name: "Perform contracting",
              _id: 1206,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "Purpose of this process is to resolve production capacity shortages by plant specific means.",
              _type: "Process",
              GUID: "e7888668-61e5-11e9-2ddf-001bc640cd9b",
              Advisory_Notes:
                " ***The alignment of Production Planning and Procurement Planning during preparation of Master Planning and S&OP alignment is quite common. Especially if raw material availability is a mentionable restriction and/or subcontracting is a standard solution to resolve internal capacity issues.  ***If interdependencies are strong it might make sense to combine Production Planning and Procurement Planning and sometimes also Distribution Planning to one central Supply Planning.",
              Type: "Business Process",
              Scope:
                "For each identified capacity issue a suitable solution needs to be defined and documented. In order to resolve issues different suppliers but also other planning disciplines (e.g. Production Planning) might need to be involved.",
              Outcome: "Supply issues have been resolved.",
              name: "Clarify capacity issues with suppliers",
              _id: 306,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "After identification of suitable vendors it is possible to maintain the vendor in a source list to determine through which vendor a certain material/service is to be purchased at one specific time. ",
              _type: "Process",
              GUID: "feb3c726-514b-11e5-0a66-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "The supplier is identified as a source.  It is captured and that the supplier delivers certain materials/services for the organization within a pre-defined period. ",
              Outcome: "Source list created.",
              name: "Create source list",
              _id: 304,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "Purpose of this process is to define needed rules on how to choose the source of supply during actual procurement activities.",
              _type: "Process",
              GUID: "1afd6134-61ec-11e9-2ddf-001bc640cd9b",
              Advisory_Notes:
                "* in a multi-plant setup it needs to be determined which plant is allowed to order raw materials at a supplier and which should order raw materials via intercompany replenishment setups.",
              Type: "Business Process",
              Scope:
                "For all cases with multiple possible suppliers the rules for choosing source of supply are defined. This includes consideration of existing contracts with suppliers, alignment with Purchasing on impacts to new contracts and results into source of supply settings in the system (e.g. favorite SoS, fair-share rules or certain quotas per supplier). In addition intercompany supply needs to be considered as possible option. Sometimes even externally sourced products might be supplied internally via intercompany in order to improve external supply set up by bundling the sourcing from a certain supplier to one of the plants.",
              Outcome: "Determination rules for source of supply are defined.",
              name: "Determine rules for source of supply decisions",
              _id: 307,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "Monitoring the vendor contract ensures that the supplier is meeting the performance criteria stated in the contractual agreement. It involves measuring, analysing and managing the suppliers ability to comply with the contractual obligations as well as keeping track of own commitments. ",
              _type: "Process",
              GUID: "2164f38b-7f0b-11e5-0a66-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "Consulting the order, it is checked whether the supplier is compliant with the contract. This includes confirming on timely delivery of goods that conform to specifications, as well as measuring performance against other contract requirements over time.",
              Outcome:
                "Up-to-date information regarding the suppliers performance is available.",
              name: "Monitor vendor contract",
              _id: 1209,
              Business_Rules:
                "It is necessary to define:   ***What will be measured   ***Criteria for measuring performance e.g. service levels and quality, written down in the contract ",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "Possible suppliers are evaluated regarding aspects that are important for a well-set and cordial working co-operation. These can include:   ***company profile   ***management   ***quality   ***compliance   ***reputation   ***location   ",
              _type: "Process",
              GUID: "9d60b014-7f0d-11e5-0a66-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "Depending on the purchasing requirements, possible suppliers are sorted and evaluated in order to determine who is most suited. Based on the assessment, a quotation or proposal is requested from the selected suppliers.",
              Outcome:
                "Suppliers, who will be involved in further sourcing process, are selected.",
              name: "Evaluate source of supply",
              _id: 303,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "This process ensures that any obligation from both parties are met and financially settled. This is executed periodically or at expiration of the contract.",
              _type: "Process",
              GUID: "31d74f14-7f0b-11e5-0a66-001bc640cd9b",
              Advisory_Notes:
                "Settling the contract may lead to a debit or credit note. Follow-up contracts might be negotiated.",
              Type: "Business Process",
              Scope:
                "Actual business transactions are evaluated against the contractually agreed conditions. Financial obligations are documented and settled.",
              Outcome: "Contract settled.",
              name: "Settle contract",
              _id: 1208,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "In this process supplier and customer negotiate and agree the terms and conditions of their anticipated relationship.  The negotiation can be based on the previous exchange of RFP/RFQ and the related responses.",
              _type: "Process",
              GUID: "ec357bad-7e11-11e5-0a66-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "Align and agree scope of services, negotiate and agree contract terms.",
              Outcome: "Agreement ready for contracting.",
              name: "Perform vendor negotiations",
              _id: 1205,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "Receiving information about a supplier contract and capturing it for further processing are key first steps in purchasing processes which are based on the contractual agreement with a supplier.        ",
              _type: "Process",
              GUID: "ec357bcb-7e11-11e5-0a66-001bc640cd9b",
              Advisory_Notes:
                "Not all vendor contracts need to be captured in SAP. Often, only details such as prices or rebates will be reflected in the purchasing master data.   Only if special treatment of contracts within SAP is required, the contracts have to be captured as such in SAP. This is basically the case for scheduling agreements and for more complex rebate agreements.",
              Type: "Business Process",
              Scope:
                "Details regarding contracts with a supplier are  recorded, stored and forwarded for further processing. The information can include the type of products, the quantity and the agreed price, as well as other terms and condition related the purchase. ",
              Outcome:
                "All relevant information regarding the contract with the supplier is stored.",
              name: "Capture vendor contract",
              _id: 1203,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "Evaluating the market enables the purchasing department to know what products and services are currently offered in relation to the defined products and services requested by the various departments within the organization.    ",
              _type: "Process",
              GUID: "915d6e75-7f0d-11e5-0a66-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "Once the requested products or services have been clearly defined, the purchasing department performs market research in order to get a clear picture of the products and services being offered by various suppliers on the market.    The evaluation can include:   ***profiling of suppliers   ***profiling of products and services   ***analysis of market trends   ***assessment of cost structures   ***gaining insight on distribution channels   ***assessment of key success factors and review of success details",
              Outcome:
                "All relevant information on the market is available for further processing.",
              name: "Evaluate market",
              _id: 305,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "Information on products or services requested by the various departments must be amended in order to ensure that all the necessary details are available to begin the search for suppliers.   ",
              _type: "Process",
              GUID: "915d6ea8-7f0d-11e5-0a66-001bc640cd9b",
              Advisory_Notes: "Material master data might be already captured.",
              Type: "Business Process",
              Scope:
                "The purchasing department uses the initial information gathered concerning products and services needed by the various departments as the basis for documenting the specifics of the requests. Additional information is sourced in order to complete the definition of requested product or services.   ",
              Outcome: "Requested product or service is defined.",
              name: "Complete definition of requested product or services",
              _id: 310,
              Business_Rules:
                "It must be defined:   ***Where additional information will be taken from e.g. asking the various departments to provide the missing details   ***Whether approval is required once the products or services have been defined and if yes, through whom it will be defined    ",
              Prerequisite: "",
            },
          ],
        },
        {
          BPMN_GUID: "a0082cc1-8ddc-11e5-0a66-001bc640cd9b",
          Type: "Process Group",
          Description:
            "This process group covers all processes concerned with receipt of services and low value, non-inventory-managed consumables not related to actual supply chain activities.",
          _type: "Group",
          name: "Service Receipt Handling",
          GUID: "a0082cc1-8ddc-11e5-0a66-001bc640cd9b",
          _id: 2153,
          process_of: [
            {
              BPMN_GUID: "NULL",
              Description:
                "Since directly consumable materials are not stock-managed, upon receipt they are forwarded to the cost center or receiver which is responsible for the original request.      ",
              _type: "Process",
              GUID: "bdcb60d1-7f03-11e5-0a66-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "Once the directly consumable materials are delivered to the requesting department, the goods are verified and confirmed regarding correct product, correct quantity, and correct cost allocation. They are then posted to consumption accounts.",
              Outcome: "GR posted to cost object.",
              name: "Manage goods receipt for directly consumable materials",
              _id: 1221,
              Business_Rules:
                "Rules and procedures should be established in order to avoid unnecessary delays which might affect timely payment and the entitlement of cash discounts.",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "Receipt confirmation takes place after service has been performed and is used to record the service performance fact and initiate billing process on vendor side and payment process on service recipient side. ",
              _type: "Process",
              GUID: "529c6284-7f06-11e5-0a66-001bc640cd9b",
              Advisory_Notes:
                "Depending on the nature of the service and the organizational set-up different variations who actually captures the service delivery via the service entry sheet and who confirms it finally can be established.",
              Type: "Business Process",
              Scope:
                "Vendor provides a confirmation once the service has been performed. This serves as a trigger for recording the service fulfillment, which is done via service entry sheet. Once service fulfillment is recorded, it must be confirmed by the requestor, if the service has actually been performed, as well as if it complies with agreed requirements on time, cost and quality. If requestor confirms the service, billing and payment process can be initiated. ",
              Outcome:
                "Service is confirmed, billing and payment can be initiated.",
              name: "Confirm service receipt",
              _id: 1236,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "Monitoring purchase order (PO) for indirect materials ensures - through communication with the vendor - that the correct material is sent and can be/is delivered on time, and in full quantity.   ",
              _type: "Process",
              GUID: "bdc1eaff-7f03-11e5-0a66-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "After the PO has been sent to the vendor, it needs to be tracked. The vendor hereby sends an order acknowledgement to confirm the receipt of the PO. Furthermore, the delivery date is tracked and, in case of imported material, the estimated time of arrival (ETA) as well as the estimated time of sailing is provided by the vendor. In comparison to monitoring PO for direct materials the timely receipt of purchased materials by consumable procurement is less strictly monitored by the purchasing department, therefore overdue deliveries are possible. In this case information regarding the delays needs to be received from vendor and delivery information updated.",
              Outcome:
                "Order acknowledgement and details on import status, delivery date and confirmed quantity.",
              name: "Monitor purchase order for indirect materials",
              _id: 1220,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "Monitoring purchase order (PO) for services ensures - through communication with the vendor - that the correct service will be performed on time.        ",
              _type: "Process",
              GUID: "94eda4a0-83f0-11e5-0a66-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "After the PO has been sent to the vendor, it needs to be tracked. The vendor hereby sends an order acknowledgement to confirm the receipt of the PO. The service provider may provide additional data or inform about changes at any time. ",
              Outcome: "Order acknowledgement received.",
              name: "Monitor purchase order for services",
              _id: 1234,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "Receipt confirmation takes place after service has been performed and is used to record the service performance fact and initiate billing process on vendor side and payment process on service recipient side.    ",
              _type: "Process",
              GUID: "fbfd3e97-7f05-11e5-0a66-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "Vendor provides a confirmation, once the service has been performed. This serves as trigger for recording the service fulfillment by procurement. For one-time services the recording typically is done by using the goods receipt functionality of ERP system. Once service fulfillment is recorded, it must be confirmed by the requestor, if the service has actually been performed, as well as if it complies with agreed requirements on time, cost and quality. If requestor confirms the service, billing and payment process can be initiated.",
              Outcome:
                "Service is confirmed, billing and payment can be initiated.",
              name: "Confirm one-time service receipt",
              _id: 281,
              Business_Rules: "",
              Prerequisite: "",
            },
          ],
        },
        {
          BPMN_GUID: "d5ef27d1-8ddc-11e5-0a66-001bc640cd9b",
          Type: "Process Group",
          Description:
            "This process group describes the processes of returning procured material to the vendor due to quality issues, wrong material delivery or wrong delivery quantity.",
          _type: "Group",
          name: "Vendor Returns",
          GUID: "d5ef27d1-8ddc-11e5-0a66-001bc640cd9b",
          _id: 2152,
          process_of: [
            {
              BPMN_GUID: "NULL",
              Description:
                "Before packaging materials can be sent back to the vendor, it is necessary to provide the vendor with information regarding the return. This includes the specific packaging units and quantity being returned as well as other conditions related to the delivery of the packaging materials.     ",
              _type: "Process",
              GUID: "ba511800-7f0c-11e5-0a66-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "A document to initiate the return of packing units is compiled, specifying the quantity being returned and the proposed delivery date. The information is then sent to the vendor.",
              Outcome:
                "The vendor receives information about the return of the packaging units.",
              name: "Provide information on return of packaging units to vendor",
              _id: 279,
              Business_Rules:
                "It is necessary to define:   ***What information will be documented and sent to the vendor.   ***How the information will be communicated to the vendor.  ",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "The creation of a return purchase order is required to initiate the return of products to the vendor. The return can result from a valid complaint or the over-delivery of products, part of which needs to be returned to vendor.       ",
              _type: "Process",
              GUID: "17bfd3bc-5171-11e5-0a66-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "After the notification is given that products must be sent back to the vendor, a return purchase order is created and forwarded for further processing. ",
              Outcome:
                "All relevant information regarding the return is documented in the return purchase order.",
              name: "Create return purchase order",
              _id: 1248,
              Business_Rules:
                "***The format of the order (electronic, paper) and communication channel must be defined.    ***It is necessary to  define who covers the transportation and other related costs.",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "A delivery is the central object of the goods issue process - all shipping activities including picking, packing, transportation and goods issue are initiated based on the delivery. For the return to vendor scenario creation of a return delivery is required (similar to an outbound delivery in sales scenarios).      .",
              _type: "Process",
              GUID: "7b1559a0-7f0c-11e5-0a66-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "A return delivery is created with reference to the return purchase order. A delivery can be created only for purchase orders, which are due to delivery (material availability date or the transportation scheduling date is reached). ",
              Outcome:
                "Return delivery document is created and shipping activities can be initiated.",
              name: "Create return delivery for vendor returns",
              _id: 1252,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "Regarding the receipt of returnable packaging materials, it is necessary to capture the fact that the packaging units received come from a specific vendor and are now at a specific storage location of the purchasing entity.",
              _type: "Process",
              GUID: "ba511821-7f0c-11e5-0a66-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "Once the packaging material is received along with the products, details regarding what packaging has been received and where it is, is kept documented. Furthermore the packaging material is stored in an unvalued special stock. The information is forwarded for further processing.",
              Outcome:
                "Details regarding returnable packaging at the storage location is recorded. ",
              name: "Capture receipt of packaging units",
              _id: 278,
              Business_Rules:
                "It is necessary to define how and which data will be captured and stored.",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "The purchase department checks the status of the goods return delivery.",
              _type: "Process",
              GUID: "01d98350-a15e-11e8-2ddf-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "Status of return delivery is verify, and all relevant details are recorded. ",
              Outcome: "Status of vendor return is available and up to date.",
              name: "Monitor vendor return",
              _id: 1249,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "Receiving notification from the relevant department about a product related complaint to a vendor and processing the information initiates the return-to-vendor process.        ",
              _type: "Process",
              GUID: "7b15599a-7f0c-11e5-0a66-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "This process includes receiving the complaint from a relevant department, assessing and updating it with the necessary information (e.g. PO number, warranty terms), identifying the responsible vendor and requesting a return material authorization (RMA). After the vendor has confirmed the complaint, the return purchase order can be created.",
              Outcome:
                "The complaint contains all necessary information. The vendor has approved the return and the product can be returned to the vendor.",
              name: "Process complaint to vendor",
              _id: 1250,
              Business_Rules:
                "It must be defined:   ***How the validity of the complaint will be assessed and what parties (departments) will be involved.   ***Who will have authorization to approve which type of complaints.   ***What information is required to ensure that the vendor accepts the complaint.   ***What the timeframe for processing of complaints is.  ",
              Prerequisite: "",
            },
          ],
        },
        {
          BPMN_GUID: "1a6bf641-8ddd-11e5-0a66-001bc640cd9b",
          Type: "Process Group",
          Description:
            "This group includes processes related to supply chain integration with suppliers or customers. This includes but is not limited to different subcontracting setups, virtual deliveries in the field of drop shipments or different kinds of EDI integrations. The responsibility for these processes is often subject to discussions.",
          _type: "Group",
          name: "Integrated Supply Chain Activities",
          GUID: "1a6bf641-8ddd-11e5-0a66-001bc640cd9b",
          _id: 2151,
          process_of: [
            {
              BPMN_GUID: "NULL",
              Description:
                "Determine supply source and conditions aims at finding the external source from which a customer order will be fulfilled, as well as agreeing on the related conditions. The Determine supply source and conditions process is required for third party sales and sales via subcontracting, as well as in certain constellations of intercompany processes. In case of the latter, external source may also refer to other entities of the group.       ",
              _type: "Process",
              GUID: "5ac7e463-e8e2-11e4-0a66-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "After the customer order data is captured, the external source (external vendor) used to fulfil the order and related conditions must be determined. ",
              Outcome:
                "Source of supply and conditions related to the order are defined and documented.",
              name: "Determine supply source and conditions",
              _id: 180,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "With procurement via vendor-managed inventory, the vendor is responsible for creating and maintaining the inventory plan, as well as monitoring stock levels and making replenishment decisions for products owned by the organization which purchases the goods. Extensive information sharing is required so that the vendor can maintain a high degree of visibility. This must be ensured either by giving the vendor access to the organizations system or providing consistent and regular information sharing with the vendor about stock levels and planned consumption.      ",
              _type: "Process",
              GUID: "fcf412ed-7f0b-11e5-0a66-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "Based on information regarding stock levels and planned consumption, the vendor plans and initiates replenishment by scheduling its production/distribution. The creation of the purchase order is triggered by notification of future replenishment from the vendor.",
              Outcome:
                "Replenishment of stock is initiated and the purchasing process is triggered",
              name: "Exchange, monitor stock levels and plan replenishment",
              _id: 299,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "The subcontractor must fulfil the order by manufacturing the products stated in the purchase order (PO) under the agreed conditions. After the manufacturing the products can be sent to the contractor. ",
              _type: "Process",
              GUID: "de59c969-7efd-11e5-0a66-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "Upon reception of the components, the subcontractor manufactures the products with the specifications stipulated in the PO. Manufacturing is performed exclusively by the subcontractor, who provides notification and delivers the finished products.",
              Outcome:
                "Products are manufactured and ready to be sent out by the subcontractor.",
              name: "Perform subcontracting manufacturing",
              _id: 1197,
              Business_Rules:
                "It must be defined:   ***How/whether the manufacturing process will be monitored by the contracting organization.   ***How/whether manufacturing practices of the subcontractor will be aligned with the contracting organization.  ",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "In order to provide the subcontractor with the components required for manufacturing, it is necessary to determine where the components will be taken from and approve the components delivery. It is also essential to verify and confirm that the correct components are being sent to the correct subcontractor. ",
              _type: "Process",
              GUID: "de59c975-7efd-11e5-0a66-001bc640cd9b",
              Advisory_Notes:
                "The quantities of components delivered to a subcontractor do not have to match exactly the required quantities from the purchase order. It is possible to keep stock of components at the subcontractor. Before sending components to the subcontractor the availability of these for own production should be checked. Especially if own produced orders have already been confirmed towards customers this check avoids reliability breaches.",
              Type: "Business Process",
              Scope:
                "After the purchase order (PO) has been issued and acknowledgement is communicated by the subcontractor, the required components are located. The delivery of the stated quantity to the designated subcontractor, who will fulfil the order, is initiated. Releasing the components delivery triggers the outbound logistics process. ",
              Outcome:
                "Components are released and can be sent to the subcontractor.",
              name: "Initiate and release components delivery to subcontractor",
              _id: 1199,
              Business_Rules:
                "***Rules must be established for allocating authorization for components release.   ***It must be clarified how information regarding the release will be communicated across the organization (e.g. to storage locations, to outbound logistics).   ***It must be defined who has authorization to approve the delivery and to what amount.",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "Consumption of vendor consigned inventory is recorded triggering reporting to the vendor.",
              _type: "Process",
              GUID: "1b7433e8-9fd9-11e8-2ddf-001bc640cd9b",
              Advisory_Notes:
                " ***the allocation of actually consumed qty to the different existing PO needs to be considered. It needs to be defined if vendor is defining the split or receives it as part of the consumption information.   *** the invoicing scheme needs to be aligned with the vendor (e.g. per consumption, weekly, monthly).",
              Type: "Business Process",
              Scope:
                "After the vendor owned inventory is used up, the vendor will be informed.",
              Outcome: "Vendor informed about goods consumption",
              name: "Notify supplier about goods consumption",
              _id: 298,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "When a request for materials is received from production, relevant information must be forwarded to a designated vendor. This ensures that the required material is available in the stated quantity and on the specified date. ",
              _type: "Process",
              GUID: "8df4fb95-7e0f-11e5-0a66-001bc640cd9b",
              Advisory_Notes:
                "The supply demand references a specific scheduling agreement for specific components.    The following questions need to be answered: ***Who is the recipient for the demand information? ***How are the documents submitted (EDI, Email etc.)? ***What information is required? ***Who communicates with the vendor?",
              Type: "Business Process",
              Scope:
                "Material is requested from a vendor with specified quantities and dates and reference to a scheduling agreement.",
              Outcome: "The supply demand is received by the vendor.",
              name: "Receive production supply demand and provide to vendor",
              _id: 90,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "In an active subcontracting process, the company must receive the components before providing the subcontracting services. ",
              _type: "Process",
              GUID: "1923a0ad-900d-11e8-2ddf-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "In this process, the goods are received, which triggers production. ",
              Outcome: "Components received from sub-contractor.",
              name: "Initiate and confirm components from subcontractor",
              _id: 1333,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "Subsequent adjustments of components is required in order to make corrections of goods receipt for a subcontracting order. With this, the actual quantity of components used to fulfill a subcontracting order is recorded. ",
              _type: "Process",
              GUID: "dfb588d2-7efd-11e5-0a66-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "When the subcontractor gives notice that a larger or smaller quantity of the subcontracting components was actually consumed than planned, a subsequent adjustment to correct the component consumption must to be done. ",
              Outcome: "The actual components consumption is recorded.",
              name: "Perform subsequent goods issue and adjustments of components",
              _id: 1196,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "This process is required in order to track components delivered to the subcontractor. The components still belong to the organization, but are held in storage at the subcontractor. That way the subcontractor is able to fulfill his service to manufacture a product with components from the requesting organization.",
              _type: "Process",
              GUID: "dfb588c6-7efd-11e5-0a66-001bc640cd9b",
              Advisory_Notes:
                "Subcontracting stock underlies the regular inventory procedures.",
              Type: "Business Process",
              Scope:
                "When components are delivered to the subcontractor, they must be recorded as stock that is stored at an external location (not at an internal storage location). ",
              Outcome:
                "Information regarding components consumption and subcontracting progress is available.",
              name: "Perform goods receipt in subcontracting stock and monitor stock",
              _id: 1195,
              Business_Rules:
                "The level of system integration with the subcontractor as well as external vendors of components must be defined. It is necessary to define how tracking and reporting will be done.",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "In a third party sales scenario there is no physical inbound movement of goods or materials. Nevertheless it is necessary to acknowledge change of ownership of goods from supplier to selling entity to be able to further record the sale to the customer.      ",
              _type: "Process",
              GUID: "2bb46437-7f03-11e5-0a66-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "Once advanced shipping notification is received, a non-physical goods receipt is recorded, documenting assumption of the title of ownership for the goods or materials by the selling entity. The non-physical goods receipt does not initiate any changes in inventory levels.",
              Outcome: "Change of goods ownership is recorded.",
              name: "Perform non-physical goods receipt",
              _id: 1213,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "When the goods provided on consignment have been sold, the ownership of the goods must be transferred to the customer. Since the goods are already being stored at the customers location, the movement is non-physical and represents only a change of ownership. Nevertheless an outbound delivery needs to be created to record the goods issue from consignment stock.",
              _type: "Process",
              GUID: "7f932416-7e49-11e5-0a66-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "Once the customer provides notification that the goods have been sold, the outbound delivery is created and goods issue posted, allocating the goods as a property of the customer. ",
              Outcome:
                "Goods provided on consignment become property of the customer. This triggers the invoicing process.",
              name: "Simulate outbound delivery and post goods issue",
              _id: 156,
              Business_Rules: "",
              Prerequisite: "",
            },
          ],
        },
      ],
      _id: 276,
    },
    {
      BPMN_GUID: "1a352815-d86b-4e2d-ae53-ade5cac3f7fb",
      Type: "Process Cluster",
      _type: "Cluster",
      name: "F. Store",
      GUID: "26bea461-256a-11e9-2ddf-001bc640cd9b",
      group_of: [
        {
          BPMN_GUID: "ac683ef1-8ddd-11e5-0a66-001bc640cd9b",
          Type: "Process Group",
          Description:
            "This process group covers all processes involved in initiating the transportation of sold goods to the customer or replenishments to other plants which cannot be accomplished via internal logistics (e.g. due to road transportation).",
          _type: "Group",
          name: "Outbound Delivery Management",
          GUID: "ac683ef1-8ddd-11e5-0a66-001bc640cd9b",
          _id: 2162,
          process_of: [
            {
              BPMN_GUID: "NULL",
              Description:
                "In this process the shipment documents are prepared and the goods issue gets posted. In some cases shipment documents may have to be printed in advance to be included in the packaging.",
              _type: "Process",
              GUID: "41a28422-3c0a-11e9-2ddf-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "Goods issue posting and printing of transport and customs documents are involved in this process.",
              Outcome: "Goods issue posted and shipment ready for dispatch.",
              name: "Perform goods issue for customer-owned material",
              _id: 11,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "Proof of delivery (POD) is the confirmation sent by the Ship to party upon the receipt of goods. The ship-to party transfers the proof of delivery in the System via IDoc. This way, quantities are confirmed once for the entire delivery.",
              _type: "Process",
              GUID: "f4301854-7e0f-11e5-0a66-001bc640cd9b",
              Advisory_Notes:
                "Proof of delivery is exclusively an instrument for confirmation of external customers deliveries.",
              Type: "Business Process",
              Scope:
                "Receipt of POD from ship-to party, processing of discrepancies as required, confirmation of POD to goods recipient.",
              Outcome: "POD confirmed.",
              name: "Receive and process proof of delivery",
              _id: 73,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "An outbound delivery is usually created with reference to another document (sales order, purchase order, stock transport order). Outbound deliveries can only be created for orders, which are due to delivery (material availability date or the transportation scheduling date is reached) - e.g. delivery is created 3 days before planned ship-out. Deliveries can not be created for orders, which are blocked e.g. sales order blocked due to exceeded credit limit.",
              _type: "Process",
              GUID: "e9f51001-e9bd-11e4-0a66-001bc640cd9b",
              Advisory_Notes:
                "Dealing with delivery creation the legal requirements in the country of operations must be considered - some countries do not permit a sales order to be broken up into more than one delivery, and likewise they may not permit more than one sales document to be combined into one delivery.",
              Type: "Business Process",
              Scope:
                "An outbound delivery is the central object of the goods issue process - all shipping activities including picking, packing, transportation and goods issue are initiated based on the outbound delivery.",
              Outcome:
                "Outbound delivery document is created and shipping activities can be initiated.",
              name: "Create outbound delivery",
              _id: 63,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "In this process the shipment documents are prepared and the goods issue gets posted. In some cases shipment documents may have to be printed in advance to be included in the packaging.",
              _type: "Process",
              GUID: "7e8a0fce-7e0d-11e5-0a66-001bc640cd9b",
              Advisory_Notes:
                "The goods issue posting does not necessarily require prior creation of shipment and loading.",
              Type: "Business Process",
              Scope:
                "Goods issue posting and printing of transport documents are invovled in this process.",
              Outcome: "Goods issue posted and shipment ready for dispatch.",
              name: "Perform goods issue from stock",
              _id: 61,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "This process describes issuing the goods to an outbound delivery for cross-docking. In some cases shipment documents may have to be printed in advance to be included in the packaging.",
              _type: "Process",
              GUID: "e5a63124-9eea-11e8-2ddf-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "The goods issue posting is performed including printing of transport documents.",
              Outcome: "Goods issue for cross-docking posted. ",
              name: "Perform goods issue for cross-docking",
              _id: 341,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "Parts designated for refurbishment must be taken from the plant to the area where the refurbishment is performed. This must be accompanied by the relevant goods issue posting.   ",
              _type: "Process",
              GUID: "2348f377-7efc-11e5-0a66-001bc640cd9b",
              Advisory_Notes:
                "For refurbishment orders, goods issue posting is performed using transaction MB1A (not MIGO).",
              Type: "Business Process",
              Scope:
                "Goods issue is posted with reference to the maintenance order to inform the responsible that parts can be moved to the refurbishment site.",
              Outcome:
                "Goods issue with reference to maintenance order posted. ",
              name: "Perform goods issue for maintenance order",
              _id: 1276,
              Business_Rules: "",
              Prerequisite: "",
            },
          ],
        },
        {
          BPMN_GUID: "49335d61-8ddd-11e5-0a66-001bc640cd9b",
          Type: "Process Group",
          Description:
            "Inventory management includes processes for the tracking of inventory movements within the organisation. Processes include goods issue and goods receipt postings, as well as the physical count of stock regular intervals.",
          _type: "Group",
          name: "Inventory Management",
          GUID: "49335d61-8ddd-11e5-0a66-001bc640cd9b",
          _id: 2161,
          process_of: [
            {
              BPMN_GUID: "NULL",
              Description:
                "This process refers to the operational adjustment of the differences in physical inventory as compared to what has been previously recorded by Inventory Management. This reflects a manual adjustment which triggers postings to the inventory differences account which will be received by Controlling. ",
              _type: "Process",
              GUID: "f86b2aaf-8e54-11e8-2ddf-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "The process includes clearance of all the differences in physical inventory and results in an update of the storage location of the inventory.",
              Outcome:
                "The storage location for physical inventory is updated and differences are cleared.",
              name: "Clear differences in physical inventory",
              _id: 314,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "Following the physical count, this process describes maintaining the count in the system. In the case of a mismatch, a re-count is triggered  and the inventory is adjusted.",
              _type: "Process",
              GUID: "f86b2a9d-8e54-11e8-2ddf-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "This maintenance includes the documentation of the count in the system and identification of count differences.",
              Outcome: "Inventory count maintained in the system.",
              name: "Maintain count in system",
              _id: 316,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "Goods reservation ensures that products related to the customer inquiry will be available when the customer wants to buy them. ",
              _type: "Process",
              GUID: "5acb9a3c-7e59-11e5-0a66-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "Based on the customer inquiry, a reservation is created for a specific batch of product. This information needs to be transferred to the warehouse in order to ensure that the reserved batch is not shipped out to fulfil another customer order.",
              Outcome: "A batch of goods is reserved. ",
              name: "Reserve goods on batch level",
              _id: 170,
              Business_Rules:
                "The duration of the reservation, means of securing that the batch is not shipped out, and the means of locating the batch and matching it to a sample must be established.",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "This process describes the physical count of the goods against the count document. The purpose ist to determine exactly the inventory quantities and to identify differences. The physical inventory, system and physical stock levels should be aligned. ",
              _type: "Process",
              GUID: "f86b2a9b-8e54-11e8-2ddf-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "This count includes all items included in the created count list.",
              Outcome: "Physical inventory is determined. ",
              name: "Count goods physically",
              _id: 317,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "This process describes the first requirement of generating and printing an inventory count document. The document describes what should be counted and at what locations the items can be found. ",
              _type: "Process",
              GUID: "f86b2a99-8e54-11e8-2ddf-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "The count document includes materials for inventory count and the storage bins/locations where the items can be found.",
              Outcome: "Count document generated and printed.",
              name: "Create count document",
              _id: 315,
              Business_Rules: "",
              Prerequisite: "",
            },
          ],
        },
        {
          BPMN_GUID: "8f993b61-8f83-11e5-0a66-001bc640cd9b",
          Type: "Process Group",
          Description:
            "This process group covers all processes involved in scheduling and processing the actual transportation of goods. This includes for example carrier selection for specific shipments, scheduling of transports (inbound or outbound), creation and processing of transport papers and other administrative activities.",
          _type: "Group",
          name: "Transportation Management",
          GUID: "8f993b61-8f83-11e5-0a66-001bc640cd9b",
          _id: 2160,
          process_of: [
            {
              BPMN_GUID: "NULL",
              Description:
                "This process describes the planning of cross-docking elements including planning of incoming shipments and outgoing shipping requirements. This procedure can reduce material handling costs, labour and time. Furthermore this process enables the warehouse to map inbound delivery documents to outbound deliveries which results in the reduction of storing in the warehouse.",
              _type: "Process",
              GUID: "7eaea9a9-9ee7-11e8-2ddf-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "For cross-docking logistics, it is required to plan and align incoming supply with outgoing demand requirements.",
              Outcome:
                "Incoming and outgoing transfers and deliveries planned. ",
              name: "Plan cross-docking",
              _id: 331,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "Based on the available information (e.g. proposal, past performance, market intelligence) and binding price quote from several carriers a preliminary assignment of suppliers to specific lanes is made and optimized. Individual carrier may only be awarded particular subset of lanes covered within a bidding and selection process. Subsequent negotiations are based on these prices and lane assignments.",
              _type: "Process",
              GUID: "abfff400-7e11-11e5-0a66-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "Analyze current proposals and quotes against past performance and provisionally assign transportation lanes.",
              Outcome: "Carrier proposed for negotiations.",
              name: "Evaluate carrier responses",
              _id: 81,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "This process consolidates demands for transportation, sales- and stock transfer orders, into shipments and freight orders, or freight bookings for subsequently tendering carriers. Freight orders capture all information required for transportation planning by the shipper (sender) and transportation execution.They are a combination of shipments, which can be assigned to specific legs of the transport and/or carriers. The combination is based on transportation planning or transportation charges calculation. Freight orders can be created directly or as part of transportation order based or delivery based planning. Freight bookings, in contrast, are used to book freight space in advance, for example, with a shipping company. Transportation execution can then happen on the same documents. Freight orders and freight bookings also form the basis for track & trace activities, event management and reporting.",
              _type: "Process",
              GUID: "94126c64-7e10-11e5-0a66-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "Creation of freight units and subsequent load optimization for definition of shipments and freight orders/freight bookings.",
              Outcome: "Freight order/freight booking created.",
              name: "Receive and process transportation demand",
              _id: 329,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "In this process the truck about to leave the site and its driver are checked. This includes the de-registering procedure (e.g. capturing drivers name and license plate, check of loading documents, load securing and seals, or if truck must be empty).",
              _type: "Process",
              GUID: "8b5b772a-7caa-11e5-0a66-001bc640cd9b",
              Advisory_Notes:
                "It must be defined which checks and additional tasks (e.g. cleaning) shall be performed for which kind of vehicles and goods, and what data shall be recorded.",
              Type: "Business Process",
              Scope:
                "Check of driver and vehicle identity against transport documentation.",
              Outcome:
                "Vehicle and persons leaving the site have been de-registered. Authorisation for moving goods off the site has been checked.",
              name: "Check-out delivery truck",
              _id: 86,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "Purpose of this process is to document guidelines for transport management and execution processes. This is needed in order to facilitate later compliance with the derived transport plan.  ",
              _type: "Process",
              GUID: "1cabcf62-764a-11e9-2ddf-001bc640cd9b",
              Advisory_Notes:
                " ***the amount of documentation highly depends on the standardization of transport services and on the importance of transport for the company/business unit success. In environments where transport is not considered crucial part of customer service or has a very small impact on total cost, companies might rely on flexible execution processes instead of ensuring compliance to the transport plan.",
              Type: "Business Process",
              Scope:
                "Decisions taken and assumptions made during creation of the transport plan need to be documented for each transport route. This information will on the one hand be used for carrier negotiations but also during transport planning and carrier selection. Necessary service levels (e.g. number of departures per week, lead time) and carrier selection criteria could be part of these guidelines.",
              Outcome:
                "Guidelines are documented and available for Transport Management and execution processes.",
              name: "Document guidelines for transport management and execution processes",
              _id: 325,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "Transportation requirements are deduced from different orders in the ERP system (e.g. sales orders, purchase orders, and stock transport orders, as well as the corresponding returns orders for each order type). Depending on the time scale considered either actual, planned or projected transportation requirements can be formulated.    Data summarized include: materials, volumes, dates, pick-up locations and recipients, transport routes and conditions, etc.",
              _type: "Process",
              GUID: "ec357baa-7e11-11e5-0a66-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "This process includes the assessment of orders and translation into transportation requirements.",
              Outcome: "Transportation requirements defined.",
              name: "Gather transportation requirements",
              _id: 80,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "This process assures that the warehouse receives the delivery and pickup information (e.g. times, carrier, transportation requirements) provided by the 4PL.",
              _type: "Process",
              GUID: "abe34419-7e11-11e5-0a66-001bc640cd9b",
              Advisory_Notes:
                "Transport information needs to be exchanged directly with the warehouse.    The following questions need to be answered: ***Who receives the transport information? ***How are the documents transmitted (system workflow, EDI, Email etc.)? ***What information is required? ***Who communicates with the warehouse?",
              Type: "Business Process",
              Scope:
                "Provide transportation information to warehouse and confirm receipt.",
              Outcome: "Transportation information communicated to warehouse.",
              name: "Communicate transportation information to warehouse",
              _id: 54,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "This process describes the handling of shipping documents and related customs interactions in case of international trade.",
              _type: "Process",
              GUID: "94126c6a-7e10-11e5-0a66-001bc640cd9b",
              Advisory_Notes:
                "Minimum Shipping documentation:  ***Truck: Bill of Lading (BOL), Master Bill of Lading (MBOL) ***Air: Airway Bill (AWB), House Way Bill (HWB)  ***Sea: Bill of Lading (BOL) ***Parcel: Carrier compliant parcel labels",
              Type: "Business Process",
              Scope:
                "Alignment of shipping documents with carrier, submission to customs for clearance.",
              Outcome: "Shipment is customs cleared and ready for pickup.",
              name: "Prepare and communicate transportation documents",
              _id: 335,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "Firm planning and booking transportation capacities and services by a 4PL is based on actual orders or deliveries provided by the shipper, as would be the case for internal operations.",
              _type: "Process",
              GUID: "abe3442e-7e11-11e5-0a66-001bc640cd9b",
              Advisory_Notes:
                "Order information needs to be sent directly to the 4PL.    The following questions need to be answered: ***Who receives the order information? ***How are the documents transmitted (EDI, Email etc.)? ***What information is required? ***Who communicates with the 4PL?",
              Type: "Business Process",
              Scope:
                "Transmission of firm transportation demands (e.g. deliveries from O2C) to 4PL for actual booking.",
              Outcome:
                "Definite orders (sales order, purchase order, transfer order)  or deliveries transmitted to 4PL for processing.",
              name: "Send transportation demands to 4PL",
              _id: 53,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "This process consolidates demands for drop shipments based on sales- and stock transfer orders, into shipments and freight orders for subsequently tendering carriers.",
              _type: "Process",
              GUID: "106a8142-7e10-11e5-0a66-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "Creation of freight units and subsequent load optimization for definition of shipments and freight orders.",
              Outcome: "Freight orders created.",
              name: "Receive and process drop shipment transportation demand",
              _id: 330,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "The external service provider conducts freight settlement services and forwards information about distribution of transportation costs. Freight cost settlement allows the assignment and forwarding of the shipment costs to FI and cost accounting.",
              _type: "Process",
              GUID: "a703ec87-94af-11e8-2ddf-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "The freight costs are calculated based on freight agreements with predefined rates and tariffs and scales for inhouse calculation. Furthermore G/L account determination takes place during the cost determination and the transfer to FICO will be triggered by a transfer procedure with Service PO and service entry sheet creation for each creditor which will be included in the freight charge document on item level. ",
              Outcome:
                "Service provider provides freight cost distribution data.",
              name: "Conduct freight settlement services",
              _id: 75,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "The process facilitates planning outbound shipments related to deliveries. In addition it  supports carrier selection, call-off and booking activities.",
              _type: "Process",
              GUID: "7bf5a971-7e0d-11e5-0a66-001bc640cd9b",
              Advisory_Notes:
                "There might be situations where you source transport on the spot market. In these cases the I2O Transportation sourcing scenario needs to be conducted for each single shipment. ",
              Type: "Business Process",
              Scope:
                "The creation of shipment with assigned deliveries and booking of carrier is performed.",
              Outcome:
                "Shipment has been confirmed by a carrier and planning status has been maintained",
              name: "Plan shipment",
              _id: 332,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "This process describes the transport execution from leaving the enterprise grounds to receiving  the eventual proof of delivery signed by the receiver which acts as service confirmation between sender and carrier.  ",
              _type: "Process",
              GUID: "8a80f581-7e0d-11e5-0a66-001bc640cd9b",
              Advisory_Notes:
                "The requirement and channel for ASN must be specified per recipient.",
              Type: "Business Process",
              Scope:
                "Dispatching the shipment and delivery of goods to recipient. After associating the vehicle details with the delivery an Advance Shipping Notice is sent to the recipient.",
              Outcome:
                "Recipient has been provided with Advance Shipping Note. Forwarder has received proof of delivery from recipient.",
              name: "Execute shipment",
              _id: 69,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "Freight orders capture all information required for transportation planning by the shipper (sender) and transportation execution.They are a combination of shipments, which can be assigned to specific legs of the transport and/or carriers. The combination is based on transportation planning or transportation charges calculation. Freight orders also form the basis for track & trace activities, event management and reporting. Freight orders can be created directly or as part of transportation order based or delivery based planning. Freight bookings, in contrast, are used to book freight space in advance, for example, with a shipping company. Transportation execution can then happen on the same documents.",
              _type: "Process",
              GUID: "106a8145-7e10-11e5-0a66-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "The process includes the creation and maintenance with all relevant transportation information, e.g. scheduling and location data, units, charges. ",
              Outcome:
                "Freight order has been created and transmitted to the carrier.of the transport and/or carriers. The combination is based on transportation planning or transportation charges calculation.",
              name: "Create freight order",
              _id: 333,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "This process is required for the actual booking of a transport and recording of the confirmation by the carrier.",
              _type: "Process",
              GUID: "106a8148-7e10-11e5-0a66-001bc640cd9b",
              Advisory_Notes:
                "Shipment order needs to be sent directly to the carrier in order for freight booking and confirmation must be received.   The following questions need to be answered:  ***Who receives the order information on shipper and carrier side?  ***How are the documents received (EDI, Email etc.)?  ***What information is required?  ***Who communicates with the carrier?",
              Type: "Business Process",
              Scope:
                "This process includes the booking of a transport with required information like pick-up location and time and receiving the booking confirmation from the carrier.",
              Outcome: "Booking of carrier and receipt of confirmation.",
              name: "Book and confirm carrier",
              _id: 326,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "In handing over of physical possession of the asset to the buyer, it is necessary to define the means of transportation and who will cover the transportation cost, as well as record the sale.",
              _type: "Process",
              GUID: "feff4074-7ef0-11e5-0a66-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "Once sale consideration or the down payment (as per negotiated terms of payment approved has been received, the possession of the asset is handed over to the buyer. If a portion of sale consideration is deferred, i.e. payable over a span of time, the rightful title of the assets can not be transferred to the purchaser. However, the buyer will take possession of the asset on depositing down payment and may be required to pay interest on the deferred part of sale.",
              Outcome: "Asset is in possession of the buyer.",
              name: "Hand over asset to buyer",
              _id: 334,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "Transportation lanes are used for planning distribution and procurement between different locations. This simplifies transportation planning of recurring complex or high volume transportation requirements. Awarding lanes complements the remaining vendor negotiations.",
              _type: "Process",
              GUID: "ec357bb0-7e11-11e5-0a66-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "Once the carrier and method of transportation have been documented, transportation lanes are awarded to selected carriers. Subsequently, the rates and conditions of transportation are recorded.",
              Outcome: "Transportation lane awarded to carrier.",
              name: "Award lanes",
              _id: 78,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "The shipping schedule outlines the overall expected quantity of the material(s) to be shipped over a specific period of time. It is used to plan activities in the warehouse and as a reference point for site access controls. ",
              _type: "Process",
              GUID: "ec357bc8-7e11-11e5-0a66-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "The shipping schedule specifies the carriers and associated lanes with the expected transportation volumes and shipping days, pick-up window, laycan, etc.",
              Outcome: "Shipping schedules updated.",
              name: "Update shipping schedules",
              _id: 82,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "Receive consolidated and planned outbound shipment message from 4PL to trigger internal shipment creation.",
              _type: "Process",
              GUID: "f123cd4c-7e10-11e5-0a66-001bc640cd9b",
              Advisory_Notes:
                "Order information needs to be received directly from the 4PL.   The following questions need to be answered: ***Who receives the shipping information? ***How are the documents transmitted (EDI, Email etc.)? ***What information is required? ***Who communicates with the 4PL?",
              Type: "Business Process",
              Scope:
                "Process shipment information from 4PL and update deliveries in own system.",
              Outcome: "Deliveries updated.",
              name: "Receive transportation information from 4PL",
              _id: 55,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "In this process the truck about to leave the site and its driver are checked. This includes the de-registering procedure (e.g. capturing driver name license plate, check of loading documents, loading security and seals).",
              _type: "Process",
              GUID: "3cd60ced-e831-11e4-0a66-001bc640cd9b",
              Advisory_Notes:
                "It must be defined which checks and additional tasks (e.g. cleaning) shall be performed for which kind of vehicles and goods, and what data shall be recorded.",
              Type: "Business Process",
              Scope:
                "Check of driver and vehicle identity against transport documentation. Company grounds exit control.",
              Outcome:
                "Vehicle and persons leaving the site have been de-registered. Authorisation for moving goods off the site has been checked.",
              name: "Check-out truck",
              _id: 60,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "In case of outsourced transportation planning the 4PL need information about transportation requirements as early as possible to update their planning. The preliminary plans will be updated later when more solid information becomes available.",
              _type: "Process",
              GUID: "abe34416-7e11-11e5-0a66-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "Send transportation request information, e.g. sales order and stock transfer order, to 4PL Transport Management System (TMS) to enable the 4PL TMS to perform transportation planning.",
              Outcome:
                "Preliminary orders (sales order, purchase order, transfer order) or deliveries transmitted to 4PL for processing.",
              name: "Provide transportation outlook to 4PL",
              _id: 52,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "Pre-booking of freight space at a carrier is done based on freight bookings. This is important for complex transports, e.g. involving sea freight or time critical transports.  The booking must be confirmed at a later point in any case.",
              _type: "Process",
              GUID: "106a8136-7e10-11e5-0a66-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "Reserve transportation capacity (freight space) with carrier.",
              Outcome: "Carrier freight space assured.",
              name: "Perform carrier pre-booking",
              _id: 327,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "In this process a truck about to enter the site and its driver are checked for suitability, e.g. purpose for entering the site, state of the vehicle. This includes the registering procedure (e.g. capturing drivers name and license plate).",
              _type: "Process",
              GUID: "057b285c-7caa-11e5-0a66-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "Alignment of transportation planning and actual vehicle movements in addition to site entry controls for trucks.",
              Outcome:
                "Vehicle and persons entering the site have been registered and considered suitable to fulfill their pick-up or delivery task. Driving route and unloading/pick-up location have been communicated and the recipient at the (un-) loading location has been informed.",
              name: "Check-in truck",
              _id: 59,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "This process covers the assignment of transportation tasks to a suitable carrier and freight orders or shipments under consideration of case-specific constraints and costs.",
              _type: "Process",
              GUID: "348c88f8-7e10-11e5-0a66-001bc640cd9b",
              Advisory_Notes:
                "Rules must be defined guiding the selection of carriers. These may consider load size, destination, route and means of transportation, transportation time, transport conditions (e.g. cold chain), compatibility of goods. Existing framework contracts may also be a criterion.",
              Type: "Business Process",
              Scope:
                "Selection and assignment of carriers based on a set of rules. ",
              Outcome: "Carrier is selected and assigned.",
              name: "Select carrier",
              _id: 328,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "This process describes the monitoring of transportation of goods to the recipient and initiation of a response in case of deviations.",
              _type: "Process",
              GUID: "8a80f575-7e0d-11e5-0a66-001bc640cd9b",
              Advisory_Notes:
                "Rules must be defined which means of tracking (GPS, regular update calls, stage related postings, etc.) are applied to which kind of transport (value based, customer based, product class based, etc.).",
              Type: "Business Process",
              Scope:
                "Monitoring of transportation status updates compared to plan. Issuing and tracking of corrective actions in case of deviations, and recording of updates.",
              Outcome: "Current transport status is recorded in the system.",
              name: "Monitor transportation",
              _id: 66,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "Requests For Quotation (RFQ) is sent to potential transportation suppliers, i.e. carriers. It contains a description of all relevant parameters of the required transportation services, such as: ***Transportation lanes   ***Quantities/Volumes  ***Delivery requirements (e.g. cold chain, hazardous goods)  ***Other value added requirements or terms  ***Term (duration) of contract  ***Terms and conditions",
              _type: "Process",
              GUID: "ec357bc2-7e11-11e5-0a66-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope: "The RFQ is created and submitted to potential vendors.",
              Outcome: "Defined requirement submitted to vendors.",
              name: "Request quote for transport sourcing",
              _id: 79,
              Business_Rules: "",
              Prerequisite: "",
            },
          ],
        },
        {
          BPMN_GUID: "bb1e6101-8ddc-11e5-0a66-001bc640cd9b",
          Type: "Process Group",
          Description:
            "This process group covers all processes related to the processing of inbound deliveries either from suppliers or from other plants.",
          _type: "Group",
          name: "Inbound Delivery Management",
          GUID: "bb1e6101-8ddc-11e5-0a66-001bc640cd9b",
          _id: 2159,
          process_of: [
            {
              BPMN_GUID: "NULL",
              Description:
                "When inventory-managed indirect materials are delivered, they need to be placed in the warehouse and their receipt must be documented.",
              _type: "Process",
              GUID: "dac2e2cd-7f04-11e5-0a66-001bc640cd9b",
              Advisory_Notes:
                "It is necessary to define how the actual physical storage will be performed. The procedure for informing the requester must be defined. ***In case a more sophisticated warehouse management is needed, refer to the processes of warehouse management within the domain I2O.",
              Type: "Business Process",
              Scope:
                "Upon delivery of inventory-managed indirect materials, it is confirmed and documented that the items are correct, in good condition and in accordance with requirements of the purchase order. These materials are inventory-managed on a quantity basis. The process covers simplified goods receipt without inbound delivery. Shown here as exemplary process, since it is assumed that consumable handling does not require sophisticated goods receipt process.",
              Outcome: "Indirect materials are received and available for use.",
              name: "Perform goods receipt of indirect materials",
              _id: 339,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "This process contains the planning of inbound warehouse activities related to either vendor deliveries or customer returns. ",
              _type: "Process",
              GUID: "8710390a-7caa-11e5-0a66-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "The inbound receipt schedule includes information on shipment of goods and additional information related to the shipment, such as order information, product description, physical characteristics, type of packaging, markings, carrier information, and configuration of goods within the transportation equipment.",
              Outcome: "Inbound receipt schedule is current and comprehensive.",
              name: "Update inbound receipt schedule",
              _id: 88,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "In this process goods receipt posting is performed thus triggering an update in inventory levels. As the material remains property of the customer no postings and accounting are required.",
              _type: "Process",
              GUID: "41a28420-3c0a-11e9-2ddf-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "Goods receipt for received goods is posted and paper documentation is created as required.",
              Outcome:
                "Goods receipt material document created, as required in addition goods receipt slip.",
              name: "Perform goods receipt  for customer-owned material",
              _id: 12,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "The advanced shipping notification (ASN) issued by the vendor provides information about the carrier, the amount of material, the mode of transport, and the company which executes the shipping. It also provides information about the delivery date. Most importantly it allows better material availability planning and hence more reliable confirmation to customers.",
              _type: "Process",
              GUID: "871038f2-7caa-11e5-0a66-001bc640cd9b",
              Advisory_Notes:
                "The following questions need to be answered:  ***Who receives the shipping information?  ***How are the documents received (EDI, Email etc.)?  ***What information is required?  ***Who communicates with the vendor?  ***Who must be made aware of the processed ASN internally?",
              Type: "Business Process",
              Scope:
                "The shipping notification is received from the vendor and forwarded to all business units involved in goods receipt e.g. warehouse management and quality management.    The ASN includes information on shipment of goods and additional information related to the shipment, such as order information, product description, physical characteristics, type of packaging, markings, carrier information, and configuration of goods within the transportation equipment.",
              Outcome:
                "Advanced shipping notification is received and distributed to involved business units and inbound delivery has been created.",
              name: "Receive and process advanced shipping notification",
              _id: 1212,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "A goods receipt (GR) is the physical inbound movement of goods or materials into the warehouse. It is a goods movement that is used to post goods received from external vendors or from in-plant production.",
              _type: "Process",
              GUID: "31aa9d5b-b9cd-11e9-0cfc-001bc641ce4f",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "Once the purchased materials are delivered to the requesting department a GR is created in Ariba and a notification on it is sent to the supplier and also to the own ERP system. After verification and check of the materials for correct type/product, correct quantity, and correct cost allocation and triggering any necessary put-away actions, the GR is confirmed. The confirmation is then transferred back to Ariba. ",
              Outcome: "GR is confirmed. ",
              name: "Create goods receipt (Ariba)",
              _id: 337,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "This process describes the goods receipt from inbound deliveries needing to be directly processed via cross-docking to outbound deliveries.",
              _type: "Process",
              GUID: "7eaea9b3-9ee7-11e8-2ddf-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "Materials, goods or finished goods that are arriving at an inbound dock and directly needing to be shipped outbound.",
              Outcome:
                "Goods receipt is performed and transfer order can be created. ",
              name: "Perform goods receipt to inbound delivery for cross-docking",
              _id: 338,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "This process describes subcontracting production. When a co-product is manufactured for sub-contracting, the semi-finished good is put onto stock as a co-product. Once the co-product is on stock, the subcontracting can be triggered. ",
              _type: "Process",
              GUID: "693c7fcf-94c9-11e8-2ddf-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "Semi-finished product produced internally and ready for subcontracting are staged for further processing. ",
              Outcome: "Co-products are checked and ready for subcontracting. ",
              name: "Perform goods receipt of co-product for subcontracting",
              _id: 1343,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "In this process goods are checked to ensure quantity, quality, and items match. After checking goods, the goods receipt posting is performed thus triggering an update in inventory levels and accounts payable postings. Finally, the goods are stored in an interim storage location and final putaway is triggered. ",
              _type: "Process",
              GUID: "871038f8-7caa-11e5-0a66-001bc640cd9b",
              Advisory_Notes:
                "Procedures for the visual check of received goods must be established including guidelines for exception handling of damaged or missing items or excess items.   Verification of goods for goods receipt is supported by label scanners or radio frequency scanners. Labels must be agreed between the supplier and the receiving party before shipment. A paper-based variant of the process must be possible for exceptional cases.",
              Type: "Business Process",
              Scope:
                "Goods receipt for received goods is posted and paper documentation is created as required.",
              Outcome:
                "Goods receipt material document created, as required in addition goods receipt slip.",
              name: "Perform goods receipt of stock items",
              _id: 87,
              Business_Rules: "",
              Prerequisite: "",
            },
          ],
        },
        {
          BPMN_GUID: "18b494b2-33ac-11e9-2ddf-001bc640cd9b",
          Type: "Process Group",
          Description:
            "This process group covers all processes involved in onsite logistic movements outside of warehouses. This includes for example production supply (staging activities) and transfer of produced goods to onsite warehouses with forklifts.",
          _type: "Group",
          name: "Internal Logistics",
          GUID: "18b494b2-33ac-11e9-2ddf-001bc640cd9b",
          _id: 2158,
          process_of: [
            {
              BPMN_GUID: "NULL",
              Description:
                "Production staging makes warehouse managed materials available within a supply area close to the consuming production workstation. This happens with reference to a production order. Goods issue posting to the process order typically is done when the materials are taken from the staging area to be consumed during production. In cases when the consumed quantity is known only after production goods issue posting is possible after confirming the process order.",
              _type: "Process",
              GUID: "05d0f3bb-7e0e-11e5-0a66-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "Creation of transfer requirement based on production order(s), transformation into transfer order, movement of materials from warehouse to staging area.",
              Outcome: "Transfer order for picking is created.",
              name: "Initiate production staging and create transfer order",
              _id: 343,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "The vendor provided goods are delivered directly to the staging area without prior warehousing. Goods receipt is posted against a production storage location or directly against a production order.   Unloading and transfer to staging may be facilitated by carrier or warehouse staff and may involve quality management (QM) procedures. Typically, QM will have been performed by the supplier in this integrated scenario.",
              _type: "Process",
              GUID: "8df4fb9e-7e0f-11e5-0a66-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "Transfer of unloaded goods to production staging, goods receipt and goods issue.",
              Outcome: "Material is available for production.",
              name: "Execute production staging directly from vendor",
              _id: 91,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "During unloading, a first verification of goods is supported by label scanners or radio frequency scanners. Labels are agreed between the supplier and the receiving party before shipment. A paper-based variant of the process must be possible for exceptional cases.",
              _type: "Process",
              GUID: "889f619b-e803-11e4-0a66-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "Goods are unloaded and compared against the shipping documents. A first visual check is performed.",
              Outcome: "Goods are unloaded and available for goods receipt.",
              name: "Unload goods",
              _id: 85,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "The process describes the loading procedure for a shipment to ensure that all packages of a delivery are shipped to the Customer. This process also can be supported with RF technology.",
              _type: "Process",
              GUID: "7bf5a968-7e0d-11e5-0a66-001bc640cd9b",
              Advisory_Notes:
                "The Shipment loading process is optional and can be executed paper based or with a RF Scenario",
              Type: "Business Process",
              Scope: "Loading of vehicle and loading confirmation.",
              Outcome:
                "Goods are loaded onto a shipment and are ready for goods issue.",
              name: "Load goods",
              _id: 62,
              Business_Rules: "",
              Prerequisite: "",
            },
          ],
        },
        {
          BPMN_GUID: "547c4fb1-8ddd-11e5-0a66-001bc640cd9b",
          Type: "Process Group",
          Description:
            "This process group covers all physical warehouse activities and material movements.",
          _type: "Group",
          name: "Warehouse Operations",
          GUID: "547c4fb1-8ddd-11e5-0a66-001bc640cd9b",
          _id: 2157,
          process_of: [
            {
              BPMN_GUID: "NULL",
              Description:
                "The cross-docking picking process involves taking goods for cross docking staging at the right quantity.",
              _type: "Process",
              GUID: "7eaea9c0-9ee7-11e8-2ddf-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "For cross-docking transfer of materials, it is necessary to specify which materials to pick and the storage location where to find the materials.",
              Outcome: "Goods picked from inbound picking location.",
              name: "Pick goods for cross-docking",
              _id: 323,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "The process describes picking of finished goods from a warehouse for delivery to a customer or intercompany.    ",
              _type: "Process",
              GUID: "e50fe5ce-6e30-11e8-2ddf-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "A picking list which is created in this process is enriched with additional information, e.g. the ID of the storage bin, quantity, etc. and will be transfered to delivery. ",
              Outcome: "Material is picked and ready for delivery.",
              name: "Perform pick for outbound logistics",
              _id: 64,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "The putaway process includes putting goods away in storage bins in the warehouse. There are putaway strategies in the Warehouse Management (WM) system that simplify the search for appropriate storage bins.",
              _type: "Process",
              GUID: "1c4d66ca-7ca9-11e5-0a66-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "Based on an goods receipt for an inbound delivery a transfer order is created to guide the putaway of goods within the actual warehouse.",
              Outcome:
                "Goods are stored in the warehouse and storage location has been confirmed.",
              name: "Perform putaway",
              _id: 49,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "The process describes picking of goods from a warehouse for delivery to a customer or intercompany.   ",
              _type: "Process",
              GUID: "7e8a0fc2-7e0d-11e5-0a66-001bc640cd9b",
              Advisory_Notes:
                "Picking items can be sorted to optimize the picking distance / resources Deviations from picking quantities require adaptations in the delivery item. It is possible to cover pick and pack combined in an automated procedure.",
              Type: "Business Process",
              Scope:
                "The process includes the creation of transfer order, picking and confirmation of picked items.",
              Outcome: "Items for delivery have been picked.",
              name: "Perform pick for production staging",
              _id: 319,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "This process describes the goods receipt process for products and by-products manufactured in production. After goods receipt, the system increases the stock of the goods at the relevant storage location.      ",
              _type: "Process",
              GUID: "1c4d66cd-7ca9-11e5-0a66-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "Goods or semi-finished goods that have been produced have to be posted on stock with a goods receipt.",
              Outcome:
                "Goods receipt posted (in many cases in a special stock as long as quality inspection is still ongoing).",
              name: "Perform goods receipt from production",
              _id: 1357,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "The transfer order is a document used in the warehouse management (WM) system that contains all the information needed to carry out the physical movement of material into the warehouse, out of the warehouse or from one storage place to another within the warehouse. Transfer can also occur to a production staging area or loading bay. Confirmation can happen manually, automatically, or by scanning.",
              _type: "Process",
              GUID: "d9267281-e82f-11e4-0a66-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "Verification of materials at storage location, confirmation of transfer order in system.",
              Outcome:
                "Transfer order has been confirmed. Material is available at the recorded storage location.",
              name: "Save and confirm transfer order",
              _id: 70,
              Business_Rules:
                "It must be defined which stock movements shall require confirmation.",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "This process describes the goods receipt process for products and by-products manufactured in production via re-working of a product normally due to quality issues.    ",
              _type: "Process",
              GUID: "45c3aeb7-9c7c-11e8-2ddf-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "Re-worked goods that have been produced have to be posted on stock with a goods receipt.",
              Outcome:
                "Goods receipt posted (in many cases in a special stock as long as quality inspection is still ongoing).",
              name: "Perform goods receipt from re-work",
              _id: 1352,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "Purpose of this process is to document guidelines for execution processes. This is needed in order to facilitate later compliance with the derived distribution plan.",
              _type: "Process",
              GUID: "e46c4390-765c-11e9-2ddf-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "Decisions taken and assumptions made during creation of the distribution plan need to be documented. Of special importance in this field is the planned allocation of products, customers and warehouses (e.g. standard delivering plant) to facilitate the compliance with planned material flows.",
              Outcome:
                "Critical decisions are documented in order to be considered during execution processes. ",
              name: "Document guidelines for distribution execution processes",
              _id: 321,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "Goods issue for materials and components used within the production process have to be posted after delivery and when they are moved from the warehouse for further processing. ",
              _type: "Process",
              GUID: "baf08434-7f1a-11e5-0a66-001bc640cd9b",
              Advisory_Notes:
                "The segregation of staging for production and actual posting of the GI of the components can differ depending on the physical and organizational set-up as well as on the required transparency on the material flow.",
              Type: "Business Process",
              Scope:
                "Goods issues are performed for materials and components retrieved from stock used in the respective production step. Some stock materials might be exempt and later be posted in the cause of a backflushing process.",
              Outcome: "Goods issue is posted.",
              name: "Perform goods issue of components for production",
              _id: 1328,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "A transfer order is used as an instruction to move materials from a source storage location to a destination storage location at a specified time. The transfer order contains information on the quantity of the material to be moved and requirements throughout a warehouse.",
              _type: "Process",
              GUID: "109ebff1-e804-11e4-0a66-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "The transfer order contains all the information required to execute the physical transfer of materials into the warehouse, out of the warehouse, or from one storage bin to another storage bin within the warehouse. ",
              Outcome: "Transfer order created.",
              name: "Create transfer order",
              _id: 320,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "Materials together with packing materials are packed into handling units suitable for loading and transport. Handling units can be nested (e.g. carton, pallet, container).",
              _type: "Process",
              GUID: "7bf5a959-7e0d-11e5-0a66-001bc640cd9b",
              Advisory_Notes:
                "It is possible to cover pick and pack combined in an automated procedure.",
              Type: "Business Process",
              Scope:
                "In this process the physical packing of materials, creation of handling units and labelling of the packages is invovled. ",
              Outcome: "Goods ready to be loaded.",
              name: "Perform pack",
              _id: 58,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "After external subcontracting is complete, a goods issue of the externally subcontracted material is posted to the internal production order. The inventory of the components at the relevant storage location will be decreased.",
              _type: "Process",
              GUID: "693c7fe4-94c9-11e8-2ddf-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "Before resuming production internally, a goods issue must be posted for the externally subcontracted materials. ",
              Outcome:
                "Goods issue posting of externally subcontracted material to internal production order. ",
              name: "Perform goods issue of processed materials to subsequent internal production step",
              _id: 1344,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "This process is used to record and store any reusable material derived from scrapping. ",
              _type: "Process",
              GUID: "feff4062-7ef0-11e5-0a66-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "After scrapping of asset is complete, components that can be reused are placed in a storage location with recording of the receipt into the warehouse.",
              Outcome: "Goods receipt of material from strapping posted.",
              name: "Store reusable materials",
              _id: 322,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "Goods issue for materials and components used within the production process have to be posted after delivery and when they are moved from the warehouse for further processing. ",
              _type: "Process",
              GUID: "f9a05553-7f25-11e5-0a66-001bc640cd9b",
              Advisory_Notes:
                "If weighing systems are used, they might be directly connected to the system and integrated in the user dialog.",
              Type: "Business Process",
              Scope:
                "Goods issues are performed for materials and components retrieved from stock used in the respective production step. Prior to the goods issue it is possible that weighing of components required for the production needs to be executed first.Goods issues are performed for materials and components retrieved from stock used in the respective production step. Prior to the goods issue it is possible that weighing of components required for the production needs to be executed first.",
              Outcome: "Goods issues are posted.",
              name: "Perform goods issue of components for process order",
              _id: 1338,
              Business_Rules: "",
              Prerequisite: "",
            },
          ],
        },
      ],
      _id: 312,
    },
    {
      BPMN_GUID: "3c32bd15-7b6d-4725-a421-611af90a7169",
      Type: "Process Cluster",
      _type: "Cluster",
      name: "E. Make",
      GUID: "9eca6991-2569-11e9-2ddf-001bc640cd9b",
      group_of: [
        {
          BPMN_GUID: "f70a7f51-8ddc-11e5-0a66-001bc640cd9b",
          Type: "Process Group",
          Description:
            "This process groups comprises activities executed during the physical production of products. It also includes confirmation of material consumption and technical completion of the production order. ",
          _type: "Group",
          name: "Production Operations",
          GUID: "f70a7f51-8ddc-11e5-0a66-001bc640cd9b",
          _id: 2165,
          process_of: [
            {
              BPMN_GUID: "NULL",
              Description:
                "The development result and the specifications are to be compared. If the result and the the specifications do not match, the product development process restarts.",
              _type: "Process",
              GUID: "35a36f71-09d8-11e9-2ddf-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope: "",
              Outcome: "",
              name: "Implement manufacturing process change",
              _id: 353,
              Business_Rules:
                "Test are done by R&D, AE (application engineering) and customer. Approved by project manager and gate review by EPSC. Documentation mainly done in QSYS.  Follow up concerning time line driven by EPSC and project manager.",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "In a project assembly situation it is necessary to perform assembly work at the customers location. For this purpose, work performed must be tracked and confirmed upon completion. ",
              _type: "Process",
              GUID: "8172e291-7ef7-11e5-0a66-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "Once the WBS element is released, assembly work can begin with execution of all the required tasks. At each stage of the assembly, completion of activities is followed by confirmation and reporting to the responsible project lead on-site at the customer location.",
              Outcome: "Assembly work is complete and confirmed",
              name: "Execute assembly work",
              _id: 1256,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description: "The actual production progress is monitored.",
              _type: "Process",
              GUID: "0f289faf-974c-11e9-0cfc-001bc641ce4f",
              Advisory_Notes:
                " ***it needs to be defined which events will trigger the adjustment of schedule or even feedback to production planning.",
              Type: "Business Process",
              Scope:
                "After orders have been released to production the actual progress needs to be monitored. In case of any special events or deviations follow-up activities as well as counter-measures need to be triggered.  Most important is the update of the production schedule and information/feedback to production planning in case of need.",
              Outcome:
                "Actual production progress is monitored and appropriate reactions are triggered.",
              name: "Monitor production progress",
              _id: 1326,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "After the production or after the process order is released shop-floor papers (alternative via electronic files) are generated and the supply of necessary materials to the production initiated. ",
              _type: "Process",
              GUID: "712673e8-7f1e-11e5-0a66-001bc640cd9b",
              Advisory_Notes:
                "There are three options for production preparation:   ***using PI Sheet (production instructions in electronic form)   ***using shop floor papers (paper based solution)   ***transfer production instructions to an external manufacturing control system.     PI sheets (including electronic signatures) are often used in the pharmaceutical industry in order to comply with regulatory requirements concerning batch records.",
              Type: "Business Process",
              Scope:
                "The production preparation process covers all activities required to start manufacturing based on the released process order or production order. Information about the actual manufacturing process and control parameters and instructions for the production operators must be transferred to the production control to be carried out (for this purpose control recipes are used). Shop-floor papers for the coordination of manufacturing tasks must be created and material supply to production initiated. ",
              Outcome:
                "All preparation steps for production are performed, physical manufacturing can start.",
              name: "Prepare production (Paper-based)",
              _id: 1322,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "After all preparations are carried out, the physical production can take place. Besides the manufacturing of products the process usually includes also their labeling and packing. ",
              _type: "Process",
              GUID: "8a5dc0c7-7f1c-11e5-0a66-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "Physical manufacturing of products is carried out and monitored by production control. After goods are produced, they must be packed and labeled. ",
              Outcome:
                "Products are manufactured using MES and deviations are recorded in MES.",
              name: "Manufacture products using manufacturing execution system",
              _id: 355,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "To reflect the actual stock situation, component consumption, which takes place during the manufacturing process must be recorded and stock level in the shop-floor papers lowered accordingly. In manufacturing this is often done via backflushing for components, which are not explicitly counted or weighed. Component consumption is recorded only after finishing the actual manufacturing operation calculated using the BOM or recipe and the actual recorded quantity of produced goods. Additionally or alternatively the consumption of material can be entered via scanner or manually in advance.",
              _type: "Process",
              GUID: "baf083fe-7f1a-11e5-0a66-001bc640cd9b",
              Advisory_Notes:
                "Within the material master and BOM it must be defined whether back flushing or any other specific type of GI (e.g. pipeline) can and will be performed.",
              Type: "Business Process",
              Scope:
                "The recording of material consumption for manufacturing process is carried out before, during, or after finishing manufacturing operation. The goods issue adjusts inventory and the value of the components is accounted to the order as actual costs.",
              Outcome: "Goods issues are posted",
              name: "Perform consumption for production",
              _id: 1320,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "An assembly is either done based on a simple sales BOM (bill of material) or on a more complex configuration logic. The outcome of this process serves as input for determining the price and conditions of the assembly order as well as for the actual assembly work.",
              _type: "Process",
              GUID: "97016803-eaa6-11e4-0a66-001bc640cd9b",
              Advisory_Notes:
                "If assembly orders are used for final filling or packaging, typically sales BOMs are used to specify the components (product and packaging material).  Sometimes, assembly orders are used for processes like blending and mixing. In such cases, either a configuration logic is established or the BOM is specified for each order specifically.  Even more complex configurations are typically handled using configurable products.",
              Type: "Business Process",
              Scope:
                "Once the sales order data for assembly production has been captured, the next step is to define how the product will be assembled and what components will be used for production.",
              Outcome:
                "The product configuration and required components are defined based on the sales order. ",
              name: "Configure product (Assembly)",
              _id: 356,
              Business_Rules:
                "Depending on the product and type of assembly, rules to limit options might be required.",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "After all preparations are carried out, the actually assembly work can be performed place. Besides the assembly of the products the process usually includes also labeling and packing.",
              _type: "Process",
              GUID: "baf08428-7f1a-11e5-0a66-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "The work tasks for the assembly are performed. This includes setting-up and dismantling of machines and tools if necessary.",
              Outcome:
                "The product is assembled, packaged, labelled and ready to be putaway.",
              name: "Assemble products",
              _id: 354,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "After all preparations are carried out, the physical production can take place. Besides the manufacturing of products the process usually includes also their labeling and packing.",
              _type: "Process",
              GUID: "712673e2-7f1e-11e5-0a66-001bc640cd9b",
              Advisory_Notes:
                "Set-up of manufacturing quality inspection must be defined-when it should be carried out (during production, based on first manufactured products, at end of production) and how it is initiated (information flow from production).   Production monitoring setup, exchange of production relevant information with external systems (e.g. process messages), production and downtime reporting requirements must be defined.   Label use (manufacturing, shipping, receiving, scanning, customer specific, general in-house label use) must be defined and considered for product labeling.",
              Type: "Business Process",
              Scope:
                "Physical manufacturing of products is carried out and monitored by production control.  Prior to the actual production of goods it is possible that machines need to be set up and after goods are produced, they must be packed and labeled. After labeling and packing production tools need to be dismantled.",
              Outcome: "Goods have been produced.",
              name: "Manufacture products",
              _id: 1324,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "During production process data is documented in the PI sheet.",
              _type: "Process",
              GUID: "0e8d9651-7559-11e9-2ddf-001bc640cd9b",
              Advisory_Notes:
                " ***With a higher degree of shopfloor integration it is possible to automate the capturing of data directly from the interfaces of available machine execution systems (MES).  ***captured data in PI sheet can directly be used for postings (e.g. consumption postings) or gathered for later processing after completion of process order.  ***Milestone confirmation data can be used for process analyses (e.g. throughput times or interruptions). But it is essential to keep in mind that this needs to be heavily supported by master data design (esp. structure of recipes) as well as the discipline on the shopfloor in case of manual capturing.",
              Type: "Business Process",
              Scope:
                "During production the shop floor operator receives step-by-step process instructions for the related process order. For some process steps the capturing of data is required (e.g. actually dosed quantities, process data as specified in inspection points, milestone confirmation).  This process shows manual data entry and confirmation as well as milestone confirmation.",
              Outcome:
                "Process data has been captured and confirmed and is available in the PI sheet for further processing.",
              name: "Capture process data in PI sheet",
              _id: 1340,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "Employees working on projects or services have to record the hours spend for it. This can be done using Cross-Application Time Sheet (CATS). CATS is a tool for recording working times and tasks. ",
              _type: "Process",
              GUID: "1f40c4e3-7ca3-11e5-0a66-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "Maintenance of working hours, approval of working hours by the manager, transfer of working hours to controlling.",
              Outcome: "Working hours are recorded.",
              name: "Record and approve working hours",
              _id: 1261,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "After the process order is released, production set-up and control needs to be prepared-process instructions transferred from process order, shop-floor papers generated and supply of necessary materials to the production initiated.",
              _type: "Process",
              GUID: "70fa5e73-e8f7-11e4-0a66-001bc640cd9b",
              Advisory_Notes:
                "There are three options for production preparation:   ***using PI Sheet (production instructions in electronic form)   ***using shop floor papers (paper based solution)   ***transfer production instructions to an external manufacturing control system.    In process industry PI sheet is the typical solution.",
              Type: "Business Process",
              Scope:
                "The production preparation process covers all activities required to start manufacturing based on the released process order. Information about the actual manufacturing process and control parameters and instructions for the process operators must be transferred to the production control to be carried out (for this purpose control recipes are used). Shop-floor papers for the coordination of manufacturing tasks must be created and material supply to production initiated.",
              Outcome:
                "All preparation steps for production are performed, physical manufacturing can start.",
              name: "Prepare production (PI sheet)",
              _id: 1341,
              Business_Rules:
                "It must be defined:   ***Which shop-floor papers are required (e.g. process order operations, goods issue slip),   ***What information must be included into control recipe, to what destinations they must be sent as well as communication channels   ***What checks are required before the release of control recipes and how they are set-up  ",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "After the production or process order is released shop-floor papers (or electronic files) are generated and the supply of necessary materials to the production initiated. ",
              _type: "Process",
              GUID: "ed92ae3d-9724-11e8-2ddf-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "The production preparation process covers all activities required to start manufacturing based on the released process order or production order. Information about the actual manufacturing process and control parameters and instructions for the production operators must be transferred to the production control to be carried out (for this purpose control recipes are used). Shop-floor papers for the coordination of manufacturing tasks must be created and material supply to production initiated. ",
              Outcome:
                "All preparation steps for production are performed, physical manufacturing can start.",
              name: "Prepare production with manufacturing execution system",
              _id: 352,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "After all preparations are carried out, the physical production can take place. Besides the manufacturing of products the process usually includes also their labeling and packing. ",
              _type: "Process",
              GUID: "c3fb9c10-94b8-11e8-2ddf-001bc640cd9b",
              Advisory_Notes:
                "Set-up of manufacturing quality inspection must be defined-when it should be carried out (during production, based on first manufactured products, at end of production) and how it is initiated (information flow from production).   Production monitoring setup, exchange of production relevant information with external systems (e.g. process messages), production and downtime reporting requirements must be defined.   Label use (manufacturing, shipping, receiving, scanning, customer specific, general in-house label use) must be defined and considered for product labeling.",
              Type: "Business Process",
              Scope:
                "Physical recycling of products is carried out and monitored by production control. ",
              Outcome: "Goods have been recycled. ",
              name: "Perform recycling",
              _id: 1358,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "After all preparations are carried out, the physical production can take place. Besides the manufacturing of products the process usually includes also their labeling and packing.  ",
              _type: "Process",
              GUID: "26d12177-7f27-11e5-0a66-001bc640cd9b",
              Advisory_Notes:
                " *** Re-labelling is often conducted in the warehouse whereas re-filling often needs to be done in production due to safety and environmental compliance (e.g. EX protection).  ***Set-up of manufacturing quality inspection must be defined-when it should be carried out (during production, based on first manufactured products, at end of production) and how it is initiated (information flow from production).    ***Production monitoring setup, exchange of production relevant information with external systems (e.g. process messages), production and downtime reporting requirements must be defined.    ***Label use (manufacturing, shipping, receiving, scanning, customer specific, general in-house label use) must be defined and considered for product labeling.  ***If products are serialized and new serial numbers are required, then the existing ones must be marked/written off as internal consumption for tracking purposes ",
              Type: "Business Process",
              Scope:
                "Physical re-filling and re-labeling of products is carried out and monitored by production control. ",
              Outcome: "Product is refilled/relabelled and packed.",
              name: "Process re-filling/re-labelling",
              _id: 1348,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "To reflect the actual stock situation, component consumption, which takes place during the manufacturing process must be recorded and stock level in the process instruction sheet lowered accordingly. In manufacturing this is often done via backflushing for components, which are not explicitly counted or weighed. Component consumption is recorded only after finishing the actual manufacturing operation calculated using the BOM or recipe and the actual recorded quantity of produced goods. Additionally or alternatively the consumption of material can be entered via scanner or manually in advance.",
              _type: "Process",
              GUID: "d5ab3667-689a-11e8-2ddf-001bc640cd9b",
              Advisory_Notes:
                "Within the material master and BOM it must be defined whether back flushing or any other specific type of GI (e.g. pipeline) can and will be performed.",
              Type: "Business Process",
              Scope:
                "The recording of material consumption for manufacturing process is carried out before, during, or after finishing manufacturing operation. The goods issue adjusts inventory and the value of the components is accounted to the order as actual costs.",
              Outcome: "Consumption postings have been conducted.",
              name: "Perform consumption for production in PI sheet",
              _id: 1339,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "After the production is finished, the process order related to it must be confirmed. This represents the technical closure of the production.",
              _type: "Process",
              GUID: "baf08401-7f1a-11e5-0a66-001bc640cd9b",
              Advisory_Notes:
                "Capturing of working times and posting on the production order can be done automatically in case of electronic work sheets.",
              Type: "Business Process",
              Scope:
                "The production or process order confirmation takes place after the manufacturing process is finished. Confirmation can be performed for an order, for a completed manufacturing phase (milestone) or a single production step and is used to finally record and confirm produced quantity of goods, used resources and performed activities as well as actual order completion dates. Depending on set-up this process can have impact on production planning by reducing planned production quantities, reducing capacity load, making adjustments to dependent requirements for components etc.",
              Outcome:
                "The confirmed production steps or the complete order is considered as technically closed. No further activities can be performed on it. However, commercial closure will be done later - typically in a month end procedure in controlling.",
              name: "Confirm production",
              _id: 1329,
              Business_Rules: "",
              Prerequisite: "",
            },
          ],
        },
        {
          BPMN_GUID: "e9f04f71-8ddc-11e5-0a66-001bc640cd9b",
          Type: "Process Group",
          Description:
            "Production Scheduling comprises processes for pooling the planned orders, levelling those to take out variability, and eventually sequencing the orders within the available capacity. Production scheduling facilitates effective utilization of free resources to fulfil demand.",
          _type: "Group",
          name: "Production Scheduling",
          GUID: "e9f04f71-8ddc-11e5-0a66-001bc640cd9b",
          _id: 2164,
          process_of: [
            {
              BPMN_GUID: "NULL",
              Description:
                "Manually create a process order in order to cater for the special circumstances of refilling or repacking. The situation that requires refilling or repacking of products can vary i.e. damaged packaging or just wrong labels. Depending on the necessary steps the process order requires manual selection and creation.",
              _type: "Process",
              GUID: "26d1217a-7f27-11e5-0a66-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "A process order needs to be created to trigger the refilling/repackaging process.",
              Outcome: "A process order is created.",
              name: "Create process order for re-filling/re-packaging",
              _id: 1349,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "Planned orders are scheduled in order to determine the actual production sequence per machine/workplace.",
              _type: "Process",
              GUID: "0f289faa-974c-11e9-0cfc-001bc641ce4f",
              Advisory_Notes:
                "***It needs to be decided how the schedule is going to be fixed. Often fixation of planned orders or the conversion to process orders is used to show, that scheduling has been conducted.In addition it is useful to discuss frozen horizons in order to keep production schedule stable.    ***Typically in scheduling the following aspects are taken into consideration: detailed capacities, change-over cost, alternative production versions/recipes, lot size optimization, component availability for JIT components. *** Real-time status information from shop floor integration systems might be used in order to keep schedule up to date.",
              Type: "Business Process",
              Scope:
                "After receiving planned orders from production planning these need to be brought into sequence for actual production. During this scheduling detailed restrictions of the different needed machines/workplaces are considered. Furthermore change-over optimization is conducted in order to achieve a cost efficient production sequence.",
              Outcome: "",
              name: "Schedule planned orders",
              _id: 1327,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "Assembly orders are used for highly standardized make to order products which typically have a short delivery time. In order to expedite the process an assembly order is automatically created after saving the sales order based on master data settings for BOM and routing.",
              _type: "Process",
              GUID: "baf08422-7f1a-11e5-0a66-001bc640cd9b",
              Advisory_Notes:
                "Use a Sales BOM where there is a fixed combination of components and the customer needs confirmation of each item. The component items cannot be altered or removed from a sales order. Other items cannot be inserted between the parent and component items in the sales order. However, the quantities of the component items can be modified.  Use the Assembly BOM when you would not expect the customer to check each component in the order.",
              Type: "Business Process",
              Scope:
                "Based on master data, a production order for assembly is generated.",
              Outcome: "Assembly order created.",
              name: "Convert sales order demand to assembly order",
              _id: 348,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "This process explains the collection of a family of demands and scheduling them for serial production. ",
              _type: "Process",
              GUID: "88c21247-7f1c-11e5-0a66-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "For serial production, demands are placed for products to be produced within the same family. It must be planned that production lines are efficiently planned to maximize production lots. ",
              Outcome: "Campaign production scheduled.",
              name: "Schedule confirmed demands within campaign",
              _id: 346,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "Once an assembly order is created, the system copies the routing and BOM into the production order and performs the calculation of capacity and cost, and schedules the order.",
              _type: "Process",
              GUID: "b5e81079-7f1a-11e5-0a66-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "Master data get copied into the order and timelines, requirements, and costs are calculated. Finally, the production order has to be released.",
              Outcome:
                "Production order for assembly scheduled, costs and requirements (material and capacity) generated and order released.",
              name: "Schedule and release assembly order",
              _id: 349,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "As a result of a planning procedure planned orders are created. Those need to be converted to production orders.",
              _type: "Process",
              GUID: "712673f1-7f1e-11e5-0a66-001bc640cd9b",
              Advisory_Notes:
                "Regardless whether a production order gets created directly or via converting a planned order material, routing and BOM related data get retrieved again from respective master data.   Possible conversion options must be defined:   ***one planned order to one production order,   ***several planned orders to one production order,   ***partial conversion of planned orders.  ",
              Type: "Business Process",
              Scope:
                "Within a defined horizon, planned production orders get converted in order to become executable production orders. The conversion typically includes the adding of production specific information.   A production order describes the production of the specified product following the selected production version (combination of BOM alternative and version or routing). The production order is used to plan the quantities, dates, and resources of the manufacturing process, to control order execution, and to define rules for the account assignment and settlement of the costs incurred.    A routing version is selected, its operations and sequences are transferred to the production order. The bill of materials (BOM) is exploded and the items in the BOM are transferred to the order. Reservations are generated for bill of material items held in stock. The planned costs for the order are generated. The capacity requirements are generated for the work centers. Purchase requisitions are generated for non-stock items and externally-processed operations.",
              Outcome:
                "Planned order is partly or completely converted to a production order.",
              name: "Convert planned order to production order",
              _id: 1325,
              Business_Rules:
                "It must be defined, how production orders can be created - either by converting a planned order to a production order only, or also by creating it manually. Latter bypasses planning violating best practices principals, though.",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "A production order for recycling needs to be created for the breakdown and recycling of components. The nature of recycling processes require the selection of required operations and components either selected from standard routings and standard BOMs or captured manually. ",
              _type: "Process",
              GUID: "c3fb9beb-94b8-11e8-2ddf-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "A recycling order is created with its own product as component. Within the order the required operations and additionally required components are defined and batches will be assigned if applicable.",
              Outcome:
                "The rework order with the required operations and components is created.",
              name: "Create production order for recycling",
              _id: 1356,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "When rework is to be done a production order for rework needs to be created. The nature of reworking processes require the selection of required operations and components either selected from standard routings and standard BOMs or captured manually.  A process order can also be used in the process industry.",
              _type: "Process",
              GUID: "5a5e62f7-7f29-11e5-0a66-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "A rework order is created with its own product as component. Within the order the required operations and additionally required components are defined and batches will be assigned if applicable.",
              Outcome:
                "The rework order with the required operations and components is created.",
              name: "Create production order for rework",
              _id: 1351,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "Specific refurbishment are planned by creating planned orders in material requirements planning (MRP). When required, the planned order is converted into a refurbishment order to start the refurbishment process.",
              _type: "Process",
              GUID: "2359bc58-7efc-11e5-0a66-001bc640cd9b",
              Advisory_Notes:
                "MRP can be used to generate planned orders for refurbishment for rotable or repairable material types. Special settings in the material master are required to activate this process.",
              Type: "Business Process",
              Scope:
                "Refurbishment process is started by converting the planned order into a refurbishment order. The planned order provides the quantity and dates for the refurbishment order.",
              Outcome: "Planned order converted into refurbishment order.",
              name: "Convert planned order into refurbishment  order",
              _id: 1293,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "The assignment of component batches processes covers the determination of batch assignment and their strategy used in production.  Normally, there are 3 types of determinations utilized: manual assignment, automatic assignment or via submission of date to the expert system. ",
              _type: "Process",
              GUID: "cf57b44e-e9c8-11e4-0a66-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "The assignment can happen during production planning,production order release, or during the production staging goods issue.  ",
              Outcome: "Component batches assigned. ",
              name: "Assign component batches",
              _id: 347,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "Manufacturing orders can be created in advance to the planned manufacturing start date and are not bound to the availability of required materials or capacity. The release process represents the final step to initiate the manufacturing process. Unless the order is released, no further processing is possible.",
              _type: "Process",
              GUID: "024d6439-e8f7-11e4-0a66-001bc640cd9b",
              Advisory_Notes:
                "The release will also impact the status of the material reservations of the components. Latest at this point in time, the reservations should be treated as firm and no other planning or logistics process should have access to the reserved quantities anymore.",
              Type: "Business Process",
              Scope:
                "Created manufacturing orders must be released, before they can be processed by production. The release is done, when the release date has been reached and when the required materials and capacity are available. The availability of materials and capacity should be checked within the release process.",
              Outcome: "Production order is released.",
              name: "Release production order",
              _id: 1321,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "Planned orders created in material requirement planning or detailed scheduling are only planning elements, in order to trigger actual manufacturing in process industry they must be converted to process orders.",
              _type: "Process",
              GUID: "88c21232-7f1c-11e5-0a66-001bc640cd9b",
              Advisory_Notes:
                "Regardless whether a process order gets created directly or via converting a planned order material, recipe and BOM related data get retrieved again from respective master data.   Possible conversion options must be defined:   ***one planned order to one process order,   ***several planned orders to one process order,   ***partial conversion of planned orders.  ",
              Type: "Business Process",
              Scope:
                "Within a defined horizon, planned orders get converted in process orders to become executable. The conversion typically includes the adding of production specific information.   A process order describes the production of batches (materials) in a production run or the rendering of services. It is generated from the master recipe and contains all the information specified during process planning. You use a process order to plan the quantities, dates, and resources of the manufacturing process, to control process order execution, and to define rules for the account assignment and settlement of the costs incurred.    A recipe version is selected, its operations and sequences are transferred to the process order. The bill of materials (BOM) is exploded and the items in the BOM are transferred to the order. Reservations are generated for bill of material items held in stock. The planned costs for the order are generated. The capacity requirements are generated for the work centers. Purchase requisitions are generated for non-stock items and externally-processed operations.",
              Outcome:
                "Planned order is partly or completely converted to a process order.",
              name: "Convert planned order to process order",
              _id: 1336,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "Process orders can be created in advance to the planned manufacturing start date and are not bound to the availability of required materials or capacity. The release process represents the final step to initiate the manufacturing process. Unless the order is released, no further processing is possible.",
              _type: "Process",
              GUID: "cf57b45a-e9c8-11e4-0a66-001bc640cd9b",
              Advisory_Notes:
                "The release will also impact the status of the material reservations of the components. Latest at this point in time, the reservations should be treated as firm and no other planning or logistics process should no longer have access to the reserved quantities.",
              Type: "Business Process",
              Scope:
                "Created process orders must be released, before they can be processed by production. The release is done, when the release date has been reached and when the required materials and capacity are available. The availability of materials and capacity should be checked within the release process. The process order release may also include batch determination and assignments. ",
              Outcome: "Process order is released.",
              name: "Release process order",
              _id: 1337,
              Business_Rules: "",
              Prerequisite: "",
            },
          ],
        },
        {
          BPMN_GUID: "f734cb01-8dd9-11e5-0a66-001bc640cd9b",
          Type: "Process Group",
          Description:
            "This group covers processes for the managing of projects, from creating structures to reporting and integration. Key areas of project management include time scheduling, work planning, and resource related billing. It is allocated to make due to the project character of engineer-to-order production processes including design and construction of related production lines.",
          _type: "Group",
          GUID: "f734cb01-8dd9-11e5-0a66-001bc640cd9b",
          name: "Project Management, Construction and Design",
          _id: 2163,
          process_of: [
            {
              BPMN_GUID: "NULL",
              Description:
                "The budget is the approved cost structure for a project. It is used by management to approve the expected development of order costs over a given timeframe. Availability control enables the control of costs actively by issuing warnings and error messages when costs are incurred.     ",
              _type: "Process",
              GUID: "ab66384d-7e4e-11e5-0a66-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "The original budget is allocated to the WBS elements. After a specified point in time, the budget can only be corrected using budget updates.",
              Outcome: "The budget is allocated to the WBS elements.",
              name: "Perform budget planning",
              _id: 198,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "The project maps the work structure and is the object for collecting costs incurred for the internal work.This allows the capturing of times and expenses incurred on project tasks.",
              _type: "Process",
              GUID: "400b3f50-9627-11e8-2ddf-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "The project is created from a standard template. The settlement rule is generated, forming the basis for capturing project related costs.",
              Outcome: "Project is created and related costs can be allocated.",
              name: "Create internal project",
              _id: 1282,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "In order to ensure execution of the project in a timely manner and the controlling of costs, planning and scheduling of the project must be performed.      ",
              _type: "Process",
              GUID: "1cdd8084-7ca3-11e5-0a66-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "Scheduling is carried out according to project timelines, milestones and deliverables.  The cost of materials and planned labour comes from the project template. This information is incorporated into the project plan. ",
              Outcome:
                "Project plan is created and project activities are scheduled.",
              name: "Plan and schedule project",
              _id: 1280,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "Project execution phase involves fulfilment of planned activities, collecting and reporting on project progress, as well as tracking of time, cost, and deliverables.       ",
              _type: "Process",
              GUID: "44b6ae91-7eed-11e5-0a66-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "Once the project has been released, it is executed in accordance with the project plan. Depending on the project status, it is possible to assign purchase requisitions and purchase orders, perform goods receipt / issue and book of invoices, time confirmations and other allocations to project elements. During project execution, all actual costs are considered during the individual month end closing activities (e.g. settlement).",
              Outcome: "Project related activities are executed and tracked.",
              name: "Execute project",
              _id: 1300,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "When all requirements are met, the project is approved, released and communicated to the stakeholders.      ",
              _type: "Process",
              GUID: "1cdd808a-7ca3-11e5-0a66-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "Project deliverables, plan and resources assignments are reviewed. If all requirements are fulfilled the project is released for execution. It is possible to release the whole project or only a phase of the project.",
              Outcome:
                "The entire project or a project phase is released for execution.",
              name: "Approve and release project",
              _id: 1266,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "A project is technically closed if work is completed but costs are still expected to accrue.   ",
              _type: "Process",
              GUID: "1f40c4ed-7ca3-11e5-0a66-001bc640cd9b",
              Advisory_Notes:
                "For Capital Investment Projects (CAPEX) the Asset master has to be maintained and the relevant asset related information updated on the settlement rule for the project. This is to enable costs to be settled to the asset when settlement is run after technical completion.",
              Type: "Business Process",
              Scope:
                "Once all project related activities have been performed and confirmed, the project is completed by setting the project status to technically complete. This prevents further changes to the project.",
              Outcome: "The project is technically complete.",
              name: "Complete project technically",
              _id: 1263,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "A project is created in order to add further details to the inquiry (for example, scheduling, payment processing). This ensures calculation of the cost as the basis for determining the gross price in the quotation.",
              _type: "Process",
              GUID: "c29e2dc6-e996-11e4-0a66-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "The project is created with activities, components, and milestones in a complex structure.",
              Outcome:
                "Project is created, and timeline, costs, etc. can now be assigned.",
              name: "Create project",
              _id: 202,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "In a project environment, several personnel resources may be assigned to a work center. In case capacity planning is performed on work center level, and not assigned to a single person a detailed and meaningful reporting will not be possible. Therefore, assigning personnel resources enables capacity planning on personnel level to avoid overwork or to assign personnel with relevant qualification. ",
              _type: "Process",
              GUID: "ebe8c58f-e753-11e4-0a66-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "Once the customer order is created, activities are assigned to personnel resources. To ensure the advanced procurement, the status of a Work Breakdown Structure (WBS) element has to have the status Released.",
              Outcome:
                "Required personnel resources are assigned to project activities.",
              name: "Assign personnel resources to project",
              _id: 1262,
              Business_Rules: "",
              Prerequisite: "",
            },
          ],
        },
      ],
      _id: 344,
    },
    {
      BPMN_GUID: "a3bc4362-2c57-41b6-80a2-6c2aabde4f50",
      Type: "Process Cluster",
      _type: "Cluster",
      name: "G. Sell",
      GUID: "3f93c241-256a-11e9-2ddf-001bc640cd9b",
      group_of: [
        {
          BPMN_GUID: "80cf5b71-8ddd-11e5-0a66-001bc640cd9b",
          Type: "Process Group",
          Description:
            "This process group covers all processes involved in handling a customer inquiry to creating a quotation in response to the inquiry.",
          _type: "Group",
          name: "Pre-order Management",
          GUID: "80cf5b71-8ddd-11e5-0a66-001bc640cd9b",
          _id: 2171,
          process_of: [
            {
              BPMN_GUID: "NULL",
              Description:
                "Quotation is a binding commitment and can deal with significant product quantities and monetary values. Therefore, it must be ensured that information included into quotation is correct and corresponds to companys interests, which is ensured through an approval process.",
              _type: "Process",
              GUID: "f806160f-7e50-11e5-0a66-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "After passing all checks related to the quotation, the commercial aspects of the quotation are approved and quotation is sent to the customer.",
              Outcome: "Quotation is sent to customer.",
              name: "Approve and send out quotation",
              _id: 213,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "Once the confirmation is provided that the customer order can be fulfilled, the product price must be calculated (or extracted from the system) taking into account relevant conditions (e.g. rebates, shipping condition, customer price groups).",
              _type: "Process",
              GUID: "197ea3f9-eaa9-11e4-0a66-001bc640cd9b",
              Advisory_Notes:
                "In addition to the pricing itself, the workflows and required approvals have to be defined. Consider all types of sales (priced samples, free of charge samples etc.) and possible promotions and rebates.  In case a quotation has been placed, the conditions between both should be aligned or at least an interaction with the customer has to take place in case of any deviation.",
              Type: "Business Process",
              Scope:
                "Pricing takes into account all conditions related to the product and customer.",
              Outcome:
                "Pricing and conditions related to the order are generated, documented and agreed with customer.",
              name: "Determine product price and conditions",
              _id: 157,
              Business_Rules:
                "Pricing schemas are typically defined upfront.  It must be defined who (and with which limitations) has the rights to adjust prices and conditions within the sales process.   The price and trading conditions of the product must be based on a set of guidelines that take into account:   ***Cost of product   ***Market value of product   ***Customers perceived value of product    ***Relationship with customer (rebates etc.)   ***Defined profit margin expectations  ",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "In order to quote a price and conditions to the customer, the quotation must be created and processed. Depending on the business model this may include collecting different information and processing various checks. Base for the creation of a quotation are customer details and information from the customer inquiry.",
              _type: "Process",
              GUID: "5fbf8d47-82c7-11e5-0a66-001bc640cd9b",
              Advisory_Notes:
                "In case standard agreements exist, the check with the supplier might be replaced by an internal check against standard lead times documented in the respective master data.",
              Type: "Business Process",
              Scope:
                "A quotation document is prepared based on information captured in the inquiry. The sales price is calculated and conditions determined (e.g. rebates, shipping conditions, pre-payment requirements etc.). The availability, and potentially also conditions and price are checked with the supplier.",
              Outcome: "A quotation is prepared and can be formally processed.",
              name: "Create quotation for trading goods",
              _id: 214,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "Back order processing must be performed in case the ATP check did not provide a satisfying result, or an unplanned change to the supply situation requires a reconsideration/ recalculation of already confirmed quantities and dates. The process might affect other sales orders, stock transfer orders, and confirmed documents such as material reservations, production or repair orders, which compete with the respective sales documents for the available quantity of the requested material. The resolution of a shortage situation includes the alignment (and consequent adjustment) of the supply situation as well as a reallocation of already confirmed and allocated ATP quantities. All affected parties must be involved or informed accordingly.",
              _type: "Process",
              GUID: "3e124dd1-602a-11e8-2ddf-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "After the ATP situation has been analysed, a solution is elaborated by e.g. rescheduling the order confirmation or evaluating other supplier options. Finally the solution is communicated with all relevant parties.",
              Outcome:
                "Aligned supply situation and communicated with all affected parties ",
              name: "Perform back order processing",
              _id: 177,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "When a prospective customer sends an request for a  project, the information must be captured and stored for further processing in form of an inquiry.     ",
              _type: "Process",
              GUID: "ab66382c-7e4e-11e5-0a66-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "An inquiry of a customer that would require a project for implementation is recorded. A respective project number is then assigned to the inquiry.",
              Outcome: "Customer inquiry for a  project is created.",
              name: "Create inquiry for customer project",
              _id: 199,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "The quotation is a binding commitment and can deal with significant product quantities and monetary values. Therefore, it must be ensured that the information included in the quotation is correct and corresponds to the companys interests, which is ensured through the approval process.",
              _type: "Process",
              GUID: "e22cbe01-e8c2-11e4-0a66-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "After the quotation is created, it needs to be send to customer and receive feedback. Based on customer response, a quotation is updated, rejected or accepted.",
              Outcome:
                "After passing all checks related to the quotation, the commercial aspects of the quotation are approved and the quotation is sent to the customer.",
              name: "Send quotation",
              _id: 200,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "ATP check for quotation verifies whether goods related to a quotation will be available at the indicated date. The check performed is dependent on the underlying sales scenario, i.e. stocked products, assembly to order, make to order.",
              _type: "Process",
              GUID: "c046c5f1-eaa3-11e4-0a66-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "Based on the product and connected sales scenario, the check is performed against stock, production capability or component availability. ",
              Outcome: "ATP check is performed.",
              name: "Check capability to promise for quotation",
              _id: 382,
              Business_Rules:
                "It must be defined:   ***How the availability check will be done   ***What type of actual stock or planned changes are considered for the availability check   ***Whether and how to reserve material / capacity",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "Capable to Promise (CTP) verifies whether a customers order can be fulfilled by production within a given time frame.   .  ",
              _type: "Process",
              GUID: "bf26ba8a-eaa4-11e4-0a66-001bc640cd9b",
              Advisory_Notes:
                "Depending on the product structure it must be decided how many production stages are considered during the CTP check and how stock levels of intermediates are factored in.    Even more complex rules can be required in case other intermediates can be used as substitutes.",
              Type: "Business Process",
              Scope:
                "After the customer order data is captured for a make to order production, it must be checked whether the order can be fulfilled. It is verified whether capacity is available to manufacture the finished product in the requested quantity and for the desired delivery date. In doing so, it must be ensured that the order can be placed and fits into the production plan. Components might be checked directly or their availability is considered as given based on the production plan",
              Outcome: "CTP check is performed.",
              name: "Check capability to promise",
              _id: 274,
              Business_Rules:
                "It must be defined what capacity is checked and reserved:   ***Capacity at local manufacturing unit     ***Capacity at all manufacturing units owned by the global organisation   ***Capacity of external contractors      It is necessary to define what consumes capacity and therefore reduces the available capacity.    The decision should be made whether capacity can be adjusted due to consumer demand. A production campaign for a certain type of product can be extended/increased.",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "In order to quote a price and conditions to the customer, the quotation must be created and processed. Depending on the business model this may include collecting different information and processing various checks. Base for the creation of a quotation are customer details and information from the customer inquiry.",
              _type: "Process",
              GUID: "7c55365a-7e54-11e5-0a66-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "A quotation document is prepared based on information captured in the inquiry. The sales price is calculated and conditions determined (e.g. rebates, shipping conditions, pre-payment requirements etc.). The requested quantity and delivery data is checked against production capabilities and/or production plans.A quotation document is prepared based on information captured in the inquiry. A sales price is calculated and conditions determined (e.g. rebates, shipping conditions, pre-payment requirements etc.). The requested quantity and delivery data is checked against production capabilities and/or production plans.",
              Outcome: "A quotation is prepared and can be formally processed.",
              name: "Create quotation for MTO",
              _id: 383,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "In order to quote a price and conditions to the customer, the quotation must be created and processed. Depending on the business model this may include collecting different information and processing various checks. Base for the creation of a quotation are customer details and information from the customer inquiry. ",
              _type: "Process",
              GUID: "f80615e2-7e50-11e5-0a66-001bc640cd9b",
              Advisory_Notes:
                "For inquiries from and quotations for new potential customers, a dummy customer master might be used. Latest with the order entry, a real customer master is created and the sales order will be captured with reference to it.",
              Type: "Business Process",
              Scope:
                "A quotation document is prepared based on information captured in the inquiry. The sales price is calculated and conditions determined (e.g. rebates, shipping conditions, pre-payment requirements etc.). The requested delivery data is checked against availability.",
              Outcome: "Quotation is prepared and can be formally processed.",
              name: "Create quotation",
              _id: 273,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "Process describes the initial capturing and checking of an inquiry. In comparison to a lead an inquiry contains an explicit request for a quotation. Receiving the customer inquiry and capturing it in a format necessary for further processing and initiating pre-sales business processes. ",
              _type: "Process",
              GUID: "f80615ee-7e50-11e5-0a66-001bc640cd9b",
              Advisory_Notes:
                "For inquiries from and quotations for new potential customers, a dummy customer master might be used. Latest with the order entry, a real customer master gets created and the sales order will be captured with reference to it.",
              Type: "Business Process",
              Scope:
                "Once a customer inquires about a certain product, the data must be recorded, stored and forwarded for further processing. ",
              Outcome:
                "All relevant information regarding the customers inquiry is documented. The information captured provides the basis for preparing a quotation or reservation of goods.",
              name: "Receive and capture customer inquiry",
              _id: 171,
              Business_Rules:
                "Rules should determine in which cases inquiries have to be documented.   There are several aspects why inquiries should be captured in the system:   ***Avoidance of duplicated capturing of data   ***Considering the information in planning   ***Internal compliance and approval guidelines   ***Using the information for reporting and identifying reasons for lost sales  ",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "This process ensures the creation of a quotation based on the information in the inquiry. The quotation is subsequently sent to the prospective customer.",
              _type: "Process",
              GUID: "ab663832-7e4e-11e5-0a66-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "The inquiry is first copied into the quotation. After maintaining all the details for the labour and materials used in the project, the pricing information is added to the quotation. Project milestones are transferred to the quotation. By means of a project version a snapshot of the project is created before sending out the quotation.",
              Outcome: "The quotation is forwarded to the customer",
              name: "Create quotation with ref. to customer inquiry",
              _id: 203,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "The results of the final ATP check need to be reviewed and the order confirmation to the customer is sent out. If the ATP check has not already been (re-)performed, this needs to be done now out of the individual sales document to consider the newly available ATP quantities and to adjust confirmation dates.",
              _type: "Process",
              GUID: "3e124dd3-602a-11e8-2ddf-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "After reviewing the results of the ATP, the customer needs to be informed via order confirmation or the supply situation must be clarified with the MRP planner.",
              Outcome: "Reviewed ATP and communicated order confirmation",
              name: "Review ATP and reconfirm order",
              _id: 176,
              Business_Rules: "",
              Prerequisite: "",
            },
          ],
        },
        {
          BPMN_GUID: "8e7c2e61-8ddd-11e5-0a66-001bc640cd9b",
          Type: "Process Group",
          Description:
            "This process group covers all processes involved in creating a contract. This includes also rebate and scheduling agreement.",
          _type: "Group",
          name: "Customer Contracts",
          GUID: "8e7c2e61-8ddd-11e5-0a66-001bc640cd9b",
          _id: 2170,
          process_of: [
            {
              BPMN_GUID: "NULL",
              Description:
                "A simple approval of the sales contract is performed prior to sending out to the business partner.",
              _type: "Process",
              GUID: "1de237b6-6a51-11e8-2ddf-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "Request approval of sales contract and issue released sales contract to customer.",
              Outcome: "Released sales contract",
              name: "Approve and issue sales contract",
              _id: 266,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "After an outline agreement with the customer regarding items, prices, delivery quantities and dates is negotiated, a scheduling agreement is created in the system containing the agreement details (individual deliveries form schedule lines) and serve as base for further performing of actual deliveries.",
              _type: "Process",
              GUID: "4021aa79-7e5b-11e5-0a66-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "A scheduling agreement is created in the system. Schedule lines for individual deliveries can be entered either at creation of the scheduling agreement or later. Similar to a standard sales order, dangerous goods, global trade, availability and credit management checks are performed, when the scheduling agreement is created. Because of the nature and often long lead times of such agreements, the result of an ATP check and credit check might only be for information purposes.",
              Outcome:
                "Scheduling agreement is created and individual deliveries can be carried out.",
              name: "Capture scheduling agreement",
              _id: 370,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "When a change in the customer data is needed, the change can be requested in a particular area,  as well as in all areas at the same time. The reason for change should be defined and approved. The data for one customer can also differ for each sales area, therefore it is possible to change data for one customer in only one sales area. The sales area is a combination of sales organization, distribution channel and division. Therefore, when maintaining sales document, it is necessary to assign a sales group. If you edit a customer master record, you must enter the customer number and the sales area in order to access screens containing sales and distribution data.",
              _type: "Process",
              GUID: "57c03164-9173-11e8-2ddf-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope: "Customer view to be maintained.",
              Outcome:
                "Request documented in specific system with required detail based on internal policy.",
              name: "Maintain the customer and sales data",
              _id: 368,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "Layout profiles assignment to materials and customers including issuing of certificates in O2C attributes",
              _type: "Process",
              GUID: "e4e8df94-5f50-11e9-2ddf-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope: "",
              Outcome: "",
              name: "Manage business partner profile",
              _id: 369,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "Process to reflect reporting requirements on monitoring sales contracts. This can be on volumes agreed upon or the realization of cash discounts. ",
              _type: "Process",
              GUID: "1de237c2-6a51-11e8-2ddf-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope: "Monitor fulfillment of contractual obligations ",
              Outcome: "Sales contract report",
              name: "Monitor sales contracts",
              _id: 269,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "This process describes the formalization of negotiation results within a sales contract. The actual sequence and amount of negotiations will vary according to the subject matter and business relation. This is not reflected in the process.",
              _type: "Process",
              GUID: "1de237ae-6a51-11e8-2ddf-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope: "Negotiate and maintain sales contract conditions",
              Outcome: "Sales contract with maintained conditions",
              name: "Negotiate and maintain sales and contract conditions",
              _id: 270,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "This process reflects follow up activities that may be required in order to fulfill certain sales contracts. One example could be the creation of consignment warehouses adaption of stock levels in order to fulfill service levels.",
              _type: "Process",
              GUID: "1de237b9-6a51-11e8-2ddf-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope: "Trigger activities according to contract type",
              Outcome: "Contract follow-up activities defined and documented",
              name: "Perform contract follow-up activities",
              _id: 271,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "Depending on the nature of a tender the activities required to be performed in order to reply sufficiently may vary strongly. These can include (non-exhaustive):   ***Product   ***Development/ technologies   ***Service levels Supply   ***Chain   ***Legal",
              _type: "Process",
              GUID: "3eda628e-9fd5-11e8-2ddf-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "Trigger definition of legal terms, provision of certifications and product specifications.",
              Outcome: "All documents required for tender",
              name: "Perform tender management activities",
              _id: 371,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "This process describes the initial creation of a sales contract. Prerequisite is the existence of a business partner. Typically, if a contract already exists it may solely need a renewal or update.",
              _type: "Process",
              GUID: "1de237a4-6a51-11e8-2ddf-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope: "Creation of sales contract",
              Outcome: "Sales contract",
              name: "Create sales contract",
              _id: 267,
              Business_Rules: "",
              Prerequisite: "",
            },
          ],
        },
        {
          BPMN_GUID: "f7c16be1-8ddf-11e5-0a66-001bc640cd9b",
          Type: "Process Group",
          Description:
            "Returnable packaging consists of materials that are stored at the customer location but which remain the property of your company. The customer is only required to pay you for the returnable packaging if he does not return it by a specified time. Since stocks of returnable packaging form part of your valuated stock even when they are at the customer site, you must manage, assign and track them in your system.",
          _type: "Group",
          name: "Returnable Packaging Management",
          GUID: "f7c16be1-8ddf-11e5-0a66-001bc640cd9b",
          _id: 2169,
          process_of: [
            {
              BPMN_GUID: "NULL",
              Description:
                "The assignment of returnable packaging to a sales order ensures that the cost and quantity of  the materials are mapped to the customer order. ",
              _type: "Process",
              GUID: "605f699d-7e4f-11e5-0a66-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "Information about the quantity and value of the returnable packaging, as well as the plant to which the materials are to be returned is linked to the customer order and documented accordingly.",
              Outcome:
                "Returnable packaging information is recorded with the sales order data.",
              name: "Assign returnable packaging to sales order",
              _id: 379,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "In a rental situation it is necessary to track returnable distribution packaging in order to:   ***Know how long the packaging will stay with the customer   ***Know what units are with which customer   ***Record when packaging were issued and when they are returned.",
              _type: "Process",
              GUID: "0182227a-7e4f-11e5-0a66-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "Once returnable packaging have been sent out to the customer, all relevant information concerning the packaging is collected and recorded. With this information the movement of the packaging is tracked until returned to the storage facility of the organisation.",
              Outcome:
                "Returnable distribution packaging can be tracked after issuing them to the customer.",
              name: "Track returnable distribution packaging",
              _id: 380,
              Business_Rules:
                "The decision must be made regarding what information is required for tracking.  It is necessary to define what movements of the packaging will be tracked e.g. only movement to/from internal storage facility to customer location, movement between external storage locations, etc.",
              Prerequisite: "",
            },
          ],
        },
        {
          BPMN_GUID: "740f0b61-8ddd-11e5-0a66-001bc640cd9b",
          Type: "Process Group",
          Description:
            "This process group covers all processes related to managing customer relationships. This includes customer interaction channels such as SalesForce.",
          _type: "Group",
          name: "Customer Relationship Management",
          GUID: "740f0b61-8ddd-11e5-0a66-001bc640cd9b",
          _id: 2168,
          process_of: [
            {
              BPMN_GUID: "NULL",
              Description:
                "An inquiry including requirements which arent apparently covered by an existing standard product needs to be evaluated by product management. The result can be a reference to a standard product, a solution based on the adaption / configuration of an existing product or the solution based on a new product. In latter case the result needs to be further specified and developed.",
              _type: "Process",
              GUID: "5a61ffcf-9f1d-11e8-2ddf-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "The scope of the feasibility check varies and depends on the extend to which the request deviates from the standard products and the level of customization required.",
              Outcome: "Product or solution to customer inquiry",
              name: "Evaluate feasibility of request",
              _id: 275,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "This process describes the creation of a new business partner.",
              _type: "Process",
              GUID: "37205d81-8d0d-11e9-0cfc-001bc641ce4f",
              Advisory_Notes:
                "Business partners could be customers but also suppliers. Even focussing on sales only, there are multiple different business partner roles, such as sold-to and ship-to parties.   General data needed to maintain might be: ***Title and name ***Street address ***Postal code/country/city ***Communication language ***Bank account details.   Sales data is maintain per sales area, which is a combination of sales organization, distribution channel and division.The data includes: ***shipping address *** invoicing address ***different status data to block certain transactions.   Procurement data typically includes: ***vendors name and address ***currency used for ordering from vendor ***terms of payments ***source list ***quota arrangement ***conditions ***purchasing info records ***vendor evaluation.   Mandatory financial data might differ based on the business policy. It usually includes: ***company code ***terms of payment for customer ***reconciliation account ***dunning procedure ***account group.   In addition to the above mentioned data a business partner profile might need to be maintained, which includes for example business partner specific documents or document layouts (this is represented in a separate process). If needed also the credit limit of a business partner could be maintained including the necessary approval (this is represented in a separate process).",
              Type: "Business Process",
              Scope:
                "After entering the general data, additional information on sales, ship-to, procurement, finance is maintained.",
              Outcome: "The business partner has been created.",
              name: "Create business partner",
              _id: 364,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "Based on a marketing campaign plan the process execute marketing campaign requires different activities to be initiated and coordinated. Depending on the character of the marketing campaign (event, fair, email, advertising, telephone campaign) these activities can vary and involve the purchasing of services and material.",
              _type: "Process",
              GUID: "55508473-a053-11e8-2ddf-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "Execution and monitoring of defined marketing activities, initiate and participate in purchasing of marketing materials and services.",
              Outcome: "Marketing campaign plan is executed.",
              name: "Execute marketing campaign",
              _id: 362,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "After the decision has been taken to sell an asset, it is necessary to find a buyer and negotiate the terms of the sale.",
              _type: "Process",
              GUID: "feff4071-7ef0-11e5-0a66-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "The remaining value of the asset is defined and based on that, negotiations are conducted with several potential buyers. The sale agreement is made with the buyer offering the best terms and conditions.",
              Outcome: "Buyer found and sale terms and conditions defined.",
              name: "Negotiate and determine buyer of asset",
              _id: 359,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "This process describes changing the business partner data.",
              _type: "Process",
              GUID: "37205d85-8d0d-11e9-0cfc-001bc641ce4f",
              Advisory_Notes:
                "It is highly recommended to implement strict rules on who is allowed to adjust which business partner data.",
              Type: "Business Process",
              Scope:
                "The requested changes are conducted on the respective areas of business partner data. ",
              Outcome: "Business partner data has been changed.",
              name: "Change business partner",
              _id: 366,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "This process is triggered on behalf of a lead in the context of an existing customer. The lead is typically qualified and based on the outcome an opportunity may be created.",
              _type: "Process",
              GUID: "5a1ed1e2-6a4f-11e8-2ddf-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "Qualify customer request and define follow up activities and create opportunity",
              Outcome: "Opportunity created",
              name: "Qualify customer request and create opportunity",
              _id: 365,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "The process describes the creation of customer specific documentation that may be requested by our customers.",
              _type: "Process",
              GUID: "56165656-7169-11e9-2ddf-001bc640cd9b",
              Advisory_Notes:
                "The documentation may be delivered together with the goods receipt or independently from it.    The documentation may be delivered as hard copy or as an electronic version.",
              Type: "Business Process",
              Scope:
                "After the request for creating customer specific documentation has been received, the documentation is created and prepared for a shipment to the customer.",
              Outcome:
                "The customer receives specific documentation as requested.",
              name: "Prepare customer specific documentation",
              _id: 160,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "This process covers the activities required to register a lead in a system and identify and trigger the responsibilities for follow up activities.",
              _type: "Process",
              GUID: "5a1ed1dc-6a4f-11e8-2ddf-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "Typical activities are:***register lead  ***evaluate existing  ***business relationship  ***identify and assign responsible sales department  ***trigger follow up activities",
              Outcome: "Registered lead",
              name: "Register and evaluate lead",
              _id: 261,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "Within a reasonable time before a planned product phase out the customers have to be informed. This is necessary to give customers the chance to place replacement orders or to fill their stocks. In case a follow-up-product will be set up, this information has to be given to the customers too.",
              _type: "Process",
              GUID: "686d8612-94c0-11e8-2ddf-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "Customers need to be informed in case products are being discontinued as follows:   ***date of phase-out (last production date at vendor     ***last acceptance date for PO from customers   ***successor material     ***timeline of availability of spare parts if applicable     ***timeline of service being provided All information given is prior to availability!",
              Outcome:
                "Customer informed and prepped for upcoming product phase out",
              name: "Inform customer about last option to buy",
              _id: 363,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "After executing a marketing campaign activities the results of the campaign are evaluated in the process Close marketing campaign and documented in a campaign report. This includes checking if all activities were executed, budgets exceeded, services provided in time and an evaluation in how far the objectives were achieved (if tangible, i.e. amount of leads).",
              _type: "Process",
              GUID: "55508475-a053-11e8-2ddf-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope: "Evaluation of campaign and creation of campaign report",
              Outcome: "Campaign report",
              name: "Close marketing campaign",
              _id: 361,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "Qualify lead describes the activities required in order to qualify a lead initiated by a potential new customer for whom no prior business relationship existed and no corresponding business partner exists in the system.",
              _type: "Process",
              GUID: "5a1ed1e5-6a4f-11e8-2ddf-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "Typical activities are:***qualify lead  ***trigger business partner creation  ***initiate follow up activities",
              Outcome: "Qualified lead",
              name: "Qualify lead",
              _id: 263,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "Plan marketing campaign is a process which defines in detail the activities, required resources and budget for a marketing campaign. The main input of objective and target group is typically predefined in the annual marketing plan. ",
              _type: "Process",
              GUID: "55508471-a053-11e8-2ddf-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "Planning of the timely sequence and dependency of required marketing activities. Define required marketing material and services. ",
              Outcome: "Marketing campaign plan is executable.",
              name: "Plan marketing campaign",
              _id: 360,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "This process includes the required activities to set and release the credit limit for a business partner.",
              _type: "Process",
              GUID: "ba2c9099-6a54-11e8-2ddf-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "Review credit limit for specific business partner, approve and release.",
              Outcome: "Released credit limit",
              name: "Approve and release credit limit",
              _id: 268,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "This process describes the activities in case a lead requirements can be fulfilled by sending standard marketing material or small samples. ",
              _type: "Process",
              GUID: "5a1ed1e8-6a4f-11e8-2ddf-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "After a selection of material has been made, it is sent out. In the system it is registered which material has been sent out to whom and when. ",
              Outcome:
                "Potential customer provided with marketing material and provision documented",
              name: "Send marketing material",
              _id: 262,
              Business_Rules: "",
              Prerequisite: "",
            },
          ],
        },
        {
          BPMN_GUID: "9c7b18f1-8ddd-11e5-0a66-001bc640cd9b",
          Type: "Process Group",
          Description:
            "This process group covers all processes related to customer orders for goods or services.",
          _type: "Group",
          name: "Sales Order Management",
          GUID: "9c7b18f1-8ddd-11e5-0a66-001bc640cd9b",
          _id: 2167,
          process_of: [
            {
              BPMN_GUID: "NULL",
              Description:
                "This process includes the customer notification that goods provided on consignment have been consumed captured by an consignment issue order. From the sellers perspective this happens when the goods are sold. ",
              _type: "Process",
              GUID: "7f93241f-7e49-11e5-0a66-001bc640cd9b",
              Advisory_Notes:
                "Most of the data will be copied from the consignment fill-up order including price. However, there might be scenarios where price determination is affected by the date of consumption and therefore price determination runs again.    Customer notification can be part of self invoicing. In this case payment will be settled with issuing the invoice.",
              Type: "Business Process",
              Scope:
                "Once the customer provides information that goods on consignment have been consumed, the data is amended, stored and forwarded for further processing.",
              Outcome: "A sales order for consignment issue is created.",
              name: "Capture and issue consignment consumption",
              _id: 162,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "In the sales via subcontracting scenario, once the selling entity has accepted a customer order, a purchase order is issued to the subcontractor. Receipt of this order and capturing it in a format necessary for further processing are the key first steps required for fulfillment of the subcontracting service.",
              _type: "Process",
              GUID: "e7ceea7f-7e57-11e5-0a66-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "Once a purchase order is received, its data must be amended, stored and forwarded for further processing.",
              Outcome:
                "All relevant information regarding the received purchase order are documented. The information captured provides the basis for determining whether the order can be fulfilled.",
              name: "Receive subcontracting order and capture sales order data",
              _id: 375,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "Sales document needs to be maintained for all customers and the data for one customer can also differ for each sales area. The sales area is a combination of sales organization, distribution channel and division. Therefore where maintaining sales document, it is necessary to assign a sales group. If you edit a customer master record, you must enter the customer number and the sales area in order to access screens containing sales and distribution data.",
              _type: "Process",
              GUID: "ba2c9087-6a54-11e8-2ddf-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "Data in sales document to be maintained:   ***Assign sales area - data includes pricing data, delivery priority, shipping condition ***Shipping information   ***Billing information  ",
              Outcome: "Sales document maintained.",
              name: "Maintain sales document",
              _id: 374,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description: "",
              _type: "Process",
              GUID: "05042ad3-9404-11e9-0cfc-001bc641ce4f",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope: "",
              Outcome: "",
              name: "Create sales order for IC purchase order",
              _id: 181,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "With the creation of a consignment pick-up order, goods returned by the customer are recorded in the warehouse of the seller i.e. the company that actually owns the goods. This precedes the transportation of the goods from the customers storage location back to the supplying company.  ",
              _type: "Process",
              GUID: "5abd47b1-7e47-11e5-0a66-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "When the customer no longer requires goods given to them on consignment, the creation of a consignment pick-up order triggers a return delivery to the seller.",
              Outcome: "Order for consignment pick-up is created.",
              name: "Receive customer notification and create consignment pick-up order",
              _id: 195,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "Approval of the sales order is given through the confirmation and represents a commitment to fulfil the order.",
              _type: "Process",
              GUID: "a98a286c-7e52-11e5-0a66-001bc640cd9b",
              Advisory_Notes:
                "The effectiveness of the approval-workflows often is overestimated. Sales managers will receive many approval requests daily. The data to be checked should be limited to the essentials. Everything that can be checked automatically by rules should be implemented in the system. The result of the automatic checks should be obvious to the reviewer.",
              Type: "Business Process",
              Scope:
                "After all checks related to the order have been performed the final decision is made regarding execution of the order. A commitment to the customer is made. This triggers the outbound logistics, production process or service process. ",
              Outcome:
                "A decision is made to fulfil the customers order. This triggers the outbound logistics/production process. The customer is informed about the decision.",
              name: "Save and approve sales order",
              _id: 1332,
              Business_Rules:
                "Rules must be established for allocating authorization for sales order approval to users. The format of the confirmation (electronic, paper) and communication channel must be defined.",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "Receiving the customer order and capturing it in a format necessary for further processing are the key first steps to all sales processes.        ",
              _type: "Process",
              GUID: "2a215fa6-7eec-11e5-0a66-001bc640cd9b",
              Advisory_Notes:
                "The design and the quality of this process is essential to avoid any changes to the sales order later in the sales and delivery process. All relevant and necessary data should be captured in order to allow an uninterrupted order processing.",
              Type: "Business Process",
              Scope:
                "Once the customer order is placed, the data must be amended, stored and forwarded for further processing. This might include transferring or copying data from other documents such as quotations or frame contracts.",
              Outcome:
                "All relevant information regarding the customers order is documented. The information captured provides the basis for determining whether the order can be fulfilled.",
              name: "Receive customer order and capture 3rd party sales order data",
              _id: 373,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "Approval of consignment return order is required to ensure that customer request to returned previously consumed goods/materials is in accordance with company rules.",
              _type: "Process",
              GUID: "aa8c61ab-7e48-11e5-0a66-001bc640cd9b",
              Advisory_Notes:
                "There is no direct link between the different sales orders and stock movements throughout the consignment scenarios except for the fill-up and issue. Thus, it is possible to post a consignment return with higher quantities or for other batches than actually posted in the issue.",
              Type: "Business Process",
              Scope:
                "A consignment return allows customer to give company back goods/materials, which were previously reported as consumed and request refund of made payment (if applicable). Once such request is received and recorded in form of a consignment return order, its validity is checked and if request is considered justified and in accordance with company rules, the return is confirmed and can be processed. ",
              Outcome:
                "Consignment return order validity is checked and confirmed, order can be processed.",
              name: "Confirm consignment return order",
              _id: 189,
              Business_Rules:
                "Rules must be established for evaluating, when customer returns can be considered justified.",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "Approval of consignment pickup order is required to ensure that customer request to return not consumed by the customer back to the warehouse in accordance with company rules.",
              _type: "Process",
              GUID: "6bcd18ca-624c-11e8-2ddf-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "A consignment pickup allows a customer to return goods and materials, which were not consumed.   Once such a request is received and recorded in form of a consignment pickup order, its validity is checked. In case it is in accordance with company rules, the pickup is confirmed and can be processed. A consignment pickup allows a customer to return goods and materials, which were not consumed.",
              Outcome:
                "Once a pick-up request is received and recorded in form of a consignment pickup order, its validity is checked. In case it is in accordance with company rules, the pickup is confirmed and can be processed.",
              name: "Confirm consignment pick up order",
              _id: 194,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "This process describes the receipt of a customer request for services.",
              _type: "Process",
              GUID: "4f887808-82c5-11e5-0a66-001bc640cd9b",
              Advisory_Notes:
                "Services can be represented by a material number with service type and/or service item category. The service item category controls whether a delivery is required or not.",
              Type: "Business Process",
              Scope:
                "Create sales order and maintain basic order data for service and the performance of a credit check.",
              Outcome: "Sales order for services is created.",
              name: "Receive service request and create sales order",
              _id: 1331,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "The consignment scenario is initiated with a fill-up order, which triggers the transfer of consignment goods to the customer. Depending on the agreement with the customer, the planning and respectively the request for a new fill-up can be either done by the customer or by the seller.",
              _type: "Process",
              GUID: "6581198b-7e49-11e5-0a66-001bc640cd9b",
              Advisory_Notes:
                "Even though, the consignment goods are still owned by the selling company, prices get determined at this stage and later copied to the consignment issue order.   The price will be necessary for any pro forma invoice as well as will be used in case of excise tax procedures apply.",
              Type: "Business Process",
              Scope:
                "Once the consignment order has been placed, the data must be amended, stored and forwarded for further processing.",
              Outcome:
                "All relevant information regarding the consignment order is documented. The information captured provides the basis for determining whether the consignment order can be fulfilled.",
              name: "Capture consignment fill-up order",
              _id: 161,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "For customer projects, a sales order must be created with reference to the quotation sent to the customer.     ",
              _type: "Process",
              GUID: "ca0ea8e0-7eef-11e5-0a66-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "After sending the customer quotation, a customer order is created with reference to the quotation. The project baseline is adjusted based on sales order and technical documents are assigned to project related activities.",
              Outcome: "Customer order is created with reference to quotation.",
              name: "Create sales order with ref. to quotation",
              _id: 1258,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "Once customer inquiry is received, a sample order must be created to enable further processing of the customer request.",
              _type: "Process",
              GUID: "5acb9a51-7e59-11e5-0a66-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "Data from customer inquiry is amended, stored and forwarded for further processing.",
              Outcome:
                "All relevant information regarding the customers inquiry is documented. The information captured provides the basis for determining batch, from which sample will be drawn and reserving goods in this sample.",
              name: "Create batch sample order",
              _id: 173,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "Upon revision of the quotation by the customer, changes may be required. Given this, the data must be updated according to the new information.",
              _type: "Process",
              GUID: "e2365b03-e8c2-11e4-0a66-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "Based on the requested changes of the customer, the quotation is populated with the new information and sent back to the customer.",
              Outcome: "The quotation is updated and sent to the customer",
              name: "Change quotation",
              _id: 197,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "Availability to promise check (ATP check) for delivery verifies whether goods included in the outbound delivery document are available on stock on the delivery date (or necessary ship-out date) and can be delivered.",
              _type: "Process",
              GUID: "44d43b72-e8bd-11e4-0a66-001bc640cd9b",
              Advisory_Notes:
                "An availability check for delivery generation is recommended to determine whether the material situation has changed since the availability check was made for the sales order.",
              Type: "Business Process",
              Scope:
                "Before sending the outbound delivery to warehouse for picking and initiating transportation it must be checked whether goods included in this outbound delivery are available on stock at picking date. If there are not enough goods, delivery is not sent to warehouse for fulfillment.  Unlike other ATP checks, the check for delivery is typically performed only against physical stock on hand.",
              Outcome:
                "Products required for delivery are available and outbound logistics can be initiated.",
              name: "Check availability to promise for delivery",
              _id: 65,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "Based on received customer order the configuration of the product to be manufactured is captured. The outcome of this process serves as input for the actual manufacturing work. ",
              _type: "Process",
              GUID: "7c553669-7e54-11e5-0a66-001bc640cd9b",
              Advisory_Notes:
                "MTO can use either already finally specified material masters (including routing and BOM), or need to be configured. Latter is often done using special routings with criteria for determining whether a production step is required or not and Super BOMs.   In differentiation to this case by case configuration a material variant is a material that can be kept in stock and that arises from an individual configuration of a configurable product.",
              Type: "Business Process",
              Scope:
                "Once the sales order data has been captured, the next step is to define how the product will be configured, what components will be used for production and what production tasks must be carried out. In make to order scenario it is possible that no BOM (bill of material) or routing exist and must be created in this process.",
              Outcome:
                "The product configuration and required components are defined based on the sales order.",
              name: "Configure product (MTO)",
              _id: 377,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "Although requirements planning will initially have planned the availability of raw materials and components it is important to check and re-check the availability for production to avoid that any change in the actual stock situation may cause an interruption of the scheduled production. This can be a undelivered raw material or a missing component due to a break-down",
              _type: "Process",
              GUID: "88c21250-7f1c-11e5-0a66-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope: "Availability of the required components will be checked.",
              Outcome:
                "Availability confirmed or missing parts component list is generated.",
              name: "Check component availability",
              _id: 1323,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "The Available-to-Promise (ATP) check against stock verifies whether the required quantity of finished goods can be sold to the customer at the requested date. The check is carried out against actual or projected finished goods inventory.       ",
              _type: "Process",
              GUID: "eabe97ec-e8c8-11e4-0a66-001bc640cd9b",
              Advisory_Notes:
                "It is necessary to define what happens after the availability is confirmed. Typically the requested product quantity is reserved (excluded from available stock). It should be defined how undelivered sales orders are handled and when a reservation for a sales order will be canceled.  Business rules that need to be defined are:   ***What are the main and alternative supply points for regions/customers (ship-to)   ***Which future goods receipts should be considered   ***Which future goods issues should be considered   ***Are allocations required to reserve quantities for specific customers, customer groups, markets/sales channels, regions    ***If allocations are used are those valid for the whole sales period or will those be deactivated after a certain time (example: monthly allocations are only valid until mid of the month any remaining quantities will be made generally available afterwards).    Sometimes other constraints like transportation capacity or handling capacity need to be integrated into the ATP check.",
              Type: "Business Process",
              Scope:
                "After the customer order data is captured, it must be checked if the order can be fulfilled (the requested products are available in the requested quantity and on the desired date). It is also necessary to determine the storage location the goods will be delivered from.",
              Outcome: "ATP check is performed.",
              name: "Check availability to promise at sales order entry",
              _id: 163,
              Business_Rules:
                "For setting-up the check it must be defined which sources, i.e. inventory, are to be considered.  Typical business cases are:   ***Check against plan or inventory    ***Inventory in local warehouse    ***Inventory in global organisation     ***Inventory in local warehouse + placed purchase orders/released production orders    Allocations might be used in short markets to manage the distribution of stock to particular markets, customer segments, or individual customers.  The workflow in case of different partial availability situations should be defined:   ***Proposal of alternate dates   ***Proposal of substitute products   ***Proposal of partial shipments   ***Backorder processing     ",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "Approval of the sample order is provided through the confirmation and represents a commitment to deliver the sample goods to the customer on the agreed date. ",
              _type: "Process",
              GUID: "5ae11e01-7e59-11e5-0a66-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "After all checks related to the sample order have been performed the final decision is made regarding execution of the order. A commitment to the customer is made, triggering the outbound logistics process. ",
              Outcome: "A decision is made to deliver the samples to customer.",
              name: "Save and approve sample order",
              _id: 169,
              Business_Rules:
                "It is essential to define how sample order confirmation is linked to inventory management and production processes. Rules must be established for allocating authorization for sample order approval. The format of the confirmation (electronic, paper) and communication channel must be defined.",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "Receiving the customer order and capturing it in a format necessary for further processing are the key first steps to all sales processes. ",
              _type: "Process",
              GUID: "4cb51061-7e56-11e5-0a66-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "Once the customer order is placed, the data must be amended, stored and forwarded for further processing. This might include transferring or copying data from other documents such as quotations or frame contracts.",
              Outcome:
                "All relevant information regarding the customers order is documented. The information captured provides the basis for determining whether the order can be fulfilled.",
              name: "Receive customer order and capture sales order data",
              _id: 175,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "Based on the electronically transferred IC purchase order, a corresponding sales order is automatically generated.",
              _type: "Process",
              GUID: "d39d38c4-a6d2-11e9-0cfc-001bc641ce4f",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "The sales order involves the requesting IC entity as sold-to and its customer as ship-to.",
              Outcome: "The sales order is created.",
              name: "Generate IC sales order",
              _id: 376,
              Business_Rules:
                "Clear rules and procedures must ensure the proper set-up of master data to allow for an automated generation of all relevant purchasing documents (selling entity) and sales documents (supplying entity).",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "After capturing and confirming a sales order, the order fulfillment needs to be monitored. Changes in availability, confirmation dates and priorities have to be considered and reflected in the sales order. The customer needs to be informed accordingly.",
              _type: "Process",
              GUID: "397b5453-d3a1-11e9-0cfc-001bc641ce4f",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "From a time perspective, this process spans from the saving of the sales order to the creation, release of the delivery and invoicing.",
              Outcome:
                "Any changes are reflected in the sales order, communication and excalation procedures are triggered if necessary.",
              name: "Monitor order fulfillment",
              _id: 158,
              Business_Rules:
                "Clear guidelines need to be defined how to handle shortages, deviations from already given commitments to the customer, priority conflicts as well as rules need to be agreed on how and when, and under which sircumstances the customer can request changes to the order. ",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "The Available-to-Promise (ATP) check verifies, whether the required quantity of material will be available at the required date. ",
              _type: "Process",
              GUID: "817029f4-7eff-11e5-0a66-001bc640cd9b",
              Advisory_Notes:
                "In order to verify the material availability for replenishments across plants, a global availability check has to be established.",
              Type: "Business Process",
              Scope:
                "After the stock transport order is created, it must be checked if the order can be fulfilled (requested material is available in the requested quantity and on the desired date).  ",
              Outcome: "ATP check is performed.",
              name: "Check availability to promise for stock transfer",
              _id: 1227,
              Business_Rules:
                "Rules how to manage and distribute available materials to different requesting plants and legal entities have to be defined.",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "When the customer provides notification that goods sold through consignment issue are being returned to consignment stock, it is necessary to create a consignment return order to initiate the change of ownership of the goods.",
              _type: "Process",
              GUID: "aa8c61b1-7e48-11e5-0a66-001bc640cd9b",
              Advisory_Notes:
                "The return is usually posted with the same batch number the issue has been posted from except the company policies require issuing a new batch number in case of returns.  ",
              Type: "Business Process",
              Scope:
                "Once the customer provides notification to return consignment goods, a consignment return order is created. In this case the consignor must take back ownership of the goods.",
              Outcome:
                "The consignment return order is created, triggering the non-physical goods receipt for consignment returns process.",
              name: "Receive customer notification and create consignment return order",
              _id: 190,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "For customer orders related to a quotation, information from the quotation is transferred to the sales order when the customer order is received. This differs from sales processes where the source of incoming information is only the customer order itself.",
              _type: "Process",
              GUID: "7c55366c-7e54-11e5-0a66-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "Once the customer order is placed, information from the quotation is transferred to the sales order, updated and enriched if necessary.",
              Outcome:
                "Sales order with reference to the quotation is created.",
              name: "Receive customer order and transfer quotation to sales order",
              _id: 212,
              Business_Rules: "",
              Prerequisite: "",
            },
          ],
        },
        {
          BPMN_GUID: "d529fbb1-8ddf-11e5-0a66-001bc640cd9b",
          Type: "Process Group",
          Description:
            "This process group covers all processes involved in creating invoices and commissions.",
          _type: "Group",
          name: "Billing, Rebates and Commisions",
          GUID: "d529fbb1-8ddf-11e5-0a66-001bc640cd9b",
          _id: 2166,
          process_of: [
            {
              BPMN_GUID: "NULL",
              Description:
                "The pro-forma invoice provides the customer with documentation regarding the goods delivered. As opposed to a customer invoice, the pro-forma invoice is not a request for payment. ",
              _type: "Process",
              GUID: "5acb9a33-7e59-11e5-0a66-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "After the samples have been delivered to the customer, a pro-forma invoice is create with information on the quantity (value of samples if required). This concludes the sample handling process.",
              Outcome: "Pro-forma invoice is created and sent to the customer.",
              name: "Create pro forma invoice",
              _id: 172,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "After the service execution the billing request needs to be created. Due to the previous decision that the customer will not be invoiced due to action of goodwill the pricing within the billing request needs to be corrected to 0. Once the billing has been adjusted and saved the system automatically blocks the billing request, which needs to be removed from it.",
              _type: "Process",
              GUID: "35d8dd0b-7eea-11e5-0a66-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope: "Correct price for service 0, unblock billing request.",
              Outcome: "Billing request created.",
              name: "Create billing request (with goodwill)",
              _id: 386,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "The process describes materials/spare parts/working hours used for the execution of the service and its confirmation with reference to the service order.",
              _type: "Process",
              GUID: "f56c62e3-7e51-11e5-0a66-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope: "Capturing of resource-related expenses.",
              Outcome: "Billing request created.",
              name: "Create billing request",
              _id: 385,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "With the commercial completion of the service, no more costs can be allocated to the service. The service order is completed from an accounting/controlling perspective. This process describes the settling of the service order, which is the posting of all costs and revenues related to the service order and its transfer to the profitability analysis. ",
              _type: "Process",
              GUID: "c12cdac1-90b6-11e8-2ddf-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "Post costs and revenues related to the service order and transfer to the profitability analysis via settlement, complete service order.",
              Outcome:
                "All costs and revenues settled to service order and service order is closed from a business perspective.",
              name: "Settle and complete service and sales order",
              _id: 388,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "After rebate agreement terms have been negotiated with the customer, a rebate agreement is created in the system containing the agreement details. The agreement will be further used for tracking of customer commitment fulfillment.",
              _type: "Process",
              GUID: "7093bfe7-7e51-11e5-0a66-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "A rebate agreement is created in the system after general negotiation with customer. The agreement contains details necessary for tracking of customer commitment fulfillment and calculation of applied rebates during settlement - rebate validity period, value/volume of goods customer commits to, items, for which rebates are relevant, rebate amounts/percentages, payment methods etc.",
              Outcome:
                "Rebate agreement is created and applied rebates can be tracked.",
              name: "Capture rebate agreement",
              _id: 208,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "Receiving the customer order and capturing it in a format necessary for further processing are the key first steps to all sales processes. A billing plan is maintained and the initial down payment to be paid by the customer captured.",
              _type: "Process",
              GUID: "ad54682d-7e58-11e5-0a66-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "Once the customer order is placed, the data must be amended, stored and forwarded for further processing. This might include transferring or copying data from other documents such as quotations or frame contracts.",
              Outcome:
                "All relevant information regarding the customers order is documented. The information captured provides the basis for determining whether the order can be fulfilled.",
              name: "Create sales order with down payment",
              _id: 387,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "This process describes the steps after the sales order was confirmed. The customer is required to pay the down payment. In order to do that, the billing block of the sales order needs to be removed first and a down payment request sent to the customer.",
              _type: "Process",
              GUID: "ad546818-7e58-11e5-0a66-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope: "Remove billing block, create down payment request",
              Outcome: "Down payment request created and sent to customer.",
              name: "Process down payment request",
              _id: 389,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "At the end of validity period of a rebate agreement the transactions under the terms of the agreement are evaluated and mutual liabilities arising form the agreement are settled. During the validity of the rebate agreement, partial settlements can take place. ",
              _type: "Process",
              GUID: "7093bfdb-7e51-11e5-0a66-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "The customer invoices are evaluated and accrued. Rebates are calculated and a credit note request gets created. At the end of the rebate agreement, the final settlement is executed to close the agreement.",
              Outcome:
                "Rebates are calculated in accordance to the agreement and credit note requests are created.",
              name: "Settle rebate agreement",
              _id: 206,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "This process involves activities required to create a billing document that is sent to the customer for payment.  ",
              _type: "Process",
              GUID: "ca0ea8e9-7eef-11e5-0a66-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "The milestones set in the project are transferred to the sales order and a billing document is created.",
              Outcome:
                "Billing document is created and can be sent to the customer. ",
              name: "Perform milestone billing",
              _id: 1257,
              Business_Rules: "",
              Prerequisite: "",
            },
          ],
        },
        {
          BPMN_GUID: "e3346531-4853-11ec-0d64-0201f3c99a7b",
          Type: "Process Group",
          Description:
            "This process group covers all processes related to customer orders for goods or services.",
          _type: "Group",
          name: "Sales Order Management",
          GUID: "e3346531-4853-11ec-0d64-0201f3c99a7b",
          _id: 372,
          process_of: [
            {
              BPMN_GUID: "NULL",
              Description: "",
              _type: "Process",
              GUID: "05042ad3-9404-11e9-0cfc-001bc641ce4f",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope: "",
              Outcome: "",
              name: "Create sales order for IC purchase order",
              _id: 181,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "Based on received customer order the configuration of the product to be manufactured is captured. The outcome of this process serves as input for the actual manufacturing work. ",
              _type: "Process",
              GUID: "7c553669-7e54-11e5-0a66-001bc640cd9b",
              Advisory_Notes:
                "MTO can use either already finally specified material masters (including routing and BOM), or need to be configured. Latter is often done using special routings with criteria for determining whether a production step is required or not and Super BOMs.   In differentiation to this case by case configuration a material variant is a material that can be kept in stock and that arises from an individual configuration of a configurable product.",
              Type: "Business Process",
              Scope:
                "Once the sales order data has been captured, the next step is to define how the product will be configured, what components will be used for production and what production tasks must be carried out. In make to order scenario it is possible that no BOM (bill of material) or routing exist and must be created in this process.",
              Outcome:
                "The product configuration and required components are defined based on the sales order.",
              name: "Configure product (MTO)",
              _id: 377,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "The Available-to-Promise (ATP) check verifies, whether the required quantity of material will be available at the required date. ",
              _type: "Process",
              GUID: "817029f4-7eff-11e5-0a66-001bc640cd9b",
              Advisory_Notes:
                "In order to verify the material availability for replenishments across plants, a global availability check has to be established.",
              Type: "Business Process",
              Scope:
                "After the stock transport order is created, it must be checked if the order can be fulfilled (requested material is available in the requested quantity and on the desired date).  ",
              Outcome: "ATP check is performed.",
              name: "Check availability to promise for stock transfer",
              _id: 1227,
              Business_Rules:
                "Rules how to manage and distribute available materials to different requesting plants and legal entities have to be defined.",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "The consignment scenario is initiated with a fill-up order, which triggers the transfer of consignment goods to the customer. Depending on the agreement with the customer, the planning and respectively the request for a new fill-up can be either done by the customer or by the seller.",
              _type: "Process",
              GUID: "6581198b-7e49-11e5-0a66-001bc640cd9b",
              Advisory_Notes:
                "Even though, the consignment goods are still owned by the selling company, prices get determined at this stage and later copied to the consignment issue order.   The price will be necessary for any pro forma invoice as well as will be used in case of excise tax procedures apply.",
              Type: "Business Process",
              Scope:
                "Once the consignment order has been placed, the data must be amended, stored and forwarded for further processing.",
              Outcome:
                "All relevant information regarding the consignment order is documented. The information captured provides the basis for determining whether the consignment order can be fulfilled.",
              name: "Capture consignment fill-up order",
              _id: 161,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "This process includes the customer notification that goods provided on consignment have been consumed captured by an consignment issue order. From the sellers perspective this happens when the goods are sold. ",
              _type: "Process",
              GUID: "7f93241f-7e49-11e5-0a66-001bc640cd9b",
              Advisory_Notes:
                "Most of the data will be copied from the consignment fill-up order including price. However, there might be scenarios where price determination is affected by the date of consumption and therefore price determination runs again.    Customer notification can be part of self invoicing. In this case payment will be settled with issuing the invoice.",
              Type: "Business Process",
              Scope:
                "Once the customer provides information that goods on consignment have been consumed, the data is amended, stored and forwarded for further processing.",
              Outcome: "A sales order for consignment issue is created.",
              name: "Capture and issue consignment consumption",
              _id: 162,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "Approval of consignment return order is required to ensure that customer request to returned previously consumed goods/materials is in accordance with company rules.",
              _type: "Process",
              GUID: "aa8c61ab-7e48-11e5-0a66-001bc640cd9b",
              Advisory_Notes:
                "There is no direct link between the different sales orders and stock movements throughout the consignment scenarios except for the fill-up and issue. Thus, it is possible to post a consignment return with higher quantities or for other batches than actually posted in the issue.",
              Type: "Business Process",
              Scope:
                "A consignment return allows customer to give company back goods/materials, which were previously reported as consumed and request refund of made payment (if applicable). Once such request is received and recorded in form of a consignment return order, its validity is checked and if request is considered justified and in accordance with company rules, the return is confirmed and can be processed. ",
              Outcome:
                "Consignment return order validity is checked and confirmed, order can be processed.",
              name: "Confirm consignment return order",
              _id: 189,
              Business_Rules:
                "Rules must be established for evaluating, when customer returns can be considered justified.",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "Although requirements planning will initially have planned the availability of raw materials and components it is important to check and re-check the availability for production to avoid that any change in the actual stock situation may cause an interruption of the scheduled production. This can be a undelivered raw material or a missing component due to a break-down",
              _type: "Process",
              GUID: "88c21250-7f1c-11e5-0a66-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope: "Availability of the required components will be checked.",
              Outcome:
                "Availability confirmed or missing parts component list is generated.",
              name: "Check component availability",
              _id: 1323,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "Upon revision of the quotation by the customer, changes may be required. Given this, the data must be updated according to the new information.",
              _type: "Process",
              GUID: "e2365b03-e8c2-11e4-0a66-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "Based on the requested changes of the customer, the quotation is populated with the new information and sent back to the customer.",
              Outcome: "The quotation is updated and sent to the customer",
              name: "Change quotation",
              _id: 197,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "This process describes the receipt of a customer request for services.",
              _type: "Process",
              GUID: "4f887808-82c5-11e5-0a66-001bc640cd9b",
              Advisory_Notes:
                "Services can be represented by a material number with service type and/or service item category. The service item category controls whether a delivery is required or not.",
              Type: "Business Process",
              Scope:
                "Create sales order and maintain basic order data for service and the performance of a credit check.",
              Outcome: "Sales order for services is created.",
              name: "Receive service request and create sales order",
              _id: 1331,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "Based on the electronically transferred IC purchase order, a corresponding sales order is automatically generated.",
              _type: "Process",
              GUID: "d39d38c4-a6d2-11e9-0cfc-001bc641ce4f",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "The sales order involves the requesting IC entity as sold-to and its customer as ship-to.",
              Outcome: "The sales order is created.",
              name: "Generate IC sales order",
              _id: 376,
              Business_Rules:
                "Clear rules and procedures must ensure the proper set-up of master data to allow for an automated generation of all relevant purchasing documents (selling entity) and sales documents (supplying entity).",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "Approval of consignment pickup order is required to ensure that customer request to return not consumed by the customer back to the warehouse in accordance with company rules.",
              _type: "Process",
              GUID: "6bcd18ca-624c-11e8-2ddf-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "A consignment pickup allows a customer to return goods and materials, which were not consumed.   Once such a request is received and recorded in form of a consignment pickup order, its validity is checked. In case it is in accordance with company rules, the pickup is confirmed and can be processed. A consignment pickup allows a customer to return goods and materials, which were not consumed.",
              Outcome:
                "Once a pick-up request is received and recorded in form of a consignment pickup order, its validity is checked. In case it is in accordance with company rules, the pickup is confirmed and can be processed.",
              name: "Confirm consignment pick up order",
              _id: 194,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "After capturing and confirming a sales order, the order fulfillment needs to be monitored. Changes in availability, confirmation dates and priorities have to be considered and reflected in the sales order. The customer needs to be informed accordingly.",
              _type: "Process",
              GUID: "397b5453-d3a1-11e9-0cfc-001bc641ce4f",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "From a time perspective, this process spans from the saving of the sales order to the creation, release of the delivery and invoicing.",
              Outcome:
                "Any changes are reflected in the sales order, communication and excalation procedures are triggered if necessary.",
              name: "Monitor order fulfillment",
              _id: 158,
              Business_Rules:
                "Clear guidelines need to be defined how to handle shortages, deviations from already given commitments to the customer, priority conflicts as well as rules need to be agreed on how and when, and under which sircumstances the customer can request changes to the order. ",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "For customer orders related to a quotation, information from the quotation is transferred to the sales order when the customer order is received. This differs from sales processes where the source of incoming information is only the customer order itself.",
              _type: "Process",
              GUID: "7c55366c-7e54-11e5-0a66-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "Once the customer order is placed, information from the quotation is transferred to the sales order, updated and enriched if necessary.",
              Outcome:
                "Sales order with reference to the quotation is created.",
              name: "Receive customer order and transfer quotation to sales order",
              _id: 212,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "Availability to promise check (ATP check) for delivery verifies whether goods included in the outbound delivery document are available on stock on the delivery date (or necessary ship-out date) and can be delivered.",
              _type: "Process",
              GUID: "44d43b72-e8bd-11e4-0a66-001bc640cd9b",
              Advisory_Notes:
                "An availability check for delivery generation is recommended to determine whether the material situation has changed since the availability check was made for the sales order.",
              Type: "Business Process",
              Scope:
                "Before sending the outbound delivery to warehouse for picking and initiating transportation it must be checked whether goods included in this outbound delivery are available on stock at picking date. If there are not enough goods, delivery is not sent to warehouse for fulfillment.  Unlike other ATP checks, the check for delivery is typically performed only against physical stock on hand.",
              Outcome:
                "Products required for delivery are available and outbound logistics can be initiated.",
              name: "Check availability to promise for delivery",
              _id: 65,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "Once customer inquiry is received, a sample order must be created to enable further processing of the customer request.",
              _type: "Process",
              GUID: "5acb9a51-7e59-11e5-0a66-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "Data from customer inquiry is amended, stored and forwarded for further processing.",
              Outcome:
                "All relevant information regarding the customers inquiry is documented. The information captured provides the basis for determining batch, from which sample will be drawn and reserving goods in this sample.",
              name: "Create batch sample order",
              _id: 173,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "When the customer provides notification that goods sold through consignment issue are being returned to consignment stock, it is necessary to create a consignment return order to initiate the change of ownership of the goods.",
              _type: "Process",
              GUID: "aa8c61b1-7e48-11e5-0a66-001bc640cd9b",
              Advisory_Notes:
                "The return is usually posted with the same batch number the issue has been posted from except the company policies require issuing a new batch number in case of returns.  ",
              Type: "Business Process",
              Scope:
                "Once the customer provides notification to return consignment goods, a consignment return order is created. In this case the consignor must take back ownership of the goods.",
              Outcome:
                "The consignment return order is created, triggering the non-physical goods receipt for consignment returns process.",
              name: "Receive customer notification and create consignment return order",
              _id: 190,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "In the sales via subcontracting scenario, once the selling entity has accepted a customer order, a purchase order is issued to the subcontractor. Receipt of this order and capturing it in a format necessary for further processing are the key first steps required for fulfillment of the subcontracting service.",
              _type: "Process",
              GUID: "e7ceea7f-7e57-11e5-0a66-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "Once a purchase order is received, its data must be amended, stored and forwarded for further processing.",
              Outcome:
                "All relevant information regarding the received purchase order are documented. The information captured provides the basis for determining whether the order can be fulfilled.",
              name: "Receive subcontracting order and capture sales order data",
              _id: 375,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "Sales document needs to be maintained for all customers and the data for one customer can also differ for each sales area. The sales area is a combination of sales organization, distribution channel and division. Therefore where maintaining sales document, it is necessary to assign a sales group. If you edit a customer master record, you must enter the customer number and the sales area in order to access screens containing sales and distribution data.",
              _type: "Process",
              GUID: "ba2c9087-6a54-11e8-2ddf-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "Data in sales document to be maintained:   ***Assign sales area - data includes pricing data, delivery priority, shipping condition ***Shipping information   ***Billing information  ",
              Outcome: "Sales document maintained.",
              name: "Maintain sales document",
              _id: 374,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "The Available-to-Promise (ATP) check against stock verifies whether the required quantity of finished goods can be sold to the customer at the requested date. The check is carried out against actual or projected finished goods inventory.       ",
              _type: "Process",
              GUID: "eabe97ec-e8c8-11e4-0a66-001bc640cd9b",
              Advisory_Notes:
                "It is necessary to define what happens after the availability is confirmed. Typically the requested product quantity is reserved (excluded from available stock). It should be defined how undelivered sales orders are handled and when a reservation for a sales order will be canceled.  Business rules that need to be defined are:   ***What are the main and alternative supply points for regions/customers (ship-to)   ***Which future goods receipts should be considered   ***Which future goods issues should be considered   ***Are allocations required to reserve quantities for specific customers, customer groups, markets/sales channels, regions    ***If allocations are used are those valid for the whole sales period or will those be deactivated after a certain time (example: monthly allocations are only valid until mid of the month any remaining quantities will be made generally available afterwards).    Sometimes other constraints like transportation capacity or handling capacity need to be integrated into the ATP check.",
              Type: "Business Process",
              Scope:
                "After the customer order data is captured, it must be checked if the order can be fulfilled (the requested products are available in the requested quantity and on the desired date). It is also necessary to determine the storage location the goods will be delivered from.",
              Outcome: "ATP check is performed.",
              name: "Check availability to promise at sales order entry",
              _id: 163,
              Business_Rules:
                "For setting-up the check it must be defined which sources, i.e. inventory, are to be considered.  Typical business cases are:   ***Check against plan or inventory    ***Inventory in local warehouse    ***Inventory in global organisation     ***Inventory in local warehouse + placed purchase orders/released production orders    Allocations might be used in short markets to manage the distribution of stock to particular markets, customer segments, or individual customers.  The workflow in case of different partial availability situations should be defined:   ***Proposal of alternate dates   ***Proposal of substitute products   ***Proposal of partial shipments   ***Backorder processing     ",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "Approval of the sales order is given through the confirmation and represents a commitment to fulfil the order.",
              _type: "Process",
              GUID: "a98a286c-7e52-11e5-0a66-001bc640cd9b",
              Advisory_Notes:
                "The effectiveness of the approval-workflows often is overestimated. Sales managers will receive many approval requests daily. The data to be checked should be limited to the essentials. Everything that can be checked automatically by rules should be implemented in the system. The result of the automatic checks should be obvious to the reviewer.",
              Type: "Business Process",
              Scope:
                "After all checks related to the order have been performed the final decision is made regarding execution of the order. A commitment to the customer is made. This triggers the outbound logistics, production process or service process. ",
              Outcome:
                "A decision is made to fulfil the customers order. This triggers the outbound logistics/production process. The customer is informed about the decision.",
              name: "Save and approve sales order",
              _id: 1332,
              Business_Rules:
                "Rules must be established for allocating authorization for sales order approval to users. The format of the confirmation (electronic, paper) and communication channel must be defined.",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "Receiving the customer order and capturing it in a format necessary for further processing are the key first steps to all sales processes.        ",
              _type: "Process",
              GUID: "2a215fa6-7eec-11e5-0a66-001bc640cd9b",
              Advisory_Notes:
                "The design and the quality of this process is essential to avoid any changes to the sales order later in the sales and delivery process. All relevant and necessary data should be captured in order to allow an uninterrupted order processing.",
              Type: "Business Process",
              Scope:
                "Once the customer order is placed, the data must be amended, stored and forwarded for further processing. This might include transferring or copying data from other documents such as quotations or frame contracts.",
              Outcome:
                "All relevant information regarding the customers order is documented. The information captured provides the basis for determining whether the order can be fulfilled.",
              name: "Receive customer order and capture 3rd party sales order data",
              _id: 373,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "Receiving the customer order and capturing it in a format necessary for further processing are the key first steps to all sales processes. ",
              _type: "Process",
              GUID: "4cb51061-7e56-11e5-0a66-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "Once the customer order is placed, the data must be amended, stored and forwarded for further processing. This might include transferring or copying data from other documents such as quotations or frame contracts.",
              Outcome:
                "All relevant information regarding the customers order is documented. The information captured provides the basis for determining whether the order can be fulfilled.",
              name: "Receive customer order and capture sales order data",
              _id: 175,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "With the creation of a consignment pick-up order, goods returned by the customer are recorded in the warehouse of the seller i.e. the company that actually owns the goods. This precedes the transportation of the goods from the customers storage location back to the supplying company.  ",
              _type: "Process",
              GUID: "5abd47b1-7e47-11e5-0a66-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "When the customer no longer requires goods given to them on consignment, the creation of a consignment pick-up order triggers a return delivery to the seller.",
              Outcome: "Order for consignment pick-up is created.",
              name: "Receive customer notification and create consignment pick-up order",
              _id: 195,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "Approval of the sample order is provided through the confirmation and represents a commitment to deliver the sample goods to the customer on the agreed date. ",
              _type: "Process",
              GUID: "5ae11e01-7e59-11e5-0a66-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "After all checks related to the sample order have been performed the final decision is made regarding execution of the order. A commitment to the customer is made, triggering the outbound logistics process. ",
              Outcome: "A decision is made to deliver the samples to customer.",
              name: "Save and approve sample order",
              _id: 169,
              Business_Rules:
                "It is essential to define how sample order confirmation is linked to inventory management and production processes. Rules must be established for allocating authorization for sample order approval. The format of the confirmation (electronic, paper) and communication channel must be defined.",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "For customer projects, a sales order must be created with reference to the quotation sent to the customer.     ",
              _type: "Process",
              GUID: "ca0ea8e0-7eef-11e5-0a66-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "After sending the customer quotation, a customer order is created with reference to the quotation. The project baseline is adjusted based on sales order and technical documents are assigned to project related activities.",
              Outcome: "Customer order is created with reference to quotation.",
              name: "Create sales order with ref. to quotation",
              _id: 1258,
              Business_Rules: "",
              Prerequisite: "",
            },
          ],
        },
        {
          BPMN_GUID: "e80fae71-4853-11ec-0d64-0201f3c99a7b",
          Type: "Process Group",
          Description:
            "Customer Relationship Management is a strategy for systematically shaping the relationships and interactions of an organization with existing and potential customers. A CRM system helps companies stay in touch with their customers, streamline processes, and increase profitability",
          _type: "Group",
          name: "Customer Relationship Management",
          GUID: "e80fae71-4853-11ec-0d64-0201f3c99a7b",
          _id: 358,
          process_of: [
            {
              BPMN_GUID: "NULL",
              Description:
                "This process describes changing the business partner data.",
              _type: "Process",
              GUID: "37205d85-8d0d-11e9-0cfc-001bc641ce4f",
              Advisory_Notes:
                "It is highly recommended to implement strict rules on who is allowed to adjust which business partner data.",
              Type: "Business Process",
              Scope:
                "The requested changes are conducted on the respective areas of business partner data. ",
              Outcome: "Business partner data has been changed.",
              name: "Change business partner",
              _id: 366,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "The process describes the creation of customer specific documentation that may be requested by our customers.",
              _type: "Process",
              GUID: "56165656-7169-11e9-2ddf-001bc640cd9b",
              Advisory_Notes:
                "The documentation may be delivered together with the goods receipt or independently from it.    The documentation may be delivered as hard copy or as an electronic version.",
              Type: "Business Process",
              Scope:
                "After the request for creating customer specific documentation has been received, the documentation is created and prepared for a shipment to the customer.",
              Outcome:
                "The customer receives specific documentation as requested.",
              name: "Prepare customer specific documentation",
              _id: 160,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "This process describes the activities in case a lead requirements can be fulfilled by sending standard marketing material or small samples. ",
              _type: "Process",
              GUID: "5a1ed1e8-6a4f-11e8-2ddf-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "After a selection of material has been made, it is sent out. In the system it is registered which material has been sent out to whom and when. ",
              Outcome:
                "Potential customer provided with marketing material and provision documented",
              name: "Send marketing material",
              _id: 262,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "Qualify lead describes the activities required in order to qualify a lead initiated by a potential new customer for whom no prior business relationship existed and no corresponding business partner exists in the system.",
              _type: "Process",
              GUID: "5a1ed1e5-6a4f-11e8-2ddf-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "Typical activities are:***qualify lead  ***trigger business partner creation  ***initiate follow up activities",
              Outcome: "Qualified lead",
              name: "Qualify lead",
              _id: 263,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "An inquiry including requirements which arent apparently covered by an existing standard product needs to be evaluated by product management. The result can be a reference to a standard product, a solution based on the adaption / configuration of an existing product or the solution based on a new product. In latter case the result needs to be further specified and developed.",
              _type: "Process",
              GUID: "5a61ffcf-9f1d-11e8-2ddf-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "The scope of the feasibility check varies and depends on the extend to which the request deviates from the standard products and the level of customization required.",
              Outcome: "Product or solution to customer inquiry",
              name: "Evaluate feasibility of request",
              _id: 275,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "This process is triggered on behalf of a lead in the context of an existing customer. The lead is typically qualified and based on the outcome an opportunity may be created.",
              _type: "Process",
              GUID: "5a1ed1e2-6a4f-11e8-2ddf-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "Qualify customer request and define follow up activities and create opportunity",
              Outcome: "Opportunity created",
              name: "Qualify customer request and create opportunity",
              _id: 365,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "This process describes the creation of a new business partner.",
              _type: "Process",
              GUID: "37205d81-8d0d-11e9-0cfc-001bc641ce4f",
              Advisory_Notes:
                "Business partners could be customers but also suppliers. Even focussing on sales only, there are multiple different business partner roles, such as sold-to and ship-to parties.   General data needed to maintain might be: ***Title and name ***Street address ***Postal code/country/city ***Communication language ***Bank account details.   Sales data is maintain per sales area, which is a combination of sales organization, distribution channel and division.The data includes: ***shipping address *** invoicing address ***different status data to block certain transactions.   Procurement data typically includes: ***vendors name and address ***currency used for ordering from vendor ***terms of payments ***source list ***quota arrangement ***conditions ***purchasing info records ***vendor evaluation.   Mandatory financial data might differ based on the business policy. It usually includes: ***company code ***terms of payment for customer ***reconciliation account ***dunning procedure ***account group.   In addition to the above mentioned data a business partner profile might need to be maintained, which includes for example business partner specific documents or document layouts (this is represented in a separate process). If needed also the credit limit of a business partner could be maintained including the necessary approval (this is represented in a separate process).",
              Type: "Business Process",
              Scope:
                "After entering the general data, additional information on sales, ship-to, procurement, finance is maintained.",
              Outcome: "The business partner has been created.",
              name: "Create business partner",
              _id: 364,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "Within a reasonable time before a planned product phase out the customers have to be informed. This is necessary to give customers the chance to place replacement orders or to fill their stocks. In case a follow-up-product will be set up, this information has to be given to the customers too.",
              _type: "Process",
              GUID: "686d8612-94c0-11e8-2ddf-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "Customers need to be informed in case products are being discontinued as follows:   ***date of phase-out (last production date at vendor     ***last acceptance date for PO from customers   ***successor material     ***timeline of availability of spare parts if applicable     ***timeline of service being provided All information given is prior to availability!",
              Outcome:
                "Customer informed and prepped for upcoming product phase out",
              name: "Inform customer about last option to buy",
              _id: 363,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "Based on a marketing campaign plan the process execute marketing campaign requires different activities to be initiated and coordinated. Depending on the character of the marketing campaign (event, fair, email, advertising, telephone campaign) these activities can vary and involve the purchasing of services and material.",
              _type: "Process",
              GUID: "55508473-a053-11e8-2ddf-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "Execution and monitoring of defined marketing activities, initiate and participate in purchasing of marketing materials and services.",
              Outcome: "Marketing campaign plan is executed.",
              name: "Execute marketing campaign",
              _id: 362,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "This process includes the required activities to set and release the credit limit for a business partner.",
              _type: "Process",
              GUID: "ba2c9099-6a54-11e8-2ddf-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "Review credit limit for specific business partner, approve and release.",
              Outcome: "Released credit limit",
              name: "Approve and release credit limit",
              _id: 268,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "After executing a marketing campaign activities the results of the campaign are evaluated in the process Close marketing campaign and documented in a campaign report. This includes checking if all activities were executed, budgets exceeded, services provided in time and an evaluation in how far the objectives were achieved (if tangible, i.e. amount of leads).",
              _type: "Process",
              GUID: "55508475-a053-11e8-2ddf-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope: "Evaluation of campaign and creation of campaign report",
              Outcome: "Campaign report",
              name: "Close marketing campaign",
              _id: 361,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "Plan marketing campaign is a process which defines in detail the activities, required resources and budget for a marketing campaign. The main input of objective and target group is typically predefined in the annual marketing plan. ",
              _type: "Process",
              GUID: "55508471-a053-11e8-2ddf-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "Planning of the timely sequence and dependency of required marketing activities. Define required marketing material and services. ",
              Outcome: "Marketing campaign plan is executable.",
              name: "Plan marketing campaign",
              _id: 360,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "This process covers the activities required to register a lead in a system and identify and trigger the responsibilities for follow up activities.",
              _type: "Process",
              GUID: "5a1ed1dc-6a4f-11e8-2ddf-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "Typical activities are:***register lead  ***evaluate existing  ***business relationship  ***identify and assign responsible sales department  ***trigger follow up activities",
              Outcome: "Registered lead",
              name: "Register and evaluate lead",
              _id: 261,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "After the decision has been taken to sell an asset, it is necessary to find a buyer and negotiate the terms of the sale.",
              _type: "Process",
              GUID: "feff4071-7ef0-11e5-0a66-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "The remaining value of the asset is defined and based on that, negotiations are conducted with several potential buyers. The sale agreement is made with the buyer offering the best terms and conditions.",
              Outcome: "Buyer found and sale terms and conditions defined.",
              name: "Negotiate and determine buyer of asset",
              _id: 359,
              Business_Rules: "",
              Prerequisite: "",
            },
          ],
        },
      ],
      _id: 357,
    },
    {
      BPMN_GUID: "923fabbf-6c8c-491f-b5ae-323c1b0a8b62",
      Type: "Process Cluster",
      _type: "Cluster",
      name: "C. Plan",
      GUID: "9e140831-2474-11e9-2ddf-001bc640cd9b",
      group_of: [
        {
          BPMN_GUID: "f4aa3141-770f-11e9-2ddf-001bc640cd9b",
          Type: "Process Group",
          Description:
            "Sales & Operations Planning (S&OP) is a flexible forecasting and planning approach with which sales, production, and other supply chain targets can be set on the basis of historical, existing, and estimated future data. It consists of all activities managing and orchestrating the other supply chain planning disciplines.",
          _type: "Group",
          name: "Sales and Operations Planning",
          GUID: "f4aa3141-770f-11e9-2ddf-001bc640cd9b",
          _id: 2177,
          process_of: [
            {
              BPMN_GUID: "NULL",
              Description:
                "Purpose of this process is to conduct different Sales & Operations Planning alignment meetings.",
              _type: "Process",
              GUID: "c4955b22-5f6c-11e9-2ddf-001bc640cd9b",
              Advisory_Notes:
                "*** The detailed sequence of alignment and the actual constitution of meetings highly depends on the business structures and supply chain setups. It is not possible to show a best practice as reference. It might be necessary to run cascaded meetings from global, regional to local level or vice-a-versa and maybe consider different meetings for different business units as well. *** In some cases it might even be necessary to review and adjust certain plans in between the alignment meetings and then align again afterwards.",
              Type: "Business Process",
              Scope:
                "In order to create sound Sales & Operations Planning results different Supply Chain Planning disciplines need to come together, align and make adjustments to the different single plans in order to achieve an overall feasible mutual Sales & Operations Plan.",
              Outcome:
                "Mutually agreed on and feasible Sales & Operations Plan has been defined and single Supply Chain discipline plans have been adjusted accordingly.",
              name: "Conduct Sales & Operations Planning alignment meetings",
              _id: 419,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "Purpose of this process is to determine the aggregation levels for planning.",
              _type: "Process",
              GUID: "79f07d61-cee4-11e9-0cfc-001bc641ce4f",
              Advisory_Notes:
                " ***aggregation level is a key driver of effort during planning but is also crucial with regards to how meaningful planning results will be.",
              Type: "Business Process",
              Scope:
                "In order to allow all planning disciplines to interact efficiently it is necessary to align on the aggregation levels of the different plans or at least of the interfaced/exchanged information.",
              Outcome:
                "Aggregation levels for all planning disciplines are determined.",
              name: "Determine aggregation levels",
              _id: 416,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "Purpose of this process is to monitor the planning accuracy.",
              _type: "Process",
              GUID: "79f07d67-cee4-11e9-0cfc-001bc641ce4f",
              Advisory_Notes:
                " ***forecast accuracy is the most common KPI for accuracy measurement, but also in other areas it is useful to have a look at deviations between plan and actuals.",
              Type: "Business Process",
              Scope:
                "Planning accuracy is measured and monitored continuously. Deviation from plan to actual needs to be investigated in order to determine if planning procedure should be adjusted or optimization potentials in operations can be derived from the findings.",
              Outcome:
                "Planning accuracy is monitored and follow-up activities have been initiated.",
              name: "Monitor planning accuracy",
              _id: 417,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "Purpose of this process is to prepare the Sales & Operations Planning alignment meetings.",
              _type: "Process",
              GUID: "fa5b781c-5f7d-11e9-2ddf-001bc640cd9b",
              Advisory_Notes:
                "*** Depending on the complexity of the Supply Chain and the amount and nature of issues mentioned by the different planning disciplines it might be necessary to run further cycles of alignment between Production, Procurement and Distribution before entering the alignment meetings. Sometimes it might even be necessary to involve more strategic planning areas (e.g. strategic network planning) in order to prepare solutions to critical aspects within the different plans.",
              Type: "Business Process",
              Scope:
                "In order to prepare Sales & Operations Planning alignment meetings the plans of Sales, Production, Procurement and Distribution need to be consolidated. Furthermore invitations need to be prepared and participation of the different stakeholders need to be ensured. Decision points need to be prepared and shared upfront in order to facilitate decision making during the S&OP meetings.",
              Outcome: "S&OP alignment meetings have been prepared.",
              name: "Prepare Sales & Operations Planning alignment meetings",
              _id: 421,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "Purpose of this process is to define the different needed planning approaches.",
              _type: "Process",
              GUID: "79f07d63-cee4-11e9-0cfc-001bc641ce4f",
              Advisory_Notes:
                " ***Sales and Operations Planning function should coordinate and define the different approaches in order to ensure feasibility of usage of the different results.  ***Furthermore logical breaches and contradictory assumptions need to be avoided.",
              Type: "Business Process",
              Scope:
                "Different planning disciplines will need different approaches. This is about planning strategy, cascades within the discipline  and detail levels but is not limited to these topics.",
              Outcome:
                "Planning approaches for different planning disciplines are defined.",
              name: "Define planning approach for different planning disciplines",
              _id: 415,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "Purpose of this process is to define the overall planning procedure.",
              _type: "Process",
              GUID: "79ee3370-cee4-11e9-0cfc-001bc641ce4f",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "Aspects such as frequency, horizon, scope, responsibilities and stakeholders as well as necessary cascades an an overall timetable for planning iterations need to be defined (aligned) to ensure efficient and successful planning.",
              Outcome: "Planning procedure is defined.",
              name: "Define Sales & Operations Planning procedure",
              _id: 418,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "Purpose of this process is to determine the logic for forecast consumption",
              _type: "Process",
              GUID: "79f07d65-cee4-11e9-0cfc-001bc641ce4f",
              Advisory_Notes:
                " ***different forecasts might need a different consumption logic.  ***especially critical is the question on whether and how to consider left-over forecast in the next period.",
              Type: "Business Process",
              Scope:
                "Forecast consumption is an essential logic to steer how forecast values are replaced by actual demands. It needs to be defined on how to react to deviations between forecasts and actuals.",
              Outcome: "Forecast consumption logic is defined.",
              name: "Determine forecast consumption logic",
              _id: 420,
              Business_Rules: "",
              Prerequisite: "",
            },
          ],
        },
        {
          BPMN_GUID: "e9b92f51-8ddb-11e5-0a66-001bc640cd9b",
          Type: "Process Group",
          Description:
            "This group bundles all processes around planning of procurement activities related to the determination of planned quantities to be procured during specific timeframes at specific suppliers. This includes the planning of stock levels for procured goods such as raw materials or components.",
          _type: "Group",
          name: "Procurement Planning",
          GUID: "e9b92f51-8ddb-11e5-0a66-001bc640cd9b",
          _id: 2176,
          process_of: [
            {
              BPMN_GUID: "NULL",
              Description:
                "Purpose of this process is to analyze procurement requirements and brake these down to certain categories and suppliers.",
              _type: "Process",
              GUID: "e788866b-61e5-11e9-2ddf-001bc640cd9b",
              Advisory_Notes:
                " ***It is necessary to align with Master Planning on the structure and format of the procurement requirements provided to Procurement Planning.",
              Type: "Business Process",
              Scope:
                "After receiving procurement requirements from Master Planning these are analyzed and structured according to categories and suppliers. ",
              Outcome:
                "Procurement requirements from Master Planning have been structured in order to prepare procurement planning.",
              name: "Analyze procurement requirements",
              _id: 395,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "Purpose of this process is to identify the most suitable source of supply for each procurement requirement.",
              _type: "Process",
              GUID: "1afd612e-61ec-11e9-2ddf-001bc640cd9b",
              Advisory_Notes:
                "***At this point in time there can be more then one source of supply for each procurement requirement. The selection of the source of supply will typically be done during actual Procurement activity based on rules, that need to be defined in a later stage of Procurement Planning scenario.",
              Type: "Business Process",
              Scope:
                "Structured procurement requirements are matched with known sources of supply. Missing sources of supply are identified and escalated to Purchasing. ",
              Outcome:
                "Procurement requirements are matched with sources of supply.",
              name: "Match requirements with sources of supply",
              _id: 393,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "Purpose of this process is to escalate procurement requirements back to Master Planning for which procurement planning cant provide a suitable supply.",
              _type: "Process",
              GUID: "e788866e-61e5-11e9-2ddf-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "A summary of all procurement issues is provided to Master Planning. Partially this is meant as information on already resolved issues (e.g. additional storage location to cover certain peaks). More importantly the not yet resolved issues (e.g. suppliers cant delivery sufficient material) need to be escalated in order to be aligned with the other planning disciplines during Master Planning. Typically Production Planning is the most closely related supply chain planning discipline. Alignment with Production Planning needs to be ensured especially when escalating issues to Master Planning and when adjusting procurement plans.",
              Outcome:
                "Procurement issues that need to be resolved mutually with other planning disciplines are escalated to Master Planning.",
              name: "Escalate infeasible procurement requirements to Master Planning",
              _id: 398,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "Requirements for externally source materials are evaluated considering all relevant demand elements.",
              _type: "Process",
              GUID: "279a79cf-99bb-11e9-0cfc-001bc641ce4f",
              Advisory_Notes:
                " *** In SAP environments typically the MRP run is used as functionality to calculate the final requirements per material and create the corresponding purchase requisitions.  ***Often (not always!) you will find separate MRP calculation setups for externally sourced materials compared to the own produced materials. Difference can be found for example in frequency of the calculation and also in the scope of demand elements taken into account.  ***If requirements for externally source materials are calculated with a different logic it is still absolutely essential to consider the overall sequence of MRP calculations in combination with calculations of own-produced materials and those of across other plants in the supply network.",
              Type: "Business Process",
              Scope:
                "In order to evaluate the material requirements the demands from production (esp. components), from customer orders or intercompany replenishments (esp. trading goods), as well as stock replenishments and planned procurment quantities need to be considered and combined. Based on this comprehensive information the purchase requisitions are created.",
              Outcome:
                "Purchase requisitions for externally sourced materials have been created according to the overall demand situation.",
              name: "Evaluate material requirements for procurement",
              _id: 1204,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "Purpose of this process is to make the planned procurement activities known to the operative Procurement team.",
              _type: "Process",
              GUID: "1afd6127-61ec-11e9-2ddf-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "Different responsible Procurement teams are informed about the planned procurement activities with focus on important upcoming changes (e.g. certain new rules for source of supply determination) and most critical situation in the mid-term horizon (e.g. special peak demands that need tight monitoring).",
              Outcome:
                "Procurement requirements and plan are known to Procurement.",
              name: "Provide procurement plan to Procurement",
              _id: 392,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "The process describes the maintenance of the forecast delivery schedule containing requirements concerning delivery quantities and dates for regularly purchased material from a vendor. The forecast is transmitted to the vendor for incorporation into their planning.",
              _type: "Process",
              GUID: "59938f35-7e0f-11e5-0a66-001bc640cd9b",
              Advisory_Notes:
                "The forecast delivery schedule must be submitted to the vendor in regular intervals defined by a scheduling agreement for specific components.    The following questions need to be answered: ***Who is the recipient for the forecast information? ***How are the documents submitted (EDI, Email etc.)? ***What information is required? ***Who communicates with the vendor?",
              Type: "Business Process",
              Scope:
                "Create forecast delivery schedule and transmit to vendor.",
              Outcome:
                "The current forecast delivery schedule is submitted to the vendor.",
              name: "Create forecast delivery schedule and provide to vendor",
              _id: 92,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "Purpose of this process is to adjust the initial procurement plan according to the results/decisions coming from Master Planning as a result of the S&OP alignment meetings.",
              _type: "Process",
              GUID: "e7888671-61e5-11e9-2ddf-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "After receiving input from Master Planning the procurement plan needs to be adjusted accordingly and issue resolution needs to be incorporated. The resulting Production Planning after S&OP alignment should be considered as input for procurement planning since it will provide additional details on required raw material, components and subcontracted materials.  ",
              Outcome:
                "Procurement plan is aligned with mutually agreed on S&OP plan.",
              name: "Adjust procurement plan to S&OP alignment",
              _id: 396,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "Purpose of this process is to align available and needed supply capacities.",
              _type: "Process",
              GUID: "1afd612a-61ec-11e9-2ddf-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "After receiving requirements from Master Planning these need to be aligned with actual available capacities of source of supplies. Discrepancies need to be identified for further processing.",
              Outcome:
                "Needed and available supply capacities have been aligned and discrepancies have been identified.",
              name: "Evaluate and align capacities of supply sources",
              _id: 394,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "Purpose of this process is to create an initial procurement plan.",
              _type: "Process",
              GUID: "1afd6124-61ec-11e9-2ddf-001bc640cd9b",
              Advisory_Notes:
                "Detail level and horizon of the procurement plan depend on the procurement lead times and the utilization level of storage facilities. In case of very tight storage capacities, the planning of inventories needs to be paid more attention and detail level might need to consider weekly or even daily buckets. ",
              Type: "Business Process",
              Scope:
                "Transform procurement requirements into a comprehensive procurement plan showing when to procure which quantity from which source of supply. This includes the definition of rules in case of multiple source of supplies for the same material.",
              Outcome: "Initial procurement plan is set up.",
              name: "Create initial procurement plan",
              _id: 397,
              Business_Rules: "",
              Prerequisite: "",
            },
          ],
        },
        {
          BPMN_GUID: "6328adc1-8ddc-11e5-0a66-001bc640cd9b",
          Type: "Process Group",
          Description:
            "This group bundles all processes around planning of storage and transportation of goods in terms of determination of planned quantities to be stored at specific warehouses/distribution centers and moved on specific transport relations during specific timeframes.",
          _type: "Group",
          name: "Distribution Planning",
          GUID: "6328adc1-8ddc-11e5-0a66-001bc640cd9b",
          _id: 2175,
          process_of: [
            {
              BPMN_GUID: "NULL",
              Description:
                "Purpose of this process is to analyze distribution requirements and brake these down to different warehouse locations and transportation routes.",
              _type: "Process",
              GUID: "54233eb9-6591-11e9-2ddf-001bc640cd9b",
              Advisory_Notes:
                " ***It is necessary to align with Master Planning on the structure and format of the distribution requirements provided to Distribution Planning.",
              Type: "Business Process",
              Scope:
                "After receiving distribution requirements from Master Planning these are analyzed and structured according warehouse locations and transportation routes. In addition the warehousing requirements from Production Planning especially for semi- and finished goods (e.g. inventory development due to campaign production) need to be considered as input.",
              Outcome:
                "Distribution requirements from Master Planning have been structured in order to prepare distribution planning.",
              name: "Analyze distribution requirements",
              _id: 432,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "Purpose of this process is to determine a first distribution setup considering the planned distribution requirements.",
              _type: "Process",
              GUID: "1cabcf3c-764a-11e9-2ddf-001bc640cd9b",
              Advisory_Notes:
                " ***the determination of distribution setup needs to rely on results and guidelines provide by Strategic Network Planning. This includes for example distribution concepts such as local, regional or global hub-and-spoke systems and maybe fixed allocation of certain markets to certain distribution centers.  ***in many industries logistics capacities are considered as infinite in this stage of planning. The contracting of additional capacities is often carried out by operative functions during logistics execution processes.   ***additional data on customer requirements (e.g.expected lead times), supplier service conditions (e.g. only full trucks as direct shipments) and product specifics (e.g. thermo transport) need to be available in order to determine feasible distribution setups.  ***it needs to be considered that decisions on stock allocation have an effect on the transport requirements due to related replenishments.  ***in the definition of transport routes several parameters need to be considered. These are for example transport mode (e.g. road, sea, air, rail), transport service type (e.g. parcel, express, groupage, LTL, FTL, milk run), truck types (e.g. cooled or heated) and other influencing factors.",
              Type: "Business Process",
              Scope:
                "The already structured distribution requirements are matched to existing distribution resources. It is decided which warehouses and storage location should be used for which products/customers. In this way the planned warehouse requirements are specified. In addition first decisions are taken on the material flows through the distribution networks. Especially direct shipments, multi-stop and cross-docking options are considered.  As a last step the resulting transport requirements are specified per transport route.",
              Outcome:
                "A first distribution setup is determined and can be used for further distribution planning.",
              name: "Determine initial distribution setup",
              _id: 434,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "Purpose of this process is to adjust warehouse requirements and setup a stock allocation plan according to the results/decisions coming from Master Planning as a result of the S&OP alignment meetings.",
              _type: "Process",
              GUID: "1cabcf50-764a-11e9-2ddf-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "After receiving input from Master Planning the warehouse requirements need to be adjusted accordingly and issue resolution needs to be incorporated. Based on this the stock allocation including projected stock levels are determined.",
              Outcome:
                "Warehouse requirements including stock allocation are determined and aligned aligned with mutually agreed on S&OP plan.",
              name: "Adjust warehouse requirements and setup stock allocation plan",
              _id: 437,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "Purpose of this process is to adjust the transport requirement according to the results/decisions coming from Master Planning as a result of the S&OP alignment meetings.",
              _type: "Process",
              GUID: "1cabcf54-764a-11e9-2ddf-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "After receiving input from Master Planning the transport requirements need to be adjusted accordingly and issue resolution needs to be incorporated. Based on these adjusted requirements a transport plan for each transport route is determined.",
              Outcome:
                "Procurement plan is aligned with mutually agreed on S&OP plan.",
              name: "Adjust transport requirements and setup transport  plan",
              _id: 433,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "Purpose of this process is to transfer the distribution plan into the data needed for automated planning functionalities (esp. material requirements planning).",
              _type: "Process",
              GUID: "e46c438c-765c-11e9-2ddf-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "Stock parameters (such as target, minimum and maximum stock, as well as reorder levels and replenishment leadtimes) are determined for each material and storage location. Typically the parameters are maintained in material master data in order to facilitate material requirements planning functionalities.",
              Outcome:
                "Stock parameters of related materials are up to date and reflect the aligned distribution plan.",
              name: "Maintain stock parameters in material master",
              _id: 435,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "Purpose of this process is to escalate distribution requirements back to Master Planning for which distribution planning cant provide a suitable solution.",
              _type: "Process",
              GUID: "1cabcf4c-764a-11e9-2ddf-001bc640cd9b",
              Advisory_Notes:
                "***the scope of issue solving in Distribution Planning is most commonly restricted, since some important decisions (e.g. on new warehouses) typically do not fall under the responsibility of Distribution Planning but rather are considered as part of Strategic Network Planning (SNP). SNP is mostly involved after escalation to Master Planning and in alignment with other Supply Chain Planning functions and business functions like Sales and Corporate Strategy.",
              Type: "Business Process",
              Scope:
                "A summary of critical distribution issues is provided to Master Planning. Partially this is meant as information on already resolved issues. More importantly the not yet resolved issues (e.g. customer lead time expectations can not be met) need to be escalated in order to be aligned with the other planning disciplines during Master Planning.",
              Outcome:
                "Distribution issues that need to be resolved mutually with other planning disciplines are escalated to Master Planning.",
              name: "Escalate infeasible distribution requirements to Master Planning",
              _id: 440,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "Purpose of this process is to provide Transport Management with the derived transport plan.",
              _type: "Process",
              GUID: "e46c4382-765c-11e9-2ddf-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "Transport Management is provided with the transport plan and critical requirements and changes to the current setup are clarified. This also includes the documented guidelines to ensure compliance to the plan.",
              Outcome:
                "Transport plan has been provided to Transport Management as basis for transport sourcing and execution processes.",
              name: "Provide transport plan to Transport Management",
              _id: 441,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "Purpose of this process is to hand over the planning results to Warehouse Management.",
              _type: "Process",
              GUID: "e46c4389-765c-11e9-2ddf-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "The stock allocation plan is provided to Warehouse Management and critical topics are clarified. Focus lies on information needed to later coordinate needed warehousing resources (e.g. for pick&pack activities) or to organize interim warehouse capacities (e.g. at external warehouses to cover peaks).  But there could also be insights from Planning alignments to be transferred to Warehouse Management (e.g. mid-term increase of full pallet movement).",
              Outcome:
                "The requirements and plan have been provided and explained to Warehouse Management.",
              name: "Provide planned warehouse requirements to Warehouse Management",
              _id: 438,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "Purpose of this process is to align planned and existing warehouse capacities.",
              _type: "Process",
              GUID: "1cabcf58-764a-11e9-2ddf-001bc640cd9b",
              Advisory_Notes:
                " ***in many industries distribution capacities are considered as infinite in this stage of planning and are resolved by operational functions during later execution.",
              Type: "Business Process",
              Scope:
                "Based on the planned distribution setup the implications on stock levels and warehouse operations (e.g. pick& pack, cross docking) are determined. These planned warehouse requirements are compared to existing capacities. Most commonly the focus lies on expected issues regarding customer service level violations.",
              Outcome:
                "Warehouse requirements and capacities are aligned and most critical issues have been identified.",
              name: "Align warehouse requirements with capacities",
              _id: 442,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "Purpose of this process is to align planned and existing transport capacities.",
              _type: "Process",
              GUID: "1cabcf44-764a-11e9-2ddf-001bc640cd9b",
              Advisory_Notes:
                " ***in many industries distribution capacities are considered as infinite in this stage of planning and are resolved by operational functions during later execution.",
              Type: "Business Process",
              Scope:
                "Based on planned distribution setup the implications on transport routes are determined. These planned transport requirements are compared to existing capacities. Most commonly the focus lies on expected transport cost increases (e.g. express freigt) and on customer service level violations (e.g. lead time requirements).",
              Outcome:
                "Transport requirements are aligned with capacities and most critical issues have been identified.",
              name: "Align transport requirements with capacities",
              _id: 439,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "Purpose of this process is to optimize the distribution setup for planned distribution requirements.",
              _type: "Process",
              GUID: "1cabcf40-764a-11e9-2ddf-001bc640cd9b",
              Advisory_Notes:
                " ***Distribution Planning needs to have clear targets in order to be able to optimize the planned distribution setup. These targets need to reflect the overall strategy of the company or related business unit to allow resolution of conflicts between cost and service aspects.  ***It needs to be defined to which extend Distribution Planning is allowed to adjust the distribution network. For example, if it is a valid option to plan an additional warehouse location in a certain market. This might be considered as strategic decision which should be taken in Strategic Network Planning with alignment further business functions.",
              Type: "Business Process",
              Scope:
                "The initial distribution plan is taken as a basis in order to compile different optimization options. Most commonly decisions on storage location versus transport lead time and costs (which warehouse to use) as well as transport lead time vs. cost (express freight or groupage) are reconsidered to improve overall setup. Each option is evaluated regarding different targets (e.g. customer service/lead time, transport cost, warehousing cost). Then an optimized distribution plan is derived.",
              Outcome:
                "Distribution plan has been optimized with regards to various optimization targets.",
              name: "Optimize distribution setup",
              _id: 436,
              Business_Rules: "",
              Prerequisite: "",
            },
          ],
        },
        {
          BPMN_GUID: "6df0c231-8dda-11e5-0a66-001bc640cd9b",
          Type: "Process Group",
          Description:
            "Demand planning is a multi-step operational supply chain management (SCM) process used to create reliable forecasts. This process group covers activities in the Demand Planning cycle. It also includes processes related to Availability-to-promise checks (ATP) in order to highlight the importance of thoroughly planned demands within this field.",
          _type: "Group",
          name: "Demand Planning",
          GUID: "6df0c231-8dda-11e5-0a66-001bc640cd9b",
          _id: 2174,
          process_of: [
            {
              BPMN_GUID: "NULL",
              Description:
                "Purpose of the manual Sales forecasting is to derive and maintain the actual forecast figures on the defined aggregation level.",
              _type: "Process",
              GUID: "ab363ebb-2632-11e9-2ddf-001bc640cd9b",
              Advisory_Notes:
                "***It is important to document assumptions made in order to make use of this information during root-cause analyses within the review process.   ***Also for manual forecast the aggregation/calculation logic needs to be carefully defined upfront in order to avoid blind spots or double counting during later consolidation of the individual forecast values.    ***The aggregation level needs to be defined with care in order to allow for meaningful sales forecast without overly extensive efforts. Often sales forecasts are derived as monthly or quarterly figures on product type or group level per market or channel.   ***The aggregation and consolidation rules and also the planning horizons need to be aligned with other planning disciplines. This task and the orchestration of the different planning disciplines is often considered as Sales & Operations Planning.",
              Type: "Business Process",
              Scope:
                "Based on available information (e.g. market intelligence, internal sources, specific customer information) and own experience the expected Sales figures are derived, validated Sales internally and maintained within the demand planning tool.",
              Outcome:
                "Sales forecast has been derived, validated and provided to the demand planning.",
              name: "Derive manual sales forecast",
              _id: 448,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "Purpose of the alignment with projects is to consider impact of running or upcoming projects on the sales figures within the forecasting horizon.",
              _type: "Process",
              GUID: "ab363ed9-2632-11e9-2ddf-001bc640cd9b",
              Advisory_Notes:
                "Consideration of M&A activities could also be part of this process.",
              Type: "Business Process",
              Scope:
                "The derived Sales forecast is presented and explained to the different Project Managements or the Project Portfolio Management in order to identify potential impacts. Identified impacts are quantified together. The forecast is adjusted accordingly and reasons are documented.",
              Outcome: "Impact of projects has been considered in forecast.",
              name: "Align forecast with projects",
              _id: 453,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "Purpose of the alignment with the Service Organization is to consider impact of running or upcoming service and after sales activities on the sales figures within the forecasting horizon.",
              _type: "Process",
              GUID: "ab363ed4-2632-11e9-2ddf-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "The derived Sales forecast is presented and explained to the Service Organization. The Service Organization presents running or upcoming activities in order to identify potential impacts. Identified impacts are quantified together. The forecast is adjusted accordingly and reasons are documented.",
              Outcome:
                "Impact of Service and After Sales activities has been considered in forecast",
              name: "Align forecast with service organization",
              _id: 456,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "Purpose of this process is to receive and understand sales forecasts of different business units.",
              _type: "Process",
              GUID: "73a83063-5f77-11e9-2ddf-001bc640cd9b",
              Advisory_Notes:
                "*** The consolidation of different sales forecasts of different business units is especially relevant in case of mutually used resources either in sourcing (same raw material or suppliers), in production (same production lines) or in distribution (same warehouses or transportation means).",
              Type: "Business Process",
              Scope:
                "Receiving the approved sales forecasts from all relevant business units some of the data might need clarification in order to be interpreted the right way. Only after understanding the forecast data this can be used for master planning.",
              Outcome:
                "Business specific sales forecasts have been received and clarification needs have been resolved.",
              name: "Review demand plan of different business units",
              _id: 446,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "Purpose of the forecast consolidation for a specific business unit is to provide the overview on aggregated level and to prepare forecast information for the upcoming alignments.",
              _type: "Process",
              GUID: "4d8735f4-2640-11e9-2ddf-001bc640cd9b",
              Advisory_Notes:
                "***The actual consolidation logic depends on the granularity level the forecast is being derived in and how it is going to be used during master planning.   ***The aggregation and consolidation rules and also the planning horizons need to be aligned with other planning disciplines. This task and the orchestration of the different planning disciplines is often considered as Sales & Operations Planning.",
              Type: "Business Process",
              Scope:
                "***Single forecast inputs are gathered and a first quality check is conducted. The validated inputs are combined in order to derive the consolidated overall forecast on business unit level.  ***If needed documentation on assumptions is completed.",
              Outcome:
                "All forecast data for the respective business unit has been consolidated to one central business unit sales forecast.",
              name: "Consolidate sales forecast for business unit",
              _id: 452,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "Purpose of reviewing the last forecast is to verify taken assumptions as well as information sources and to continuously improve the forecast accuracy.  ",
              _type: "Process",
              GUID: "ab363ebf-2632-11e9-2ddf-001bc640cd9b",
              Advisory_Notes:
                "Insights from former forecasts need to be discussed and shared to ensure consideration in the next forecast cycle. This is especially important in case of manual forecasting.",
              Type: "Business Process",
              Scope:
                "Former forecasts are compared to actual figures. Depending on extend of deviation (forecast accuracy) root cause analyses might be conducted in order to optimize assumptions and inputs for the upcoming forecast cycles.",
              Outcome:
                "Insights from former forecast cycles have been derived and shared.",
              name: "Review former forecast cycles",
              _id: 447,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "Purpose of this process is to finalize the forecast and approve it for further usage in master planning.",
              _type: "Process",
              GUID: "ab363eb7-2632-11e9-2ddf-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "The previously aligned figures receive a last quality check. The approval process for sales forecast is run. After approval the forecast is provided to master planning.",
              Outcome:
                "The sales forecast has been approved and is available for master planning.",
              name: "Approve sales forecast",
              _id: 450,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "Purpose of this process is consolidate sales forecasts of different business units into one sales forecast for the specific plant.",
              _type: "Process",
              GUID: "629eacc5-5f71-11e9-2ddf-001bc640cd9b",
              Advisory_Notes:
                "*** The consolidation of different sales forecasts of different business units is especially relevant in case of mutually used resources either in sourcing (same raw material or suppliers), in production (same production lines) or in distribution (same warehouses or transportation means).",
              Type: "Business Process",
              Scope:
                "After receiving the approved sales forecasts from all relevant business units the data needs to be consolidated in order to create one mutual sales forecast for the relevant plant.",
              Outcome:
                "One consolidated business unit overarching sales forecast for the relevant plant has been set up.",
              name: "Consolidate demand plans of different business units",
              _id: 444,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "Purpose of the alignment with Marketing is to consider impact of running or upcoming marketing activities such campaigns on the sales figures within the forecasting horizon.",
              _type: "Process",
              GUID: "ab363eca-2632-11e9-2ddf-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "The derived Sales forecast is presented and explained to Marketing. Marketing presents running or upcoming activities in order to identify potential impacts. Identified impacts are quantified together. The forecast is adjusted accordingly and reasons are documented.",
              Outcome:
                "Impact of Marketing activities has been considered in forecast.",
              name: "Align forecast with marketing",
              _id: 454,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "Before the  maintenance order can be released, it is necessary to check whether materials needed to conduct the maintenance are available. ",
              _type: "Process",
              GUID: "7d178dc7-7eee-11e5-0a66-001bc640cd9b",
              Advisory_Notes:
                "Pre-requisites: each material, whose availability is checked, must be assigned a checking group on the MRP detail screen in its material master. The checking group combines materials whose availability is checked at the same time. You can, for instance, group materials according to the material type or the MRP type.",
              Type: "Business Process",
              Scope:
                "The system checks whether material components in networks are available on the required date. If the requirements are not covered, the system determines the date on which they can be covered.",
              Outcome: "Availability of required material is determined.",
              name: "Check material availability",
              _id: 1270,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "Purpose of Initiate and monitor forecasting is to trigger and monitor the forecasting activities of the actual forecast cycle.",
              _type: "Process",
              GUID: "ab363eb3-2632-11e9-2ddf-001bc640cd9b",
              Advisory_Notes:
                "For manual forecasting it can be helpful to provide a statistical forecast based on historic data as a starting point. Sales department can use this as basis to adjust with information from further sources (e.g. knowledge about upcoming customer agreements or ending contracts). ",
              Type: "Business Process",
              Scope:
                "***After running necessary preparations the conduction of the actual forecast cycle can be triggered by informing and instructing the different involved parties. ***The timely performance of forecasting activities as well as the provision of results needs to be closely monitored.",
              Outcome: "outcome",
              name: "Initiate and monitor forecasting",
              _id: 445,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "Purpose of the alignment with Research & Development (R&D) is to consider impact of running or upcoming technological and product developments on the sales figures within the forecasting horizon.",
              _type: "Process",
              GUID: "ab363ed0-2632-11e9-2ddf-001bc640cd9b",
              Advisory_Notes:
                "R&D impacts might be related to new product development (phase-in / phase-out), changes to existing products (incl. components, intermediates or raw materials) as well as major changes in production technology.",
              Type: "Business Process",
              Scope:
                "The derived Sales forecast is presented and explained to R&D. R&D presents running or upcoming activities in order to identify potential impacts. Identified impacts are quantified together. The forecast is adjusted accordingly and reasons are documented.",
              Outcome:
                "Impact of R&D activities has been considered in forecast.",
              name: "Align forecast with Research & Development",
              _id: 455,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "Purpose of the alignment with the Financial Planning is to identify deviation between forecast and budget and to be able to provide early feedback on budget fulfillment.",
              _type: "Process",
              GUID: "4d8735ea-2640-11e9-2ddf-001bc640cd9b",
              Advisory_Notes:
                "***Some of the follow-up activities might be tasks for other planning disciplines and would then be considered again during master planning. Other identified deviations might lead to new sales, project, marketing, R&D or service activities. These should then also consider additional information from Master planning, since master planning might lead to adjusted forecast figures.   ***The comparison of budget and forecast needs to be conducted with care. Deviation can not be avoided and the extend highly depends on the process how the budget is derived. It is strongly recommended to not misuse budget figures as forecast neglecting other more actual information sources. When forecast consists of budget data only you should rather not setup any demand planning processes at all.",
              Type: "Business Process",
              Scope:
                "The derived Sales forecast is compared to budget. In case of major deviations (especially but not limited to shortfalls) main drivers are identified. The documented assumptions of the forecast might be re-evaluated or follow-up activities might be defined in order to keep up with budget. Potentially the forecast data is adjusted to cover the impact of defined countermeasures to specific budget shortfalls.",
              Outcome:
                "Deviation of forecast to budget has been identified. Potentially countermeasures have been defined or initiatives have been started to do so.",
              name: "Align forecast with financial planning",
              _id: 451,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "Purpose of this process is to adjust the sales forecast according to insights from S&OP alignment meetings.",
              _type: "Process",
              GUID: "629eacbd-5f71-11e9-2ddf-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "The approved forecast is being adjusted according to decisions from mutual S&OP alignment meetings. The adjusted forecast is communicated to all related stakeholders.",
              Outcome:
                "The sales forecast has been adjusted to fit the overall and mutually aligned Sales & Operations Plan.",
              name: "Adjust sales forecast according to S&OP alignment",
              _id: 449,
              Business_Rules: "",
              Prerequisite: "",
            },
          ],
        },
        {
          BPMN_GUID: "4b1977f1-8ddc-11e5-0a66-001bc640cd9b",
          Type: "Process Group",
          Description:
            "This group bundles all processes around planning of production in terms of determination of planned quantities to be produced during specific timeframes at specific plants.",
          _type: "Group",
          name: "Production Planning",
          GUID: "4b1977f1-8ddc-11e5-0a66-001bc640cd9b",
          _id: 2173,
          process_of: [
            {
              BPMN_GUID: "NULL",
              Description:
                "Purpose of this process is to make (planned) production orders available to scheduling and declare the planning as finalized.",
              _type: "Process",
              GUID: "22a192cc-605d-11e9-2ddf-001bc640cd9b",
              Advisory_Notes:
                " ***The logic and rules for handover of planned production orders to scheduling/production needs to be defined depending on the scheduling requirements and overall planning procedure.   ***Often fixation or a certain frozen horizon is used to determine those planned elements ready for scheduling. Frozen horizon is many times related to the creation of production orders from planned orders.   ***When defining the handover procedure from planning to scheduling one should also define the handover from scheduling to production. Also here a frozen horizon might make sense.",
              Type: "Business Process",
              Scope:
                "The planned production orders updated during MRP and capacity planning need to be released to scheduling. ",
              Outcome:
                "Planned production orders are available for detailed scheduling and are no longer in the responsibility of planning.",
              name: "Hand-over production orders to scheduling",
              _id: 102,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "Purpose of this process is to identify available production capacities in a mid-term horizon.",
              _type: "Process",
              GUID: "7a091b4b-6053-11e9-2ddf-001bc640cd9b",
              Advisory_Notes:
                "Depending on the used planning software, available capacities also need to be maintained in system.",
              Type: "Business Process",
              Scope:
                "Planned available capacities of different parts of operations need to be gathered, structured and aligned. This includes but is not limited to personnel and machine resources in production operations, quality control, warehouses, logistics as well as planned maintenance activities with impact on availability of resources.",
              Outcome:
                "Planned available capacities are gathered from and aligned with different operations departments.",
              name: "Determine planned available capacities",
              _id: 430,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "Purpose of this process is to align available and needed production capacities.",
              _type: "Process",
              GUID: "43d8c3bc-5f81-11e9-2ddf-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "After receiving production requirements from Master Planning these need to be aligned with actual available capacities within the plant. Discrepancies need to be identified for further processing.",
              Outcome:
                "Needed and available capacities have been aligned and discrepancies have been identified.",
              name: "Evaluate and align mid-term capacities",
              _id: 429,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "Purpose of this process is to obtain an overview of actually available capacities and to maintain these as basis for capacity leveling.",
              _type: "Process",
              GUID: "22a192b1-605d-11e9-2ddf-001bc640cd9b",
              Advisory_Notes:
                " ***The quality of data on actually available capacities is a key driver for overall planning and scheduling accuracy. Especially when using advanced planning solutions the automatic planning functions and alert mechanisms are depending on this data.  ***The granularity level of capacity evaluation highly depends on the time sensitivity and on the utilization of production.  ***Within environments with clear bottlenecks, focussing on these areas is a common way to reduce complexity of planning.",
              Type: "Business Process",
              Scope:
                "Available capacity for all relevant resources need to be clarified, aligned and maintained. This include production lines/ machines, special equipment but also personnel resources from different operations areas (e.g. production, quality control, warehouses, logistics).",
              Outcome:
                "Actual available capacities are known to Production Planning and are maintained for capacity planning.",
              name: "Determine actual available capacities",
              _id: 99,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "Purpose of this process is to make the needed capacities available to operations departments (e.g. production, quality control, on-site logistics, maintenance) in order for them to plan/organize resources accordingly.",
              _type: "Process",
              GUID: "7a091b50-6053-11e9-2ddf-001bc640cd9b",
              Advisory_Notes:
                "***The capacity requirement information from mid-term production planning is most often used to trigger vacation planning and temporary workforce contracts.",
              Type: "Business Process",
              Scope:
                "Needed capacities for the different operations departments are extracted from the adjusted production plan and communicated. This includes information on decisions taken during Master Planning in order to resolve issues. ",
              Outcome:
                "Needed capacities are known by the related departments.",
              name: "Provide capacity requirements to operations",
              _id: 423,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "Purpose of this process is to update existing and new planned production orders according to most actual MRP and capacity information. ",
              _type: "Process",
              GUID: "3d2f2549-5f90-11e9-2ddf-001bc640cd9b",
              Advisory_Notes:
                " *** Production Planning and Production Operations need to define clear rules on frozen horizons within which production orders are no longer subject to adjustments. Common practice is to use the creation of production orders from planned orders as cut-off point. From this point in time, planning is considered to be finalized and there should be no further changes to the production orders.",
              Type: "Business Process",
              Scope:
                "The planned production orders are updated with regards to for example quantity, date or production version.",
              Outcome: "Planned production orders are up to date.",
              name: "Adjust planned production orders based on MRP and capacity planning",
              _id: 100,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "Purpose of this process is to resolve production capacity shortages by plant specific means.",
              _type: "Process",
              GUID: "43d8c3c0-5f81-11e9-2ddf-001bc640cd9b",
              Advisory_Notes:
                " ***The alignment of Production Planning and Procurement Planning during preparation of Master Planning and S&OP alignment is quite common. Especially if raw material availability is a mentionable restriction and/or subcontracting is a standard solution to resolve internal capacity issues.  ***If interdependencies are strong it might make sense to combine Production Planning and Procurement Planning and sometimes also Distribution Planning to one central Supply Planning.",
              Type: "Business Process",
              Scope:
                "For each identified capacity issue a suitable solution needs to be defined and documented. In order to resolve issues different operations departments but also other planning disciplines (e.g. Procurement Planning) might need to be involved.",
              Outcome:
                "Capacity issues have been resolved by plant specific means as far as possible.",
              name: "Resolve capacity issues with plant specific means",
              _id: 425,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "Purpose of this process is to adjust the initial production plan according to the results/decisions coming from Master Planning as a result of the S&OP alignment meetings.",
              _type: "Process",
              GUID: "3d2f252c-5f90-11e9-2ddf-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "After receiving input from Master Planning the production plan needs to be adjusted accordingly and issue resolution needs to be incorporated.",
              Outcome:
                "Production plan is aligned with mutually agreed on S&OP plan.",
              name: "Adjust mid-term plant specific production plan to S&OP alignment",
              _id: 427,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "Material requirements planning assumes infinite production capacity, therefore its outcome - recommended production schedule - is often unrealistic. Actual production capacity must be evaluated, to identify if the required capacity does not exceed work centers defined capacity limits in a given time period. ",
              _type: "Process",
              GUID: "712673d6-7f1e-11e5-0a66-001bc640cd9b",
              Advisory_Notes:
                "Production capacity is often checked for bottleneck work centers or key work centers only.   It should also be defined on which granularity capacity should be leveled.  In less time sensitive environments, it might be sufficient to look at the weekly load not considering individual days of overload.",
              Type: "Business Process",
              Scope:
                "Recommended production schedule from MRP must be combined with information from routing sheets about work centers and working hours required to meet production plan, by taking into account also setup times. This allows to accurately determine the required capacity at each work center during each time period and to identify problem areas - overcommitment of resources.",
              Outcome:
                "Recommended production schedule revised by taking into account actual capacity constraints, bottlenecks and overcommitment of resources identified.  ",
              name: "Evaluate and align capacity",
              _id: 1347,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "Purpose of this process is to escalate production requirements back to Master Planning for which plant specific production capacity is not sufficient.",
              _type: "Process",
              GUID: "3d2f2526-5f90-11e9-2ddf-001bc640cd9b",
              Advisory_Notes:
                "Some plant specific issue resolutions might have an impact on other planning disciplines as well (e.g. pre-ponement of production will lead to higher demand of warehouse capacities). Whether this kind of solutions can be aligned locally or need to be escalated via Master Planning and S&OP alignment meetings needs to be defined upfront.",
              Type: "Business Process",
              Scope:
                "A summary of all capacity issues is provided to Master Planning. Partially this is meant as information on already resolved issues. More importantly the not yet resolved issues need to be escalated in order to be aligned with the other planning disciplines during Master Planning.",
              Outcome:
                "Capacity issues that need to be resolved mutually with other planning disciplines are escalated to Master Planning.",
              name: "Escalate infeasible production requirements to Master Planning",
              _id: 424,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "Purpose of this process is to create an initial plant specific production plan.",
              _type: "Process",
              GUID: "3d2f2522-5f90-11e9-2ddf-001bc640cd9b",
              Advisory_Notes:
                "Detail level and horizon of the production plan depends on the duration of production process and volatility of business in general. In non-volatile and long-running production environments it might be sufficient to set up a production plan with monthly quantities. Other environments might need weekly or even daily quantities. Also the granularity might differ from production areas/lines down to single resources (e.g. bottleneck machines)",
              Type: "Business Process",
              Scope:
                "Transform production requirements into a comprehensive production plan for all plant specific production resources.",
              Outcome: "Plant specific production plan is created.",
              name: "Create initial mid-term plant specific production plan",
              _id: 426,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "Purpose of this process is to evaluate and align impact of short-term changes to the production plan with impacted operations and related planning disciplines.",
              _type: "Process",
              GUID: "22a192d2-605d-11e9-2ddf-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "Changes to the production plan are evaluated with regards to potential impact on operations areas (e.g. production operations, procurement, warehouse operations, transport management, on-site logistics, maintenance). Changes and impact are aligned together and agreements are made in order to resolve potential issues. In case of need the related short-term to mid-term planning disciplines (e.g. procurement planning, distribution planning) are involved in order to check the impact on their plan.",
              Outcome:
                "Impact of adjustments is evaluated and solutions have been aligned.",
              name: "Align adjustment needs with related planning disciplines and operations",
              _id: 103,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "Purpose of this process is to analyze and brake down production requirements to the plant specific structures.",
              _type: "Process",
              GUID: "e9dd474e-60de-11e9-2ddf-001bc640cd9b",
              Advisory_Notes:
                " ***It is necessary to align with Master Planning on the structure and format of the production requirements provided to Production Planning. If possible the structure used in master Planning is already usable for production planning but more often Master Planning is run on a less detailed granularity level in order to reduce complexity.  ***The needed structure of data depends on production setup, production stages, product portfolio, planning horizons and many more aspects. Sometimes it might be plant specific.",
              Type: "Business Process",
              Scope:
                "After receiving production requirements from Master Planning these need to be broken down to the actual production structures. ",
              Outcome:
                "Production requirements from Master Planning have been structured in order to prepare mid-term production planning.",
              name: "Analyze production requirements",
              _id: 428,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "Effective short term production planning must be based on the most accurate data available, this can be ensured by regularly revising and updating the independent demands from both sales forecasts as well as actual demands from customer orders.       ",
              _type: "Process",
              GUID: "eb3c00f1-7f1f-11e5-0a66-001bc640cd9b",
              Advisory_Notes:
                "The basis for demand calculation must be defined. Typical setups are based on demand forecasts, actual demand, or a combination of both.    ",
              Type: "Business Process",
              Scope:
                "Demand information from sales and operations planning, which is taken as initial base for detailed production scheduling is usually based on sales forecast and historical sales data. The demand for finished products must be revised at this stage, the actual demand (firm customer orders) considered and a final demand provided as input to enable analysis of material requirements and generation of detailed production schedules.",
              Outcome:
                "Actual demand for finished products is considered and information for creation of detailed production schedules is updated.  ",
              name: "Revise and update independent demands",
              _id: 101,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "Purpose of this process is to make the most actual production plan available for MRP and capacity planning.",
              _type: "Process",
              GUID: "3d2f2530-5f90-11e9-2ddf-001bc640cd9b",
              Advisory_Notes:
                " ***Short-term production plan should already be available as planned production orders in the system and should not be impacted by changes to the PIR.  ***The usage of planned independent requirements to document the production plan has the advantage to be able to use MRP logic for the BOM brake down to different production stages and even raw material requirements. Depending on the planning strategies used it might not be possible to use planned independent requirements.  ***Using planned independent requirements implies the necessity to define suitable consumption rules for incoming orders/requirements. If these rules are not defined well or forecast accuracy is poor the consumption logic will in most cases lead to bad planning results.",
              Type: "Business Process",
              Scope:
                "Most actual information is evaluated and adjustment needs are determined. Existing production requirements are checked (e.g. planned independent requirements). Also the planned production orders from short-term production plan are considered to determine the actual necessary adjustments to the mid-term plan. Production requirements are either adjusted or maintained in order to represent the aligned production plan.",
              Outcome:
                "Adjusted production plan is available as independent demands in the system.",
              name: "Maintain production plan",
              _id: 98,
              Business_Rules: "",
              Prerequisite: "",
            },
          ],
        },
        {
          BPMN_GUID: "cd8bad42-33ae-11e9-2ddf-001bc640cd9b",
          Type: "Process Group",
          Description:
            "The process groups contains all processes related to the aggregation and disaggregation of demand plans in the context of the existing supply chain setup in order to prepare the generation of high-level plans for procurement, production, and distribution. Since master is also responsible to align procurement, production plans of different plants the Material Requirements Planning (MRP) is also integrated into this group.",
          _type: "Group",
          name: "Master Planning",
          GUID: "cd8bad42-33ae-11e9-2ddf-001bc640cd9b",
          _id: 2172,
          process_of: [
            {
              BPMN_GUID: "NULL",
              Description:
                "Purpose of this process is de-consolidate the forecast and prepare it for procurement planning.",
              _type: "Process",
              GUID: "73a8307c-5f77-11e9-2ddf-001bc640cd9b",
              Advisory_Notes:
                "*** The brake down logic from sales forecast to procurement requirement depends on the actual structure of products (e.g. bill of material, potential substitution). *** The detail-level of procurement requirements should correspond to the detail-level of procurement planning. Both with regards to raw material structures (e.g. raw material classification) as well as accumulation level on a timeline (e.g. yearly, quarterly, monthly or weekly requirements) *** Necessity to de-consolidate depends on the overall procurement situation. For some materials a detailed procurement plan is not necessary (e.g. piped water). Details need to be aligned together with Procurement Planning.",
              Type: "Business Process",
              Scope:
                "Forecast data is broken down according to the structure of the products into requirements of procured material (e.g. raw materials).",
              Outcome:
                "Procurement requirements are available for further planning.",
              name: "Brake down demand plan into procurement requirements",
              _id: 412,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "Purpose of the clarification of Supply Chain assumptions is to be able to consider important restrictions in order to reduce later adjustment efforts.",
              _type: "Process",
              GUID: "4d8735e4-2640-11e9-2ddf-001bc640cd9b",
              Advisory_Notes:
                "***Even if the final consolidated forecast will be input to Master Planning it is recommended to already consider information from Supply Chain in order to adjust assumptions and restrictions before starting to work on the Sales forecast itself. ***Master planning (and subsequent all other planning disciplines) is often involved in order to provide this information (e.g. major restrictions of capacities for a certain product range).",
              Type: "Business Process",
              Scope:
                "Already known restrictions within the supply chain are retrieved and prepared as guidance for the later forecast conduction.",
              Outcome:
                "Major restrictions coming from Supply Chain are known and can be considered during forecasting.",
              name: "Clarify supply chain assumptions",
              _id: 413,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "Purpose of this process is to provide requirements from sales forecast to Distribution Planning.",
              _type: "Process",
              GUID: "73a83086-5f77-11e9-2ddf-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "Requirements data is provided to the Distribution Planning and clarification needs are resolved to facilitate planning.",
              Outcome:
                "Distribution requirements are known to Distribution Planning and available for further planning.",
              name: "Provide and explain requirements to Distribution Planning",
              _id: 408,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "Purpose of this process is summarize and distribute the aligned plan after decision taken during S&OP alignment meetings.",
              _type: "Process",
              GUID: "fa5b7820-5f7d-11e9-2ddf-001bc640cd9b",
              Advisory_Notes:
                "The detailed sequence of alignment and the actual constitution of meetings highly depends on the business structures and supply chain setups. It is not possible to show a best practice as reference.",
              Type: "Business Process",
              Scope:
                "Different taken decisions need to be summarized including the impact on different planning disciplines. The mutual plan needs to be adjusted according to these decisions and then distributed to all planing disciplines as basis for operationalization.",
              Outcome:
                "Mutual plan is distributed and available for all planning disciplines.",
              name: "Distribute mutual plan to different planning disciplines",
              _id: 404,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "Purpose of this process is to provide procurement requirements from sales forecast to Procurement Planning.",
              _type: "Process",
              GUID: "fa5b7807-5f7d-11e9-2ddf-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "Requirements data is provided to the Procurement Planning and clarification needs are resolved to facilitate planning.",
              Outcome:
                "Procurement requirements are known to Procurement Planning and available for further planning.",
              name: "Consolidate production plan and procurement plan",
              _id: 411,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "Purpose of this process is to receive feedback from Distribution Planning with regards to feasibility of forecasted requirements.",
              _type: "Process",
              GUID: "43d8c39c-5f81-11e9-2ddf-001bc640cd9b",
              Advisory_Notes:
                "A typical critical aspect which distribution planning could escalate towards master planning is a insufficient space in a certain warehouse.",
              Type: "Business Process",
              Scope:
                "Distribution Planning provides feedback and planning details back to Master Planning with a special focus on critical aspects and/or impact on other planning disciplines.",
              Outcome:
                "Distribution plan and critical aspects are known to Master Planning.",
              name: "Receive feedback from distribution planning",
              _id: 410,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "Material requirements planning (MRP) helps to ensure the availability of all materials (raw materials, semifinished goods) required for production or other use in the right quantity and on time.",
              _type: "Process",
              GUID: "817029ee-7eff-11e5-0a66-001bc640cd9b",
              Advisory_Notes:
                "Material requirements planning (MRP) requires bills of materials and material lead times to be defined and correctly maintained. The MRP schedule must be defined regularly.   There are many different MRP strategies available for planning driven and consumption driven material approaches.   The identified materials requirement can be the triggering event for this process. Other triggering events can be: ***Production order created ***Independent demand revised and updated ***Rework production order created ***Refilling/Re-packaging process order created ***Campaign scheduling completed ***Dependent demand revised and updated",
              Type: "Business Process",
              Scope:
                "In stepwise production scheduling procedure after defining finished product demand for the planning period, material requirements planning must be performed to compare demand with current stocks and planned manufacturing, identify final products to be manufactured, analyze, what materials, in what quantity and when are required for the manufacturing and propose production and purchasing schedule (material requirements planning assumes infinite production capacity).",
              Outcome:
                "As a result of material requirements planning, the recommended production schedule is created together with planned orders. In parallel, a recommended purchasing schedule for materials required for production but not available on stock is needed.",
              name: "Perform and evaluate material requirements planning",
              _id: 1228,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "In inventory managed consumables procurement scenario, the procurement process is triggered by planning instead of acting on an actual, individual request. Parameters used in the material requirement planning (MRP) and resulting in purchase requisitions must be reviewed regularly to ensure their relevance to current situation and avoid unnecessary purchases.",
              _type: "Process",
              GUID: "dac2e2bc-7f04-11e5-0a66-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "Parameters used in MRP for calculating purchasing requirements are reviewed and updated. Such parameters could include minimal stock levels, replenishment time, minimal order size. Review should also answer the question if consumables under question are still required and their planned purchasing should be continued.",
              Outcome: "Parameters used in MRP are up-to-date.",
              name: "Review material requirement parameters",
              _id: 406,
              Business_Rules:
                "In order to ensure consistency of all relevant planning parameters and logistics data, it is recommended to define standardized types of materials/products and to set rules how to classify and periodically verify those.",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "Purpose of this process is to provide production requirements from sales forecast to Production Planning.",
              _type: "Process",
              GUID: "73a8306b-5f77-11e9-2ddf-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "Requirements data is provided to the Production Planning and clarification needs are resolved to facilitate planning.",
              Outcome:
                "Production capacity requirements are known to Production Planing and available for further planning.",
              name: "Provide and explain requirements to Production Planning",
              _id: 401,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "Purpose of this process is provide requirements data to distribution planning.",
              _type: "Process",
              GUID: "73a83089-5f77-11e9-2ddf-001bc640cd9b",
              Advisory_Notes:
                "*** The brake down logic from sales forecast to distribution requirements depends on the actual setup of  production facilities (own production, sub contractors) on the distribution network setup (location of plants, warehouses, suppliers and customers) and special requirements either customer or product related (e.g. temperature based transport, storage of hazardous material) . *** The detail-level of distribution requirements should correspond to the detail-level of distribution planning. Both with regards to special requirements as well as accumulation level on a timeline (e.g. yearly, quarterly, monthly or weekly requirements) *** Necessity to de-consolidate depends on the overall distribution situation. For some customers a detailed procurement plan might not be necessary (e.g. EXW contracts). Details need to be aligned together with Distribution Planning.",
              Type: "Business Process",
              Scope:
                "Forecast data is broken down according to warehousing and transportation structures into requirements for distribution. In addition information about make or buy decisions (e.g. own production vs. subcontracting) is being included.",
              Outcome:
                "Distribution requirements are available for further planning.",
              name: "Brake down demand plan and information from make or buy cases into distribution requirements",
              _id: 400,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "Purpose of this process is to provide procurement requirements from sales forecast to Procurement Planning.",
              _type: "Process",
              GUID: "fa5b7803-5f7d-11e9-2ddf-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "Procurement requirements data is provided to the Procurement Planning and clarification needs are resolved to facilitate Procurement Planning.",
              Outcome:
                "Procurement requirements are known to Procurement Planning and available for further planning.",
              name: "Evaluate and decide on make or buy cases with Production and Procurement Planning",
              _id: 405,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "Purpose of this process is to receive feedback from Procurement Planning with regards to feasibility of forecasted requirements.",
              _type: "Process",
              GUID: "43d8c396-5f81-11e9-2ddf-001bc640cd9b",
              Advisory_Notes:
                "A typical critical aspect which procurement planning could escalate towards master planning is a insufficient source of supply for certain raw materials.",
              Type: "Business Process",
              Scope:
                "Procurement Planning provides feedback and planning details back to Master Planning with a special focus on critical aspects and/or impact on other planning disciplines.",
              Outcome:
                "Procurement plan and critical aspects are known to Master Planning.",
              name: "Receive feedback from procurement planning",
              _id: 409,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "Purpose of this process is to receive feedback from Production Planning with regards to feasibility of forecasted requirements.",
              _type: "Process",
              GUID: "fa5b7815-5f7d-11e9-2ddf-001bc640cd9b",
              Advisory_Notes:
                "A typical critical aspect which production planning could escalate towards master planning is insufficient production capacity within specific production areas / concerning specific product groups (either personnel or equipment based).",
              Type: "Business Process",
              Scope:
                "Production Planning provides feedback and planning details back to Master Planning with a special focus on critical aspects and/or impact on other planning disciplines.",
              Outcome:
                "Production plan and critical aspects are known to Master Planning.",
              name: "Receive feedback from production planning",
              _id: 402,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "Purpose of this process is de-consolidate the forecast and prepare it for production planning.",
              _type: "Process",
              GUID: "73a83067-5f77-11e9-2ddf-001bc640cd9b",
              Advisory_Notes:
                "*** The brake down logic from sales forecast to capacity requirement depends on the actual structure of products, the production setup of the plant and of the different production lines/areas. *** The detail-level of capacity requirements should correspond to the detail-level of production planning. Both with regards to usage of resources and equipments including availability of details in master data (e.g. from work plans / recipes) as well as accumulation level on a timeline (e.g. yearly, quarterly, monthly or weekly capacities) *** Necessity to de-consolidate depends on the overall capacity utilization. In some cases it might be sufficient to focus on bottleneck areas only. This needs to be decided together with production planning.",
              Type: "Business Process",
              Scope:
                "Forecast data is broken down according to the structure of the products and the product specific production process in order to determine the capacity requirements.",
              Outcome:
                "Production capacity requirements are available for further planning.",
              name: "Brake down demand plan into production requirements",
              _id: 403,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "Purpose of this process is to provide procurement requirements from sales forecast to Procurement Planning.",
              _type: "Process",
              GUID: "73a83076-5f77-11e9-2ddf-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "Requirements data is provided to the Procurement Planning and clarification needs are resolved to facilitate planning.",
              Outcome:
                "Procurement requirements are known to Procurement Planning and available for further planning.",
              name: "Provide and explain requirements to Procurement Planning",
              _id: 407,
              Business_Rules: "",
              Prerequisite: "",
            },
          ],
        },
      ],
      _id: 390,
    },
    {
      BPMN_GUID: "d65661a3-4103-45ee-89ed-317d7eac77b4",
      Type: "Process Cluster",
      _type: "Cluster",
      GUID: "86ff4c31-256a-11e9-2ddf-001bc640cd9b",
      name: "J. Quality Assurance + Compliance Management",
      group_of: [
        {
          BPMN_GUID: "65421b11-8de0-11e5-0a66-001bc640cd9b",
          Type: "Process Group",
          Description:
            "Global Trade Services covers processes for managing the complexities of international trade including full regulatory compliance, customs declarations, and risk mitigation, while importing and exporting on a global scale.",
          _type: "Group",
          name: "Global Trade Compliance",
          GUID: "65421b11-8de0-11e5-0a66-001bc640cd9b",
          _id: 2179,
          process_of: [
            {
              BPMN_GUID: "NULL",
              Description:
                "Global trade compliance ensures due diligence with trade regulatory programs and cross-border customs requirements. Global trade checks are required for all business operations.",
              _type: "Process",
              GUID: "1b684ec4-44de-11e9-2ddf-001bc640cd9b",
              Advisory_Notes:
                "The check itself is typically done automatically by the ERP system. The actual process discussion should focus on the maintenance of the relevant GTS data (including the integration of external data sources) and the handling of sales orders failing to pass the checks.",
              Type: "Business Process",
              Scope:
                "Global trade checks verify conformance with regulations of the countries from/to which the trade is being conducted. In this respect both involved business partners and products being sold or related services are subject to checks.",
              Outcome:
                "The sales order complies with the applicable trade regulations and the product can be shipped.",
              name: "Perform global trade check",
              _id: 57,
              Business_Rules:
                "It needs to be ensured that information (sanctioned party lists, embargo lists, dual use products) used for screening is comprehensive and up to date.   Customer data must be up to date.   Rules and procedures how to handle sales orders which failed the compliance checks have to be in place.",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "Global trade checks ensure compliance with trade regulatory programs and cross-border customs requirements. Compliance must be ensured in all international business operations.     ",
              _type: "Process",
              GUID: "fb9de07b-7f05-11e5-0a66-001bc640cd9b",
              Advisory_Notes:
                "The GTS check itself is in most cases just an automated check against tables.",
              Type: "Business Process",
              Scope:
                "Global trade checks verify conformance with regulations of the countries from/to which the trade is being conducted. In this respect both the vendor and products being purchased are subject to checks.",
              Outcome:
                "The purchase order complies with the applicable trade regulations and the goods can be purchased and imported.",
              name: "Perform global trade checks for purchasing",
              _id: 1198,
              Business_Rules:
                "It needs to be ensured that information (sanctioned party lists, embargo lists) used for screening is comprehensive and up to date.   Vendor data must be up to date.   Rules and procedures how to handle purchase orders which failed the compliance checks have to be in place.",
              Prerequisite: "",
            },
          ],
        },
        {
          BPMN_GUID: "125047b1-8de0-11e5-0a66-001bc640cd9b",
          Type: "Process Group",
          Description:
            "This process group covers all processes concerning the handling of customer, vendor and internal complaints by the Quality Management department.",
          _type: "Group",
          name: "Complaint Management",
          GUID: "125047b1-8de0-11e5-0a66-001bc640cd9b",
          _id: 2178,
          process_of: [
            {
              BPMN_GUID: "NULL",
              Description:
                "Closing customer complaint is the final step in the customer dispute management process and occurs when all matters related to the dispute have been settled.         .",
              _type: "Process",
              GUID: "a3824c8c-e992-11e4-0a66-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "Once the issuing of a credit memo or replacement, or a return by the customer has occurred, all information related to the dispute settlement is recorded, stored and sent to the customer. This marks the end of the customer dispute.",
              Outcome: "Customer complaint is closed",
              name: "Close customer complaint",
              _id: 1372,
              Business_Rules:
                "It is important to decide:   ***Who is responsible for closing what customer complaint   ***Whether and how confirmation of the closure needs to be confirmed by the customer?    ",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "The corrective actions process provides information about the further processing of the record results.  ",
              _type: "Process",
              GUID: "7d364531-7e45-11e5-0a66-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "In case, the assessed quality parameters are not within the required range, a decision on corrective actions takes place to bring the production back in quality or on the initiation of rework if no other options are possible.",
              Outcome:
                "Based on the inspection results, a decision regarding necessary corrective actions is made and required actions are triggered.",
              name: "Initiate corrective actions",
              _id: 459,
              Business_Rules: "",
              Prerequisite: "",
            },
          ],
        },
      ],
      _id: 457,
    },
    {
      BPMN_GUID: "71b004dd-0356-4ff3-864b-3f753e99ea1c",
      Type: "Process Cluster",
      _type: "Cluster",
      name: "H. Service",
      GUID: "53ec31a1-256a-11e9-2ddf-001bc640cd9b",
      group_of: [
        {
          BPMN_GUID: "deedbf81-8ddd-11e5-0a66-001bc640cd9b",
          Type: "Process Group",
          Description:
            "This process group covers all processes related to after sales repair services executed on our premises. Processes involve recording of working hours and recording of spare parts used etc.",
          _type: "Group",
          name: "Repairs",
          GUID: "deedbf81-8ddd-11e5-0a66-001bc640cd9b",
          _id: 2182,
          process_of: [
            {
              BPMN_GUID: "NULL",
              Description:
                "Employees working on repair or services have to record the hours spent on it. These hours need to be confirmed by the Head of repair.",
              _type: "Process",
              GUID: "b27a5490-90b2-11e8-2ddf-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "Working hours are recorded by employees and transferred to controlling.",
              Outcome: "Working hours recorded.",
              name: "Confirmation of time",
              _id: 494,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "A service order is required prior to service execution. In this case the service order is automatically created once the repair order is confirmed for repair execution.",
              _type: "Process",
              GUID: "499e1b22-7e50-11e5-0a66-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope: "Select repair order and confirm repair order.",
              Outcome:
                "Repair order confirmed and service order automatically created.",
              name: "Process repair order and create service order",
              _id: 493,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "The service notification is reopened for adding more details to it. Additional details can be added by calling  back the customer. Based on the information of the customer, the service employee decides if the service request is further processed. If the service notification can be further processed, the service employee captures what material and quantity is requested for the repair of the equipment. The repair order is generated from the service notification.",
              _type: "Process",
              GUID: "a9cc68f6-7e4f-11e5-0a66-001bc640cd9b",
              Advisory_Notes:
                "Service products (material master record) are used to represent a service and to manage inventory of the customers returned equipment/product (unit of measure is piece)",
              Type: "Business Process",
              Scope:
                "Add details to service notification, create repair order.",
              Outcome: "Repair order created.",
              name: "Process service notification and create repair order",
              _id: 495,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "The service must be executed based on the specifications provided in the service order and status needs to be maintained accordingly.",
              _type: "Process",
              GUID: "b27a548a-90b2-11e8-2ddf-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "Once the service has been dispatched, required activities are carried out to perform the service. After service execution, it is required to document the provided service and update the service order status.",
              Outcome: "Service operations are fulfilled and status updated.",
              name: "Perform service and update service order status",
              _id: 492,
              Business_Rules: "",
              Prerequisite: "",
            },
          ],
        },
        {
          BPMN_GUID: "ecb2d561-8ddd-11e5-0a66-001bc640cd9b",
          Type: "Process Group",
          Description:
            "This process group covers all processes involved:  ***in initiating a return delivery of goods sold to the customer  ***the reason why goods are being sent back   ***the closure of customer complaint.",
          _type: "Group",
          name: "Customer Returns",
          GUID: "ecb2d561-8ddd-11e5-0a66-001bc640cd9b",
          _id: 2181,
          process_of: [
            {
              BPMN_GUID: "NULL",
              Description:
                "This process ensures the proper handling of complaints.",
              _type: "Process",
              GUID: "1f6c4922-7e4d-11e5-0a66-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "The progress of the complaint is monitored and tracked. In case of delays reasons are investigated and escalated.",
              Outcome: "Complaint monitored.",
              name: "Monitor complaint",
              _id: 479,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "Receiving the customer complaint and capturing it in a format necessary for further processing helps to ensure the effective and efficient handling of customer disputes.",
              _type: "Process",
              GUID: "1f6c491c-7e4d-11e5-0a66-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "Once the customer places a complaint, the data is recorded, stored and forwarded for further processing.",
              Outcome:
                "All relevant information regarding the customers complaint is documented.",
              name: "Document and assess customer complaint",
              _id: 1370,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "The customers equipment is delivered for repair to the companys premises. The equipment delivered for repair needs to be posted into the companys stock with reference to the repair order.",
              _type: "Process",
              GUID: "499e1b1f-7e50-11e5-0a66-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "Once a repair order has been placed, a return delivery is initiated with all relevant shipping information. On arrival of the equipment, the goods receipt is posted.",
              Outcome: "Goods receipt of equipment to be repaired posted",
              name: "Create return delivery w. ref. to repair order",
              _id: 481,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "Prior to the pick-up of returnable packaging sent to the customer, a document must be created that specifies related conditions and initiates the return.",
              _type: "Process",
              GUID: "605f69a6-7e4f-11e5-0a66-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "Compilation of the document to initiate the pick-up of returnable packing, specifying conditions related to the return of the materials. Information is provided on whether or not the customer will be charged for transportation.",
              Outcome:
                "Document for pick-up of returnable packaging is created and the return process is triggered.",
              name: "Create returnable packaging pick-up",
              _id: 480,
              Business_Rules:
                "The information required in the document must be defined. The decision must be made on whether this process includes checks performed for standard sales order creation i.e. EH+S, GTS Check, etc.",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "It is necessary to determine the validity of each complaint received and the decision made regarding the settlement of the customer dispute.",
              _type: "Process",
              GUID: "1f6c4931-7e4d-11e5-0a66-001bc640cd9b",
              Advisory_Notes:
                "Guidelines must be established regarding the categorization and prioritization of complaints and how this information should be used in steering the handling (response time) of the customer dispute.   It is required to set-up the information flow and monitoring of received customer complaints between all involved parties involved in complaint handling (e.g. Quality Management).",
              Type: "Business Process",
              Scope:
                "The information regarding the customer complaint is assessed to determine the validity of the claims made. The decision is made on the steps necessary to resolve the customer dispute. The required steps must then be initiated.",
              Outcome:
                "The decision is made on:   ***Whether to take the goods back from the customer   ***If possible/appropriate, how to compensate the customer.",
              name: "Create return sales order",
              _id: 1375,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "Non-physical goods receipt is required to record the change of ownership of goods from an external entity to the organization. Physically, the goods are still located at customer premises. Return delivery is required to record goods receipt into consignment stock.",
              _type: "Process",
              GUID: "1577b599-7e48-11e5-0a66-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "Once the consignment return order has been created, the return delivery is created and non-physical goods receipt posted. This documents the fact that goods have been returned by the customer and are now owned by the organization. However, the goods are still at the customers location physically.",
              Outcome:
                "Non-physical goods receipt is recorded, goods become property of the organization and consignment stock has increased.",
              name: "Simulate return delivery and post goods receipt",
              _id: 191,
              Business_Rules:
                "Rules must be established regarding:   ***How to deal with consignment returns i.e. if they are added to regular customer consignment stock or tracked separately.",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "A delivery is the central object of the goods receipt process containing all the data required for triggering and monitoring it, starting from goods receipt in the yard and ending with transferral of the goods to the final putaway. In a customer return (as well as consignment pick-up) process, a return delivery document is used for this purpose.",
              _type: "Process",
              GUID: "3712628c-e9bd-11e4-0a66-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "After the return order (consignment pick-up order for consignment process) is created, a return delivery is created with reference to it.",
              Outcome:
                "Return delivery is created and goods receipt process can be initiated. ",
              name: "Create return delivery",
              _id: 1371,
              Business_Rules: "",
              Prerequisite: "",
            },
          ],
        },
        {
          BPMN_GUID: "cd73b7f1-8ddd-11e5-0a66-001bc640cd9b",
          Type: "Process Group",
          Description:
            "This process group covers all processes related to after sales field services executed at customers site. Processes involve recording of working hours, provision of spare parts at customers site, and recording of spare parts used etc.",
          _type: "Group",
          name: "Field Services",
          GUID: "cd73b7f1-8ddd-11e5-0a66-001bc640cd9b",
          _id: 2180,
          process_of: [
            {
              BPMN_GUID: "NULL",
              Description:
                "The service employee calls up the outstanding notification. The service employee defines further actions that have to be taken e.g. creation of service order.  Optionally, the service order employee requests more information from the customer prior to deciding if the service notification is further processed. The customer can now be informed that his service request is further processed.",
              _type: "Process",
              GUID: "f56c6304-7e51-11e5-0a66-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "Call up notification, call back customer and create service order.",
              Outcome: "Service order created.",
              name: "Process service notification and create service order",
              _id: 10,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "The customer requests a spare part. A spare part order is created.",
              _type: "Process",
              GUID: "d46a2a9b-7eeb-11e5-0a66-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope: "Create notification and spare part order.",
              Outcome: "Spare part order created.",
              name: "Enter service notification and create spare parts order",
              _id: 1362,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "Receiving an assembly order and capturing it in a standard format necessary for further processing are the key first steps for the assembly sales scenario. ",
              _type: "Process",
              GUID: "7263da23-7e53-11e5-0a66-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "Once the customer order is placed, the data must be amended, stored and forwarded for further processing. This might include transferring or copying data from other documents such as quotations or frame contracts.",
              Outcome:
                "All relevant information regarding the customers order is documented. The information captured provides the basis for determining whether the order can be fulfilled.",
              name: "Receive assembly order and capture sales order data",
              _id: 490,
              Business_Rules: "",
              Prerequisite:
                "Prerequisite: set-up of communication channels to be used.",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "This process describes the receipt of a service request of a customer for an equipment. From a service notification, a service order is directly created.",
              _type: "Process",
              GUID: "35d8dd17-7eea-11e5-0a66-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "A service notification with reference to an equipment master record is captured. The related service order is generated automatically.",
              Outcome: "Service notification and service order created.",
              name: "Create service notification and service order",
              _id: 485,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "Based on the equipment number, the service employee can perform a warranty check. If the  equipment has a valid warranty which covers the kind of damage a sales order free of charge (potentially with returns delivery) is created. If the warranty does not apply, the customer is invoiced for the repair.",
              _type: "Process",
              GUID: "499e1b34-7e50-11e5-0a66-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope: "Check customer warranty.",
              Outcome: "Warranty status determined.",
              name: "Determine warranty status",
              _id: 14,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "The service must be executed according to the specifications provided in the service order. ",
              _type: "Process",
              GUID: "49b377d4-7e50-11e5-0a66-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "Once the service has been dispatched, required activities are carried out to perform the service. After service execution, it is required to document the provided service.",
              Outcome: "Service operations are fulfilled.",
              name: "Perform service",
              _id: 1366,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "In order to quote price and conditions to the customer, a quotation can be prepared in a predefined format. Depending on the business model quotation may include different information. The basis for the creation of quotation are the customer details and information from customer inquiry. The quotation is sent out to the customer.",
              _type: "Process",
              GUID: "f56c62d4-7e51-11e5-0a66-001bc640cd9b",
              Advisory_Notes:
                "The service order must be blocked until the customer accepts the quotation.",
              Type: "Business Process",
              Scope:
                "Quotation document is prepared based on information captured in inquiry. Sales price is calculated and conditions determined (e.g. service costs, margin tables, rebates, delivery terms).",
              Outcome: "Quotation is created and sent to customer.",
              name: "Create quotation for service request",
              _id: 486,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "With the commercial completion of the service, no more costs can be allocated to the service. The service order is completed from an accounting/controlling perspective. This process describes the settling of the service order, which is the posting of all costs and revenues related to the service order and its transfer to the profitability analysis.",
              _type: "Process",
              GUID: "499e1b10-7e50-11e5-0a66-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "Post costs and revenues related to the service order and transfer to the profitability analysis via settlement, complete service order.",
              Outcome:
                "All costs and revenues settled to service order and service order is closed from a business perspective.",
              name: "Settle and complete service order",
              _id: 484,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "It is sometimes required to maintain additional information to the customer notification based on new input internally, or from the customer.",
              _type: "Process",
              GUID: "84843a2b-7e5b-11e5-0a66-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "Maintain additional information and update service notification.",
              Outcome: "Service notification updated.",
              name: "Change service notification",
              _id: 6,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "This process describes the planning of the service execution. Based on the description of the service order this can include the planned working hours and the required material/spare parts used for the repair process. A first repair cost calculation is calculated. ",
              _type: "Process",
              GUID: "35d8dd2f-7eea-11e5-0a66-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "Capture estimates on work hours and required material/spare parts, and calculate the costs for the repair.",
              Outcome: "Costs are calculated for the service order.",
              name: "Process service order",
              _id: 483,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "Prior to the service execution the planned working hours have to be maintained and if required also spare parts.",
              _type: "Process",
              GUID: "019b08e2-83f2-11e5-0a66-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "Prior to the repair process the planned working hours and required spare parts are maintained in the system.",
              Outcome: "Working hours maintained, spare parts maintained.",
              name: "Maintain planned working hours",
              _id: 487,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "After the quotation acceptance of  the customer the release of the service order is triggered. Furthermore, spare parts that are required to execute the service are reserved.",
              _type: "Process",
              GUID: "bc205e7b-e8da-11e4-0a66-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope: "Release service order and reserve spare parts.",
              Outcome: "Service order released and spare parts reserved.",
              name: "Release service order",
              _id: 13,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "During phase out, the continued time for service needs to be defined and communicated to the customer. This includes not only repair, service onsite but also stockholding of spare parts. Purchasing or production of spare parts must be ensured until end of repair/service time.",
              _type: "Process",
              GUID: "686d8614-94c0-11e8-2ddf-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "Consider sold units, warranty times, service quota and used spare parts.",
              Outcome:
                "End of service defined with all relevant details. Communicated to all relevant parties.",
              name: "Define end of service time",
              _id: 488,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "This process describes the receipt of a service request of a customer and how to proceed with the request.",
              _type: "Process",
              GUID: "499e1b19-7e50-11e5-0a66-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "Create and maintain the service notification based on the customers request for a service.",
              Outcome: "Service notification created.",
              name: "Create service notification",
              _id: 8,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "Once all service order activities are complete and completion confirmations are posted, the status of the service order can be set to technically complete.",
              _type: "Process",
              GUID: "49b377d1-7e50-11e5-0a66-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "The status of the service order is set to technically complete.",
              Outcome: "Service is technically complete.",
              name: "Technically complete service order",
              _id: 2,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "When a request for refurbishment is sent, all relevant details must be documented and then evaluated in order to determine whether to proceeded with creation of a refurbishment order.",
              _type: "Process",
              GUID: "233ac2a9-7efc-11e5-0a66-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "A refurbishment maintenance request is maintained in the system. It is then evaluated by the maintenance approver who may either approve or reject the request. If approved, the request notification forms the basis for creating the refurbishment order.",
              Outcome: "Decision made whether to accept or reject the request.",
              name: "Receive and process refurbishment request",
              _id: 1288,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "This process describes the creation of a service contract.",
              _type: "Process",
              GUID: "84843a40-7e5b-11e5-0a66-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "Creation of customer contract based on the contract data.",
              Outcome: "Service contract has been created.",
              name: "Create service contract",
              _id: 4,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "The service department decides to perform the repair service free of charge (goodwill).    ",
              _type: "Process",
              GUID: "31be9a19-7eea-11e5-0a66-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope: "Maintain repair service as free of charge and confirm.",
              Outcome: "Service order confirmed and ready for execution.",
              name: "Confirm service order for service execution (with goodwill)",
              _id: 489,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "Spare parts have been reserved from the service order in the stock for the repair. Spare part consumption takes place during the service process and must be recorded. Stock level in the system must be lowered accordingly.",
              _type: "Process",
              GUID: "499e1b3d-7e50-11e5-0a66-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "Confirmation of material consumption, goods issue adjusts inventory and the spare parts are accounted to the service order as costs.",
              Outcome: "Material consumption captured and goods issue posted.",
              name: "Confirm consumption of spare parts",
              _id: 3,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "After noting down the customers problem in a service notification, the service manager creates a service order with reference to the notification. A service order is required prior to service execution. The service order can include the requirement of spare parts.",
              _type: "Process",
              GUID: "81351d3c-e9bd-11e4-0a66-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "Creation of service order, reservation of required spare parts, release of service order.",
              Outcome: "Service order created and released.",
              name: "Create service order",
              _id: 5,
              Business_Rules: "",
              Prerequisite: "",
            },
          ],
        },
      ],
      _id: 477,
    },
    {
      BPMN_GUID: "a10e24b8-627a-49d7-925d-9a76a4934c08",
      Type: "Process Cluster",
      _type: "Cluster",
      name: "I. Enterprise Management",
      GUID: "6b5eb241-256a-11e9-2ddf-001bc640cd9b",
      group_of: [
        {
          BPMN_GUID: "1e467be1-8dde-11e5-0a66-001bc640cd9b",
          Type: "Process Group",
          Description:
            "This process group covers daily accounting, checking and reporting processes within Accounts Receivable. It also describes activities performed as follow up from last closing and/or in preparation of next closing.",
          _type: "Group",
          name: "Accounts Receivable Activities",
          GUID: "1e467be1-8dde-11e5-0a66-001bc640cd9b",
          _id: 2195,
          process_of: [
            {
              BPMN_GUID: "NULL",
              Description:
                "A debit memo is issued by the seller of goods to the customer, increasing the amount that is owed to the seller under the terms of an earlier invoice. Typically a debit memo is issued, when the amount billed on the original invoice was too low. An example for such situation would be a debit note for the amount of a rebate, which was included into initial invoice, but for which the customer has not qualified.",
              _type: "Process",
              GUID: "1ea694f6-e995-11e4-0a66-001bc640cd9b",
              Advisory_Notes:
                "Many companies do not use debit memos, but instead rather re-issue the original invoice with an adjustment, or issue an invoice for the incremental amount.",
              Type: "Business Process",
              Scope:
                "Once it has been identified, that amount billed on the original invoice was too low, the debit memo document is created with or without reference to original invoice.",
              Outcome: "Debit memo is created and sent to the customer.",
              name: "Create debit memo",
              _id: 207,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "With this process, an analysis is done of all errors generated in the failed IDOC report.",
              _type: "Process",
              GUID: "fccd2e78-9569-11e8-2ddf-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "The causes of IDOC errors are identified at this point. All errors are then corrected and the segment type is adjusted. ",
              Outcome: "The IDOC segment type is adjusted.",
              name: "Analyse IDOC errors and adjust segment",
              _id: 582,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "This refers to activities which ensure that the customers cleared amounts are no longer reflected as an open item on their account.",
              _type: "Process",
              GUID: "e7fef68a-5db5-11e8-2ddf-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "This involves the corresponding maintenance and adjustment of customer credit limits based on good repayment history.  ",
              Outcome:
                "The business partner details are adjusted in accordance with the customers payment history. ",
              name: "Perform account maintenance for AR",
              _id: 220,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "This process reflects the triggering of the output message type or labelling the invoicing medium as EDI related. This can usually be maintained during business partner creation. ",
              _type: "Process",
              GUID: "80843c09-9567-11e8-2ddf-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "This particularly useful for:   ***Intercompany Replenishment    ***Sales via Intercompany",
              Outcome:
                "The invoice is generated without errors and the PDF is sent to the receiving entity. ",
              name: "Propagate IC invoice for STO via EDI",
              _id: 575,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "Manage incoming payment process ensures that the payment from a customer is captured and documented appropriately.",
              _type: "Process",
              GUID: "f5f29ea1-e8f4-11e4-0a66-001bc640cd9b",
              Advisory_Notes:
                "Especially in global template projects different variants of payments have to be considered but their use should often be limited to a group of countries rather than being made available to all.",
              Type: "Business Process",
              Scope:
                "Manage incoming payment involves receiving a payment from a customer and entering the information in accounting. The incoming payment is mapped to the customer invoice and customer account.",
              Outcome:
                "Incoming payment is recorded and the customer account is credited.",
              name: "Manage incoming payment",
              _id: 1264,
              Business_Rules:
                "The methods of payment must be established taking into account country specific factors related to the business and customer. For instance: The bill of exchange (BoE) payment method is used in some countries only.   Typical methods of payment are:   ***Check   ***Direct debits   ***Credit cards   ***Bank transfers",
              Prerequisite:
                "Prerequisite: currency exchange and hedging processes",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "Once a credit memo is received, the details must be captured and documented accordingly. Relevant details include the vendor that sent the credit memo and the reason for issuing the credit memo.   ",
              _type: "Process",
              GUID: "7b15599d-7f0c-11e5-0a66-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "After products or packaging have been returned to the vendor, a credit memo can be issued. When this occurs the credit memo is checked regarding whether it is formally compliant and it is checked against the original invoice. ",
              Outcome: "The credit memo is posted and processed in accounting.",
              name: "Manage incoming credit memo",
              _id: 1251,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "The customer balanced the down payment request. The amount now needs to be identified to the down payment request and posted in the system.  Both automatic and manual postings are possible. The down payment request gets cleared.        ",
              _type: "Process",
              GUID: "f5f054bc-e8f4-11e4-0a66-001bc640cd9b",
              Advisory_Notes:
                "Down payments have to be considered in the credit check.",
              Type: "Business Process",
              Scope: "Clear down payment request.",
              Outcome: "Down payment request cleared.",
              name: "Clear down payment request",
              _id: 581,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "This refers to the accompanying process step which is triggered by a warranty claim or a customer return which will be honored and refunded.",
              _type: "Process",
              GUID: "e7fef697-5db5-11e8-2ddf-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "After the inspection lot is performed, the decision of whether or not a warranty claim is applicable is made. ",
              Outcome:
                "The warranty claim decision is made with the decision to issue a refund or credit note to the customer. ",
              name: "Manage outgoing customer payments and rebates",
              _id: 580,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "This reflects a communication flow between Accounts receivable and Sales/ party responsible for issuing the credit note. This is to ensure that the terms and conditions of the warranty have been met before the amounts can be released by accounts receivable.  ",
              _type: "Process",
              GUID: "e7fef69f-5db5-11e8-2ddf-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "This process applies to all instances where there is a communication flow between sales and corresponding departments. ",
              Outcome: "All parties involved are informed and aligned. ",
              name: "Align with sales staff",
              _id: 576,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "This process ensures that sales billing documents are released to accounts receivable accounting in a timely manner for period end closing.",
              _type: "Process",
              GUID: "68f6e4f1-9732-11e8-2ddf-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "Release all sales transactions with corresponding outbound delivery documents to accounting so that they are reflected in the correct to accounting period.",
              Outcome: "Sale billing documents released to accounting.",
              name: "Release billing to accounting",
              _id: 249,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "After final invoice has been issued to the customer the down payment can be cleared with the invoice.       ",
              _type: "Process",
              GUID: "ad54682a-7e58-11e5-0a66-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "A customer pays an invoice for which a down payment was made. The down payment open item is clear to indicate the payment made.",
              Outcome: "Down payment cleared.",
              name: "Clear down payment",
              _id: 584,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "When entities from the same corporate group are involved in a business transaction (e.g. intercompany purchasing), intercompany billing is required.  ",
              _type: "Process",
              GUID: "837a78be-7eff-11e5-0a66-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "The intercompany invoice must be captured and documented accordingly in the accounting system. The invoice is mapped to business transactions performed in purchasing.",
              Outcome:
                "Incoming intercompany invoice is recorded and forwarded for payment.",
              name: "Manage incoming intercompany invoice",
              _id: 1231,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "Conduct clearing AR refers to the offsetting of incoming customer payments against outstanding amounts owed by debtors. This clearing process results in a decrease in the total accounts receivable which will be reported in the balance as at that given point in time.",
              _type: "Process",
              GUID: "e7fef685-5db5-11e8-2ddf-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "This involves the clearing of outstanding open outstanding balances which are directly correlated to the payment.",
              Outcome:
                "The amounts owed are cleared with no differences outstanding against the initial customer order.",
              name: "Conduct clearing AR",
              _id: 219,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "Spare parts that were required for executing the service will be added to the customer invoice.       ",
              _type: "Process",
              GUID: "4260a7b9-e8db-11e4-0a66-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "Price capturing of spare part/material, invoice will be linked to the customer invoice.",
              Outcome: "Invoice added to final customer invoice.",
              name: "Maintain spare parts for invoicing",
              _id: 7,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "Credit management helps to control the companys exposure to credit risk. Credit management must be aligned taking into account the customers liquidity and payment history (credit limit/insurance) and outstanding receivables.",
              _type: "Process",
              GUID: "5aa42fcb-e8e2-11e4-0a66-001bc640cd9b",
              Advisory_Notes:
                "Many corporations start looking at credit management from a group perspective. This requires some collaboration across companies and business units, which might not be established. Hierarchical credit limits might help to hinder a single business unit from already consuming the entire credit limit of a customer.  Any kind of guarantees such as letter of credit or bank guarantees and advanced payments need to be considered in this process.  Credit management for consignment fill-ups - despite goods remaining in sellers property, it is common to perform credit check, since control over goods is lost and the goods issue is typically retrospective.",
              Type: "Business Process",
              Scope:
                "The customers ability to pay for the product or service is evaluated based on their liquidity and payment history and a decision is made on whether the sale is allowed or not.",
              Outcome:
                "The decision is made on whether to proceed with sale or not.",
              name: "Execute credit management",
              _id: 1334,
              Business_Rules:
                "It is necessary to define the information necessary to execute the credit management process.  A framework must be put in place for:   ***Calculating the rating value of customers    ***Determining Credit Limit   ***Setting rules for Credit Check    ",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "A credit memo is issued by the seller of goods to the customer, reducing the amount that is owed to the seller under the terms of an earlier invoice. A credit memo may be issued because the goods were returned to the seller, or there may have been a pricing dispute or other reasons for which the customer will not pay the seller the full amount of the invoice.       ",
              _type: "Process",
              GUID: "e83072e4-e98c-11e4-0a66-001bc640cd9b",
              Advisory_Notes:
                "The appropriate sales tax is also calculated along with any shipping charges that are being credited to the customer. ",
              Type: "Business Process",
              Scope:
                "Once the decision is made to compensate a customer with a credit memo, the document is created with details regarding reduction in the previous amount owed. The appropriate sales tax is also calculated along with any shipping charges that are being credited to the customer.",
              Outcome: "Credit memo is created and sent to the customer.",
              name: "Create credit memo",
              _id: 1368,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "In an intercompany situation, product price may vary from those offers to external customers. In such cases, it is necessary to determine the price offered to the IC buyer. Pricing will be performed, using SD-functionality.",
              _type: "Process",
              GUID: "80843c23-9567-11e8-2ddf-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "Transfer product price is determined based on corporate guidelines and corporate tax regulations.",
              Outcome: "Transfer product price is determined.",
              name: "Determine transfer product price for IC",
              _id: 178,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "This process involves the tracking of failed IDOCS and the generation of reports for the failed IDOCs.  ",
              _type: "Process",
              GUID: "fccd2e74-9569-11e8-2ddf-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "Conduct timely checks to ensures that all failed Inter-company IDOC invoices are identified for analysis.",
              Outcome: "List of failed IDOC invoices are ready for analysis.",
              name: "Monitor IDOCS",
              _id: 583,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "The creation of an intercompany invoice initiates the payment process for a transaction between two entities from the same corporate group.       ",
              _type: "Process",
              GUID: "53da3f54-e8c9-11e4-0a66-001bc640cd9b",
              Advisory_Notes:
                "Typically there are special calculation rules to determine the profit margin in intercompany transactions.",
              Type: "Business Process",
              Scope:
                "The intercompany invoice, with which the supplier entity bills the selling or receiving entity, is created. In the case of a direct delivery to a customer, this happens automatically at the same time when the customer invoice is created. The required accounting entries are created using information from this invoice.",
              Outcome: "Intercompany invoice is created and issued. ",
              name: "Create intercompany invoice",
              _id: 1229,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "The creation of a customer invoice provides documentation of the financial obligation related to a customer order. The customer invoice is created from a sales order.",
              _type: "Process",
              GUID: "75ff0dfc-e8bd-11e4-0a66-001bc640cd9b",
              Advisory_Notes:
                "Circumstances such as required documents for customs clearance or advance payments might have an impact on the timing when an invoice needs to be created during the sales process.",
              Type: "Business Process",
              Scope:
                "After the customer order has been fulfilled, the invoice is created. It provides the customer with information required to settle the payment related to their order.",
              Outcome: "Invoice is created and sent to the customer.",
              name: "Create customer invoice",
              _id: 1363,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "Once the failed IDOC invoices are identified, they must be compared with a PDF version of the invoice.",
              _type: "Process",
              GUID: "fccd2e76-9569-11e8-2ddf-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "As a second quality check the accounts receivable clerk will compare the failed IDOCS against the automatically generated pdf file which was created at invoice creation. ",
              Outcome:
                "Comparison of incoming IDOC with PDF has been executed.",
              name: "Compare incoming IDOC's with PDF's",
              _id: 579,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "Once sales has aligned with Accounts Receivable, the release takes place where accounts receivable collects the costs of this warranty claim against the applicable cost center responsible for dealing with quality claims.",
              _type: "Process",
              GUID: "bb4dabe1-5dba-11e8-2ddf-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "The accounts receivable department approves the refund to be given to the customer in the form of a credit note or a reversal of the initial payment. The initial customer order should be connected as a reference to the credit note. ",
              Outcome: "Credit note is released",
              name: "Release credit note to sales",
              _id: 578,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "When transactions occur between two related legal entities in an intercompany organization or between two groups in the same legal entity, the resulting balances from these transactions must be eliminated or appropriately adjusted during the preparation of the organizations consolidated financial statements. In such cases only the open balances are settled with actual payments. It must be ensured that open items in both legal entities are closed in the same way.      ",
              _type: "Process",
              GUID: "a99fac28-7e52-11e5-0a66-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "Any open liabilities arising from intercompany transactions between two related legal entities are cleared and resulting open balances balances reconciled and settled by making actual payments.",
              Outcome:
                "Intercompany balances are cleared and recorded in accounting.",
              name: "Clear intercompany balance",
              _id: 574,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "Required re-process the generation and transfer of intercompany invoices via EDI",
              _type: "Process",
              GUID: "fccd2e7c-9569-11e8-2ddf-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "After adjustments have been made a corrected IDOC is generated with an accompanying PDF file.",
              Outcome: "IC invoice transferred EDI.",
              name: "Re-process IDOCS",
              _id: 577,
              Business_Rules: "",
              Prerequisite: "",
            },
          ],
        },
        {
          BPMN_GUID: "65d2b511-13ef-11e9-2ddf-001bc640cd9b",
          Type: "Process Group",
          Description:
            "Human Resources covers all the process from the hiring and onboarding of employees through to their compensation and retirement.",
          _type: "Group",
          name: "Human Resources",
          GUID: "65d2b511-13ef-11e9-2ddf-001bc640cd9b",
          _id: 2194,
          process_of: [
            {
              BPMN_GUID: "NULL",
              Description:
                "This process ensures that permission is given by the works council for hiring an employee. ",
              _type: "Process",
              GUID: "b8af5678-f569-11e8-2ddf-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "During the hiring process the works council must be informed, consulted or approve certain steps as provided for by law.",
              Outcome: "Works council approves or rejects new employee hire.",
              name: "Conduct works council approval",
              _id: 130,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "The process for detailing an employee value proposition to fit specific target groups.",
              _type: "Process",
              GUID: "c4b8d26f-f56c-11e8-2ddf-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "Localize EVP in order to improve candidate experience.Detail the EVP taking into account factors specific to  talent segments and markets where the organization operates.",
              Outcome: "Localised employee value proposition is derived.",
              name: "Localize employee value proposition and core messages",
              _id: 551,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description: "",
              _type: "Process",
              GUID: "7d720703-f56f-11e8-2ddf-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope: "",
              Outcome: "",
              name: "Deliver HR marketing initiatives",
              _id: 553,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "Approval process for all hiring requests created by HR.",
              _type: "Process",
              GUID: "c4b8d258-f56c-11e8-2ddf-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "Forward job request to management. Review by management, confirm completeness and ensure applicable requirements are met.",
              Outcome: "Job description reviewed and approved by management.",
              name: "Create and approve hiring request",
              _id: 129,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "The definition of the job itself, often a formal job description rendered on paper, an important aspect of which is determination of the compensation to be paid.",
              _type: "Process",
              GUID: "639863b7-f56a-11e8-2ddf-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "Create proper job description format including title, job summary, task and responsibilities, compensation. ",
              Outcome: "Job description ready for review/approval.",
              name: "Create formal job description",
              _id: 131,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "Candidates must undergo interviews to determine if the candidate fits the profile which is intended for the job as per job description.",
              _type: "Process",
              GUID: "53fa79c0-f56b-11e8-2ddf-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "Prepare questions,assess potential, ask for solutions and make candidates comfortable.",
              Outcome: "Job offer for fitting candidate.",
              name: "Conduct job interviews",
              _id: 136,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "This process enables the  organisation to assess its branding needs in order to derive strategies that improve its reputation internally and externally. ",
              _type: "Process",
              GUID: "c4b8d26b-f56c-11e8-2ddf-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "Conduct critical analysis of the organizations strengths and weaknesses. Evaluate target groups for employer branding initiatives. ",
              Outcome: "Employer branding needs analysis complete.",
              name: "Analyse internal/external employer branding",
              _id: 554,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "Candidates are shortlisted based on predetermined criteria and a profile which must fit the job description.",
              _type: "Process",
              GUID: "639863c6-f56a-11e8-2ddf-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "Screen resumes based on minimum qualifications, screen resumes based on preferred qualifications and create shortlist.",
              Outcome: "Shortlist of candidates ready for interview.",
              name: "Screen and select candidates",
              _id: 132,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "The process for detailing an employee value proposition that will be used for branding strategy. ",
              _type: "Process",
              GUID: "c4b8d26d-f56c-11e8-2ddf-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "Evaluate existing perception of the organization, and determine the key selling points. Derive EVP and test it with a group of existing employees. Then test EVP on a sample group of externals. Make adjustments where required and finalise the EVP. ",
              Outcome: "Employee value proposition is derived.",
              name: "Develop employee value proposition (EVP)",
              _id: 549,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "Based on the branding needs and employee value proposition, a branding strategy is developed.",
              _type: "Process",
              GUID: "7d720701-f56f-11e8-2ddf-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "Develop the organizations brand mission, brand vision, and brand story, taking into account the all target groups.",
              Outcome:
                "Branding strategy is detailed and specifing of marketing activities can begin.",
              name: "Develop branding strategy",
              _id: 550,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "This process descries the search, identifying and contact of potential hires.",
              _type: "Process",
              GUID: "b8af5675-f569-11e8-2ddf-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "Collect candidate information, pre-screen candidates, contact and inform candidates about open positions.",
              Outcome:
                "Successful pre-screening and relationship building with candidates.",
              name: "Source candidates",
              _id: 134,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description: "",
              _type: "Process",
              GUID: "c4b8d271-f56c-11e8-2ddf-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope: "",
              Outcome: "",
              name: "Develop employer branding materials",
              _id: 552,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "Once the candidate has been selected, a job offer is made with further negotiation if necessary.",
              _type: "Process",
              GUID: "53fa79c2-f56b-11e8-2ddf-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "The job offer is made with the necessary salary and fringe benefits. Discussions are conducted with the potential new hire to agree on salary and benefits. ",
              Outcome: "The job is offered and salary negotiations complete.",
              name: "Offer job and negotiate salary",
              _id: 133,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "Once the prospective employee accepts the position, he or she has to undergo an orientation process.",
              _type: "Process",
              GUID: "53fa79c4-f56b-11e8-2ddf-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope: "Copy signed contract and store. ",
              Outcome: "Signed contract received from new hire.",
              name: "Receive signed contract and initiate onboarding",
              _id: 137,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "A process of recruitment where the preferred method and medium of recruitments and selection is determined.",
              _type: "Process",
              GUID: "639863b9-f56a-11e8-2ddf-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "Analyze HR strategy, collect data, analyze data, setup recruitment process.",
              Outcome: "Development of suitable HR Strategy.",
              name: "Define and determine recruitment process",
              _id: 135,
              Business_Rules: "",
              Prerequisite: "",
            },
          ],
        },
        {
          BPMN_GUID: "fa0b0331-8dde-11e5-0a66-001bc640cd9b",
          Type: "Process Group",
          Description:
            "This process group contains business process to plan, budget, and monitor the costs and revenues of a project. Project accounting allows to calculate the level of costs and the expected revenues when planning a project. Once the costs have been approved, it forms the basis for allocating the budget.",
          _type: "Group",
          name: "Project Accounting Activities",
          GUID: "fa0b0331-8dde-11e5-0a66-001bc640cd9b",
          _id: 2193,
          process_of: [
            {
              BPMN_GUID: "NULL",
              Description:
                "With the commercial completion of the project, no more costs can be allocated to the project. This marks the completion of the project from an accounting/ controlling perspective.",
              _type: "Process",
              GUID: "54e6bc6c-e7fc-11e4-0a66-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "After all the project activities have been completed, actual costs settled and commitments removed, the project is completed commercially and the project portfolio item is closed. Completion of a project will usually be linked to a quality control gate.",
              Outcome:
                "All actual costs are settled to the project and commitments removed.",
              name: "Complete project commercially",
              _id: 1259,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "The settlement rule is set up to post to costs. If the proofed requirement to activate the expenditure exists, it is possible to settle to assets under construction.",
              _type: "Process",
              GUID: "420f5791-6d90-11e8-2ddf-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "Establish the rules for settling collected costs and revenues on a project to one or more receivers.  Project revenues and costs are allocated according to settlement rules as part of period-end processing.",
              Outcome:
                "All rules for settling of project revenues and costs are approved.",
              name: "Define and approve settlement rules for project",
              _id: 537,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "Perform project period end contains the process steps to be performed for the settlement of projects (WBS-elements) at period-end.",
              _type: "Process",
              GUID: "26c56e14-7ca1-11e5-0a66-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "Costs are allocated to the project/WBS-element and Projects/WBS-element are settled in controlling.",
              Outcome: "Project expenses posted.",
              name: "Perform project period end closing",
              _id: 536,
              Business_Rules: "",
              Prerequisite:
                "In order ensure timely and accurate closing for projects at period end the following preconditions business must fulfilled:   -  Purchase order invoices have to be posted.   -  Blocked purchase order invoices have to be released.   -  All goods shipped in previous periods have been posted.   -  All goods movements have been posted.   - All confirmations belonging to service order have been posted.",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "Process to ensure that correct records of projects closure are reflected in management reports for a given period.",
              _type: "Process",
              GUID: "68f6e4ff-9732-11e8-2ddf-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "Reflects the settlement and subsequent allocation and distribution of costs, variances to various controlling objects and WBS controlling elements.",
              Outcome:
                "All projects for the given accounting period correctly closed and costs allocated.",
              name: "Conduct period end closing for projects",
              _id: 245,
              Business_Rules: "",
              Prerequisite: "",
            },
          ],
        },
        {
          BPMN_GUID: "e01fe171-8dde-11e5-0a66-001bc640cd9b",
          Type: "Process Group",
          Description:
            "Cost Accounting is used for controlling the costs incurred by the organization. All costs are assigned according to their source, allowing the analysis of the overhead costs according to where they were incurred within the organization.",
          _type: "Group",
          name: "Cost Accounting Activities",
          GUID: "e01fe171-8dde-11e5-0a66-001bc640cd9b",
          _id: 2192,
          process_of: [
            {
              BPMN_GUID: "NULL",
              Description:
                "Easy cost planning enables the planning of costs at WBS level based on quantities and characteristics. It is used to create quantity structures for the cost calculation, starting from the project structure.   ",
              _type: "Process",
              GUID: "135cff11-e998-11e4-0a66-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "Easy cost planning is performed to create a first rough calculation of the project cost. ",
              Outcome: "Initial estimate of project cost is available",
              name: "Perform easy cost planning",
              _id: 204,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "Product cost planning affords the planning of costs for materials. It allows the setting of prices for materials.",
              _type: "Process",
              GUID: "b19f8499-7c99-11e5-0a66-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "The process describes all steps to be considered for the product cost planning, starting from overhead production cost planning to the calculation of the standard price of a material.",
              Outcome:
                "Product cost planning is complete. All plan figures are derived.",
              name: "Conduct product cost planning",
              _id: 502,
              Business_Rules:
                "All materials need to have a released standard price before any movements or postings are created, usually at the 1st working day at every new fiscal period.",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "In this process the shipment costs will be captured based on incoming carrier-provided information and subject to proof of delivery. G/L account determination takes place during the cost determination and transfer to FICO is triggered by a transfer procedure with Service PO and service entry sheet creation for each creditor which will be included in the shipment cost document on item level.     ",
              _type: "Process",
              GUID: "3cd87de3-e831-11e4-0a66-001bc640cd9b",
              Advisory_Notes:
                "This scenario does not apply when Transportation Management is used.",
              Type: "Business Process",
              Scope:
                "Creation of shipment cost document, cost calculation and allocation.",
              Outcome: "Shipment cost document has been updated.",
              name: "Settle freight cost",
              _id: 72,
              Business_Rules: "Cost allocation rules must be maintained.",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "The costs that arise from processing an order (for example, costs for material, personnel, or external services) must be allocated to the correct cost object. ",
              _type: "Process",
              GUID: "18d52036-51b1-11e5-0a66-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "The costs are initially collected on the order, and when the order is technically completed, the costs are transferred to the settlement receiver specified in the settlement rule. ",
              Outcome:
                "All costs related to the maintenance order are settled.",
              name: "Settle maintenance order",
              _id: 1290,
              Business_Rules: "",
              Prerequisite:
                "An order can be settled if:    It is released    It has the status settlement rule created    Costs collected for it have not yet been settled    A business completion has not yet been performed for it",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "WIP refers to the raw materials, labor, and overhead costs incurred for products that are at various stages of the production process. WIP is a component of the inventory asset account on the balance sheet. These costs are subsequently transferred to the finished goods account and eventually to cost of sales.   ",
              _type: "Process",
              GUID: "7d3f15f0-a206-11e8-2ddf-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "When production orders have not been completed in the accounting time period when it was generated, there needs to be a calculation to accurately reflect the companys balance sheet position at that point in time. ",
              Outcome:
                "WIP calculations are reflected at the line item level of the balance sheet. ",
              name: "Calculate work in progress (WIP)",
              _id: 512,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "In addition to the activity rate calculations, cost centers must also be budgeted.",
              _type: "Process",
              GUID: "c7c0ffd9-9565-11e8-2ddf-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "This process involves the budgeting for estimated labour hours, machine hours or any other relevant activity type that will be a required resource for the achievement of periodic goals and targets (production targets etc. )",
              Outcome:
                "Capacity planning figures available for each production cost center.",
              name: "Perform capacity planning on the production cost center",
              _id: 507,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "This process ensures that controlling relevant figures are ready for management reporting in a given accounting period.",
              _type: "Process",
              GUID: "2b3cd26c-9734-11e8-2ddf-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "Performance results analysis, calculation of work in progress (WIP), settlement of variances and subsequent execution to COPA.",
              Outcome: "Closing figures are ready for reporting. ",
              name: "Close profitability analysis",
              _id: 255,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "In order to provide comprehensive P&L planning figures, future planning figures for overhead costs must be derived.",
              _type: "Process",
              GUID: "b19f847e-7c99-11e5-0a66-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "In this process, future planning figures are calculated for indirect cost objects (e.g. cost centers, internal orders, projects/WBS elements).",
              Outcome:
                "Future planning figures for indirect cost objects are available.",
              name: "Conduct planning within overhead management",
              _id: 509,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "Cost center planning forms part of the overall business planning process. It enables to perform detailed planning below the cost element level.",
              _type: "Process",
              GUID: "b4b3673c-9562-11e8-2ddf-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "Cost center planning involves entering planned figures for costs, activities, prices or statistical key figures for a particular cost center and a particular planning period.",
              Outcome:
                "Cost center planning figure is available for planning period.",
              name: "Perform cost center planning",
              _id: 501,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "During production, product cost collectors and manufacturing orders are debited with actual costs. Each time goods are received into inventory, ERP systems evaluate the goods receipt and credit it to the defined product cost collector or production order accordingly.",
              _type: "Process",
              GUID: "b4b36755-9562-11e8-2ddf-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "The actual costs posted to an order can be higher or lower than the value of the initial production order which was credited when the goods receipt was posted. This must be settled by the difference between the debit and credit of the order which is subsequently transferred to Financial Accounting.",
              Outcome: "All differences are settled.",
              name: "Settle differences via product cost collectors",
              _id: 506,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "This process involves the periodic costing of a sizeable number of products/ materials (for example all materials of one plant). ",
              _type: "Process",
              GUID: "b4b3674e-9562-11e8-2ddf-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "With help of the costing run, the high-performing costing of a great number of materials per collective processing is possible (often at plant level).",
              Outcome: "Product costing run is complete.",
              name: "Execute costing run",
              _id: 508,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "To ensure an accurate period end closing, all production related activities must be recorded in the period within which they occur.",
              _type: "Process",
              GUID: "2b3cd26e-9734-11e8-2ddf-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "Covers the production related controlling activities which result in the technical completion and commercial settlement of all production orders and the subsequent calculation of work in progress (WIP) relevant for the accounting period. ",
              Outcome: "Closing figures are ready for reporting.",
              name: "Conduct closing in production",
              _id: 251,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "The WIP calculation function evaluates the unfinished products (work in process) which have not been completed in an accounting period. ",
              _type: "Process",
              GUID: "c7c0ffb9-9565-11e8-2ddf-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "In the Product Cost by Order component, the work in process is evaluated at actual cost. Work in progress is the difference between the debit and credit of a production or process order that has not been fully completed.    WIP can be calculated at actual costs for the following objects:     ***Production orders   ***Process orders",
              Outcome: "WIP values from production/ process orders are posted.",
              name: "Post work in progress (WIP) values",
              _id: 503,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "This represents an allocation, distribution and application of settlement rules for production or project related costs which have been incurred for a given period to the correct production cost center.",
              _type: "Process",
              GUID: "c7c0ffd7-9565-11e8-2ddf-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "Once the budgetary planning has taken place, this must subsequently be allocated to the various cost centers within the company code or group organisational structure. ",
              Outcome:
                "Cost center planning figures allocation to cost centers complete and correct.",
              name: "Distribute and allocate costs to production related  cost centers",
              _id: 504,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "The production order is commercially closed when all the costs have been booked completely.",
              _type: "Process",
              GUID: "c7c0ffc4-9565-11e8-2ddf-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "The production order is set to status closed. With this - no more costs can be posted to the order, that is, confirmations and goods movements are no longer permitted for the order and the order can no longer be changed.",
              Outcome: "Production order is closed commercially.",
              name: "Close production order commercially",
              _id: 510,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "Variance calculation provides the difference between Actual Costs and Target Costs posted on order.",
              _type: "Process",
              GUID: "b4b36753-9562-11e8-2ddf-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "This process covers the analysis of price variances stemming from the manufacturing process which can include:   ***Total Variance   ***Variance   ***Planning Variance  ",
              Outcome:
                "Product price variances determined and follow up actions initiated.",
              name: "Conduct variance analysis",
              _id: 505,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "Activity Rates define or rather calculate the cost of performing an activity type. ",
              _type: "Process",
              GUID: "b4b36743-9562-11e8-2ddf-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "Perform activity rate calculation in order to calculate plan activity rates for activities in each production cost center.",
              Outcome:
                "Plan activity rates are available for each activity in each cost center in a plant.",
              name: "Perform activity rate calculation",
              _id: 513,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "Once the difference between planned and actual production/ process orders have been calculated, it is required that there is an accompanying settling of the differences which according to the allocation rules defined must be settled through a cost collector, controlling object or a cost center. ",
              _type: "Process",
              GUID: "c7c0ffc2-9565-11e8-2ddf-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "Once the difference between planned and actual production/ process orders have been calculated, it is required that there is an accompanying settling of the differences which according to the allocation rules defined must be settled through a cost collector, controlling object or a cost center.",
              Outcome: "All cost differences from production are settled.",
              name: "Perform and settle variances",
              _id: 511,
              Business_Rules: "",
              Prerequisite: "",
            },
          ],
        },
        {
          BPMN_GUID: "75182181-8dde-11e5-0a66-001bc640cd9b",
          Type: "Process Group",
          Description:
            "Tax Accounting comprises processes for managing various types of tax according to the legal requirements of a country or region. The following areas are covered: ***Tax calculation ***Tax posting ***Tax Adjustments ***Tax reporting",
          _type: "Group",
          name: "Tax Accounting Activities",
          GUID: "75182181-8dde-11e5-0a66-001bc640cd9b",
          _id: 2191,
          process_of: [
            {
              BPMN_GUID: "NULL",
              Description:
                "This process is used for managing information relevant to local tax accounting standards and regulations.",
              _type: "Process",
              GUID: "ad40fc94-6b16-11e8-2ddf-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "Local GAAP ledger for tax accounting includes fields for income, deductions, depreciations, payments to tax agencies and other data that are set forth by local tax authorities.",
              Outcome: "Local tax ledger maintained and correct.",
              name: "Manage local GAAP ledger",
              _id: 540,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "This process is used for managing information relevant to taxes, for ease of record keeping throughout the year.",
              _type: "Process",
              GUID: "ad40fc92-6b16-11e8-2ddf-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "Tax ledger can include fields for income, deductions, depreciations, payments to tax agencies and other data that may be important for tax accounting.",
              Outcome: "Tax ledger maintained and correct.",
              name: "Manage tax ledger",
              _id: 541,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "The taxes related to a sale must be calculated in accordance with regulatory requirements.",
              _type: "Process",
              GUID: "ad40fc90-6b16-11e8-2ddf-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "Condition method technique is used to calculate taxes in the system.  Predefined calculation procedures in the system and tax codes form the basis for calculating the amount of tax. ",
              Outcome: "Tax amount is calculated.",
              name: "Determine VAT",
              _id: 542,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "This is a required controlling step to ensure the correct VAT tax code has been applied to the sales order.",
              _type: "Process",
              GUID: "5f7265f5-9bc7-11e8-2ddf-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "Appropriate VAT conditions are assigned based on the final destination of where the product must be shipped. This is particularly important for cases where there are Third party drop-shipment business cases in scope. ",
              Outcome: "Tax on sales order is correct.",
              name: "Assign and review appropriate VAT conditions",
              _id: 539,
              Business_Rules: "",
              Prerequisite: "",
            },
          ],
        },
        {
          BPMN_GUID: "0934cea1-8dde-11e5-0a66-001bc640cd9b",
          Type: "Process Group",
          Description:
            "This process group covers processes by which General Ledger accounts are defined and planned, as well as postings made in the General Ledger. The General Ledger contains the complete record of all business transactions, and thus represents the main and current element in the rendering of accounts.",
          _type: "Group",
          name: "General Ledger Activities",
          GUID: "0934cea1-8dde-11e5-0a66-001bc640cd9b",
          _id: 2190,
          process_of: [
            {
              BPMN_GUID: "NULL",
              Description:
                "Maintain open item managed accounts process covers the continuous clearing of open item managed accounts by clearing jobs as well as the follow up of items, which could not be cleared automatically.",
              _type: "Process",
              GUID: "9b6dd8c9-63e8-11e8-2ddf-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "Automatic clearing job is started for open item/managed accounts. In case items could not be cleared automatically, the accounts must be cleared manually.  Conditions for automatic postings: ***balance in tolerance ***trading partner correct ***assignment correct  Zero balance accounts and accounts with balance within tolerance will be cleared automatically.",
              Outcome: "Open item managed accounts cleared.",
              name: "Maintain open item managed accounts",
              _id: 558,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "Period end closing results are published, making them available for further analysis and consideration.",
              _type: "Process",
              GUID: "26c56e2c-7ca1-11e5-0a66-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "After period end closing is completed reporting relevant figures (e.g. P&L, balance sheet) are submitted, validated and published in the reporting system.",
              Outcome: "Reporting figures are released.",
              name: "Report closing figures",
              _id: 253,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "To ensure an accurate period end closing, all sales related transactions must be recorded in the period within which they occur.",
              _type: "Process",
              GUID: "68f6e4e5-9732-11e8-2ddf-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "Complete and post all open sales documents so that they are reflected in the correct accounting period. ",
              Outcome: "Sales documents posted in correct accounting.",
              name: "Conduct period end closing sales/ distribution",
              _id: 252,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "To ensure an accurate period end closing, all purchasing related transactions must be recorded in the period within which they occur.",
              _type: "Process",
              GUID: "68f6e4df-9732-11e8-2ddf-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "Complete and post all open purchasing documents so that they are reflected in the correct accounting period. ",
              Outcome:
                "Purchasing documents posted in correct accounting period.",
              name: "Conduct period end closing purchasing",
              _id: 250,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "Closing of the general ledger is performed after closing and reconciliation of all subledgers.",
              _type: "Process",
              GUID: "68f6e4f9-9732-11e8-2ddf-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "Close general ledger involves reconciling the debit and credit balances of the posted documents with the debit and credit balances of the transactions figures in A/P, A/R and the general ledger, in order to check the consistency of the data. This is followed by execution of various closing activities including, foreign currency valuation of assets, accruals and deferrals, and clearing of accounts.",
              Outcome: "Accounts are closed for the closing period.",
              name: "Close general ledger",
              _id: 247,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "This process is used for managing currency rate information.",
              _type: "Process",
              GUID: "9b6dd8cb-63e8-11e8-2ddf-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "Current currency exchange rates are updated in the system on a daily/weekly/monthly basis Preferably it is established using interfaces with a bank.",
              Outcome:
                "Current exchange rates are available and maybe used for P&L and further analysis to evaluate currency effects.",
              name: "Import current exchange rates",
              _id: 559,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "Conduct balance sheet planning describes the generation of balance sheet planning figures according to financial reporting requirements.",
              _type: "Process",
              GUID: "b19f8481-7c99-11e5-0a66-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "Planning guidelines are reviewed, planned depreciation calculated, and investment plan is derived. Once this has been done, the plan balance sheet data is ready for release.",
              Outcome:
                "Balance sheet planning figures are available and can be released.",
              name: "Conduct profit and loss and balance sheet planning",
              _id: 560,
              Business_Rules:
                "Planning guideline for the corresponding planning period will determine the rules for gathering, calculating and entering the plan information into the system.",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "When planning figures have been derived, they must be made available to concerned parties within the organization. ",
              _type: "Process",
              GUID: "b19f8484-7c99-11e5-0a66-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "Release planning figures describes the submission, validation and publishing of all planning relevant reports.",
              Outcome: "Plan data is released.",
              name: "Release planning figures",
              _id: 562,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "Reconcile balance sheet process describes the reconciliation  of GL accounts and the clarification of provisions, accruals and other accounts in order to establish a sound trading, profit and loss account and balance sheet.",
              _type: "Process",
              GUID: "9b6dd8cd-63e8-11e8-2ddf-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "In this process, the GL account report is created, account balances are checked and discrepancies are posted to the balance sheet or P&L.",
              Outcome: "Balance sheet is reconciled.",
              name: "Reconcile balance sheet",
              _id: 561,
              Business_Rules: "",
              Prerequisite: "",
            },
          ],
        },
        {
          BPMN_GUID: "721bb9a1-87b8-11e5-0a66-001bc640cd9b",
          Type: "Process Group",
          Description:
            "Treasury comprises business processes for efficient liquidity, investment portfolio and financial risk management. This facilitates sound decision making on future investments and borrowings, considering the current liquidity and risk situation of the organization, as well as conditions on financial markets.",
          _type: "Group",
          name: "Treasury",
          GUID: "721bb9a1-87b8-11e5-0a66-001bc640cd9b",
          _id: 2189,
          process_of: [
            {
              BPMN_GUID: "NULL",
              Description:
                "Currency risk management ensures effective analysis and hedging of foreign currency risks.",
              _type: "Process",
              GUID: "741518b2-87b8-11e5-0a66-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "Based on data from local payment activities, financial transactions are compared with operative payments. Remaining open items for each currency is calculated as currency exposure, forming the basis for further hedging activities.",
              Outcome:
                "Currency risk is determined, forming the basis for hedging activities.",
              name: "Manage currency risk",
              _id: 529,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "Financial instruments are contracts that give rise to a financial asset of one entity and a financial liability or equity instrument of another entity. The appropriate financial instrument must be selected, representing a legally binding agreement for fulfillment of an obligation arising from a transaction.",
              _type: "Process",
              GUID: "741518ae-87b8-11e5-0a66-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "Financial instruments are selected according to various criteria, taking underlying transactions (existing and future) into account.    Financial instruments used for Treasury Risk Management can include:    * Forward exchange transactions  * Currency options  * Bonds  * Loans  * Interest rate guarantees  * Interest rate swaps  * Futures  * Bond options  * Options on futures",
              Outcome: "Financial instruments are selected.",
              name: "Select financial instruments",
              _id: 532,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "Loans management ensures adequate documentation and coordination of loan and borrowing transactions at all stages. This may include making an offer to a prospective customer, or requesting financing from a lender, and drawing up the conditions of the actual contract through to posting in Financial Accounting. Loans management also takes into account lending/borrowing between entities of the same group to level uneven cash demands.",
              _type: "Process",
              GUID: "741518a8-87b8-11e5-0a66-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "The loan or borrowing process is executed based on standardized procedures, with timely accessing and processing of relevant information. Integration of loans management with cash management and market risk management, allows transparency on the effect of respective loans on liquidity, and the exposure to currency risks and interest rate risk.",
              Outcome:
                "The effect of loans or borrowings on liquidity is determined and decision making on loans is enabled.",
              name: "Execute loans management",
              _id: 531,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "This process provides support for managing financial transactions and positions from the trading stage through to the transfer to Financial Accounting. Treasury management affords flexible reporting and evaluation structures for analyzing financial transactions, positions and portfolios of financial assets and fixed assets.",
              _type: "Process",
              GUID: "741518a6-87b8-11e5-0a66-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "Financial transactions are entered in treasury management. With analyses of the current liquidity and risk situation, decisions are made on future investments and borrowings.",
              Outcome:
                "Records of financial transactions are available and analyzed for decision making on future investments and borrowings.",
              name: "Perform treasury management",
              _id: 527,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "By knowing the risk involved in acquiring or maintaining ownership of a fixed asset, investment strategy can be optimized.",
              _type: "Process",
              GUID: "741518b6-87b8-11e5-0a66-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "This process involves measuring risk stemming from a loss in the value of a fixed asset. By evaluating the probability and extent of the loss in asset value, the decision can be made on whether or not the asset should be kept as part of the organization`s portfolio. Reasons for a loss in asset value may include market conditions, political and environmental developments, accessibility and legal regulations, technological trends.",
              Outcome:
                "Value at risk of a fixed asset is assessed and required actions taken to mitigate the impact.",
              name: "Manage asset value risk",
              _id: 533,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "Cash Management ensures that sufficient liquidity is available for making payments. Moreover, monitoring of payment flows affords an overview of cash positions and short-term liquidity on a group or/and individual legal entity level.",
              _type: "Process",
              GUID: "741518a2-87b8-11e5-0a66-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "Payment inflows and outflows of various accounts are recorded and monitored. Source of payments can include, Manual/electronic bank statement, Manual/electronic check deposit, and Bill of exchange.  The core records of payment inflows and outflows are based on the concentration strategy defined in advance (e.g. cash pooling: combining credit and debit positions of various accounts into one account). The result of the cash concentration is output in form of payment orders. The payments due form the basis for determining the current cash position.",
              Outcome:
                "Information is available regarding the current cash position of the organization.",
              name: "Conduct cash management",
              _id: 530,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "The manage cash budget process has the goal of monitoring and securing liquidity in the medium to long term. This delivers the actual and target figures for reviewing plans, analyzing deviations and form the basis for future planning.",
              _type: "Process",
              GUID: "741518ac-87b8-11e5-0a66-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "Planning of expected incoming and outgoing flows for exact periods /typically weeks or months) is conducted. The planned items indicate the source of incoming payments and purpose of outgoing payments, which results in the cash required to fund future business operations. The relevant data is derived from the business plan and postings from previous periods.",
              Outcome:
                "The funds required for payment at the beginning and remaining expected cash at the end of a period are determined.",
              name: "Manage cash budget",
              _id: 534,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "Credit risk management affords the measuring, analysis, and controls of default risks. Default risk implies the potential loss arising from a financial transaction when the business partner fails to fulfill their contractual obligations.",
              _type: "Process",
              GUID: "741518b4-87b8-11e5-0a66-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "Attributable amounts for each single transaction entered is calculated, showing the risk for the respective transaction. Credit and settlement risks are taken into account when quantifying default risk. Default risk is calculated based on counterparties (individual organizations andenterprises) and issuers. The calculated risks are assigned to affected portfolio segments. Risks can be controlled by setting up and monitoring limits.",
              Outcome: "Controls for default risk are implemented.",
              name: "Manage credit risk",
              _id: 528,
              Business_Rules: "",
              Prerequisite: "",
            },
          ],
        },
        {
          BPMN_GUID: "2e223cc1-8dde-11e5-0a66-001bc640cd9b",
          Type: "Process Group",
          Description:
            "This process group involves daily accounting, checking and reporting processes within Accounts Payable. It also describes activities performed as follow up from last closing and/or in preparation of next closing.",
          _type: "Group",
          name: "Accounts Payable Activities",
          GUID: "2e223cc1-8dde-11e5-0a66-001bc640cd9b",
          _id: 2188,
          process_of: [
            {
              BPMN_GUID: "NULL",
              Description:
                "Conduct clearing AP refers to the offsetting of outgoing vendor payments against outstanding amounts owed to creditors.  ",
              _type: "Process",
              GUID: "981573d8-9499-11e8-2ddf-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "Conduct clearing AP process results in a decrease in the total accounts payables which will be reported in the balance as at that given point in time.",
              Outcome: "Vendor account is cleared.",
              name: "Conduct clearing AP",
              _id: 521,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "With self invoicing an agreement is reached with a supplier, that he does not create invoices for delivered goods/provided services. Instead the customer himself creates an invoice for the received goods and provides it to vendor.   ",
              _type: "Process",
              GUID: "bdcb60c8-7f03-11e5-0a66-001bc640cd9b",
              Advisory_Notes:
                "Self-billing agreements usually last for 12 months. At the end of this period it is necessary to review the agreement to ensure existence of a proof that the agreement is still in force. It is important not to self-bill a supplier without their written agreement to do so. It is necessary to enter into a separate agreement with each vendor.",
              Type: "Business Process",
              Scope:
                "After receiving ordered goods, a self invoice is automatically created in customers system based on data in the purchase orders and goods receipts. The self invoice is posted  and sent to vendor. This allows closer control over correctness of the invoices and speeds the recognition of the liability.",
              Outcome:
                "Self invoice is created, posted and issued, liability for received goods is recorded.",
              name: "Manage self invoice",
              _id: 1216,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "This process ensures that no quantity differences outside of the defined tolerance have occured which is often the prerequisite to ensure that all risks related to differences in physical inventory and transit stock have been identified and mitigated. ",
              _type: "Process",
              GUID: "80843c0f-9567-11e8-2ddf-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "Inter-company invoices transferred via EDI are verified for consistency against the goods received.",
              Outcome:
                "The goods receipt quantities and the amounts billed correspond or fall within an agreed tolerance limit. ",
              name: "Execute IC invoice verification",
              _id: 520,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description: "",
              _type: "Process",
              GUID: "2ec9bf75-a205-11e8-2ddf-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope: "",
              Outcome: "",
              name: "Perform account maintenance for AP",
              _id: 525,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "The credit note may be issued based on the defined terms of payment which will be defined in the customer business partner records. ",
              _type: "Process",
              GUID: "bb4d0f90-5dba-11e8-2ddf-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "The customer receives the credit note and provides confirmation of receipt either automatically via electronic fund transfers or manually via signed off receipt. ",
              Outcome:
                "The customer received the credit note and the dispute case is closed. ",
              name: "Issue credit note to customer",
              _id: 518,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "All invoices which have been flagged and subsequently blocked must be released before they can be considered for the accounting period.",
              _type: "Process",
              GUID: "68f6e4eb-9732-11e8-2ddf-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "Review and release blocked incoming invoices so that they can be applied to the appropriate accounting period in accordance with the matching concept. ",
              Outcome: "Invoices that had been blocked are released.",
              name: "Release blocked incoming invoices",
              _id: 244,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "After verification the invoiced amount must be posted accordingly.",
              _type: "Process",
              GUID: "981573d6-9499-11e8-2ddf-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "Confirmation is given that the invoiced amounts correspond with the inbound delivery and has a corresponding goods receipt posting which also matches with the invoiced quantities and amounts.",
              Outcome: "Posting of invoice complete and correct.",
              name: "Post verified invoice",
              _id: 522,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "This process covers the scanning of a Purchase Order based invoice or an Expense Invoice (no PO reference).",
              _type: "Process",
              GUID: "981573d2-9499-11e8-2ddf-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "After the invoice is received, it is scanned and the data is captured. The data is then stored in a database for digital use and then transfered to for verification.",
              Outcome:
                "Received invoice information is transferred for verification.",
              name: "Scan incoming invoice with OCR software",
              _id: 1192,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "This is a check to ensure that the correct pricing conditions have been applied in cases of no transfer to the final customer.",
              _type: "Process",
              GUID: "80843c11-9567-11e8-2ddf-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "In addition to verifying correctness of purchasing pricing conditions, the accounts payable clerk ensures that no Value Added Tax has been applied to the invoiced amount. ",
              Outcome: "All IC purchase price conditions are correct. ",
              name: "Review IC purchasing pricing conditions",
              _id: 519,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "Processing of a Purchase Order based invoice from a vendor, in order to trigger the outgoing payment process.",
              _type: "Process",
              GUID: "981573d4-9499-11e8-2ddf-001bc640cd9b",
              Advisory_Notes:
                "As one of the most prominent examples of Segregation of Duty (SoD), invoice verification has to be done from a department independent from the department responsible for the purchasing. Typically, invoice verification is a department reporting to finance. Needs to be implemented.   ***In addition tolerances need to be defined in order to avoid clearing efforts for small deviations.   ***Further more rules need to be defined on how to handle invoices for partial deliveries. These rules could either effect internal procedures or external ones (e.g. allow supplier to increase quantity of next delivery to compensate for missing quantities).   ***The sequence of processing invoices compared to the goods receipt might differ from case to case. Especially for purchasing of services both sequences are common (e.g. service entry sheet). ",
              Type: "Business Process",
              Scope:
                "After the invoice has been transferred (from a scanning tool), invoice verification supports the checking for formal compliance, and whether it is consistent with the purchase order and the actually provided goods or services.",
              Outcome:
                "The invoice is posted, triggering the outgoing payment process.",
              name: "Process incoming invoice (with PO)",
              _id: 1190,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "For intercompany transactions, this process ensures that billing information is received by the delivering entity and the financial obligation related to the transaction is settled.      ",
              _type: "Process",
              GUID: "6166270a-7e56-11e5-0a66-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "After the goods have been delivered and invoice received, payment is made to the delivering entity and recording in accounting.",
              Outcome:
                "Amount in the intercompany invoice is paid and documented accordingly.",
              name: "Receive intercompany invoice",
              _id: 1232,
              Business_Rules:
                "It is necessary to define: - General payment terms and condition for intercompany invoicing, - Relations between the selling and shipping operating units in view of intercompany transaction flows.",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "This process ensures that a payment to a vendor is captured, executed, and documented accordingly.       ",
              _type: "Process",
              GUID: "fb9de08d-7f05-11e5-0a66-001bc640cd9b",
              Advisory_Notes:
                "The methods of payment must be established taking into account country specific factors.",
              Type: "Business Process",
              Scope:
                "Manage outgoing payment involves making a payment to a vendor and posting the payment to the relevant vendor account. ",
              Outcome:
                "The outgoing payment is executed and the account vendor debited.",
              name: "Manage outgoing payment",
              _id: 1218,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "Processing of an Expense Invoice from a vendor, in order to trigger the outgoing payment process. Such invoices have no reference to a Purchase Order.",
              _type: "Process",
              GUID: "be50c2f0-9556-11e8-2ddf-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "After the FI invoice has been transferred (from the scanning tool), verification involves checking for formal compliance and whether it is consistent with goods delivered or services provided.",
              Outcome: "FI invoice is verified and ready for posting.",
              name: "Process incoming invoice without reference to PO",
              _id: 524,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "Once the adjusted IDOCS are generated, the system automatically posts the contra entry open item from the selling entitys perspective.",
              _type: "Process",
              GUID: "80843c1e-9567-11e8-2ddf-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "This particularly useful for:    ***Intercompany Replenishment   ***Sales via Intercompany",
              Outcome:
                "There is the automatic change in the vendor line items or the amounts owed on the vendors account as a result of the incoming IDOC. ",
              name: "Post accounts payable open items automatically",
              _id: 523,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "A created credit memo can either be used to partly or fully clear an existing customer invoice or forms a base for making a payment to customer.    ",
              _type: "Process",
              GUID: "aa8c61b7-7e48-11e5-0a66-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "Once credit memo is created and approved, it must be checked if there are any open customer invoices, which can be cleared with it. If no open customer invoices exist, a direct payment to customer must be made to close the credit memo. Latter option is rarely used.",
              Outcome:
                "Credit memo is cleared and payment to customer made if required.",
              name: "Manage credit memo payment",
              _id: 1374,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "The invoice issued by the supplier gets processed and posted.   If goods-receipt-based invoice verification has been defined for a purchase order item, the system compares the supplier invoice data with the related goods receipt data. If the deviation is outside defined tolerances, the invoice is posted, but the invoice is automatically blocked for payment. Blocked invoices can be released and therefore enable the payment process.",
              _type: "Process",
              GUID: "4dc445b3-b9d2-11e9-0cfc-001bc641ce4f",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "The supplier invoice is transferred via the Ariba network. The invoice includes the S4/HANA PO reference.  If required, a check of the invoice and release of blocked invoices can be performed. ",
              Outcome:
                "The invoice is posted, triggering the outgoing payment process.",
              name: "Process supplier invoice (Ariba)",
              _id: 515,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "This process ensures that the invoice from a vendor is captured, documented and forwarded for payment.  ",
              _type: "Process",
              GUID: "fb9de073-7f05-11e5-0a66-001bc640cd9b",
              Advisory_Notes:
                "As one of the most prominent examples of Segregation of Duty (SoD), invoice verification has to be done from a department independent from the department responsible for the purchasing. Typically, invoice verification is a department reporting to finance.",
              Type: "Business Process",
              Scope:
                "Manage incoming payment involves receiving payment from a customer and entering the information in accounting. The incoming payment is mapped to the customer invoice and customer account.",
              Outcome:
                "The invoice is posted and forwarded to the outgoing payment process.",
              name: "Manage incoming invoice",
              _id: 516,
              Business_Rules:
                "The methods of payment must be established taking into account country specific factors related to the business and customer. For instance: The bill of exchange (BoE) payment method is used insome countries only.  Typical methods of payment are: - Check - Direct debits - Credit cards - Bank transfers",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "After receiving the accounts payable open item, the person responsible for processing accounts payable must process the outgoing payment.",
              _type: "Process",
              GUID: "80843c13-9567-11e8-2ddf-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "Once there are open items to be paid the Accounts receivable department ensures that the invoice has been applied to the correct reconciliation account. ",
              Outcome:
                "A task list is generated to be utilised during invoice verification. ",
              name: "Process open items accounts payable",
              _id: 517,
              Business_Rules: "",
              Prerequisite: "",
            },
          ],
        },
        {
          BPMN_GUID: "1d0af581-13fa-11e9-2ddf-001bc640cd9b",
          Type: "Process Group",
          Description:
            "Product Costing comprises processes to plan and value the internal cost of materials and production for profitability and management accounting. The following areas are covered:  -Product Cost Planning -Cost Object Controlling -Actual Costing",
          _type: "Group",
          name: "Product Costing",
          GUID: "1d0af581-13fa-11e9-2ddf-001bc640cd9b",
          _id: 2187,
          process_of: [
            {
              BPMN_GUID: "NULL",
              Description:
                "The cost estimate with quantity structure enables you to calculate the non-order-related cost of goods manufactured and the cost of goods sold for products, based on the BOMs and routings (PP).",
              _type: "Process",
              GUID: "b4b36749-9562-11e8-2ddf-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "Quantity structure to be established on planned production orders with the relevant BOM and routing master data maintained.",
              Outcome:
                "The quantity structure is maintained and correctly connected to planned production orders.",
              name: "Establish quantity structure based on BOM and routing",
              _id: 498,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "Purpose of this process is the commercial adjustment of stock postings in order to reflect differences in physical inventory.",
              _type: "Process",
              GUID: "f86b2ab1-8e54-11e8-2ddf-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "Once the inventory differences has been adjusted by the Warehouse management, there needs to be an accompanying settlement of this difference commercially. This commercial settlement is reflected by the settlement to the inventory differences clearing account which will subsequently be written off as an expense within the Trading, Profit and Loss account to the value of the amount settled. ",
              Outcome:
                "Stock is adjusted commercially to reflect actual inventory.",
              name: "Adjust stock postings commercially",
              _id: 499,
              Business_Rules: "",
              Prerequisite: "",
            },
          ],
        },
        {
          BPMN_GUID: "581363b1-8dde-11e5-0a66-001bc640cd9b",
          Type: "Process Group",
          Description:
            "This process group covers the acquisition, capitalization, and physical inventory counting, as well as the disposal of fixed assets.",
          _type: "Group",
          name: "Asset Management Activities",
          GUID: "581363b1-8dde-11e5-0a66-001bc640cd9b",
          _id: 2186,
          process_of: [
            {
              BPMN_GUID: "NULL",
              Description:
                "During the month end closing process, costs incurred from the project phase of a capital investment project must be capitalized according to accounting rules.        ",
              _type: "Process",
              GUID: "44b6ae88-7eed-11e5-0a66-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "For project costs to be capitalized, all necessary information relevant for the posting and depreciation of an asset is provided, e.g. cost center, useful life, asset class, location / plant, etc. With this information, the settlement rule for the project is maintained.",
              Outcome:
                "Project costs are capitalized and can be incorporated in month end closing figures.",
              name: "Capitalize asset from project phase",
              _id: 565,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "This process describes activities performed in assets accounting when an asset is acquired from an external source.",
              _type: "Process",
              GUID: "d4cc2762-6966-11e8-2ddf-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope: "",
              Outcome: "",
              name: "Acquire asset",
              _id: 568,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "This process covers the scrapping of a fixed asset within asset accounting.",
              _type: "Process",
              GUID: "feff4065-7ef0-11e5-0a66-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "When an asset is scrapped, the following activities must be performed:   ***Post the net book value to expenses   ***Set date of deactivation in asset master   ***Return information to scrapping requestor",
              Outcome: "Asset scrapping posted and requestor informed.",
              name: "Scrap asset",
              _id: 571,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "This process reflects the application of the depreciation policy to all fixed assets which fall within the companys asset register.  ",
              _type: "Process",
              GUID: "d4cc2764-6966-11e8-2ddf-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "Conduct periodic asset depreciation in accordance with the depreciation policy given in the applicable financial reporting standards (e.g. IFRS).  ",
              Outcome: "Perform periodic asset depreciation is complete.",
              name: "Perform periodic asset depreciation",
              _id: 246,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "The asset is created and its value is derived from the cost objects collecting any costs for acquiring the asset or related services. Activation is a manual process in Finance by Asset Management role (locally based on laws and regulations).   Criteria for classification of assets: If the value is <2.000 treated as an expense charged against the P&L account. If the value is >2.000 it has to be activated with a depreciation schedule If it is unclear, it can be maintained in an internal order to collect costs and decide on activation later.",
              _type: "Process",
              GUID: "22f823a4-94bc-11e8-2ddf-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "A new financial asset is created in the system. The initial value of the asset is derived from the object used for collecting costs for acquiring the asset.",
              Outcome: "Financial asset created",
              name: "Activate asset",
              _id: 1246,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "This reflects the sale of an asset which has been approved by management or the cost center responsible for scrapping and/ or sale.  ",
              _type: "Process",
              GUID: "feff4098-7ef0-11e5-0a66-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "The asset is sold upon negotiation of the selling price. There will be no sales order connected to this process and a manual invoice will be required. ",
              Outcome: "Asset sale is complete.",
              name: "Sell asset",
              _id: 567,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "Depreciate asset process ensures that decrease in the value of an assets is reflected in accounting.",
              _type: "Process",
              GUID: "33c3d03a-9560-11e8-2ddf-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "Depreciation of the asset is calculated. The value of the asset is recorded to reflect the amount depreciated. The depreciation reflects the needs for internal, external and tax reporting requirements. Extraordinary depreciation can be done according to the policy in place and reconciled with the manager.",
              Outcome: "Depreciation of asset performed.",
              name: "Execute depreciation run",
              _id: 569,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "Transfer assets to inventory process covers cases where a fixed assets is transferred and managed as inventory.",
              _type: "Process",
              GUID: "d4cc2766-6966-11e8-2ddf-001bc640cd9b",
              Advisory_Notes:
                "The transfer is to be done using the asset clearing account.",
              Type: "Business Process",
              Scope: "",
              Outcome: "",
              name: "Transfer asset to inventory",
              _id: 570,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "When an asset is aquired or a CAPEX project is released, it is necessary to create the relevant asset in the system. This is used for managing and supervising assets in Financial Accounting.",
              _type: "Process",
              GUID: "44a5e5bf-7eed-11e5-0a66-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "It is possible to create an entirely new asset or add the asset to an already existing one. When creating a new asset, the correct asset class must be determined and then entered into the initial screen of the master data transaction along with the company code. When adding an asset to an already existing one, the asset main number of the existing asset must first be identified. This is followed by the creation of a new asset sub-number for the asset being added.  Once the asset number is assigned, relevant master record information is maintained along with depreciation and valuation information for each depreciation area.",
              Outcome: "Asset is created/added and saved in the system.",
              name: "Create asset",
              _id: 566,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "When an asset can no longer be used, it is necessary to define further steps to finalize its phasing out.",
              _type: "Process",
              GUID: "a92487cd-9561-11e8-2ddf-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "The maintenance manager evaluates the asset to get a view of the asset condition. In doing this, a decision is made on whether to sell, scrap or dispose the asset.",
              Outcome:
                "Decision is made on whether to sell, scrap or dispose the asset.",
              name: "Retire assets with no scrap value",
              _id: 564,
              Business_Rules: "",
              Prerequisite: "",
            },
          ],
        },
        {
          BPMN_GUID: "64259971-8dde-11e5-0a66-001bc640cd9b",
          Type: "Process Group",
          Description:
            "This area covers processes to handle accounting transactions that are processed with banks, as well as closing activities related to bank accounts.",
          _type: "Group",
          name: "Bank Accounting Activities",
          GUID: "64259971-8dde-11e5-0a66-001bc640cd9b",
          _id: 2185,
          process_of: [
            {
              BPMN_GUID: "NULL",
              Description:
                "Process bank statement process describes the handling of bank statement files including the relevant postings.",
              _type: "Process",
              GUID: "7db97575-6a34-11e8-2ddf-001bc640cd9b",
              Advisory_Notes:
                "All incoming and outgoing payments are posted to the bank interim accounts and/or to the relevant sub ledger and balance sheet. If the nature of the payment can be defined by a rule, the postings and transactions are done automatically. As soon as all incoming and outgoing payments of the bank statement are posted, the matching items on the bank interim accounts are cleared (on a daily basis). ",
              Type: "Business Process",
              Scope: "",
              Outcome: "",
              name: "Process bank statement",
              _id: 556,
              Business_Rules: "",
              Prerequisite: "",
            },
          ],
        },
        {
          BPMN_GUID: "a34c9771-8dde-11e5-0a66-001bc640cd9b",
          Type: "Process Group",
          Description:
            "Travel Management involves the settlement of travel expenses incurred by employees and transfer, and processing in accounting. This covers the processing of settlement results from travel management system, clearing of travel expenses in controlling, and posting of travel expenses in Financial Accounting.",
          _type: "Group",
          name: "Travel Management Activities",
          GUID: "a34c9771-8dde-11e5-0a66-001bc640cd9b",
          _id: 2184,
          process_of: [
            {
              BPMN_GUID: "NULL",
              Description:
                "This process describes the recording of travel activities that can occur when performing a service.       ",
              _type: "Process",
              GUID: "84843a3d-7e5b-11e5-0a66-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "Creation of trips including start and end date of trip, travel expenses, location, finalization of travel activities recording by setting the status to approved and settled, posting travel activities to financial accounting.",
              Outcome: "Travel activities recorded and posted to accounting.",
              name: "Record travel activities",
              _id: 1265,
              Business_Rules: "",
              Prerequisite: "",
            },
          ],
        },
        {
          BPMN_GUID: "af94b4d1-8ddf-11e5-0a66-001bc640cd9b",
          Type: "Process Group",
          Description:
            "Profitability Analysis involves processes for evaluating market segments, which can be classified according to products, customers, orders or any combination of these, or strategic business units, such as sales organizations or business areas, with respect to the companys profit or contribution margin. In Profitability Analysis data is transfers from OLTP (Online Transactional Processing) to OLAP (Online Analytical Processing).",
          _type: "Group",
          name: "Profitability Analysis",
          GUID: "af94b4d1-8ddf-11e5-0a66-001bc640cd9b",
          _id: 2183,
          process_of: [
            {
              BPMN_GUID: "NULL",
              Description:
                "Controlling process to evaluate the cost of sales on line items level.",
              _type: "Process",
              GUID: "fccd2e59-9569-11e8-2ddf-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "Line items within the Trading, Profit and Loss account in addition to Balance sheet line items can be assessed, analysed and evaluated by management so that potential corrective action can be triggered if necessary. ",
              Outcome:
                "Cost of sales figures available for a specified period.",
              name: "Perform cost of sales analysis on line items",
              _id: 545,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "Process to assist management in understanding the qualitative reasoning behind variances so that corrective action can be triggered. ",
              _type: "Process",
              GUID: "fccd2e5b-9569-11e8-2ddf-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "Perform plan / actual variance analysis process evaluating the qualitative reasoning behind variances. This is often used as performance indicator in production, purchasing and overhead budgetary control. Based on the analysis corrective actions are triggered.",
              Outcome:
                "Variances between planned and actual order determined and reasons for variances captured.",
              name: "Perform plan / actual variance analysis",
              _id: 544,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "This reflects the analysis, interpretation and evaluation of the financial performance of functional units, divisions or regions. This is commonly used by senior management as a control to ensure that operational targets at the business unit level are consistent with strategic goals. ",
              _type: "Process",
              GUID: "fccd2e5d-9569-11e8-2ddf-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "To ensure accuracy of analysis, the following must be done:     ***Calculate work in process (WIP) of sales orders on the basis of planned and actual revenues and costs.    ***Generate report of WIP calculations for sales orders.    ***Settle sales orders to profitability analysis. This moves all revenues from the sales order to a controlling object for profitability analysis.    ***Review and complete (post) any outstanding goods issues.",
              Outcome: "Profitability analysis is closed.",
              name: "Perform profit and loss analysis by functional area and profit centers",
              _id: 547,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "In this process, P&L planning figures are created according to financial reporting requirements.",
              _type: "Process",
              GUID: "b19f8466-7c99-11e5-0a66-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "After business assumptions (e.g. Sales quantity, COGS) for P&L are received, planning guidelines are reviewed and updated where applicable.  The P&L is planned and sent for approval. Once approved it can be released.",
              Outcome: "P&L plan data is ready to be released.",
              name: "Conduct profitability planning",
              _id: 546,
              Business_Rules: "",
              Prerequisite: "",
            },
          ],
        },
      ],
      _id: 496,
    },
    {
      BPMN_GUID: "7fad010c-6575-4c90-8c7d-0edbc63612ec",
      Type: "Process Cluster",
      _type: "Cluster",
      name: "A. Integrated Management System",
      GUID: "8c49e4d1-2474-11e9-2ddf-001bc640cd9b",
      group_of: [
        {
          BPMN_GUID: "4e82bdd1-8de0-11e5-0a66-001bc640cd9b",
          Type: "Process Group",
          Description:
            "This process group covers all processes managing aspects related to the health and safety of employees, business partners and citizens close to facilities or products as well as any environmental impact of the companies activities. Furthermore, all regulatory processes associated with above mentioned processes is considered part of this process group.",
          _type: "Group",
          name: "Health, Safety and Environment",
          GUID: "4e82bdd1-8de0-11e5-0a66-001bc640cd9b",
          _id: 2197,
          process_of: [
            {
              BPMN_GUID: "NULL",
              Description:
                "Country specific regulations state that, above a certain quantity of chemicals and other hazardous substances, it is required to register with the authorities the maximum quantities of these substances that is planned to be purchased, imported, produced, sold, or exported within a certain period. By comparing the recorded quantities with the limit values, the system signals that a limit value is close to be exceeded if a limit value is exceeded, the system can also block the respective business process.  ",
              _type: "Process",
              GUID: "96e72960-eaa6-11e4-0a66-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "Identify required Checks   ***Check if Registration Status is positive or negative   ***Check quantity limit to import / produce   ***Block order and report issue   ***Permit Order  ",
              Outcome:
                "The product complies with the chemical compliance related requirements and sales order processing is permitted.",
              name: "Perform chemical compliance check",
              _id: 159,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "Disposal is executed according to legal guidelines and waste management requirements.",
              _type: "Process",
              GUID: "ff0fe25a-7ef0-11e5-0a66-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "Record the necessary external disposal operations and put together the legally required waste disposal documents such as waste manifests, dock receipts on the basis of the internal disposal documents. Execute the disposal and confirm completion.",
              Outcome: "Disposal of waste is confirmed.",
              name: "Execute waste disposal",
              _id: 592,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "In waste management, it is necessary to comply with and implement numerous national and international laws and regulations. This business process makes the classification of waste possible and the evaluation of waste management requirements.",
              _type: "Process",
              GUID: "12bafa97-7ef1-11e5-0a66-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "Including the waste specifications, the requirements for handling, disposal, approval and documentation of waste is specified. This includes physical-chemical properties, safety and transport information and legal data for waste. ",
              Outcome:
                "Waste classification and requirements evaluation are complete.",
              name: "Classify waste and evaluate waste management requirements",
              _id: 591,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "Provision of the Material Safety Data Sheet is a legal requirement in the context of risk communication for hazardous material. The distributor of hazardous material is obliged to inform the customer about the safety related data of a product. Carriers will require provision of a MSDS for transporting hazardous goods. The documents can be provided either as paper, e.g. as part of the shipment, or as an internet download.    ",
              _type: "Process",
              GUID: "8a80f57e-7e0d-11e5-0a66-001bc640cd9b",
              Advisory_Notes:
                "The channel for providing the MSDS ist not legally defined. Best practice is to make MSDS available for download though a customer portal.",
              Type: "Business Process",
              Scope:
                "Determine country-language specific safety data sheet and check availability. Send safety data sheet to customer.",
              Outcome:
                "Material safety data sheet has been provided to customer.",
              name: "Provide material safety data sheet (MSDS)",
              _id: 68,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "This process covers the creation of the master data required for waste management, including waste generators, points of generation, waste disposers, disposal facilities, waste transporters, authorities and waste.    In cases where waste disposal is to be executed by a service provided, preparation of waste disposal will trigger the procurement process for disposal services.",
              _type: "Process",
              GUID: "12bafa9a-7ef1-11e5-0a66-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "To describe the waste, waste materials can be created in the system with the logistic properties of the waste and waste specifications with the properties of ingredients, catalog numbers and process codes. The waste may be assigned to plant, cost centers, storage locations or the vendor.",
              Outcome:
                "Waste master data is created and the waste execution is triggered.",
              name: "Prepare waste disposal",
              _id: 593,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "This process ensures receipt of the return copies of disposal documents within the set periods.",
              _type: "Process",
              GUID: "12bafa91-7ef1-11e5-0a66-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "Generate list of the disposal documents whose return within period is soon to expire. For example, as soon as the disposer has sent the return copies and informed of the waste quantity disposed.   Adjust the waste quantity that can still be disposed of with the corresponding waste approval. Lock waste approvals which have reached their permitted quantity.",
              Outcome: "Waste disposal documents returned and up to date.",
              name: "Monitor disposal and document return",
              _id: 594,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "The purpose of this process is to prevent shipping of dangerous goods which will harm dangerous goods regulation.",
              _type: "Process",
              GUID: "3124d4df-e98f-11e4-0a66-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "The dangerous goods check verifies whether the shipment of dangerous goods contains transport regulatory data derived from UN-numbers, dangerous good classes, packaging codes etc. ",
              Outcome:
                "The product complies with the EH+S related requirements and the sales order is permitted by EH+S.",
              name: "Perform dangerous goods check",
              _id: 67,
              Business_Rules: "",
              Prerequisite: "",
            },
          ],
        },
        {
          BPMN_GUID: "35eecf01-8ddd-11e5-0a66-001bc640cd9b",
          Type: "Process Group",
          Description:
            "This group contains all processes related to the creation, maintenance and refurbishment of different equipments used in plants.",
          _type: "Group",
          name: "Plant Maintenance",
          GUID: "35eecf01-8ddd-11e5-0a66-001bc640cd9b",
          _id: 2196,
          process_of: [
            {
              BPMN_GUID: "NULL",
              Description:
                "Technical completion of plant maintenance indicates that all planned activities of the order have been performed. This activity demonstrates that all existing reservations, open commitments and planned capacities are no longer needed. ",
              _type: "Process",
              GUID: "7bbc6aa8-7eee-11e5-0a66-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "Once the maintenance work planned in the order has been performed, results verified and accepted, the order is marked as complete for plant maintenance.",
              Outcome:
                "The plant maintenance order obtains the status Technically completed.",
              name: "Complete maintenance order technically",
              _id: 1272,
              Business_Rules:
                "A business rule should enforce timely setting of the status to release unused resources and avoid faulty recording of working hours or material consumptions.",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "Repair/refurbishment can be triggered by an approved refurbishment maintenance request. In such cases, a refurbishment order is created from the request notification. A Refurbishment order is a special order, which is used  for the refurbishment of repairable spares, and for which the order type must be specially selected in the system.",
              _type: "Process",
              GUID: "233ac2c4-7efc-11e5-0a66-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "A refurbishment order is maintained in the system, which defines how many repairable spares managed in stock (if necessary, these can be identified uniquely by serial number) are to be refurbished for a particular material. The planner determines the time frame for the refurbishment and plans all the necessary operations, materials, utilities and so on.",
              Outcome: "Refurbishment order is created",
              name: "Create refurbishment maintenance order",
              _id: 1291,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "Completion of Refurbishment activities must be followed by confirmation and verification of results, as well as entry of costs and settlement.",
              _type: "Process",
              GUID: "233ac2a3-7efc-11e5-0a66-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "Once all refurbishment activities have been performed, the maintenance technician confirms work performed. The maintenance coordinator then checks whether refurbishment has been successful, whether follow-up work is required or  whether a part needs to be scrapped.    After this, related costs are either capitalized or expense according to accounting rules. Generally, If the refurbishment/repair is of a material nature i.e. a major overhaul or the repair of a major component of a machine, the amount is capitalized because the benefit is for more than the current period.",
              Outcome: "Completion of Refurbishment is verified.",
              name: "Confirm refurbishment and verify results",
              _id: 1292,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "The request must be evaluated to determine whether corrective maintenance is actually required. Approval of the request initiates the planning of required tasks to fulfil corrective maintenance procedures. ",
              _type: "Process",
              GUID: "7bbc6acc-7eee-11e5-0a66-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "Requests for maintenance are evaluated by the maintenance approver, who may either approve or reject the request. After the approval of a corrective maintenance request, a corrective maintenance order is created.",
              Outcome: "A corrective maintenance order is created.",
              name: "Evaluate and approve maintenance request",
              _id: 587,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "This process ensures the allocation of resources required to perform plant maintenance.",
              _type: "Process",
              GUID: "e46f703c-e800-11e4-0a66-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "It is necessary to determine the individual internal resources needed for specific maintenance activities, and when they are needed. Planning enables the availability of the resources at the time they are required.",
              Outcome: "Resources are assigned to the maintenance order",
              name: "Assign resources to maintenance order",
              _id: 1277,
              Business_Rules: "",
              Prerequisite: "Internal resources allocated to maintenance order",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "Approval of planned activities is given with the release of the  maintenance order. This initiates execution of all activities related to the maintenance order. ",
              _type: "Process",
              GUID: "7bbc6ad8-7eee-11e5-0a66-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "Release of a maintenance order represents the approval of the planned activities, such as purchasing of services and materials, as well as the usage of planned materials from stock. After the maintenance order is released shop papers are printed. These are the maintenance instructions for the maintenance technician.",
              Outcome:
                "Maintenance order is released and maintenance activities can be executed.",
              name: "Release maintenance order",
              _id: 1274,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "Additional resources may be required to perform plant maintenance. In such cases it is necessary determine the individual resources needed for specific maintenance activities, and when they are needed.  Planning enables the availability of the resources at the time they are required.",
              _type: "Process",
              GUID: "7bbc6aba-7eee-11e5-0a66-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "External resources are planned against the maintenance order. If an additional external service is required immediately, the maintenance planner will change the settings on the maintenance order so that a purchase requisition for the external service is generated immediately.",
              Outcome:
                "Additional external resources are incorporated into the maintenance plan.",
              name: "Plan additional external resources",
              _id: 1275,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "When the need for corrective maintenance arises, the details must be recorded and made available for evaluation and approval. A corrective maintenance request is a targeted instruction to perform an activity in the manner described.",
              _type: "Process",
              GUID: "dd4a5383-e7ff-11e4-0a66-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "A corrective maintenance request is entered in the system by a maintenance requestor. The requestor maybe a maintenance technician who can create a corrective maintenance request from a mobile device whilst conducting preventive maintenance.",
              Outcome:
                "Details regarding required corrective maintenance are recorded.",
              name: "Maintain corrective maintenance request",
              _id: 588,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "Approvals are used to define certain rules or requirements that must be observed in Work Clearance Management. ",
              _type: "Process",
              GUID: "7bbc6abd-7eee-11e5-0a66-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "The Maintenance Approver evaluates whether work clearance approval is required or not. In case work clearance approval is required, the maintenance planner requests work clearance approval and the maintenance approver either accepts or rejects the request.",
              Outcome:
                "Decision is made whether work clearance is required or not.",
              name: "Evaluate work clearance approval",
              _id: 1278,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "When an asset can no longer be used, it is necessary to define further steps to finalize its phasing out.",
              _type: "Process",
              GUID: "21fda555-7ef0-11e5-0a66-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "The maintenance manager evaluates the asset to get a view of the asset condition. In doing this, a decision is made on whether to sell, scrap or dispose the asset.",
              Outcome:
                "Decision is made whether to sell, scrap or dispose the asset.",
              name: "Analyze asset condition",
              _id: 589,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "Plant maintenance must be executed as specified in the maintenance plan, with tracking of time and task completion.   For dismantling, asset accounting is informed and EH&S is informed about harmful substances have to be transported when the asset is dismantled.",
              _type: "Process",
              GUID: "73e0f9e8-e802-11e4-0a66-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "Having collected the required materials, the maintenance technician conducts the planned maintenance as described in the shop papers. All activities performed are tracked and documented accordingly. ",
              Outcome:
                "All activities related to the plant maintenance are performed",
              name: "Perform plant maintenance",
              _id: 1268,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "Refurbishment involves the restoration of faulty repairable equipment. Related activities must be executed as specified in the maintenance plan, with tracking of time and task completion.",
              _type: "Process",
              GUID: "1d89eb24-51b0-11e5-0a66-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "The repairable equipment is brought from an initial damaged condition to an end condition at which  it can be used again. The maintenance technician performs the refurbishment of the equipment as described in the shop papers and provides confirmation upon completion.",
              Outcome: "All activities related to refurbishment are performed.",
              name: "Perform refurbishment",
              _id: 1289,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "The maintenance plan demonstrates that tasks will be done on time, that the technical objects are available and that all required resources, such as internal and external resources and materials, are on hand. The maintenance tasks that are to be performed require detailed planning, in order to minimize the time that the object is not productive. ",
              _type: "Process",
              GUID: "7bbc6a9c-7eee-11e5-0a66-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "For preventative maintenance, the maintenance order is created based on planned tasks that are required when specific predefined circumstances are given. Those can be described by specific parameters , which are verified regularly, by measuring usage (discrete counter or running time), or by a period after which refurbishment is due. Subsequently the maintenance plan is compiled.   For corrective maintenance (including refurbishment), planning is done after  a maintenance request is approved (or planned order converted) and the maintenance order is created.    In both cases, the maintenance plan provides a clear definition of tasks to be performed, roles and responsibilities, and dates.",
              Outcome: "Maintenance plan is created.",
              name: "Plan maintenance order",
              _id: 1271,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "Commercial completion of the plant maintenance order indicates that all costs related to the order have been settled. This process pertains to the business completion of an order when no further costs are expected to be posted to the order. ",
              _type: "Process",
              GUID: "7bbc6aae-7eee-11e5-0a66-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "Typically, maintenance orders are set to commercially complete by a batch process after a defined period of time.",
              Outcome:
                "The order obtains the status Completed, which indicates full completion. ",
              name: "Complete maintenance order commercially",
              _id: 1269,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "Completion of plant maintenance activities must be followed by confirmation and verification of results. ",
              _type: "Process",
              GUID: "7d178dc1-7eee-11e5-0a66-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "Once all planned activities have been performed, the Maintenance coordinator checks whether maintenance has been executed and documented as required. The Maintenance coordinator either:  1. Accepts the maintenance as successfully completed 2. Requests follow up maintenance and accepts the maintenance as having been completed 3. Rejects the completion of the maintenance, and asks for the maintenance to be completed.",
              Outcome:
                "Completion of plant maintenance activities is verified.",
              name: "Confirm plant maintenance order and verify results",
              _id: 1273,
              Business_Rules: "",
              Prerequisite: "",
            },
          ],
        },
      ],
      _id: 585,
    },
    {
      BPMN_GUID: "3780c825-5d9e-47ba-9b90-2d649eed0dcd",
      Type: "Process Cluster",
      _type: "Cluster",
      name: "B. Design",
      GUID: "9c0c5151-2474-11e9-2ddf-001bc640cd9b",
      group_of: [
        {
          BPMN_GUID: "54d01c61-8dda-11e5-0a66-001bc640cd9b",
          Type: "Process Group",
          Description:
            "This process group covers processes for the systemic removal of a product that is no longer marketed or when the change of a product version occurs and the old product version is no longer used for manufacturing.",
          _type: "Group",
          name: "Phase Out",
          GUID: "54d01c61-8dda-11e5-0a66-001bc640cd9b",
          _id: 2200,
          process_of: [
            {
              BPMN_GUID: "NULL",
              Description:
                "If a technical asset is no longer fit to be used and it no longer makes sense, for economic or technical reasons, to continue using that asset as a resource, it must be removed from productive use.",
              _type: "Process",
              GUID: "feff406b-7ef0-11e5-0a66-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "The following activities are performed:   ***Determine technical weak points   ***Determine assets that generate very high costs   ***Determine which assets no longer meets the required technical, business, safety, and environmental standards",
              Outcome: "Asset phased out and no longer available for use",
              name: "Phase out asset",
              _id: 609,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "When an request for phase out is sent, the information is recorded and stored. From todays point of view there are manual processes only.",
              _type: "Process",
              GUID: "686d860e-94c0-11e8-2ddf-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "Once the request for phase out for a product is sent, the data is documented, stored and forwarded.",
              Outcome: "Phase out information stored.",
              name: "Receive request for phase out",
              _id: 611,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "When an request for phase out is sent, the information is recorded and stored. From todays point of view there are manual processes only.",
              _type: "Process",
              GUID: "7d9d6019-c016-11e9-0cfc-001bc641ce4f",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "Once the request for phase out for a material is sent, the data is documented, stored and forwarded.",
              Outcome: "Phase out information stored.",
              name: "Receive phase out request and conduct impact analysis",
              _id: 612,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description: "",
              _type: "Process",
              GUID: "de706d8d-9408-11e9-0cfc-001bc641ce4f",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope: "",
              Outcome: "",
              name: "Inform production about phase out",
              _id: 614,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "Observe the reason for phase out and decide which following steps are needed. From todays point of view these are manual processes only.",
              _type: "Process",
              GUID: "686d8610-94c0-11e8-2ddf-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "Sold units and warehouse stock has to be considered to calculate follow-up costs. ",
              Outcome: "Classified request for product phase out.",
              name: "Classify request for phase out",
              _id: 608,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description: "",
              _type: "Process",
              GUID: "f3203d01-940e-11e9-0cfc-001bc641ce4f",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope: "",
              Outcome: "",
              name: "Approve phase-out",
              _id: 610,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "The validity of a material must be updated in the system by setting the appropriate phase out status.",
              _type: "Process",
              GUID: "686d861b-94c0-11e8-2ddf-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "The time at which the material will no longer be valid for use in production is maintained in the system.",
              Outcome:
                "Data regarding the product phase out are maintained in the system.",
              name: "Update phase out status of master data",
              _id: 613,
              Business_Rules: "",
              Prerequisite: "",
            },
          ],
        },
        {
          BPMN_GUID: "a680d9b1-8dd9-11e5-0a66-001bc640cd9b",
          Type: "Process Group",
          Description:
            "This process groups comprises processes for the controlling of projects, products, and services across their life cycles. ",
          _type: "Group",
          name: "Portfolio Management",
          GUID: "a680d9b1-8dd9-11e5-0a66-001bc640cd9b",
          _id: 2199,
          process_of: [
            {
              BPMN_GUID: "NULL",
              Description: "",
              _type: "Process",
              GUID: "51963d95-09d7-11e9-2ddf-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope: "",
              Outcome: "",
              name: "Maintain material master data",
              _id: 1308,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "This process describes changing material master of materials either produced in-house or procured externally.",
              _type: "Process",
              GUID: "37205d75-8d0d-11e9-0cfc-001bc641ce4f",
              Advisory_Notes:
                "***Depending on the request it might be necessary to involve further departments, e.g. HSE for dangerous goods specifications ",
              Type: "Business Process",
              Scope:
                "After requesting a change to an existing material master the related different views of material master is updated.",
              Outcome: "Material master has been changed.",
              name: "Change material master",
              _id: 605,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "The product master data offers a single point of reference for information regarding a product. Maintaining the data ensures that all relevant information is available and up-to-date.      ",
              _type: "Process",
              GUID: "1cdd80a5-7ca3-11e5-0a66-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "Once designing and prototyping is complete, the new material master and material BOM are created in the backend system. Later, the production scheduling department creates routing as a basis for production and calculation of the product price. To check the price level specified by the marketing department, the product cost controller calculates material costs using the BOM and routing. In case, the estimated price exceeds the specified price limit, a component of the new product BOM is exchanged for a less expensive component using change management. The design department issues an engineering change master to collect and document all changes according the specification document. Following a second costing run (cost estimate), the price meets the budget and the development status of the new product can be fixed by assigning a revision level.",
              Outcome:
                "Cost calculation is complete and product master data updated.",
              name: "Maintain product master data",
              _id: 1285,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "This process describes the creation of a new material that is either produced in-house or is externally procured.",
              _type: "Process",
              GUID: "37205d71-8d0d-11e9-0cfc-001bc641ce4f",
              Advisory_Notes:
                "Usually following basic information needs to be recorded: ***Material type ***Base unit of measures ***Material description***Material group***Model code***Net weight***Weight unit***Basic data text   Specific product information might be: ***Product hierarchy***Commodity code***Cross plant material status***Country of origin***Serial number***Tax classification***Batch management indicator*** Dangerous goods indicator profile     MRP information usually is: ***MRP group***Type*** Lot size***Planning and scheduling data.   Purchasing information includes: ***Purchasing group***Purchasing values   Price information includes: ***Price conditions***Standard price ***Intercompany price",
              Type: "Business Process",
              Scope:
                "After the new material master has been requested, basic information and product specifications are maintained. In addition information on MRP parameters and purchasing are entered. Finally price information for customers and intercompany business are added.",
              Outcome:
                "A new material master has been created and is ready for use for example in sales, procurement or production.",
              name: "Create material master",
              _id: 606,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "This process describes how to extend the material master to another plant.",
              _type: "Process",
              GUID: "37205d79-8d0d-11e9-0cfc-001bc641ce4f",
              Advisory_Notes:
                " ***The extension to plants is needed in order to make materials available in these plants for any activities. Typically there are strict rules on this extension in order to control portfolio, supply routes and price policies. ",
              Type: "Business Process",
              Scope:
                "The extension request needs to be checked and approved before the material master is actually extended to the other plant. ",
              Outcome:
                "Material master has been extended to the other plant and is available for example to launch the product in the respective legal entity.",
              name: "Extend material master to other plant",
              _id: 604,
              Business_Rules: "",
              Prerequisite: "",
            },
          ],
        },
        {
          BPMN_GUID: "0fc0aae1-8dda-11e5-0a66-001bc640cd9b",
          Type: "Process Group",
          Description:
            "Product development involves the creation of products with new or different characteristics that offer new or additional benefits to the customer. Processes in this group cover the modification of an existing product or its presentation, or formulation of an entirely new product that satisfies a newly defined customer want or market niche.",
          _type: "Group",
          name: "Product Development",
          GUID: "0fc0aae1-8dda-11e5-0a66-001bc640cd9b",
          _id: 2198,
          process_of: [
            {
              BPMN_GUID: "NULL",
              Description:
                "Implement changes according to prototype so that all changes are executed as specified in the change order.",
              _type: "Process",
              GUID: "c5c21b0f-9bd2-11e8-2ddf-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "Based on the change specifications, the responsible parties are engaged to manufacture a specific quantity of the new product with the new raw material/components. The production order is assigned to the project. ",
              Outcome: "Changes implemented.",
              name: "Implement change",
              _id: 1315,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "Based on the engineering change order, the development process is initiated.",
              _type: "Process",
              GUID: "c5c21b0b-9bd2-11e8-2ddf-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "Initiate development process sets out a series of stages that new or change products typically go through, including creation of a prototype and the implementation of the change.",
              Outcome: "Prototyping can start.",
              name: "Initiate development",
              _id: 1316,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "In a project environment, it is necessary to perform required R&D work, track the process and confirm upon completion.   ",
              _type: "Process",
              GUID: "19efab1a-7ef6-11e5-0a66-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "Required  R&D work is performed as specified in the project plan.  Milestones are tracked, and the status of the project is reported.       ",
              Outcome: "Completion of R&D work is confirmed",
              name: "Execute R&D work",
              _id: 1260,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "After the customer inquiry or information regarding internal product request is received, technical feasibility to produce requested products must be assessed before a quotation can be issued  to the customer.   ",
              _type: "Process",
              GUID: "1cdd809c-7ca3-11e5-0a66-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "In make to order scenarios, the customer inquiry may contain very specific requirements or even request a product that has never been produced before in the specific configuration. Technical feasibility to meet these requirements must be assessed in order to  prepare a quotation for the customer. Depending on complexity of the inquiry, the assessment may range from checking if existing products meet specified requirements and changes to product design to complete development of a new product prototype in a R&D project (engineer to order). During the assessment, the components and production steps required for manufacturing are defined, building a foundation for further pricing proposals.",
              Outcome:
                "Technical feasibility to meet requirements contained in customer inquiry is assessed. Manufacturing information for product pricing is available",
              name: "Gather and document product specifications",
              _id: 215,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "The request must be evaluated to determine whether a process change is actually required. ",
              _type: "Process",
              GUID: "51963d8b-09d7-11e9-2ddf-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope: "",
              Outcome: "",
              name: "Evaluate change impact of manufacturing process",
              _id: 597,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "A change order is work that is added to or deleted from the original scope of product development.",
              _type: "Process",
              GUID: "c5c21af8-9bd2-11e8-2ddf-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "Issuing of a change order initiate the development process, and can result in a a new project to handle significant changes to the current project.",
              Outcome: "Change order forwarded to relevant parties.",
              name: "Issue change order",
              _id: 1313,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "Designing and creating a prototype of the product are essential early steps in product development that provide the blueprint from which the final product is derived.     ",
              _type: "Process",
              GUID: "1f40c4c4-7ca3-11e5-0a66-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "The design process is carried out in a CAD system which can be fully integrated into the SAP system. SAP CAD Desktop is used as the central user interface by the design engineer. By linking the CAD system and the CAD Desktop in the SAP system all components designed in the CAD system can be managed. With full CAD integration, the CAD drawings are checked into the document management system (DMS). A document BOM is available in the system. CAD Desktop allows the easy creation of the necessary material masters and material BOM.",
              Outcome: "Product is design complete and documented.",
              name: "Design and prototype product",
              _id: 1286,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "This process shows the creation of a bill of materials used in production.",
              _type: "Process",
              GUID: "3f96eb01-9569-11e8-2ddf-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "The BOM list contains the object number of each component, together with the quantity and unit of measure.",
              Outcome: "The BOM is created.",
              name: "Create bill of materials (BOM)",
              _id: 602,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "When the internal marketing department sends a request for a new product, the information is recorded and stored. This forms the basis for defining product specifications.       ",
              _type: "Process",
              GUID: "1cdd8081-7ca3-11e5-0a66-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "Once the marketing department sends the request for a new product, the data is documented, stored and forwarded to the product development department. ",
              Outcome:
                "All relevant details regarding the requested product are documented.",
              name: "Receive and process product request",
              _id: 1281,
              Business_Rules:
                "It is necessary to define how the information will be recorded and forwarded for further evaluation by the product development department.",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "For internal product development, it is necessary to carry out test production based on the product prototype. Information must be passed on to the manufacturing department regarding what needs to be produced and in what quantity.       ",
              _type: "Process",
              GUID: "1cdd807b-7ca3-11e5-0a66-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "Based on the product specifications, the production department is engaged to manufacture a specific quantity of the new product.  The production order is assigned to the project. ",
              Outcome: "Test production of new product can begin.",
              name: "Initiate small scale test production",
              _id: 1284,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "Approval of the request initiates the planning of required tasks to fulfil process change procedures. ",
              _type: "Process",
              GUID: "8c36f737-9414-11e9-0cfc-001bc641ce4f",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope: "",
              Outcome: "",
              name: "Approve manufacturing process request",
              _id: 601,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "This process shows the routing instructions used in production.",
              _type: "Process",
              GUID: "3f96eb08-9569-11e8-2ddf-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "The routing is an ordered list of tasks required for a manufacturing process that is used for making a product.",
              Outcome: "The routing is created.",
              name: "Create routing",
              _id: 600,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "When an request for process change is sent, the information is recorded and stored.",
              _type: "Process",
              GUID: "0cb0b781-09d6-11e9-2ddf-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "Once the request for a process change for a product is sent, the data is documented, stored and forwarded.",
              Outcome: "",
              name: "Receive manufacturing process change request",
              _id: 598,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "When all requirements are met, the change request is approved, released and communicated to the stakeholders.",
              _type: "Process",
              GUID: "c5c21af5-9bd2-11e8-2ddf-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope:
                "Once all the tasks in a change request are approved, it is released. At this point, there are records of all items that should be changed and the parties involved. ",
              Outcome: "Change request is approved.",
              name: "Release change request",
              _id: 1304,
              Business_Rules: "",
              Prerequisite: "",
            },
            {
              BPMN_GUID: "NULL",
              Description:
                "Approval of planned activities is given with the release of the process change request. This initiates execution of all activities related to the process change request. ",
              _type: "Process",
              GUID: "0cb0b796-09d6-11e9-2ddf-001bc640cd9b",
              Advisory_Notes: "",
              Type: "Business Process",
              Scope: "",
              Outcome: "",
              name: "Release process change request",
              _id: 599,
              Business_Rules: "",
              Prerequisite: "",
            },
          ],
        },
      ],
      _id: 595,
    },
  ],
};

export default libraryDump;
