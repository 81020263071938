import React from "react";

const Message = ({ message }) => {
  const renderContent = () => {
    // Regular expression to match Markdown links and annotations
    const markdownLinkRegex = /\[([^\]]+)\]\(([^)]+)\)(?:\{([^\}]+)\})?/g;
    // Split message content by Markdown links and annotations
    const parts = message.content.split(markdownLinkRegex);

    return parts
      .map((part, index) => {
        if (index % 4 === 1) {
          // Render link text
          return (
            <a
              href={parts[index + 1]}
              key={index}
              target="_blank"
              rel="noopener noreferrer"
              style={{ whiteSpace: "pre-line" }}
            >
              {part}
            </a>
          );
        } else if (index % 4 === 0) {
          // Render regular text
          return (
            <span key={index} style={{ whiteSpace: "pre-line" }}>
              {part}
            </span>
          );
        }
        return null;
      })
      .filter((part) => typeof part !== "string" || !part.includes("source")); // Filter out "source" annotations
  };

  return (
    <div
      style={{ textAlign: message.isUser ? "right" : "left", margin: "8px" }}
    >
      <div
        style={{
          color: message.isUser ? "#ffffff" : "#000000",
          backgroundColor: message.isUser ? "#1186fe" : "#eaeaea",
          padding: "15px",
          borderRadius: "8px",
        }}
      >
        {renderContent()}
      </div>
    </div>
  );
};

export default Message;
