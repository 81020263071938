import React from "react";
import { Form, Button, Card, Alert } from "react-bootstrap";
import { useHistory, useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import LoadingSpinner from "./admin/LoadingSpinner";
import { resetPassword } from "../../actions";

export default function ConfirmationMsg() {
  const [counter, setCounter] = React.useState(10);
  const history = useHistory();
  const url = window.location.href;

  // here we change the frondend port with backend port to get the status
  //url.replace(`${frontendPort}`, `${backendPort}`);

  const { token } = useParams();
  const [alertBox, setAlertBox] = useState({
    show: true,
    variant: "success",
    message: "",
  });

  const [data, setData] = useState({ hits: [] });

  useEffect(async () => {
    const response = await resetPassword(token);
    if (response.success) {
      setAlertBox({ show: true, variant: "success", message: response.msg });
    } else {
      setAlertBox({ show: true, variant: "danger", message: response.msg });
    }
  }, []);

  function onClick() {
    history.push("/login");
    history.go(0);
  }

  React.useEffect(() => {
    const timer =
      counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
    if (counter == 0) {
      return onClick();
    }
    return () => clearInterval(timer);
  }, [counter]);

  return (
    <>
      <Card>
        <Card.Body>
          <div className="container-fluid nav_bg my-3">
            <div className="d-flex flex-column d-flex justify-content-center">
              <div className="col-12 ">
                <h1 className="alert-heading" style={{ textAlign: "center" }}>
                  Reset Password
                </h1>
                <br />
                <br />
                <div
                  className={"alert alert-" + alertBox.variant + " text-center"}
                  role="alert"
                >
                  <p> {alertBox.message}</p>
                </div>
              </div>
              <br />
              <br />
              <div className="col text-center">
                <Button className="w-10" type="submit" onClick={onClick}>
                  Click here to Login : {counter}
                </Button>
              </div>
            </div>
          </div>
        </Card.Body>
      </Card>
    </>
  );
}
