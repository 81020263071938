import React, { useState, useEffect } from "react";
import { Alert, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { requestProlongation } from "../../actions";

export default function MyAccount(props) {
  {
    /*'primary','secondary', 'success','danger', 'warning', 'info', 'light', 'dark', */
  }
  const [alertBox, setAlertBox] = useState({
    show: true,
    variant: "primary",
    msg: "My Account Details",
  });

  const resetAlertBox = () => {
    setAlertBox({
      show: true,
      variant: "primary",
      msg: "My Account Details",
    });
  };

  const accountExtesnionRequest = async () => {
    let res = await requestProlongation(props.user.email);
    if (res.success) {
      setAlertBox({
        variant: "warning",
        msg: res.msg,
      });
    } else {
      setAlertBox({
        variant: "danger",
        msg: "Internal Server problem, please try again later",
      });
    }
    setTimeout(resetAlertBox, 4000);
  };
  function GetFormattedDate(inputDate) {
    var todayTime = new Date(inputDate),
      month = todayTime.getMonth() + 1,
      day = todayTime.getDate(),
      year = todayTime.getFullYear();
    return month + "/" + day + "/" + year;
  }
  return (
    <div className="col-10 mx-auto">
      <Alert show={true} variant={alertBox.variant} align="center">
        {alertBox.msg}
      </Alert>

      <div className="container emp-profile">
        <p key={props.user.id}>
          <div className="row">
            <div className="col-md-4">
              <div className="profile-img">
                <img src="user_profile_icon.png" alt="" />
              </div>
            </div>
            <div className="col-md-6">
              <div className="profile-head">
                <h5>{props.user.firstName + " " + props.user.lastName}</h5>
                <h6>{props.user.position}</h6>

                <ul className="nav nav-tabs" id="myTab" role="tablist">
                  <li className="nav-item">
                    <a
                      className="nav-link active"
                      id="home-tab"
                      data-toggle="tab"
                      href="#home"
                      role="tab"
                      aria-controls="home"
                      aria-selected="true"
                    >
                      About
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      id="profile-tab"
                      data-toggle="tab"
                      href="#profile"
                      role="tab"
                      aria-controls="profile"
                      aria-selected="false"
                    >
                      Account Info
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-2 ">
              <button
                type="button"
                className="btn btn-primary "
                style={{ width: "100%" }}
              >
                <Link className="text-light" to="/changepassword">
                  Change Password
                </Link>
              </button>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4"></div>
            <div className="col-md-8">
              <div className="tab-content profile-tab" id="myTabContent">
                <div
                  className="tab-pane fade show active"
                  id="home"
                  role="tabpanel"
                  aria-labelledby="home-tab"
                >
                  <div className="row">
                    <div className="col-md-6">
                      <label>Subscription</label>
                    </div>
                    <div className="col-md-6">
                      <p>{props.user.subscription}</p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <label>Role</label>
                    </div>
                    <div className="col-md-6">
                      <p>{props.user.role}</p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <label>First Name</label>
                    </div>
                    <div className="col-md-6">
                      <p>{props.user.firstName}</p>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-6">
                      <label>Last Name</label>
                    </div>
                    <div className="col-md-6">
                      <p>{props.user.lastName}</p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <label>Email</label>
                    </div>
                    <div className="col-md-6">
                      <p>{props.user.email}</p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <label>Phone</label>
                    </div>
                    <div className="col-md-6">
                      <p>{props.user.phone}</p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <label>Profession</label>
                    </div>
                    <div className="col-md-6">
                      <p>{props.user.position}</p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <label>Company</label>
                    </div>
                    <div className="col-md-6">
                      <p>{props.user.company}</p>
                    </div>
                  </div>
                </div>
                <div
                  className="tab-pane fade"
                  id="profile"
                  role="tabpanel"
                  aria-labelledby="profile-tab"
                >
                  <div className="row">
                    <div className="col-md-6">
                      <label>Account creation date</label>
                    </div>
                    <div className="col-md-6">
                      <p>{GetFormattedDate(props.user.registrationDate)}</p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <label>Account expiry date</label>
                    </div>
                    <div className="col-md-6">
                      <p>{GetFormattedDate(props.user.expirationDate)}</p>
                    </div>
                  </div>
                  <br />
                  <div className="row">
                    <div className="col-md-11">
                      <h2>
                        Please contact the sales team to request for
                        extension/deletion of your BF account.
                      </h2>
                      <br />
                      <div className="col text-center">
                        <Button
                          className="w-10 text-center"
                          type="submit"
                          onClick={accountExtesnionRequest}
                        >
                          Extend Account
                        </Button>
                      </div>
                      <br />
                      <a href="mailto:sales@bpexperts.de?bcc=russell.gomersall@bpexperts.de&subject=Request for deletion of my account">
                        <div className="col text-center">
                          <Button className="w-10" type="submit">
                            Delete Account
                          </Button>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </p>
      </div>
    </div>
  );
}
