import React from "react";
import { Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";

export default function ConfirmationMsg() {
  const history = useHistory();
  function onClick() {
    history.push("/signup");
  }
  return (
    <div className="container-fluid nav_bg my-3">
      <div className="d-flex flex-column d-flex justify-content-center">
        <div className="col-5 mx-auto">
          <h1 className="alert-heading" style={{ textAlign: "center" }}>
            Registration Unsuccessful!
          </h1>
          <br />
          <br />
          <div className="alert alert-danger text-center" role="alert">
            <p>Internal Server problem, please try again</p>
          </div>
        </div>
        <br />
        <br />
        <div className="col text-center">
          <Button className="w-10" type="submit" onClick={onClick}>
            GO BACK TO SIGN-UP FORM
          </Button>
        </div>
      </div>
    </div>
  );
}
