import React, { useState, useCallback } from "react";
import { useDropzone } from "react-dropzone";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Button,
  Box,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";

// Style the submit button using the styled utility
const StyledButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(2),
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.white,
  "&:hover": {
    backgroundColor: theme.palette.primary.dark,
  },
}));

const UploadBPMN = () => {
  const [objectType, setObjectType] = useState("");
  const [objectName, setObjectName] = useState("");
  const [upperNode, setUpperNode] = useState("");
  const [description, setDescription] = useState("");
  const [outcome, setOutcome] = useState("");
  const [advisoryNotes, setAdvisoryNotes] = useState("");
  const [scope, setScope] = useState("");
  const [businessRules, setBusinessRules] = useState("");
  const [file, setFile] = useState(null);

  const onDrop = useCallback((acceptedFiles) => {
    setFile(acceptedFiles[0]);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      "application/xml": [".xml", ".bpmn"],
    },
  });

  const handleObjectTypeChange = (event) => {
    setObjectType(event.target.value);
  };

  const handleObjectNameChange = (event) => {
    setObjectName(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    // Implement what should happen on form submit, like sending the data to a server
  };

  return (
    <form onSubmit={handleSubmit}>
      <FormControl fullWidth margin="normal">
        <InputLabel id="object-type-label">Select object type</InputLabel>
        <Select
          labelId="object-type-label"
          value={objectType}
          label="Select object type"
          onChange={handleObjectTypeChange}
        >
          <MenuItem value="BusinessProcess">Business Process</MenuItem>
          <MenuItem value="Scenario">Scenario</MenuItem>
        </Select>
      </FormControl>
      <TextField
        fullWidth
        margin="normal"
        label="Object name **"
        value={objectName}
        onChange={handleObjectNameChange}
        required
      />
      <TextField
        fullWidth
        margin="normal"
        label="Upper node **"
        value={upperNode}
        onChange={(e) => setUpperNode(e.target.value)}
        required
        disabled={!!objectName}
      />
      <TextField
        fullWidth
        margin="normal"
        label="Description"
        multiline
        rows={4}
        value={description}
        onChange={(e) => setDescription(e.target.value)}
      />
      <TextField
        fullWidth
        margin="normal"
        label="Outcome"
        multiline
        rows={2}
        value={outcome}
        onChange={(e) => setOutcome(e.target.value)}
      />
      <TextField
        fullWidth
        margin="normal"
        label="Advisory notes"
        multiline
        rows={2}
        value={advisoryNotes}
        onChange={(e) => setAdvisoryNotes(e.target.value)}
      />
      <TextField
        fullWidth
        margin="normal"
        label="Scope"
        multiline
        rows={2}
        value={scope}
        onChange={(e) => setScope(e.target.value)}
      />
      <TextField
        fullWidth
        margin="normal"
        label="Business rules"
        multiline
        rows={2}
        value={businessRules}
        onChange={(e) => setBusinessRules(e.target.value)}
      />
      <Box
        {...getRootProps({ onClick: (event) => event.stopPropagation() })}
        sx={{
          border: "2px dashed gray",
          padding: 4,
          mt: 2,
          textAlign: "center",
          cursor: "pointer",
        }}
      >
        <input {...getInputProps()} />
        {isDragActive ? (
          <Typography>Drop the files here ...</Typography>
        ) : (
          <Typography>
            Drag & Drop BPMN or XML file here, or click to select files
          </Typography>
        )}
      </Box>
      <StyledButton type="submit" variant="contained">
        Submit
      </StyledButton>
    </form>
  );
};
export default UploadBPMN;
