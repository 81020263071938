import axios from "axios";

const authToken = localStorage.getItem("auth");
const database = localStorage.getItem("db");
const userId = localStorage.getItem("userId");

export const searchProcessByName = async (name) => {
  if (name.length === 0) return [];
  return await axios
    .get(`/processes/name/${name}`, {
      headers: {
        "auth-token": authToken,
        "user-id": userId,
        database: database,
      },
    })
    .then((response) => {
      if (response.status == 200) {
        return response?.data?.processes;
      }
      return [];
    })
    .catch((err) => {
      console.error(err);
      return [];
    });
};

export const getProcessOccurences = async (guid) => {
  if (guid.length == 0) return [];
  return await axios
    .get(`/processes/getOccurences/${guid}`, {
      headers: {
        "auth-token": authToken,
        "user-id": userId,
        database: database,
      },
    })
    .then((response) => {
      if (response.status == 200) {
        return response?.data?.content;
      }
      return [];
    })
    .catch((err) => {
      console.error(err);
      return [];
    });
};

export const getProcessRelationships = async (guid) => {
  if (guid.length === 0) return [];
  return await axios
    .get(`/processes/getRelationships/${guid}`, {
      headers: {
        "auth-token": authToken,
        "user-id": userId,
        database: database,
      },
    })
    .then((response) => {
      if (response.status == 200) {
        return response?.data?.content;
      }
      return [];
    })
    .catch((err) => {
      console.error(err);
      return [];
    });
};

export const getProcessSVG = async (guid) => {
  if (guid.length == 0) return [];
  return await axios
    .get(`/processes/getSVG/${guid}`, {
      headers: {
        "auth-token": authToken,
        "user-id": userId,
        database: database,
      },
    })
    .then((response) => {
      if (response.status == 200) {
        return response?.data;
      }
      return [];
    })
    .catch((err) => {
      console.error(err);
      return [];
    });
};

export const getProcessBPMN = async (guid) => {
  if (guid?.length == 0) return [];
  return await axios
    .get(`/processes/getBPMN/${guid}`, {
      headers: {
        "auth-token": authToken,
        "user-id": userId,
        database: database,
      },
    })
    .then((response) => {
      if (response.status == 200) {
        return response?.data;
      }
      return [];
    })
    .catch((err) => {
      console.error(err);
      return [];
    });
};

export const getProcessStructure = async (guid) => {
  if (guid.length == 0) return [];
  return await axios
    .get(`/processes/structure/${guid}`, {
      headers: {
        "auth-token": authToken,
        "user-id": userId,
        database: database,
      },
    })
    .then((response) => {
      if (response.status == 200) {
        return response?.data;
      }
      return [];
    })
    .catch((err) => {
      console.error(err);
      return [];
    });
};

export const getProcessLibraryTree = async () => {
  return await axios
    .get(`/processes/getProcessLibraryTree`, {
      headers: {
        "auth-token": authToken,
        "user-id": userId,
        database: database,
      },
    })
    .then((response) => {
      if (response.status == 200) {
        return response?.data?.content;
      }
      return [];
    })
    .catch((err) => {
      console.error(err);
      return [];
    });
};

export const getE2ETree = async () => {
  return await axios
    .get(`/processes/getE2ETree`, {
      headers: {
        "auth-token": authToken,
        "user-id": userId,
        database: database,
      },
    })
    .then((response) => {
      if (response.status == 200) {
        return response?.data?.content;
      }
      return [];
    })
    .catch((err) => {
      console.error(err);
      return [];
    });
};

export const getBlueprintsTree = async () => {
  return await axios
    .get(`/processes/getBlueprintsTree`, {
      headers: {
        "auth-token": authToken,
        "user-id": userId,
        database: database,
      },
    })
    .then((response) => {
      if (response.status == 200) {
        return response?.data?.content;
      }
      return [];
    })
    .catch((err) => {
      console.error(err);
      return [];
    });
};

export const getWorkPackagesTree = async () => {
  return await axios
    .get(`/processes/getWorkPackagesTree`, {
      headers: {
        "auth-token": authToken,
        "user-id": userId,
        database: database,
      },
    })
    .then((response) => {
      if (response.status == 200) {
        return response?.data?.content;
      }
      return [];
    })
    .catch((err) => {
      console.error(err);
      return [];
    });
};

export const getEdges = async (guid) => {
  if (!guid) return [];
  return await axios
    .get(`/processes/getEdges/${guid}`, {
      headers: {
        "auth-token": authToken,
        "user-id": userId,
        database: database,
      },
    })
    .then((response) => {
      if (response.status == 200) {
        return response?.data?.content;
      }
      return [];
    })
    .catch((err) => {
      console.error(err);
      return [];
    });
};

export const getNodes = async (guid, relation) => {
  if (!(guid && relation)) return [];
  return await axios
    .post(
      `/processes/getNodes/${guid}`,
      { relation },
      {
        headers: {
          "auth-token": authToken,
          "user-id": userId,
          database: database,
        },
      }
    )
    .then((response) => {
      if (response.status == 200) {
        return response?.data?.content;
      }
      return [];
    })
    .catch((err) => {
      console.error(err);
      return [];
    });
};

export const authenticate = async (email, password) => {
  if (!(email && password))
    return {
      success: false,
      msg: "Please enter email and password",
    };

  return await axios(
    "/neoPublic/authenticate",
    {
      method: "POST",
      data: {
        email: email.toLowerCase().trim(), //always convert email into lower case
        password,
      },
    },
    { withCredentials: true }
  )
    .then((response) => {
      if (response.data?.success) {
        localStorage.setItem("auth", response.data.content?.token); //saving token in local storage
        return response.data;
      }
      return {
        success: false,
        msg: "Internal Server Error",
      };
    })
    .catch((err) => {
      console.error(err);
      return {
        success: false,
        msg: err?.response?.data?.msg || "Internal Server Error",
      };
    });
};

export const getProfile = async (token) => {
  if (!token)
    return {
      success: false,
      msg: "no token available",
    };

  return await axios(
    "/neoPublic/profile",
    {
      method: "GET",
      headers: {
        "auth-token": token,
      },
    },
    { withCredentials: true }
  )
    .then((response) => {
      if (response.data?.success) {
        return response.data;
      }
      return {
        success: false,
        msg: response.data.msg,
      };
    })
    .catch((err) => {
      console.error(err);
      return {
        success: false,
        msg: err?.response?.data?.msg || "Internal Server Error",
      };
    });
};

export const signInLinkedin = async (data) => {
  if (!data)
    return {
      success: false,
      msg: "Please enter valid data",
    };

  return await axios("neoPublic/signInLinkedin", {
    method: "POST",
    data: {
      token: data.code,
    },
  })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      console.error(err);
      return {
        success: false,
        msg: err?.response?.data?.msg || "Internal Server Error",
      };
    });
};

export const requestProlongation = async (email) => {
  return await axios(`/neoPublic/request-prolongation`, {
    method: "POST",
    data: {
      email,
    },
  })
    .then((response) => {
      if (response.data?.success) {
        return response.data;
      }
      return {
        success: false,
        msg: response.data.msg,
      };
    })

    .catch((err) => {
      console.error(err);
      return {
        success: false,
        msg: err.response?.data?.msg || "Internal Server Error",
      };
    });
};

export const requestAccount = async (data) => {
  return await axios(
    "/neoPublic/request-account",
    {
      method: "POST",
      params: { regid: window.location.search.split("=")[1] }, //extract id from string and send query parameter (for analysis)
      data: {
        email: data.email.toLowerCase(), //always convert email into lower case,
        company: data.company,
        firstName: data.firstName,
        lastName: data.lastName,
        phone: data.phone,
        position: data.position,
        domains: Object.keys(data.domains).filter(
          (k) => data.domains[k] === true
        ),
        // message: message,
        topics: Object.keys(data.topics).filter((k) => data.topics[k] === true),
      },
    },
    { withCredentials: true }
  )
    .then((response) => {
      if (response.data?.success) {
        return response.data;
      }
      return {
        success: false,
        msg: response.data?.msg,
      };
    })
    .catch((err) => {
      console.error(err);
      return {
        success: false,
        msg: err?.response?.data?.msg || "Internal Server Error",
      };
    });
};

export const getAllUsers = async () => {
  return await axios
    .get("/neoUsers", {
      headers: {
        "auth-token": authToken,
        "user-id": userId,
        database: database,
      },
    })
    .then((response) => {
      if (response.data?.success) {
        return response.data;
      }
      return {
        success: false,
        msg: response.data.msg,
      };
    })
    .catch((err) => {
      console.error(err);
      return {
        success: false,
        msg: err?.response?.data?.msg || "Internal Server Error",
      };
    });
};

export const addUser = async (data) => {
  return await axios("/neoUsers/", {
    method: "POST",
    data,
    headers: {
      "auth-token": authToken,
      "user-id": userId,
      database: database,
    },
  })
    .then((response) => {
      if (response.data?.success) {
        return response.data;
      }
      return {
        success: false,
        msg: response.data.msg,
      };
    })
    .catch((err) => {
      console.error(err);
      return {
        success: false,
        msg: err?.response?.data?.msg || "Internal Server Error",
      };
    });
};

export const updateUser = async (id, data) => {
  if (!id || !data) {
    // show in the alret
    return {
      success: false,
      msg: "No user id or data presented",
    };
  }
  return await axios("/neoUsers/" + id, {
    method: "PUT",
    data,
    headers: {
      "auth-token": authToken,
      "user-id": userId,
      database: database,
    },
  })
    .then((response) => {
      if (response.data?.success) {
        return response.data;
      }
      return {
        success: false,
        msg: response.data.msg,
      };
    })
    .catch((err) => {
      console.error(err);
      return {
        success: false,
        msg: err?.response?.data?.msg || "Internal Server Error",
      };
    });
};

export const deleteUser = async (id) => {
  if (!id) {
    // show in the alret
    return {
      success: false,
      msg: "No user id provided",
    };
  }
  return await axios
    .delete("/neoUsers/" + id, {
      headers: {
        "auth-token": authToken,
        "user-id": userId,
        database: database,
      },
    })
    .then((response) => {
      if (response.data?.success) {
        return response.data;
      }
      return {
        success: false,
        msg: response.data?.msg,
      };
    })
    .catch((err) => {
      console.error(err);
      return {
        success: false,
        msg: err?.response?.data?.msg || "Internal Server Error",
      };
    });
};

export const resetPassword = async (token) => {
  if (!token) {
    // show in the alret
    return {
      success: false,
      msg: "Failed to reset password please contact Admin.",
    };
  }
  return await axios
    .get("/neoPublic/reset-password?index=" + token, {
      headers: {
        "auth-token": authToken,
        "user-id": userId,
        database: database,
      },
    })
    .then((response) => {
      if (response.data?.success) {
        return response.data;
      }
      return {
        success: false,
        msg: response.data?.msg,
      };
    })
    .catch((err) => {
      console.error(err);
      return {
        success: false,
        msg: err?.response?.data?.msg || "Internal Server Error",
      };
    });
};

export const advanceSearch = async (
  property,
  value,
  skip,
  limit,
  orderBy,
  sort,
  label
) => {
  return await axios
    .get(
      "/processes/search/" +
      property +
      "/" +
      value +
      "?" +
      "skip=" +
      skip +
      "&" +
      "limit=" +
      limit +
      "&orderBy=" +
      orderBy +
      "&sort=" +
      sort +
      "&labels=" +
      label,
      {
        headers: {
          "auth-token": authToken,
          "user-id": userId,
          database: database,
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      if (response.data?.success) {
        return response.data;
      }
      return {
        success: false,
        msg: response.data.msg,
      };
    })
    .catch((err) => {
      console.error(err);
      return {
        success: false,
        msg: err?.response?.data?.msg || "Internal Server Error",
      };
    });
};

export const advanceSearchMyFavorites = async (
  property,
  value,
  skip,
  limit,
  orderBy,
  sort,
  label
) => {
  try {
    const response = await axios.get(
      `/processes/searchMyFavorites/${encodeURIComponent(property)}/${encodeURIComponent(JSON.stringify(value))}`,
      {
        params: {
          skip,
          limit,
          orderBy,
          sort,
          labels: label,
        },
        headers: {
          'auth-token': authToken,
          'user-id': userId,
          database,
          'Content-Type': 'application/json',
        },
      }
    );

    if (response.data?.success) {
      return response.data;
    } else {
      return {
        success: false,
        msg: response.data.msg,
      };
    }
  } catch (err) {
    console.error(err);
    return {
      success: false,
      msg: err?.response?.data?.msg || 'Internal Server Error',
    };
  }
};



export const getProcessLabels = async () => {
  return await axios
    .get("/processes/getLabels", {
      headers: {
        "auth-token": authToken,
        "user-id": userId,
        database: database,
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      if (response.data?.success) {
        return response.data;
      }
      return {
        success: false,
        msg: response.data.msg,
      };
    })
    .catch((err) => {
      console.error(err);
      return {
        success: false,
        msg: err?.response?.data?.msg || "Internal Server Error",
      };
    });
};

export const getProcessTypes = async () => {
  return await axios
    .get("/processes/getTypes", {
      headers: {
        "auth-token": authToken,
        "user-id": userId,
        database: database,
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      if (response.data?.success) {
        return response.data;
      }
      return {
        success: false,
        msg: response.data.msg,
      };
    })
    .catch((err) => {
      console.error(err);
      return {
        success: false,
        msg: err?.response?.data?.msg || "Internal Server Error",
      };
    });
};

export const getSpDocumentsByURL = async (spLink) => {
  if (spLink.length == 0) return [];
  return await axios
    .get(`/sp/getDocByUrl/${spLink}`, {
      // responseType: 'blob',
      headers: {
        // Accept: "application/json"
      },
    })
    .then((response) => {
      if (response.status == 200) {
        return response?.data;
      }
      return [];
    })
    .catch((err) => {
      console.error(err);
      return [];
    });
};

export const getSpDocumentsByID = async (spID) => {
  if (spID.length == 0) return [];
  return await axios
    .get(`/sp/getDocById/${spID}`)
    .then((response) => {
      if (response.status == 200) {
        var base64str = response.data;
        //convert base64 to blob
        var binary = atob(base64str.replace(/\s/g, ""));
        var len = binary.length;
        var buffer = new ArrayBuffer(len);
        var raw = new Uint8Array(buffer);
        for (var i = 0; i < len; i++) {
          raw[i] = binary.charCodeAt(i);
        }
        return raw;
      }
      return [];
    })
    .catch((err) => {
      console.error(err);
      return [];
    });
};

export const getProcess = async (guid) => {
  if (guid.length == 0) return null;
  return await axios
    .get(`/processes/${guid}`, {
      headers: {
        "auth-token": authToken,
        "user-id": userId,
        database: database,
      },
    })
    .then((response) => {
      if (response.status == 200) {
        return response?.data;
      }
      return [];
    })
    .catch((err) => {
      console.error(err);
      return [];
    });
};

export const getAttributeMap = async () => {
  return await axios
    .get(`/admin/attributeMap`, {
      headers: {
        "auth-token": authToken,
        "user-id": userId,
        database: database,
      },
    })
    .then((response) => {
      if (response.status == 200) {
        return response?.data;
      }
      return [];
    })
    .catch((err) => {
      console.error(err);
      return [];
    });
};

export const getProcessAttributesByPartialName = async (name) => {
  return await axios
    .get(`/processes/attributes/${name}`, {
      headers: {
        "auth-token": authToken,
        "user-id": userId,
        database: database,
      },
    })
    .then((response) => {
      if (response.status == 200) {
        return response?.data;
      }
      return [];
    })
    .catch((err) => {
      console.error(err);
      return [];
    });
};

export const getAdvanceSearchFilter = async () => {
  return await axios
    .get("/admin/advanceSearchFilter", {
      headers: {
        "auth-token": authToken,
        "user-id": userId,
        database: database,
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      if (response.data?.success) {
        return response.data.content;
      }
      return {
        success: false,
        msg: response.data.msg,
      };
    })
    .catch((err) => {
      console.error(err);
      return {
        success: false,
        msg: err?.response?.data?.msg || "Internal Server Error",
      };
    });
};

export const updateAdvanceSearchFilter = async (input) => {
  return await axios
    .post("/admin/advanceSearchFilter", [...input], {
      headers: {
        "auth-token": authToken,
        "user-id": userId,
        database: database,
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      if (response.data?.success) {
        return response.data;
      }
      return {
        success: false,
        msg: response.data.msg,
      };
    })
    .catch((err) => {
      console.error(err);
      return {
        success: false,
        msg: err?.response?.data?.msg || "Internal Server Error",
      };
    });
};

//for relase notes update excel file
export const updateReleaseNotes = async (input) => {
  return await axios
    .post("/admin/releaseNotes", [...input], {
      headers: {
        "auth-token": authToken,
        "user-id": userId,
        database: database,
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      if (response.data?.success) {
        return response.data;
      }
      return {
        success: false,
        msg: response.data.msg,
      };
    })
    .catch((err) => {
      console.error(err);
      return {
        success: false,
        msg: err?.response?.data?.msg || "Internal Server Error",
      };
    });
};

//get Relase notes from neo
export const getReleaseNotes = async () => {
  return await axios
    .get("/admin/releaseNotes", {
      headers: {
        // "auth-token": authToken,
        "user-id": userId,
        database: database,
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      if (response.data?.success) {
        return response.data.content;
      }
      return {
        success: false,
        msg: response.data.msg,
      };
    })
    .catch((err) => {
      console.error(err);
      return {
        success: false,
        msg: err?.response?.data?.msg || "Internal Server Error",
      };
    });
};

//get content Relase notes from neo
export const getContentReleaseNotes = async () => {
  return await axios
    .get("/admin/getContentReleaseNotes", {
      headers: {
        "auth-token": authToken,
        "user-id": userId,
        database: database,
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      if (response.data?.success) {
        return response.data.content;
      }
      return {
        success: false,
        msg: response.data.msg,
      };
    })
    .catch((err) => {
      console.error(err);
      return {
        success: false,
        msg: err?.response?.data?.msg || "Internal Server Error",
      };
    });
};

export const initAdvanceSearchFilter = async () => {
  return await axios
    .post(
      "/admin/advanceSearchFilter?init=true",
      {},
      {
        headers: {
          // "auth-token": authToken,
          "user-id": userId,
          database: database,
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      if (response.data?.success) {
        return response.data.content;
      }
      return {
        success: false,
        msg: response.data.msg,
      };
    })
    .catch((err) => {
      console.error(err);
      return {
        success: false,
        msg: err?.response?.data?.msg || "Internal Server Error",
      };
    });
};

export const connectDb = async () => {
  return await axios
    .post(
      "/neoPublic/connect-db",
      {
        // ---old cred
        // uri: "neo4j+s://2895a571.databases.neo4j.io",
        // username: "neo4j",
        // password: "Q6TVBonVz4TLzqCfCOwhfugZ5Hcp9bK4VzathIZ",

        // ---new cred
        uri: "neo4j+s://3b54fbc4.databases.neo4j.io",
        username: "neo4j",
        password: "XMR3dvF9PRO0h5dAt7fEax2lqBw4Sbb8Gt-ScqkitI8"
      },
      {
        headers: {
          // "auth-token": authToken,
          "user-id": userId,
          database: database,
          // database: 'neo4j',
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      console.info(response.data);
      if (response.data.success) {
        return response.data;
      }
      return {
        success: false,
        msg: response.data.msg,
      };
    })
    .catch((err) => {
      console.error(err);
      return {
        success: false,
        msg: err?.response?.data?.msg || "Internal Server Error",
      };
    });
};
//   url = "neo4j://85.215.57.154:7687";
// user = "neo4j";
// pass = "bpExperts123!";
export const connectInitialDb = async () => {
  return await axios
    .post(
      "/neoPublic/connect-db",
      {
        uri: "neo4j://85.215.57.154:7687",
        username: "neo4j",
        password: "bpExperts123!",
      },
      {
        headers: {
          // "auth-token": authToken,
          "user-id": userId,
          database: database,
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      console.info(response.data);
      if (response.data.success) {
        return response.data;
      }
      return {
        success: false,
        msg: response.data.msg,
      };
    })
    .catch((err) => {
      console.error(err);
      return {
        success: false,
        msg: err?.response?.data?.msg || "Internal Server Error",
      };
    });
};

export const getRootGUID = async () => {
  return await axios
    .get("/admin/rootguid", {
      headers: {
        "auth-token": authToken,
        "user-id": userId,
        database: database,
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      if (response.data?.success) {
        return response.data.content;
      }
      return {
        success: false,
        msg: response.data.msg,
      };
    })
    .catch((err) => {
      console.error(err);
      return {
        success: false,
        msg: err?.response?.data?.msg || "Internal Server Error",
      };
    });
};


export const updateMyFavorites = async (guid) => {

  return await axios
    .post(`/admin/myFavorites/${guid}`, {}, {
      headers: {
        "auth-token": authToken,
        "user-id": userId,
        database: database,
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      if (response.data?.success) {
        return response.data;
      }
      return {
        success: false,
        msg: response.data.msg,
      };
    })
    .catch((err) => {
      console.error(err);
      return {
        success: false,
        msg: err?.response?.data?.msg || "Internal Server Error",
      };
    });
};

export const removeMyFavorites = async (guid) => {

  return await axios
    .post(`/admin/removeMyFavorites/${guid}`, {}, {
      headers: {
        "auth-token": authToken,
        "user-id": userId,
        database: database,
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      if (response.data?.success) {
        return response.data;
      }
      return {
        success: false,
        msg: response.data.msg,
      };
    })
    .catch((err) => {
      console.error(err);
      return {
        success: false,
        msg: err?.response?.data?.msg || "Internal Server Error",
      };
    });
};

export const getMyFavorites = async () => {
  return await axios
    .get("/admin/getMyFavorites", {
      headers: {
        "auth-token": authToken,
        "user-id": userId,
        database: database,
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      if (response.data?.success) {
        return response.data.content;
      }
      return {
        success: false,
        msg: response.data.msg,
      };
    })
    .catch((err) => {
      console.error(err);
      return {
        success: false,
        msg: err?.response?.data?.msg || "Internal Server Error",
      };
    });
};

export const getSurvey= async (guid) => {
    if (guid?.length == 0) return [];
    return await axios
      .get(`/processes/getSurvey/${guid}`, {
        headers: {
          "auth-token": authToken,
          "user-id": userId,
          database: database,
        },
      })
      .then((response) => {
        if (response.status == 200) {
          return response?.data;
        }
        return [];
      })
      .catch((err) => {
        console.error(err);
        return [];
      });
};
  