import React, { useState, useEffect } from "react";
import axios from "axios";
import { DataGrid } from "@mui/x-data-grid";
import { updateReleaseNotes, get } from "../../../actions";
import { Checkbox, Table, TableBody } from "@mui/material";
import { styled } from "@mui/material/styles";

import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import Alert from "@mui/material/Alert";

import Dropzone from "react-dropzone";
import * as XLSX from "xlsx";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    fontSize: "14px",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: "16px",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const ReleaseNotesUpdate = () => {
  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState([]);
  const [open, setOpen] = useState(false); // Snackbar state
  const [relaseNotesContent, setRelaseNotesContent] = useState([]); // relase notes for Neo4j
  const [content, setContent] = useState({
    severity: "success",
    msg: "Successfully updated Release Notes!",
  }); // ALERT Message

  const handleFile = (files) => {
    const file = files[0];

    const fileExtension = file.name.split(".").pop();
    if (fileExtension !== "xlsx") {
      // Show Snackbar
      setContent({
        severity: "error",
        msg: "Only Excel files are allowed.",
      });
      setOpen(true);
      return;
    }

    const reader = new FileReader();
    reader.onload = async (e) => {
      const data = e.target.result;
      const workbook = XLSX.read(data, {
        type: "binary",
        cellDates: true,
      });

      const wsname = workbook.SheetNames[1];
      const ws = workbook.Sheets[wsname];

      /* Convert array of arrays */
      const jsonData = XLSX.utils.sheet_to_json(ws, { header: 1 });

      const cols = jsonData[1];
      //Remove the first column from table 'ID'
      cols.shift();

      const rowData = jsonData.slice(2);
      //remove first element of each row which is 'ID'
      Object.keys(rowData).forEach((key) => {
        if (Array.isArray(rowData[key])) {
          rowData[key].shift();
        }
      });

      // Format JavaScript Date objects into desired date string
      rowData.forEach((row) => {
        row.forEach((cell, i) => {
          if (cell instanceof Date) {
            // Here you can format the date as you wish. For example, as MM/DD/YYYY
            row[i] = cell.toLocaleDateString("en-DE");
          }
        });
      });
      setRelaseNotesContent([[cols]]);
      setRelaseNotesContent((relaseNotesContent) => [
        ...relaseNotesContent,
        rowData,
      ]);

      setColumns(cols);
      setRows(rowData);
    };
    reader.readAsBinaryString(file);
    setOpen(true);
  };

  //push in neo
  useEffect(() => {
    const updateNotes = async () => {
      try {
        let response = await updateReleaseNotes(relaseNotesContent);
        setContent({
          severity: response.success ? "success" : "error",
          msg: response.msg,
        });
      } catch (error) {
        console.error(error);
      }
    };

    if (relaseNotesContent.length > 0) {
      updateNotes();
    }
  }, [relaseNotesContent]);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  return (
    <div>
      <h5>Upload here recent Release Notes Excel file </h5>
      <Dropzone style={{}} onDrop={handleFile}>
        {({ getRootProps, getInputProps }) => (
          <div {...getRootProps()}>
            <input {...getInputProps()} />
            <button
              style={{ width: "100%", height: "70px", marginBottom: "2vh" }}
            >
              Drag & drop Release Notes (Excel file) here, or click to select
              file
            </button>
          </div>
        )}
      </Dropzone>
      <div>
        <TableContainer component={Paper}>
          <Table size="small">
            <TableHead>
              <TableRow>
                {columns.map((col, index) => (
                  <StyledTableCell align="center" key={index}>
                    {col}
                  </StyledTableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row, index) => (
                <TableRow key={index}>
                  {row.map((cell, i) => (
                    <TableCell key={i}>{cell}</TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        {/* Snackbar component */}
        <Snackbar open={open} autoHideDuration={5000} onClose={handleClose}>
          <Alert
            onClose={handleClose}
            severity={content.severity}
            elevation={6}
            variant="filled"
          >
            {content.msg}
          </Alert>
        </Snackbar>
      </div>
    </div>
  );
};

export default ReleaseNotesUpdate;
