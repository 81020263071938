import React, { Component } from "react";
import { Form, Button, Card, Alert } from "react-bootstrap";
import { Link } from "react-router-dom";
import axios from "axios";

class ForgetPassword extends Component {
  constructor(props) {
    super();
    this.state = {
      msg: "",
      internalServerError: "",
      success: false,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }
  handleChange(event) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  handleSubmit(event) {
    event.preventDefault();
    const { email } = this.state;
    axios(
      "/neoPublic/forgot-password",
      {
        method: "POST",
        data: {
          email: email.toLowerCase(), //always convert email into lower case
        },
      },
      { withCredentials: true }
    )
      .then((response) => {
        this.setState({
          msg: response.data.msg,
          success: response.data.success,
        });
      })
      .catch(() => {
        this.setState({
          internalServerError:
            "Internal server problem, please try again later",
        });
      });
  }

  render() {
    return (
      <>
        <Card>
          <Card.Body>
            <h3 className="text-center mb-4">Account Recovery</h3>
            <Form onSubmit={this.handleSubmit}>
              {this.state.internalServerError && (
                <Alert variant="danger" align="center">
                  {this.state.internalServerError}
                </Alert>
              )}
              {this.state.msg && this.state.success == true ? (
                <Alert variant="success" align="center">
                  {this.state.msg}
                </Alert>
              ) : (
                this.state.msg && (
                  <Alert variant="danger" align="center">
                    {this.state.msg}
                  </Alert>
                )
              )}
              <Form.Group id="email">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  name="email"
                  type="email"
                  required
                  placeholder="Email"
                  onChange={this.handleChange}
                ></Form.Control>
              </Form.Group>
              <Button className="w-100" type="submit">
                Request New Password
              </Button>
            </Form>
            <div
              className="w-100 text-center mt-3"
              style={{
                textAlign: "justify",
                textDecoration: "underline",
                fontWeight: "bold",
                fontStyle: "italic",
                textUnderlinePosition: "under",
              }}
            >
              <Link to="/login">Login</Link>
            </div>
          </Card.Body>
        </Card>
        <div className="w-100 text-center mt-2">
          Want a free trial of Business Flows? {""}
          <Link
            style={{
              textAlign: "justify",
              textDecoration: "underline",
              fontWeight: "bold",
              fontStyle: "italic",
              textUnderlinePosition: "under",
            }}
            to="/signup"
          >
            Sign Up
          </Link>
        </div>
      </>
    );
  }
}

export default ForgetPassword;
