import React, { useState } from "react";
import ParseBPMN from "./Parse-BPMN";

const VisualizeSolMan = () => {
  let fileReader;
  const [bpmnData, setBpmnData] = useState("");

  const handleFileRead = (e) => {
    const content = fileReader.result;
    setBpmnData(content);
  };

  const handleFileChosen = (file) => {
    fileReader = new FileReader();
    fileReader.onloadend = handleFileRead;
    fileReader.readAsText(file);
  };

  return (
    <>
      <div
        className="upload-bpmn-file"
        style={{
          width: "25%",
          margin: "10px auto ",
        }}
      >
        <label
          className="btn btn-primary"
          style={{
            width: "400px",
          }}
        >
          Open SAP BPMN diagram from local file system
          <input
            style={{
              margin: "10px auto ",
            }}
            type="file"
            id="file"
            className="input-file"
            accept=".bpmn"
            onChange={(e) => handleFileChosen(e.target.files[0])}
          />
        </label>
      </div>
      <ParseBPMN bpmnData={bpmnData} />
    </>
  );
};

export default VisualizeSolMan;
