import React from "react";
import { Redirect } from "react-router-dom";

const Protected = (props) => {
  const Cmp = props.cmp;
  var auth = localStorage.getItem("auth");
  return (
    <div>
      {auth !== "undefined" && auth !== null ? (
        <Cmp />
      ) : (
        <Redirect to="/login"></Redirect>
      )}
    </div>
  );
};

export default Protected;
