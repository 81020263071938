import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./components/App";
import { BrowserRouter } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.js";
import axios from "axios";
import config from "./config";

const host = config.host  , backendPort=config.backendPort , frontendPort = config.frontendPort

if (!host || !backendPort || !frontendPort) {
  throw("Please maintain the environment variables");
}

axios.defaults.baseURL = host + backendPort;
axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";

ReactDOM.render(
  <>
    <BrowserRouter>
      <App url={host + backendPort} 
        
      />
    </BrowserRouter>
  </>,
  document.getElementById("root")
);
