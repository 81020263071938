import React, { useState, useEffect } from "react";
import Home from "./Home";
import Navbar from "../components/Navbar";
import Register from "./login/Register";
import Login from "./login/Login";
import { Route, Switch, useHistory } from "react-router-dom";
import { Container } from "react-bootstrap";
import ForgetPassword from "./login/ForgetPassword";
import ChangePassword from "./login/ChangePassword";
import Protected from "./login/Protected";
import AdminTabPanel from "./login/admin/AdminTabPanel";
import Footer from "./Footer";
import ConfirmationMsg from "./login/ConfirmationMsg";
import FailureMsg from "./login/FailureMsg";
import VisualizeSolMan from "./SAP-SolMan/Visualize-SolMan";
import SPDocService from "./login/SPDocService";
import MyAccount from "./login/MyAccount";
import { LinkedInPopUp } from "react-linkedin-login-oauth2";
import ResetPasswordMsg from "./login/ResetPasswordMsg";
import RelationshipExplorer from "./relationshipExplorer";
import Chat from "./bfChatBot/Chat";
import CustomD3Tree from "./customD3Tree";
import ModelRenderer from "./modelRenderer";
import RelaseNotes from "./ReleaseNotes";
import SurveyForm from "./survey/SurveyForm";


import {
  getE2ETree,
  getProcessLibraryTree,
  getProfile,
  getBlueprintsTree,
  getWorkPackagesTree,
  getRootGUID,
  connectDb,
  connectInitialDb
} from "../actions";
import SplashScreen from "../screens/SplashScreen";
import NotFoundPage from "./notFoundPage/404";
import AdvanceSearch from "./advanceSearch/AdvanceSearch";
import ExternalLinkMessage from "./externalLinkMSG/ExternalLInkMessage";
//pipe-line test
var CryptoJS = require("crypto-js");
const APP_VERSION = '4.3.34';
const App = (props) => {
  const history = useHistory();

  const [integrationAPIToken, setIntegrationAPIToken] = useState("");
  const [nodeDatum, setNodeDatum] = useState(null);

  const authToken = localStorage.getItem("auth");

  const [loading, setLoading] = useState(true);
  const [e2eMapTree, setE2eMapTree] = useState(null);
  const [processLibraryTree, setProcessLibraryTree] = useState(null);
  const [workPackagesTree, setWorkPackagesTree] = useState(null);
  const [blueprintsTree, setBlueprintsTree] = useState(null);
  const [rootGUID, setRootGUID] = useState(null);

  const [user, setUser] = useState({
    id: "",
    firstName: "",
    lastName: "",
    email: "",
    subscription: "",
    expirationDate: "",
    role: "",
    expired: "",
    databases: [],
    organisations: [],
    mainDatabase: "",
  });

  useEffect(() => {
    const currentVersion = localStorage.getItem('appVersion');
    if (currentVersion !== APP_VERSION) {
      localStorage.clear(); // Clears all data in localStorage
      localStorage.setItem('appVersion', APP_VERSION); // Set the new version
    }
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const mainDb = localStorage.getItem("db");
        if (mainDb === "neo4j") {
          const response = await connectDb();
        } else {
          const response = await connectInitialDb();
        }

        // ReactGA.initialize("G-XCK3MC5WGS");
        // ReactGA.pageview(window.location.pathname + window.location.search);

        if ((authToken && authToken.length > 1) || authToken !== null) {
          let res = await getProfile(authToken);

          if (res.success) {
            const userRes = res.content;
            setUser(userRes);

            const mainDb = localStorage.getItem("db");
            if (mainDb) {
              setUser({ ...userRes, mainDatabase: mainDb });
            } else {
              setUser({ ...userRes });
              localStorage.setItem("db", userRes.mainDatabase);
              history.push(`/home/${userRes.mainDatabase}`);
              history.go(0);
            }

            const e2eTree = await getE2ETree();
            const rootGUID = await getRootGUID();
            const libraryTree = await getProcessLibraryTree();
            const blueprintsTree = await getBlueprintsTree();
            const workPackagesTree = await getWorkPackagesTree();
            setE2eMapTree(e2eTree);
            setProcessLibraryTree(libraryTree);
            setBlueprintsTree(blueprintsTree);
            setWorkPackagesTree(workPackagesTree);
            setRootGUID(rootGUID);

            /**e2eTree and libraryTree values must be encrypted with AES before storage, needs to be decrypted and consumed in custom BPMN rendering JS class */

            var e2eTreeCipher = CryptoJS.AES.encrypt(
              JSON.stringify(e2eTree),
              "my-secret-key@123"
            ).toString();
            var libraryTreeCipher = CryptoJS.AES.encrypt(
              JSON.stringify(libraryTree),
              "my-secret-key@123"
            ).toString();
            var blueprintsTreeCipher = CryptoJS.AES.encrypt(
              JSON.stringify(blueprintsTree),
              "my-secret-key@123"
            ).toString();
            var workPackagesTreeCipher = CryptoJS.AES.encrypt(
              JSON.stringify(workPackagesTree),
              "my-secret-key@123"
            ).toString();
            var subscriptionCipher = CryptoJS.AES.encrypt(
              userRes?.subscription,
              "my-secret-key@123"
            ).toString();

            localStorage.setItem("e2eTree", e2eTreeCipher);
            localStorage.setItem("libraryTree", libraryTreeCipher);
            localStorage.setItem("blueprintsTree", blueprintsTreeCipher);
            localStorage.setItem("workPackagesTree", workPackagesTreeCipher);

            localStorage.setItem("subscription", subscriptionCipher);
            localStorage.setItem("userId", userRes.id);

            /**Eror handle needs to be added if e2eTree, libraryTree was not successfully fetched */
            setLoading(false);
          } else {
            localStorage.clear();
            history.push("/login");
            history.go(0);
          }
        } else {
          // history.push("/login");
        }
      } catch (err) {
        console.error("Error occured while initializing", err);
      }
    };

    fetchData();
  }, []);
  if (loading && authToken && authToken != undefined) return <SplashScreen />;

  return (
    <div
      className="containerMain"
      style={{ overflow: "hidden", height: "100%" }}
    >
      {/*-------------------------------------------------------------------- */
      /*                     if the user is signed-in  */
      /* -------------------------------------------------------------------- */}
      <Navbar
        e2eMap={e2eMapTree}
        processLibrary={processLibraryTree}
        workPackages={workPackagesTree}
        blueprints={blueprintsTree}
        userEmail={user.email}
        userRole={user.role}
        setNodeDatum={setNodeDatum}
        user={user}
        path={`/${user.mainDatabase}`}
        rootGUID={rootGUID}
      />

      {authToken ? (
        <Switch>
          <Route
            exact
            path="/my-account"
            component={() => <MyAccount user={user} />}
          />
          <Route
            exact
            path="/sap-solman"
            component={() => <VisualizeSolMan />}
          />
          <Route
            exact
            path="/admin"
            component={() => <AdminTabPanel userRole={user.role} user={user} />}
          />
          <Route
            exact
            path="/admin/:id"
            component={() => <AdminTabPanel userRole={user.role} user={user} />}
          />
          <Route
            exact
            path={`/home`}
            component={() => (
              <Home
                user={user}
                setUser={setUser}
                path={`/${user.mainDatabase}`}
                e2eMap={e2eMapTree}
                rootGUID={rootGUID}
              />
            )}
          />
          <Route
            exact
            path={`/home/:orgName`}
            component={() => (
              <Home
                user={user}
                setUser={setUser}
                path={`/${user.mainDatabase}`}
                e2eMap={e2eMapTree}
                rootGUID={rootGUID}
              />
            )}
          />

          <Route exact path="/:dbName/object/:id">
            <Protected
              cmp={() => (
                <CustomD3Tree
                  path={`/${user.mainDatabase}`}
                  nodeDatum={nodeDatum}
                  e2eMap={e2eMapTree}
                  processLibrary={processLibraryTree}
                  user={user}
                  rootGUID={rootGUID}
                />
              )}
            />
          </Route>

          <Route exact path="/:dbName/survey/:id">
            <Protected
              cmp={() => (
                <SurveyForm
                  path={`/${user.mainDatabase}`}
                  user={user}
                  {...props}

                />
              )}
            />
          </Route>

          <Route exact path="/:dbName/object">
            <Protected
              cmp={() => (
                <CustomD3Tree
                  path={`/${user.mainDatabase}`}
                  nodeDatum={nodeDatum}
                  e2eMap={e2eMapTree}
                  processLibrary={processLibraryTree}
                  user={user}
                  rootGUID={rootGUID}
                />
              )}
            />
          </Route>

          <Route exact path="/relationship-explorer/:id">
            <Protected cmp={() => <RelationshipExplorer user={user} />} />
          </Route>

          <Route exact path="/bf-assistant">
            <Protected cmp={() => <Chat user={user}
              {...props} />} />
          </Route>

          <Route exact path="/changepassword">
            <Protected cmp={() => <ChangePassword user={user} />} />
          </Route>

          <Route
            exact
            path="/:dbName/model/:id"
            component={() => (
              <ModelRenderer
                e2eMap={e2eMapTree}
                processLibrary={processLibraryTree}
                workPackages={workPackagesTree}
                blueprints={blueprintsTree}
                integrationAPIToken={integrationAPIToken}
                showProperties={true}
                path={`/${user.mainDatabase}`}
                width="45vw"
                height="80vh"
                propertyDivHeight="80vh"
                user={user}
                {...props}
                setNodeDatum={setNodeDatum}
              />
            )}
          />
          <Route
            exact
            path="/:dbName/advance-search"
            component={() => (
              <AdvanceSearch
                {...props}
                setNodeDatum={setNodeDatum}
                path={`/${user.mainDatabase}`}
                user={user}
              />
            )}
          ></Route>

          <Route
            exact
            path="/:dbName/external-link/*"
            component={ExternalLinkMessage}
          ></Route>
          <Route exact path="/release-notes" component={RelaseNotes} />

          {/* -------------------------------------------------------------------- */}
          {/* if the user is signed-in for the given below routes navigate to Home */}
          {/* -------------------------------------------------------------------- */}

          <Route
            exact
            path="/signup"
            component={() => <Home user={user} e2eMap={e2eMapTree} />}
          />
          <Route
            exact
            path="/signup/*"
            component={() => <Home user={user} e2eMap={e2eMapTree} />}
          />
          <Route
            exact
            path="/login"
            component={() => <Home user={user} e2eMap={e2eMapTree} />}
          />
          <Route
            exact
            path="/login/:token"
            component={() => <Home user={user} e2eMap={e2eMapTree} />}
          />
          <Route
            exact
            path="/success"
            component={() => <Home user={user} e2eMap={e2eMapTree} />}
          />
          <Route
            exact
            path="/error"
            component={() => <Home user={user} e2eMap={e2eMapTree} />}
          />
          <Route
            exact
            path="/linkedin"
            component={() => <Home user={user} e2eMap={e2eMapTree} />}
          />
          <Route exact path="/release-notes" component={RelaseNotes} />
          <Route
            exact
            path="/:dbName/not-found"
            component={() => (
              <NotFoundPage
                {...props}
                path={`/${user.mainDatabase}`}
                history={history}
              />
            )}
          />

          {/* if route does not exists show 404*/}
          <Route
            exact
            path="*"
            component={() => (
              <Home
                user={user}
                setUser={setUser}
                path={`/${user.mainDatabase}`}
                e2eMap={e2eMapTree}
              />
            )}
          />
        </Switch>
      ) : (
        /* -------------------------------------------------------------------- */
        /* if the user is NOT signed-in  */
        /* -------------------------------------------------------------------- */
        <div>
          <Container
            className="d-flex align-items-center justify-content-center"
            style={{ minHeight: "100vh" }}
          >
            <div className="w-100" style={{ maxWidth: "600px" }}>
              <Switch>
                <Route exact path="/" component={Login} />
                <Route exact path="/login" component={Login} />
                <Route exact path="/login/:token" component={Login} />
                <Route exact path="/signup/" component={Register} />
                <Route exact path="/signup/:regid" component={Register} />
                <Route exact path="/success" component={ConfirmationMsg} />
                <Route exact path="/error" component={FailureMsg} />
                {/* intermediate route for linkenIN */}
                <Route exact path="/linkedin" component={LinkedInPopUp} />
                <Route exact path="/changepassword" component={Login} />
                <Route exact path="/:dbName/model/:id" component={Login} />
                <Route exact path="/:dbName/object/:id" component={Login} />
                <Route exact path="/:dbName/survey/:id" component={Login} />
                <Route exact path="/home" component={Login} />
                {/* before below 2 routes were after </Switch>  i put it under switch 
                beacuse for NotFoundPage route it necessary it should be in the end */}
                <Route
                  exact
                  path="/forget-password"
                  component={ForgetPassword}
                />
                <Route
                  exact
                  path="/getDocument/:url"
                  component={SPDocService}
                />
                <Route exact path="/release-notes" component={RelaseNotes} />
                <Route
                  exact
                  path="/auth/reset-password/:token"
                  component={ResetPasswordMsg}
                />

                {/* if route does not exists show 404*/}
                <Route
                  exact
                  path="*"
                  component={() => (
                    <NotFoundPage
                      {...props}
                      path={`/${user.mainDatabase}`}
                      history={history}
                    />
                  )}
                />
              </Switch>
            </div>
          </Container>
        </div>
      )}

      <Footer />
    </div>
  );
};

export default App;
//test pipeline