import React, { useEffect, useState } from "react";
import Modeler from "bpmn-js/lib/Modeler";
import "bpmn-js/dist/assets/diagram-js.css";
import "bpmn-js/dist/assets/bpmn-font/css/bpmn-embedded.css";

const container = document.getElementById("container");

const parseBPMN = (props) => {
  if (props.bpmnData.length > 0) {
    const modeler = new Modeler({
      container,
      keyboard: {
        bindTo: document,
      },
    });
    modeler
      .importXML(props.bpmnData)
      .then(({ warnings }) => {
        var eventBus = modeler.get("eventBus");
        if (warnings.length) {
          console.warn("Warnings", warnings);
        }

        const canvas = modeler.get("modeling");
        canvas.setColor("CalmCustomerTask", {
          stroke: "green",
          fill: "yellow",
        });
      })
      .catch((err) => {
        console.error("error", err);
      });
  }
  return (
    <>
      <div
        id="container"
        style={{
          border: "1px solid #000000",
          height: "200vh",
          width: "90vw",
          margin: "auto",
        }}
      ></div>
    </>
  );
};
export default parseBPMN;
