import React, { useState, useEffect } from "react";

import {
  getAdvanceSearchFilter,
  initAdvanceSearchFilter,
  updateAdvanceSearchFilter,
} from "../../../actions";
import {
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";

import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

const AdvanceSearchFilter = () => {
  const [data, setData] = useState([]); //state for API
  const [checkedItems, setCheckedItems] = useState(null); //state for cheboxes
  const [open, setOpen] = useState(false); // Snackbar state
  const [content, setContent] = useState({
    severity: "success",
    msg: "successfully changed!",
  }); // ALERT Message

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    // Make an API request to fetch data
    let data = await getAdvanceSearchFilter();
    const initialCheckedState = data.map((item) => item.display);

    setData(data);
    setCheckedItems(initialCheckedState);
  };

  const handleCheckboxChange = async (index) => {
    const newCheckedItems = [...checkedItems];
    newCheckedItems[index] = !newCheckedItems[index];
    setCheckedItems(newCheckedItems);

    // Make an API request to update data on the server
    const updatedData = [...data];
    updatedData[index].display = newCheckedItems[index];
    let response = await updateAdvanceSearchFilter(updatedData);
    // Show Snackbar
    setContent({
      severity: response.success ? "success" : "error",
      msg: response.msg,
    });
    handleClick(response);
  };
  // Snackbar functions
  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  if (checkedItems === null) {
    return <div>Loading...</div>; // You can replace this with a proper loading component
  }
  return (
    <div>
      <h5>Filter the object types to display on the advance search</h5>
      <button onClick={async () => await initAdvanceSearchFilter()}>
        Synchronize
      </button>
      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Allow</TableCell>
              <TableCell>Object Type</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((item, index) => (
              <TableRow key={index}>
                <TableCell>
                  <Checkbox
                    checked={checkedItems[index] || false}
                    onChange={() => handleCheckboxChange(index)}
                  />
                </TableCell>
                <TableCell>{item.name}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {/* Snackbar component */}
      <Snackbar open={open} autoHideDuration={3000} onClose={handleClose}>
        <MuiAlert
          onClose={handleClose}
          severity={content.severity}
          elevation={6}
          variant="outlined"
        >
          {content.msg}
        </MuiAlert>
      </Snackbar>
    </div>
  );
};

export default AdvanceSearchFilter;
