import React from "react";
import { FaInfoCircle } from "react-icons/fa";

class ExternalLinkMessage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      seconds: 2,
      url: window.location.href.split("external-link/")[1],
    };
    this.timer();
  }

  onStart = () => {
    this.setState({ seconds: this.state.seconds - 1 });
    if (this.state.seconds == 0) {
      window.location.replace(this.state.url);
    }
  };
  timer = () => {
    this.f = setInterval(this.onStart, 1000);
  };

  render() {
    return (
      <div
        className="align-items-center justify-content-center "
        style={{ minHeight: "93vh", textAlign: "center" }}
      >
        <h1 style={{ marginTop: "4vh", fontSize: "190px", color: "goldenrod" }}>
          <FaInfoCircle />
        </h1>
        <p className="fs-3" style={{ fontSize: "30px" }}>
          {" "}
          You are being redirected to external link.
        </p>
        <p className="lead" style={{ fontSize: "25px" }}>
          Which is on another platform than Bussiness Flow Browser.
        </p>
        <p
          className="btn"
          style={{
            marginTop: "3vh",
            fontSize: "30px",
            backgroundColor: "#d6d6d6",
          }}
        >
          <a style={{ textDecoration: "none" }} href={this.state.url}>
            &nbsp;&nbsp;Continue : {this.state.seconds}&nbsp;&nbsp;
          </a>
        </p>
      </div>
    );
  }
}
export default ExternalLinkMessage;
