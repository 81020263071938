import React, { Component, useState } from "react";
import ReactDOM, { render } from "react-dom";
import { GraphData } from "./graphData";


export default function Legion(){

  //const [colorCodes, setColorCode] = useState( GraphData.colorCodes)
  const configData = getConfig();

  function getConfig() {
    const graphData = GraphData.populateGraphData();

    const depth = 2;
    const legionName = ["Relation", "Node", "Total"];
    const colorCodes =  ["smokewhite", "black", "red"]
    const config = [];
    for (let i = 0; i <= depth; i++) {
      config.push({
        name: legionName[i],
        style: {
          backgroundColor: colorCodes[i]
            ? colorCodes[i]
            : "000000",
        },
      });
    }
    return config;
  }

    return (
      <div className="legion-wrapper">
        <ul>
          {configData.map((config) => (
            <li>
              {config.name == "Relation" ?
              // if relation add line
              <span style={{ fontWeight:"bold"}} className="color-palette">_______</span>
            :<span style={config.style} className="color-palette"></span>}
              <span>{config.name}</span>
            </li>
          ))}
        </ul>
      </div>
    );


}


