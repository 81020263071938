import React, { useEffect, useState } from "react";
import { useHistory, Link } from "react-router-dom";
import "bpmn-js/dist/assets/diagram-js.css";
import "bpmn-js/dist/assets/bpmn-font/css/bpmn-embedded.css";
import {
  sortByProperty,
  searchByGUID,
  getShortName,
} from "../utils/accessMapFunctions";
import ReactBpmn from "./CustomBPMN";
import { useLocation } from "react-router";
import { getProcessBPMN, getProcessSVG, getSpDocumentsByID } from "../actions";
import { uuidv4 } from "../utils/utilities";
import ClassicModel from "./modelRenderer/ClassicModel";
import { getProcessAttributesByPartialName, getSurvey } from "../actions";
import AttributesPanel from "./AttributesPanel";
import { func } from "prop-types";
import { FcSurvey, FcAddressBook, FcViewDetails } from "react-icons/fc";
import axios from "axios";
// Models for wich diable buttons for zoom in/out, anjust and expand.
/***
 * n.Type
"BusinessFlows-Root"
"E2E-Map"
"E2E-Domain"
"E2E-Scenario Cluster"
"E2E-Scenario"
"BPMN"
"Business Process"
"Functional Process Library"
"Process Cluster"
"Process Group"
"SAP Scope Item"
"Work Package"
null
"Document"
"Document Category"
"SVG"
"Value Flow Posting"
"Business Capability Library"
"Blueprint Map"
"Blueprint"
"Work Package Library"
"Project Stream WP-Group"
"Business Capability (L1)"
"Business Capability (L2)"
"Business Capability (L3)"
"Business Capability (L4)"
"Business Capability (L5)"
"APQC_METRIC"
"Document Folder"
"Value Flow Library"
"Value Flow Group"
 */

const classicModels = [
  "E2E-Domain",
  "Process Group",
  "Process Cluster",
  "E2E-Scenario Cluster",
  "Blueprint Map",
  "Work Package Library",
];

export default function CustomizedBPMN(props) {
  const history = useHistory();
  const location = useLocation();
  const [occurences, setOccurences] = useState([]);
  const [relationships, setRelationships] = useState([]);
  const [modelType, setModelType] = useState("");
  const [modelName, setModelName] = useState("");
  const [modelDescription, setModelDescription] = useState("");
  const [modelGuid, setModelGuid] = useState("");
  const [propertiesAttribute, setPropertiesAttribute] = useState({});
  const [attributes, setAttributes] = useState(null);

  const [email, setEmail] = useState("");
  const [showInput, setShowInput] = useState(false);
  const [survey, setSurvey] = useState(false);

  const handleSendInvitation = () => {
    const modelGUID = location.pathname.split("/")[3];
    const db = localStorage.getItem("db")
    if (!email) {
      alert("Please enter the email address!");
    } else {
    setShowInput(false);
   
    // // Add logic to send the invitation here

    axios(
      "/neoPublic/send-survey",
      {
        method: "POST",
        data: {
          email: email.toLowerCase(), //always convert email into lower case
          guid: modelGUID,
          db :db,
        },
      },
      { withCredentials: true }
    )
      .then((response) => {
        this.setState({
          msg: response.data.msg,
          success: response.data.success,
        });
      
      })
      .catch(() => {
        this.setState({
          internalServerError:
            "Internal server problem, please try again later",
        });
      });
      alert("Survey Invitation sent to: " + email);
        setEmail("");
    }
  };

  const getModelAttributes = async () => {
    const modelGUID = location.pathname.split("/")[3];
    let modelAttr = [];
    searchByGUID(
      modelGUID,
      [
        props.e2eMap,
        props.processLibrary,
        props.workPackages,
        props.blueprints,
      ],
      1,
      modelAttr
    );
    setModelGuid(modelGUID);
    if (modelAttr.length > 0) {
      setModelType(modelAttr[0]?.Type);
      setModelName(modelAttr[0]?.name);
      setModelDescription(modelAttr[0]?.description || "");
      setPropertiesAttribute(modelAttr[0]);
      let attr = await getProcessAttributesByPartialName(modelAttr[0]?.guid);
      setAttributes(attr);
    }
    // if no information about the model or modekl not found push to home
    else {
      //history.push(`${props.path}/not-found`);
      history.push(`${props.path}/object/${modelGUID}`);
    }

    // check if survey is linked to model
    let resp = await getSurvey(modelGUID);
    try {
      if (resp.content.length > 0) {
        setSurvey(true);
      } else {
        setSurvey(false);
      }
    } catch (error) {
      setSurvey(false);
    }
  };

  //getModelAttributes on URL change to chose the URL renderer
  useEffect(() => {
    getModelAttributes();
  }, [location]);

  useEffect(() => {
    let isMounted = true;
    if (isMounted)
      if (occurences.length > 0) {
        setOccurences(occurences.sort(sortByProperty("name")));
      }
    if (relationships.length > 0) {
      setRelationships(relationships.sort(sortByProperty("name")));
    }

    return () => (isMounted = false);
  }, [occurences, relationships]);

  function openFullscreen() {
    const elem = document.getElementById("container");
    if (elem) {
      elem.requestFullscreen();
    } else if (elem) {
      /* Safari */
      elem.webkitRequestFullscreen();
    } else if (elem) {
      /* IE11 */
      elem.msRequestFullscreen();
    }
  }

  async function downloadSVG() {
    const guid = location.pathname.split("/")[3];

    const svgData = await getProcessSVG(guid);
    let modelName = svgData?.name;
    let svgContent = svgData?.content;

    try {
      var filename = `${modelName}.svg`;
      var pom = document.createElement("a");
      var bb = new Blob([svgContent], { type: "text/svg" });
      pom.setAttribute("href", window.URL.createObjectURL(bb));
      pom.setAttribute("download", filename);
      pom.dataset.downloadurl = ["text/plain", pom.download, pom.href].join(
        ":"
      );
      pom.draggable = true;
      pom.classList.add("dragout");
      pom.click();
    } catch (e) {
      console.error(e);
    }
  }

  async function downloadSpDocument(link) {
    if (link.includes("https://teams.microsoft.com/l/file")) {
      const fileFormat = link.split("Type=")[1].split("&")[0];
      const fileNameRaw = link.split("." + fileFormat)[0].split("%2F");
      const fileName = decodeURI(fileNameRaw[fileNameRaw.length - 1]);
      const teamsDocID = link
        .split("?")[0]
        .split("https://teams.microsoft.com/l/file/")[1];
      const spData = await getSpDocumentsByID(teamsDocID);
      //---------------------
      // file name and file type store in variables "fileName","fileFormat"
      // we recive the api raw data in variable "spData"
      //---------------------
      try {
        const url = window.URL.createObjectURL(
          new Blob([spData], { type: "application/pdf" })
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName + "." + fileFormat); //or any other extension
        document.body.appendChild(link);
        link.click();
      } catch (e) {
        console.error(e);
      }
    }
    if (link.includes("https://bpexperts.sharepoint.com/sites/")) {
      //share point links are not valid some links it naviagte to folder
      // and some doesnot contain id and path of file
      // we can fetch the file using id e.g. "762368B9-E78F-4D74-A3EB-32B5DBA5AD38" 4 dash(-) and 32 chracters
      // or we can fetch using server relative url e.g. "sites/BusinessFlows954/Freigegebene%20Dokumente/L2O%20PM.pdf"
    }
  }

  async function downloadBPMN() {
    const guid = location.pathname.split("/")[3];

    const svgData = await getProcessBPMN(guid);
    let modelName = svgData?.name;
    let svgContent = svgData?.content;

    try {
      var filename = `${modelName}.bpmn`;
      var pom = document.createElement("a");
      var bb = new Blob([svgContent], { type: "text/bpmn" });
      pom.setAttribute("href", window.URL.createObjectURL(bb));
      pom.setAttribute("download", filename);
      pom.dataset.downloadurl = ["text/plain", pom.download, pom.href].join(
        ":"
      );
      pom.draggable = true;
      pom.classList.add("dragout");
      pom.click();
    } catch (e) {
      console.error(e);
    }
  }

  //click on Occurences from property pannel
  function handleButtonClickOccurences(route, occGUID) {
    const { history } = this.props;
    history.push(`/${route}/${occGUID}`);
  }
  return (
    <>
      <div
        className="outer-div"
        data-testid="tp-1"
        style={{ display: "flex", flexDirection: "row-reverse" }}
      >
        {/** TOP BAR */}
        <div
          className="disableSelection"
          style={{
            border: "2px solid #000000",
            marginRight: "5px",
            backgroundColor: "#dddddd",
          }}
        >
          {/* For Ultimate-Plus user show dropdown with SVG and BPMN 2.0 Download Options */}
          {props.user.subscription === "ultimatePlus" ? (
            <span>
              <svg
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                style={{
                  cursor:
                    props.user.subscription == "ultimatePlus"
                      ? "pointer"
                      : "not-allowed",
                  width: "40px",
                  height: "25px",
                }}
                width="16"
                height="16"
                fill={
                  props.user.subscription == "ultimatePlus" ? "#000000" : "grey"
                }
                className="bi bi-save"
                viewBox="0 0 16 16"
              >
                <title>Download</title>
                <path d="M2 1a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H9.5a1 1 0 0 0-1 1v7.293l2.646-2.647a.5.5 0 0 1 .708.708l-3.5 3.5a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L7.5 9.293V2a2 2 0 0 1 2-2H14a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h2.5a.5.5 0 0 1 0 1H2z" />
              </svg>
              {props.user.subscription === "ultimatePlus" ? (
                <div
                  className="dropdown-menu"
                  aria-labelledby="dropdownMenuButton"
                >
                  <div
                    className="dropdown-item"
                    onClick={
                      props.user.subscription == "ultimatePlus"
                        ? downloadBPMN
                        : null
                    }
                  >
                    <label>Download as BPMN 2.0</label>
                  </div>
                  <div
                    className="dropdown-item"
                    onClick={
                      props.user.subscription == "ultimatePlus"
                        ? downloadSVG
                        : null
                    }
                  >
                    <label>Download as SVG</label>
                  </div>
                </div>
              ) : (
                <span></span>
              )}
            </span>
          ) : (
            // Not a Ultimate-Plus User (disable download dropdown)
            <svg
              style={{
                cursor:
                  props.user.subscription == "ultimatePlus"
                    ? "pointer"
                    : "not-allowed",
                width: "40px",
                height: "25px",
              }}
              width="16"
              height="16"
              fill={
                props.user.subscription == "ultimatePlus" ? "#000000" : "grey"
              }
              className="bi bi-save"
              viewBox="0 0 16 16"
            >
              <title>Download as BPMN 2.0 file</title>
              <path d="M2 1a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H9.5a1 1 0 0 0-1 1v7.293l2.646-2.647a.5.5 0 0 1 .708.708l-3.5 3.5a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L7.5 9.293V2a2 2 0 0 1 2-2H14a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h2.5a.5.5 0 0 1 0 1H2z" />
            </svg>
          )}
          |
          <svg
            // onClick={history.goBack}
            onClick={() => {
              history.goBack();
            }}
            style={{ cursor: "pointer", width: "40px", height: "25px" }}
            width="16"
            height="16"
            fill="currentColor"
            className="bi bi-arrow-left"
            viewBox="0 0 16 16"
          >
            <title>Click to go back</title>
            <path
              fillRule="evenodd"
              d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"
            />
          </svg>
          |
          {props.showProperties ? (
            <>
              <svg
                // onClick={history.goForward}
                onClick={() => {
                  history.goForward();
                }}
                style={{ cursor: "pointer", width: "40px", height: "25px" }}
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-arrow-right"
                viewBox="0 0 16 16"
              >
                <title>Click to go forward</title>
                <path
                  fillRule="evenodd"
                  d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"
                />
              </svg>
              |
            </>
          ) : (
            <></>
          )}
          <svg
            style={
              classicModels.includes(modelType)
                ? {
                    pointerEvents: "none",
                    cursor: "pointer",
                    width: "40px",
                    height: "25px",
                    color: "gray",
                  }
                : { cursor: "pointer", width: "40px", height: "25px" }
            }
            width="16"
            height="16"
            fill="currentColor"
            className="bi bi-zoom-in"
            id="btnZoomIn"
            viewBox="0 0 16 16"
          >
            <title>zoom in</title>
            <path
              fillRule="evenodd"
              d="M6.5 12a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11zM13 6.5a6.5 6.5 0 1 1-13 0 6.5 6.5 0 0 1 13 0z"
            />
            <path d="M10.344 11.742c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1 6.538 6.538 0 0 1-1.398 1.4z" />
            <path
              fillRule="evenodd"
              d="M6.5 3a.5.5 0 0 1 .5.5V6h2.5a.5.5 0 0 1 0 1H7v2.5a.5.5 0 0 1-1 0V7H3.5a.5.5 0 0 1 0-1H6V3.5a.5.5 0 0 1 .5-.5z"
            />
          </svg>
          |
          <svg
            style={
              classicModels.includes(modelType)
                ? {
                    pointerEvents: "none",
                    cursor: "pointer",
                    width: "40px",
                    height: "25px",
                    color: "gray",
                  }
                : { cursor: "pointer", width: "40px", height: "25px" }
            }
            width="16"
            height="16"
            fill="currentColor"
            className="bi bi-zoom-out"
            id="btnZoomOut"
            viewBox="0 0 16 16"
          >
            <title>zoom out</title>
            <path
              fillRule="evenodd"
              d="M6.5 12a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11zM13 6.5a6.5 6.5 0 1 1-13 0 6.5 6.5 0 0 1 13 0z"
            />
            <path d="M10.344 11.742c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1 6.538 6.538 0 0 1-1.398 1.4z" />
            <path
              fillRule="evenodd"
              d="M3 6.5a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 0 1h-6a.5.5 0 0 1-.5-.5z"
            />
          </svg>
          |
          <img
            alt=""
            title="fit to window"
            src="data:image/svg+xml;base64,PHN2ZyBoZWlnaHQ9IjUxMnB0IiB2aWV3Qm94PSIwIDAgNTEyIDUxMiIgd2lkdGg9IjUxMnB0IiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPjxwYXRoIGQ9Im0xNDUuOTY4NzUgNDIzLjI0NjA5NGgtMzYuNzA3MDMxbDg0LjY4NzUtODQuMzc1LTIxLjE3MTg3NS0yMS4yNTM5MDYtODQuNDc2NTYzIDg0LjE2NDA2MnYtMzYuMjAzMTI1aC0zMHY4Ny42Njc5NjloODcuNjY3OTY5em0wIDAiLz48cGF0aCBkPSJtMzY2LjAzMTI1IDQyMy4yNDYwOTR2MzBoODcuNjY3OTY5di04Ny42Njc5NjloLTMwdjM2LjIwMzEyNWwtODQuNDc2NTYzLTg0LjE2NDA2Mi0yMS4xNzE4NzUgMjEuMjUzOTA2IDg0LjY4NzUgODQuMzc1em0wIDAiLz48cGF0aCBkPSJtODguMzAwNzgxIDExMC4yMTg3NSA4NC40NzY1NjMgODQuMTY0MDYyIDIxLjE3MTg3NS0yMS4yNTM5MDYtODQuNjg3NS04NC4zNzVoMzYuNzA3MDMxdi0zMGgtODcuNjY3OTY5djg3LjY2Nzk2OWgzMHptMCAwIi8+PHBhdGggZD0ibTMzOS4yMjI2NTYgMTk0LjM4MjgxMiA4NC40NzY1NjMtODQuMTY0MDYydjM2LjIwMzEyNWgzMHYtODcuNjY3OTY5aC04Ny42Njc5Njl2MzBoMzYuNzA3MDMxbC04NC42ODc1IDg0LjM3NXptMCAwIi8+PHBhdGggZD0ibTAgMHY1MTJoNTEydi01MTJ6bTQ4MiA0ODJoLTQ1MnYtNDUyaDQ1MnptMCAwIi8+PC9zdmc+"
            style={
              classicModels.includes(modelType)
                ? {
                    pointerEvents: "none",
                    cursor: "pointer",
                    width: "40px",
                    height: "25px",
                    filter: "invert(0.5) hue-rotate(239deg)",
                    marginTop: "5px",
                    marginBottom: "5px",
                    marginRight: "5px",
                  }
                : {
                    cursor: "pointer",
                    width: "40px",
                    height: "25px",
                    marginTop: "5px",
                    marginBottom: "5px",
                    marginRight: "5px",
                  }
            }
            id="btnZoomReset"
            width="16"
            height="16"
            fill="currentColor"
            className="bi bi-fullscreen"
            viewBox="0 0 16 16"
            type="button"
          ></img>
          |
          <svg
            onClick={openFullscreen}
            style={{
              cursor: "pointer",
              width: "40px",
              height: "25px",
              marginTop: "5px",
              marginBottom: "5px",
              marginRight: "5px",
            }}
            version="1.1"
            id="Layer_1"
            x="0px"
            y="0px"
            viewBox="0 0 512 512"
            enableBackground="new 0 0 512 512"
            type="button"
          >
            <title>full screen view</title>
            <path
              d="M93.1,139.6l46.5-46.5L93.1,46.5L139.6,0H0v139.6l46.5-46.5L93.1,139.6z M93.1,372.4l-46.5,46.5L0,372.4V512h139.6
	l-46.5-46.5l46.5-46.5L93.1,372.4z M372.4,139.6H139.6v232.7h232.7V139.6z M325.8,325.8H186.2V186.2h139.6V325.8z M372.4,0
	l46.5,46.5l-46.5,46.5l46.5,46.5l46.5-46.5l46.5,46.5V0H372.4z M418.9,372.4l-46.5,46.5l46.5,46.5L372.4,512H512V372.4l-46.5,46.5
	L418.9,372.4z"
            />
          </svg>
          |
          <svg
            style={{
              width: "40px",
              height: "25px",
              marginTop: "2px",
              marginTop: "5px",
              marginBottom: "5px",
              marginRight: "5px",
            }}
            type="button"
            width="16"
            height="16"
            fill="currentColor"
            className="bi bi-info-square"
            viewBox="0 0 16 16"
            onClick={() => {
              const node = {
                id: modelGuid,
                guid: modelGuid,
                shortName: getShortName(modelName),
                fullName: modelName,
                type: modelType,
                description: modelDescription,
                children: [],
                isLink: false,
                style: {
                  background: "#FFFFFF",
                },
              };
              props.setNodeDatum(node);
              history.push(`${props.path}/object/${modelGuid}`);
            }}
          >
            <title>Open in relationship explorer</title>
            <path
              fillRule="evenodd"
              d="M6 3.5A1.5 1.5 0 0 1 7.5 2h1A1.5 1.5 0 0 1 10 3.5v1A1.5 1.5 0 0 1 8.5 6v1H14a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-1 0V8h-5v.5a.5.5 0 0 1-1 0V8h-5v.5a.5.5 0 0 1-1 0v-1A.5.5 0 0 1 2 7h5.5V6A1.5 1.5 0 0 1 6 4.5v-1zM8.5 5a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1zM0 11.5A1.5 1.5 0 0 1 1.5 10h1A1.5 1.5 0 0 1 4 11.5v1A1.5 1.5 0 0 1 2.5 14h-1A1.5 1.5 0 0 1 0 12.5v-1zm1.5-.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1zm4.5.5A1.5 1.5 0 0 1 7.5 10h1a1.5 1.5 0 0 1 1.5 1.5v1A1.5 1.5 0 0 1 8.5 14h-1A1.5 1.5 0 0 1 6 12.5v-1zm1.5-.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1zm4.5.5a1.5 1.5 0 0 1 1.5-1.5h1a1.5 1.5 0 0 1 1.5 1.5v1a1.5 1.5 0 0 1-1.5 1.5h-1a1.5 1.5 0 0 1-1.5-1.5v-1zm1.5-.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1z"
            />
          </svg>
          {survey ? (
            <span>
              |
              <svg
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                style={{ width: "40px", height: "25px" }}
                className="bi bi-save"
                viewBox="0 0 16 16"
              >
                <title>{"Survey"}</title>
                |
                <FcSurvey />
              </svg>
              <div
                className="dropdown-menu"
                aria-labelledby="dropdownMenuButton"
              >
                <div
                  className="dropdown-item"
                  // onClick={() => setShowInput(!showInput)} // Toggle input visibility
                >
                  <FcAddressBook
                    style={{
                      width: "40px",
                      height: "25px",
                    }}
                  />
                  <label>Send Survey Invitation</label>
                  {!showInput && (
                    <div className="dropdown-item">
                      <input
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder="Enter email address"
                        className="form-control"
                        style={{ marginTop: "5px" }}
                      />
                      <button
                        type="button"
                        onClick={handleSendInvitation}
                        className="btn btn-primary mt-2"
                      >
                        Submit
                      </button>
                    </div>
                  )}
                </div>

                <div
                  type="button"
                  style={{ marginTop: "5px" }}
                  className="dropdown-item"
                  onClick={() =>
                    history.push(`${props.path}/survey/${modelGuid}`)
                  }
                >
                  <FcViewDetails
                    style={{
                      width: "40px",
                      height: "25px",
                    }}
                  />
                  <label>Start Survey</label>
                </div>
              </div>
            </span>
          ) : (
            <></>
          )}
        </div>
      </div>

      <div
        id="model-view"
        className="outer-div "
        style={{ display: "flex", marginTop: "5px" }}
      >
        {/** LOADING SPINNER */}
        <div className="App" style={{ flex: 1 }}>
          {true ? null : (
            <div className="text-center">
              <div
                style={{ marginBottom: "-375px", marginTop: "-375px" }}
                className="spinner-border"
                role="status"
              ></div>
            </div>
          )}

          {/* BPMN Container **/}

          {classicModels.includes(modelType) ? (
            <ClassicModel
              {...props}
              updateOccurences={(occs) => setOccurences(occs)}
              updateRelationships={(rels) => setRelationships(rels)}
              setPropertiesAttribute={setPropertiesAttribute}
              setAttributes={setAttributes}
              attributes={attributes}
            />
          ) : (
            <ReactBpmn
              modelType={modelType}
              apiToken={props.integrationAPIToken}
              guid={location.pathname.split("/")[3]}
              updateOccurences={(occs) => setOccurences(occs)}
              updateRelationships={(rels) => setRelationships(rels)}
              modelName={modelName}
              {...props}
              setPropertiesAttribute={setPropertiesAttribute}
              setAttributes={setAttributes}
              attributes={attributes}
            />
          )}
        </div>

        {/** PROPERTY PANEL */}
        {props.showProperties && modelType !== "Process" ? (
          <div
            className="panel-group"
            style={{
              flex: 1,
              position: "relative",
              left: "390px",
              bottom: "72px",
            }}
          >
            <div className="panel panel-default">
              <div className="panel-heading">
                <h4 className="panel-title"></h4>
              </div>
              <div id="collapse1" className="collapseDiv">
                <div>
                  <h5
                    style={{
                      color: "#222",
                      fontWeight: "bold",
                    }}
                  >
                    Properties
                  </h5>
                </div>

                <ul className="nav nav-pills">
                  <li
                    style={{
                      fontStyle: "italic",
                      marginRight: "10px",
                    }}
                  >
                    <a className="active" data-toggle="tab" href="#attribute">
                      Attributes
                    </a>
                  </li>
                  |
                  <li
                    style={{
                      fontStyle: "italic",
                      marginLeft: "10px",
                    }}
                  >
                    <a data-toggle="tab" href="#occurence">
                      Occurrences &nbsp;
                    </a>
                  </li>
                  |
                  <li
                    style={{
                      fontStyle: "italic",
                      marginLeft: "10px",
                    }}
                  >
                    <a data-toggle="tab" href="#relationship">
                      Relationships
                    </a>
                  </li>
                </ul>

                <div
                  className="tab-content"
                  style={{
                    border: "2px solid #000000",
                    marginRight: "15px",
                    overflow: "scroll",
                    width: "25vw",
                    height: props.propertyDivHeight,
                  }}
                >
                  {attributes ? (
                    <AttributesPanel attributes={attributes} />
                  ) : (
                    <div>Loading...</div>
                  )}

                  <div id="occurence" className="tab-pane fade">
                    <div className="table-responsive-xl">
                      <ul className="list-group">
                        <li className="list-group-item">
                          <table className="table">
                            <thead>
                              <tr>
                                <th className="attribute-name" scope="col">
                                  Object Occurences
                                </th>
                              </tr>
                            </thead>
                          </table>
                          {
                            <div>
                              {occurences.map((occ) => (
                                <div key={uuidv4()}>
                                  <div
                                    style={{
                                      textDecoration: "underline",
                                      textUnderlinePosition: "under",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => {
                                      history.push(
                                        `${props.path}/model/${occ.GUID}`
                                      );
                                    }}
                                  >
                                    {" "}
                                    {occ.name}{" "}
                                  </div>
                                  <div
                                    key={"t" + occ.GUID}
                                    style={{
                                      fontSize: "1.3vh",
                                      marginTop: "1vh",
                                    }}
                                  >
                                    {occ.Type}
                                  </div>
                                  <hr
                                    style={{
                                      width: "50",
                                      textAlign: "left",
                                      marginLeft: "0",
                                    }}
                                  />
                                </div>
                              ))}
                            </div>
                          }
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div id="relationship" className="tab-pane fade">
                    <div className="table-responsive-xl">
                      <ul className="list-group">
                        <li className="list-group-item">
                          <table className="table">
                            <thead>
                              <tr>
                                <th className="attribute-name" scope="col">
                                  Object Relationships
                                </th>
                              </tr>
                            </thead>
                          </table>
                          {
                            <div>
                              {relationships.map((rel) => (
                                <div key={uuidv4()}>
                                  <div
                                    style={{
                                      textDecoration: "underline",
                                      textUnderlinePosition: "under",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => {
                                      if (
                                        [
                                          "E2E-Scenario",
                                          "Business Process",
                                          "E2E-Map",
                                          "Functional Process Map",
                                          "Blueprint",
                                          "Work Package",
                                          "E2E-Scenario Cluster",
                                          "E2E-Scenario",
                                          "E2E-Domain",
                                          "Process Group",
                                          "Project Stream WP-Group",
                                          "Work Package Library",
                                        ].indexOf(rel.Type) >= 0
                                      ) {
                                        history.push(
                                          `${props.path}/model/${rel.GUID}`
                                        );
                                      } else if (
                                        rel.Type === "SAP Scope Item"
                                      ) {
                                        const win = window.open(
                                          `${props.path}/external-link/${rel.URL}`,
                                          "_blank"
                                        );
                                        win.focus();
                                        // history.push(
                                        //   `${props.path}/external-link/${rel.URL}`
                                        // );
                                      } else {
                                        const node = {
                                          id: rel.GUID,
                                          guid: rel.GUID,
                                          shortName: getShortName(rel.name),
                                          fullName: rel.name,
                                          type: rel.Type,
                                          children: [],
                                          isLink: false,
                                          style: {
                                            background: "#FFFFFF",
                                          },
                                        };

                                        const win = window.open(
                                          `${props.path}/object/${rel.GUID}`,
                                          "_blank"
                                        );
                                        win.focus();

                                        // history.push(
                                        //   `${props.path}/object/${rel.GUID}`
                                        // );
                                        props.setNodeDatum(node);
                                      }

                                      // history.go();
                                    }}
                                  >
                                    {" "}
                                    {rel.name}{" "}
                                  </div>
                                  <div
                                    key={"t" + rel.GUID}
                                    style={{
                                      fontSize: "1.3vh",
                                      marginTop: "1vh",
                                    }}
                                  >
                                    {rel.Type}
                                  </div>
                                  <hr
                                    style={{
                                      width: "50",
                                      textAlign: "left",
                                      marginLeft: "0",
                                    }}
                                  />
                                </div>
                              ))}
                            </div>
                          }
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </>
  );
}
