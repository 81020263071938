import { type } from "jquery";

export function checkAccess(data, GUID, subscription) {
  if (subscription === "ultimate" || subscription == "ultimatePlus")
    return true;
  return data.some(function (e) {
    if (
      e.hasOwnProperty("Process_GUID") &&
      e["Process_GUID"] === GUID &&
      e.hasOwnProperty("IsDrill")
    )
      return true;
    else if (e.Children) return checkAccess(e.Children, GUID, subscription);
  });
}

export function verifyAccess(data, GUID, subscription) {
  if (subscription === "ultimate" || subscription === "ultimatePlus")
    return true;
  return data.some(function (e) {
    if (
      e.hasOwnProperty("GUID") &&
      e["GUID"] === GUID &&
      e.hasOwnProperty("inTrial")
    )
      return true;
    else if (e.Children) return verifyAccess(e.Children, GUID, subscription);
  });
}

export function searchJSONByName(varToSearch, jsonData, maxResults, results) {
  for (var key in jsonData) {
    if (typeof jsonData[key] === "object") {
      searchJSONByName(varToSearch, jsonData[key], maxResults, results);
    } else {
      if (jsonData[key].toLowerCase().includes(varToSearch.toLowerCase())) {
        if (
          results.length < maxResults &&
          !results.some((object) => object["name"] === jsonData["name"])
        ) {
          results.push({ ...jsonData });
          return results;
        }
      }
    }
  }
}

export function searchText(varToSearch, jsonData, maxResults, results) {
  for (var key in jsonData) {
    if (typeof jsonData[key] === "object") {
      searchText(varToSearch, jsonData[key], maxResults, results);
    } else {
      if (jsonData[key].toLowerCase().includes(varToSearch.toLowerCase())) {
        if (
          results.length < maxResults &&
          !results.some((object) => object["Name"] === jsonData["Name"])
        ) {
          results.push({
            Name: jsonData["Name"],
            Process_GUID: jsonData["Process_GUID"]
              ? jsonData["Process_GUID"]
              : jsonData["GUID"],
          });
          return results;
        }
      }
    }
  }
}

export function findParentsGUID(path, obj, target) {
  for (var k in obj) {
    if (obj.hasOwnProperty(k))
      if (obj[k] === target) return [...path, obj["Process_GUID"]];
      else if (typeof obj[k] === "object") {
        var result = findParentsGUID(
          obj["Process_GUID"] ? [...path, obj["Process_GUID"]] : path,
          obj[k],
          target
        );
        if (result) return result;
      }
  }
  return false;
}

export function findLibraryParentsGUID(path, obj, target) {
  for (var k in obj) {
    if (obj.hasOwnProperty(k))
      if (obj[k] === target) return [...path, obj["GUID"]];
      else if (typeof obj[k] === "object") {
        var result = findLibraryParentsGUID(
          obj["GUID"] ? [...path, obj["GUID"]] : path,
          obj[k],
          target
        );
        if (result) return result;
      }
  }
  return false;
}

const L2Prefix = [
  "A2R",
  "ASS",
  "F2P",
  "H2R",
  "I2O",
  "I2Q",
  "L2O",
  "O2C",
  "P2D",
  "P2P",
  "Pl2",
];
const L1 = [
  "Lead to Opportunity",
  "Order to Cash",
  "After Sales and Services",
  "Forecast to Plan",
  "Procure to Pay",
  "Plan to Produce",
  "Inbound to Outbound",
  "Account to Report",
  "Inspect to Quality",
  "Project to Decommission",
  "Hire to Retire",
];
let prefixToDomain = {
  I2Q: "Inspect to Quality",
  I2O: "Inbound to Outbound",
  P2D: "Project to Decommission",
  P2P: "Plan to Produce",
  H2R: "Hire to Retire",
  A2R: "Account to Report",
  F2P: "Forecast to Plan",
  L2O: "Lead to Opportunity",
  O2C: "Order to Cash",
  ASS: "After Sales and Services",
  P2P: "Procure to Pay",
};

export function getRoute(name) {
  if (L1.includes(name)) {
    return "domain";
  }
  const prefix = name.substring(0, 3);
  if (L2Prefix.includes(prefix)) {
    return "scenario";
  }
  return "l2process";
}

export function getLevel(name) {
  //return "End-to-End";
  if (L1.includes(name)) {
    return "E2E > Domain";
  }
  const prefix = name.substring(0, 3);
  if (L2Prefix.includes(prefix)) {
    return "E2E > Scenario";
  }
  return "E2E > L2Process";
}

export function getUrlPrefix(level) {
  switch (level) {
    case "E2E > Domain":
      return "domain";
    case "E2E > Scenario":
      return "scenario";
    case "E2E > L2Process":
      return "l2process";
    default:
    // code block
  }
}

export function sortByProperty(property) {
  return function (a, b) {
    if (a[property] > b[property]) return 1;
    else if (a[property] < b[property]) return -1;

    return 0;
  };
}

export function mapScenarioToDomain(scenario) {
  const prefix = scenario.substring(0, 3);
  return prefixToDomain[prefix];
}

export function getGUID_(data, modelName) {
  let modelGUID;
  data.Children.forEach((level1) => {
    level1.Children.forEach((level2) => {
      if (level2.Type == "L2 Library" && level2.Name == modelName) {
        modelGUID = level2.GUID;
      } else {
        level2.Children.forEach((level3) => {
          if (level3.Type == "L3 Library" && level3.Name == modelName) {
            modelGUID = level3.GUID;
          }
        });
      }
    });
  });
  return modelGUID;
}

export function findNode(varToSearch, jsonData, res, obj) {
  for (var key in jsonData) {
    if (typeof jsonData[key] === "object") {
      findNode(varToSearch, jsonData[key], res);
    } else {
      if (jsonData?.id === varToSearch) {
        res.push(jsonData);
        jsonData.children.push(obj);
        return jsonData;
      }
    }
  }
}

export function searchPath(path, obj, target) {
  for (var k in obj) {
    if (obj.hasOwnProperty(k))
      if (obj[k] === target) return [...path, k];
      else if (typeof obj[k] === "object") {
        var result = searchPath([...path, k], obj[k], target);
        if (result) return result;
      }
  }
  return false;
}

export function getFirstChildArr(json) {
  {
   if(json){
    const arr = Object.values(json);
    for (var value of arr) {
      if (Array.isArray(value)) {
        return value;
      }
    }
   } 
    return [];
  }
}

export function appendNode(idToSearch, jsonData, obj) {
  for (var key in jsonData) {
    if (typeof jsonData[key] === "object") {
      appendNode(idToSearch, jsonData[key], obj);
    } else {
      if (jsonData?.id === idToSearch) {
        jsonData.children.push(obj);
        return jsonData;
      }
    }
  }
}

export function popNodes(idToSearch, jsonData, objs) {
  for (var key in jsonData) {
    if (typeof jsonData[key] === "object") {
      popNodes(idToSearch, jsonData[key], objs);
    } else {
      if (jsonData?.id === idToSearch) {
        jsonData.children = [];

        return jsonData;
      }
    }
  }
}

export function appendNodes(idToSearch, jsonData, objs) {
  for (var key in jsonData) {
    if (typeof jsonData[key] === "object") {
      appendNodes(idToSearch, jsonData[key], objs);
    } else {
      if (jsonData?.id === idToSearch) {
        jsonData.children.push(...objs);

        return jsonData;
      }
    }
  }
}

export function getShortName(name, limit_) {
  const limit = limit_ || 25;
  return name.length > limit ? name.substr(0, limit) + ".." : name;
}

export function getRelevantTab(guid, tabNames, hierarchies) {
  if (tabNames.length !== hierarchies.length) return false;
  var i = 0;
  for (var hierarchy of hierarchies) {
    if (JSON.stringify(hierarchy).indexOf(guid) > 0) {
      return tabNames[i];
    }
    i++;
  }
  return false;
}

export function getModelData(varToSearch, jsonData) {
  for (var key of jsonData) {
    if (typeof jsonData[key] === "object" && !key === "_id") {
      getModelData(varToSearch, jsonData[key]);
    } else {
      if (jsonData[key].toLowerCase().includes(varToSearch.toLowerCase())) {
        return {
          ...jsonData,
        };
      }
    }
  }
}

export function isEntryinData(entry, text) {
  try {
    return JSON.stringify(text).includes(entry);
  } catch (err) {
    console.error("error getting guid in data");
  }
}

export function searchText_(varToSearch, jsonData, maxResults, results) {
  for (var key in jsonData) {
    if (typeof jsonData[key] === "object") {
      searchText_(varToSearch, jsonData[key], maxResults, results);
    } else {
      if (
        key === "name" &&
        jsonData[key]
          .toString()
          .toLowerCase()
          .includes(varToSearch.toLowerCase())
      ) {
        if (
          results.length < maxResults &&
          !results.some((object) => object["name"] === jsonData["name"])
        ) {
          results.push({
            name: jsonData["name"],
            guid: jsonData["GUID"],
            type: jsonData["Type"],
            //description: jsonData["Description"],
            inTrial: jsonData["inTrial"],

          });
          return results;
        }
      }
    }
  }
}

export function searchByGUID(varToSearch, jsonData, maxResults, results) {
  for (var key in jsonData) {
    if (typeof jsonData[key] === "object") {
      searchByGUID(varToSearch, jsonData[key], maxResults, results);
    } else {
      if (
        key === "GUID" &&
        jsonData[key]
          .toString()
          .toLowerCase()
          .includes(varToSearch.toLowerCase())
      ) {
        if (
          results.length < maxResults &&
          !results.some((object) => object["GUID"] === jsonData["GUID"])
        ) {
          results.push({
            name: jsonData["name"],
            guid: jsonData["GUID"],
            type: jsonData["_type"],
            Type: jsonData["Type"],
            description: jsonData["Description"],
          });
          return results;
        }
      }
    }
  }
}

export function getType(varToSearch, jsonData) {
  for (var key in jsonData) {
    if (typeof jsonData[key] === "object") {
      getType(varToSearch, jsonData[key]);
    } else {
      if (
        key == "GUID" &&
        jsonData[key]
          .toString()
          .toLowerCase()
          .includes(varToSearch.toLowerCase())
      ) {
        return jsonData["Type"];
      }
    }
  }
  return false;
}

export function getAttributes(varToSearch, jsonData, maxResults, results) {
  for (var key in jsonData) {
    if (typeof jsonData[key] === "object") {
      getAttributes(varToSearch, jsonData[key], maxResults, results);
    } else {
      if (
        key == "name" &&
        jsonData[key]
          .toString()
          .toLowerCase()
          .includes(varToSearch.toLowerCase())
      ) {
        if (
          results.length < maxResults &&
          !results.some((object) => object["name"] === jsonData["name"])
        ) {
          results.push({
            name: jsonData["name"],
            guid: jsonData["GUID"],
            description: jsonData["Description"],
            advisoryNotes: jsonData["Advisory_Notes"],
            businessRules: jsonData["Business_Rules"],
            scope: jsonData["Scope"],
            outcome: jsonData["Outcome"],
            type: jsonData["Type"],
            methodology_Process_Group :jsonData["Methodology_Process_Group"],
            roles_and_Responsibilities : jsonData["Roles_and_Responsibilities"],
            purpose :jsonData["Purpose"],
            objectives :jsonData["Objectives"],
            outputs:jsonData["Outputs"],
            outcome :jsonData["Outcome"],
            challenges :jsonData["Challenges"],
            additional_Contribution :jsonData["Additional_Contribution"],
            document_Title_1 : jsonData["Document_Title_1"],
            link_1 : jsonData["Link_1"],
            link_2 : jsonData["Link_2"],
            link_3 : jsonData["Link_3"],
            link_4 : jsonData["Link_4"],
            kommentar : jsonData["Kommentar"],
            hasAssignment: jsonData["hasAssignment"],
          });
          return results;
        }
      }
    }
  }
}

export function getAttributesByKey(varToSearch, jsonData, key_, results) {
  if (!varToSearch) return [];
  for (var key in jsonData) {
    if (typeof jsonData[key] === "object") {
      getAttributesByKey(varToSearch, jsonData[key], key_, results);
    } else {
      if (
        key === key_ &&
        jsonData[key]
          .toString()
          .toLowerCase()
          .trim()
          ===(varToSearch.toLowerCase().trim())
      ) {
        if (!results.some((object) => object["name"] === jsonData["name"])) {
          results.push({
            name: jsonData["name"],
            guid: jsonData["GUID"],
            description: jsonData["Description"],
            advisoryNotes: jsonData["Advisory_Notes"],
            businessRules: jsonData["Business_Rules"],
            scope: jsonData["Scope"],
            outcome: jsonData["Outcome"],
            type: jsonData["Type"],
            inTrial: jsonData["inTrial"],
            hasAssignment: jsonData["hasAssignment"],
          });
          return results;
        }
      }
    }
  }
}

export function quickCheckAccess(inTrial, subscription) {
  return (
    subscription === "ultimate" || subscription === "ultimatePlus" || inTrial===true
  );
}
