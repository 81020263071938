import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import { FaLink } from "react-icons/fa";
import ShowMoreText from "react-show-more-text";
import e2eMapDump from "../../assets/e2eMap-dump";
import libraryDump from "../../assets/library-dump";
import { FaInfoCircle } from "react-icons/fa";
import {
  getAttributesByKey,
  isEntryinData,
} from "../../utils/accessMapFunctions";
import {
  getProcessAttributesByPartialName,
  getProcessOccurences,
  getProcessRelationships,
} from "../../actions";
class HoverComponent extends React.Component {
  constructor(props) {
    super(props);
    this.someRefName = React.createRef();
    this.state = {
      factSheetshow: true,
      element: "",
      left: "",
      top: "",
      display: "none",
    };
  }
  handleFactSheetEnter = () => {
    this.setState({ factSheetshow: true });
    // alert(JSON.stringify(this.props.nodeDatum));
  };
  handleFactSheetLeave = () => this.setState({ factSheetshow: false });
  hasasignment() {
    let attributeList = [];
    const GUID = this.props?.nodeDatum?.guid;
    if (isEntryinData(GUID, [this.props.e2emap, this.props.processLibrary])) {
      getAttributesByKey(
        GUID,
        [e2eMapDump, libraryDump],
        "GUID",
        attributeList
      );
      if (!attributeList[0]?.hasAssignment) {
        return true;
      }
    }
    return false;
  }
  async onSingleClickModel(e, props) {
    let attributes = await getProcessAttributesByPartialName(
      props?.nodeDatum.guid
    );

    props.updateAttributes(attributes);
    //show the property pannel if user click on info Icon
    if (!props.expandedPropertyPannel) props.updateExpandedPropertyPannel(true);
    //this.props.setSearchResult(true);
    // return;
    let occs = await getProcessOccurences(props?.nodeDatum.guid);
    props.updateOccurences(occs);
    let rels = await getProcessRelationships(props?.nodeDatum.guid);
    props.updateRelationships(rels);

    
    // change the color for previous selected node to default color
    let previousNode = props.previousSelectedNode;
    if (previousNode) {
      let gid = previousNode
      if (gid) {
        let foreignObject = gid.querySelector('foreignObject');
        if (foreignObject) {
          let elementalNode = foreignObject.querySelector('.elemental-node');
          if (elementalNode) {
            elementalNode.style.backgroundColor = '#FFFFFF';
          }
        }
      }
    }

    // change the color for selected node
    let gid = document.getElementById(props.nodeDatum.__rd3t.id);
    if (gid) {
      let foreignObject = gid.querySelector('foreignObject');
      if (foreignObject) {
        let elementalNode = foreignObject.querySelector('.elemental-node');
        if (elementalNode) {
          elementalNode.style.backgroundColor = '#fbf043';
          props.updatePreviousSelectedNode(gid)
        }
      }
    }
  }

  render() {
    this.state.display = "none";
    if (this.props.show || this.state.factSheetshow) {
      if (this.props?.nodeDatum?.__rd3t?.id !== null) {
        var element = document.getElementById(
          `${this.props?.nodeDatum?.__rd3t?.id}`
        );
        var bodyOffsets =
          element !== null ? element.getBoundingClientRect() : 0;
        this.state.left = bodyOffsets.x - 420;
        this.state.top = bodyOffsets.y;
      }
      this.state.display = "";
      // to avoid show mini fact sheet in top left corner
      if (isNaN(bodyOffsets.x) || isNaN(bodyOffsets.y)) {
        this.state.display = "none";
      }
    }
    return (
      <div
        onMouseEnter={this.handleFactSheetEnter}
        onMouseLeave={this.handleFactSheetLeave}
        style={{
          backgroundColor: "rgb(255, 250, 250)",
          width: "400px",
          height: "auto",
          boxShadow: "10px 5px 5px rgb(107, 99, 99)",
          display: "flex",
          alignItems: "flex-start",
          padding: "1%",
          borderRadius: "3px",
          position: "fixed",
          left: this.state.left,
          top: this.state.top,
          zIndex: 1,
          display: this.state.display,
          // display:this.props.show ? null: "none"
        }}
      >
        <div
          className="disableSelection"
          style={{
            float: "right",
            marginLeft: "0.4em",
            order: 1,
            cursor: "pointer",
            pointerEvents: "",
          }}
        >
          {this.props.nodeDatum?.url ? (
            <Link
              to={`${this.props.path}/external-link/${this.props.nodeDatum.url}`}
              target="_blank"
            >
              <FaLink />
            </Link>
          ) : (
            <></>
          )}
          <div
            style={{
              cursor: "pointer",
              pointerEvents: "auto",
            }}
            onClick={(e) => {
              this.onSingleClickModel(e, this.props);
            }}
          >
            <FaInfoCircle />
          </div>
        </div>
        <div
          style={{
            pointerEvents: this.hasasignment() ? "" : "none",
          }}
        >
          <Link
            to={`${this.props.path}/object/${this.props.nodeDatum.guid}`}
            target="_blank"
          >
            <h5
              className="factSheetFullName disableSelection"
              style={{
                margin: "0 0 .5em",
                display: "flex",
                cursor: "pointer",
              }}
            >
              {this.props.nodeDatum.fullName}
            </h5>
          </Link>
          <p
            style={{
              fontSize: 15,
              margin: 2,
              color: "rgb(111 111 111)",
              fontWeight: "bold",
            }}
          >
            {this.props.nodeDatum.type}
          </p>
          <p
            style={{
              fontSize: 9,
              margin: 1,
              color: "#606362",
              fontStyle: "oblique",
            }}
          >
            {" "}
            {this.props.nodeDatum.description ? "Description:" : ""}{" "}
          </p>
          <p
            style={{
              fontSize: 12,
              margin: 2,
              color: "#606362",
              textAlign: "-webkit-auto",
              textJustify: "inter-word",
              pointerEvents: "all",
              wordWrap: "break-word",
            }}
          >
            <ShowMoreText
              lines={4}
              more="Show more"
              less="Show less"
              onClick={this.executeOnClick}
              expanded={false}
              truncatedEndingComponent={"... "}
            >
              {this.props.nodeDatum.description}
            </ShowMoreText>
          </p>
        </div>
      </div>
    );
  }
}
export default withRouter(HoverComponent);
