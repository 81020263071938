import React from "react";
import * as BpmnJS from "bpmn-js/dist/bpmn-navigated-viewer.development.js";
import customRendererModule from "../custom";
import jQuerry from "jquery";
import {
  getAttributes,
  getAttributesByKey,
  isEntryinData,
  quickCheckAccess,
} from "../utils/accessMapFunctions";
import { withRouter } from "react-router";
import waterMarkimg from "../images/WaterMark.svg";
import {
  getProcessOccurences,
  getProcessRelationships,
  searchProcessByName,
  getProcessAttributesByPartialName,
  getProcessBPMN
} from "../actions";

class ReactBpmn extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      occurences: [],
      isMounted: true,
      bpmnView: "",
    };
    this.containerRef = React.createRef();
  }

  getOccurences = () => {
    return this.state.occurences;
  };

  async componentDidMount() {
    const { url, guid, diagramXML } = this.props;
    const container = this.containerRef.current;
    this.setState({
      bpmnView: await new BpmnJS({
        additionalModules: [customRendererModule],
        container,
        keyboard: {
          bindTo: document,
        },
      }),
    });

    this.bpmnViewer = this.state.bpmnView;
    if (this.bpmnViewer != "") {
      this?.bpmnViewer?.on("import.done", (event) => {
        const { error, warnings } = event;

        if (error) {
          return this.handleError(error);
        }
        try {
          this.bpmnViewer.get("canvas").zoom("fit-viewport", "auto");
        } catch (err) {
          console.warn("cant use zoom functionality here");
        }
        return this.handleShown(warnings);
      });
    }

    if ((url, guid)) {
      return this.fetchDiagram(url, guid);
    }

    if (diagramXML) {
      return this.displayDiagram(diagramXML);
    }
  }
  // comment the code was not working on new release
  componentWillUnmount() {
    // if (this.bpmnViewer) {
    //   this.bpmnViewer.destroy();
    // }
    // this.setState({ isMounted: false });
  }

  componentDidUpdate(prevProps, prevState) {
    const { props, state } = this;
    if (props.guid !== prevProps.guid) {
      this.fetchDiagram(props.url, props.guid);
    }

    const currentXML = props.diagramXML || state.diagramXML;

    const previousXML = prevProps.diagramXML || prevState.diagramXML;

    if (currentXML && currentXML !== previousXML) {
      return this.displayDiagram(currentXML);
    }
  }

  async displayDiagram(diagramXML) {
    const { props } = this;


    const processTrees = [
      props.e2eMap,
      props.processLibrary,
      props.blueprints,
      props.workPackages,
    ];

    await this.bpmnViewer
      .importXML(diagramXML)
      .then(({ warnings }) => {
        let canvasZoom = this.state.bpmnView.get("zoomScroll"); //Zoom in and Zoom out
        jQuerry("svg *").css({ cursor: "default" });
        this.state.bpmnView.get("zoomScroll").reset("center");
        this.state.bpmnView.get("zoomScroll").reset("center");
        if (canvasZoom) {
          jQuerry("#btnZoomIn").on("click", function () {
            canvasZoom.stepZoom(0.01);
          });
          jQuerry("#btnZoomOut").on("click", function () {
            canvasZoom.stepZoom(-0.01);
          });
          jQuerry("#btnZoomReset").on("click", function () {
            canvasZoom.reset("center");
          });
        }

        if (
          this.props.modelType == "Library" ||
          this.props.modelType == "Methodology_Library"
        ) {
          this.bpmnViewer.get("canvas").zoom("fit-viewport", "auto");
          this.bpmnViewer.get("canvas").zoom(0.5);
        }

        //always load bpmn in the center of the bpmn container
        var eventBus = this.bpmnViewer.get("eventBus");
        // Hover functionality on object level
        var priority = 10000;

        //Double click - display Redirect to sub-processes/GetProcesses component
        eventBus.on("element.dblclick", priority, function (doubleClick) {
          if (
            (doubleClick?.element?.businessObject?.name &&
              doubleClick?.element?.type == "bpmn:Task") ||
            doubleClick?.element?.type == "bpmn:SubProcess"
          ) {
            let attributeList = [];
            // IF GUID exists
            const id = doubleClick?.element?.id;
            const GUID = attributeList[0]?.id || id.substring(3, id.length);
            let objName = doubleClick?.element?.businessObject?.name;

            if (
              isEntryinData(GUID, processTrees)
            ) {
              getAttributesByKey(
                GUID,
                processTrees,
                "GUID",
                attributeList
              );

              if (
                !quickCheckAccess(
                  attributeList[0]?.inTrial,
                  props.user.subscription
                )
              )
                return false;
              if (!attributeList[0]?.hasAssignment === true) return false;

              props.history.push(`${props.path}/model/${GUID}`);
              return false;
            }

            // Search by name matching
            else if (
              objName !== undefined &&
              isEntryinData(objName, processTrees)
            ) {
              getAttributes(
                objName,
                processTrees,
                1,
                attributeList
              );
              const objGUID = attributeList[0]?.guid;

              if (
                !objGUID &&
                !attributeList[0]?.hasAssignment === true &&
                !quickCheckAccess(
                  attributeList[0]?.inTrial,
                  props.user.subscription
                )
              )
                return false;
              props.history.push(`${props.path}/model/${objGUID}`);
              return false;
            }

            return false; // will cancel event
          }
        });

        // single click
        eventBus.on("element.click", priority, async function (singleClick) {
          let attributeList = [];
          const businessObject = singleClick?.element?.businessObject;
          var modelName = "";
          try {
            modelName =
              businessObject.id?.replace(/^ID-/, "") ||
              businessObject?.name ||
              businessObject?.text ||
              businessObject?.artifacts[0]?.text;
          } catch (e) {
            console.error(
              "Error :  Artifacts for Bussiness object Does not exists"
            );
          }


          //Single click Symbol Type
          const symbolTypes = ["bpmn:Task", "bpmn:SubProcess"];
          if (symbolTypes.includes(singleClick.element.type)) {
            //if a user clicks on the bpmn task/object then fire an API call for fetching attributes
            let attributes = await getProcessAttributesByPartialName(modelName);
            props.setAttributes(attributes);
          }
          // before it was singleClick.element.businessObject.documentation
          // remove documenataion beacuse it was not show attributes for some E2E Domains eventBus.g. L2O
          if (singleClick.element.businessObject) {
            //GET GUID
            const id = singleClick?.element?.id;
            const GUID = id.substring(3, id.length);

            let occs = await getProcessOccurences(GUID);
            props.updateOccurences(occs);

            let rels = await getProcessRelationships(GUID);
            props.updateRelationships(rels);

            return false;
          }

          return false;
        });

        // on Hover
        eventBus.on("element.hover", priority, async function (mouseHover) {
          let attributeList = [];
          const businessObject = mouseHover?.element?.type;
          if (
            businessObject == "bpmn:Task" ||
            (businessObject == "bpmn:SubProcess" &&
              mouseHover?.element?.businessObject?.name)
          ) {
            jQuerry("svg *").css({ cursor: "Pointer" });
          } else {
            jQuerry("svg *").css({ cursor: "default" });
          }
        });

        if (warnings.length) {
          console.warn("Warnings", warnings);
          return false;
        }
      })
      .catch((err) => {
        console.error(err.message);
        return false;
      });
  }
  // GET Diagram
  fetchDiagram(url, guid) {
    this.handleLoading();
    const authToken = localStorage.getItem("auth");
    const database = localStorage.getItem("db")

    // if (this.props.apiToken)
    fetch(`${url}/processes/getBPMN/${guid}`, {
      headers: {
        "auth-token": authToken,
        "Access-Control-Allow-Origin": "*",
        "database" : database
      },
    })
      .then((response) => response.json())
      .then((res) => {
        if (this.state.isMounted) {
          this.setState({ diagramXML: res.content });
        }
      })
      .catch((err) => this.handleError(err));
  }

  handleLoading() {
    const { onLoading } = this.props;

    if (onLoading) {
      onLoading();
    }
  }

  handleError(err) {
    const { onError } = this.props;
    window.location.reload(false);
    if (onError) {
      onError(err);
    }
  }

  handleShown(warnings) {
    const { onShown } = this.props;

    if (onShown) {
      onShown(warnings);
    }
  }

  render() {
    return (
      <div
        className="container disableSelection"
        id="container"
        ref={this.containerRef}
        style={{
          width: this.props.modelType !== "Process" ? this.props.width : "70vw",
          border: "2px solid #000000",
          height: this.props.height,
          margin: this.props.margin,
          backgroundImage:
            this.props.user.subscription === "trial"
              ? "url(" + waterMarkimg + ")"
              : "none",
          overflow: "hidden",
          backgroundSize: "contain",
        }}
      >
        <h2
          className="objectName"
          style={{ color: "#222", fontWeight: "bold", marginTop: "3vh" }}
        >
          {this.props.modelName}
        </h2>
      </div>
    );
  }
}

export default withRouter(ReactBpmn);
