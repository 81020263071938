import React from "react";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";

export default function ConfirmationMsg() {
  return (
    <div className="container-fluid nav_bg my-3">
      <div className="d-flex flex-column d-flex justify-content-center">
        <div className="col-10 mx-auto">
          <h1 className="alert-heading" style={{ textAlign: "center" }}>
            Registration Successful!
          </h1>
          <br />
          <br />
          <div
            className="alert alert-success"
            role="alert"
            style={{ textAlign: "center" }}
          >
            <p>
              Thank you, your registration was successful.
              <br /> Please check your email for credentials.{" "}
              <Link to="/login">Click here to visit login page</Link>
            </p>
            <p className="mb-0">
              In case you have any immediate requirements or questions please
              don’t hesitate to contact us or get to know the features of
              Business Flows.
            </p>
          </div>
        </div>
        <br />
        <br />
        <div className="col text-center">
          <a href="mailto:sales@bpexperts.de?bcc=russell.gomersall@bpexperts.de&subject=Business Flows Registration">
            <Button className="w-10" type="submit">
              CONTACT SALES
            </Button>
          </a>
        </div>
      </div>
    </div>
  );
}
