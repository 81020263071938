import CustomizedBPMN from "./../CustomizedBPMN";
import React, { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";
import ProcessHierarchy from "../ProcessHierarchy/ProcessHierarchy";

const auth = localStorage.getItem("auth");

function ModelRenderer(props) {
  const [loading, setLoading] = useState(false);


  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-11 mx-auto">
          <div className="row">
            <div className="outer-div " style={{ display: "flex" }}>
              <div
                className="inner-div1 disableSelection"
                style={{
                  border: "2px solid #000000",
                  marginRight: "15px",
                  backgroundColor: "#dddddd",
                  overflow: "scroll",
                  width: "20vw",
                  marginTop: "49px",
                  scrollbarGutter: "stable",
                  scrollbarWidth: "thin",
                }}
              >
                <h5
                  className="objectName"
                  style={{
                    color: "#222",
                    position: "absolute",
                    marginLeft: "14px",
                    wordBreak: "break-word",
                    fontWeight: "bold",
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "-15px",
                    marginLeft: "15px",
                    paddingTop: "20px",
                    top: "15px",
                    left: "-16px",
                  }}
                >
                  Business Flows Hierarchy
                </h5>
                {!loading ? (
                  <ProcessHierarchy
                    e2eMap={props.e2eMap}
                    processLibrary={props.processLibrary}
                    methodologyLibrary= {props.methodologyLibrary}
                    {...props}
                  />
                ) : (
                  <div className="d-flex justify-content-center">
                    <div
                      className="spinner-border"
                      role="status"
                      style={{
                        position: "fixed",
                        top: "400px",
                      }}
                    ></div>
                  </div>
                )}
              </div>
              <div className="inner-div2 ">
                {auth === "undefined" || auth === null ? (
                  <Redirect to="/login"></Redirect>
                ) : (
                  <CustomizedBPMN
                    integrationAPIToken={props.integrationAPIToken}
                    path={props.path}
                    processGUID={props.match}
                    width={props.width}
                    height={props.height}
                    flexGrow={props.flexGrow}
                    propertyDivHeight={props.propertyDivHeight}
                    showProperties={props.showProperties}
                    {...props}
                    setNodeDatum={props.setNodeDatum}
                  />     
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default ModelRenderer;
