import React from "react";
import { NavLink } from "react-router-dom";
import { useHistory } from "react-router";
import { Dropdown } from "react-bootstrap";
const config = require("./bfChatBot/openaiConfig");

const Common = (props) => {
  const history = useHistory();
  const disableBF_FromHome = props.user.mainDatabase === "neo4j";
  const selectedDatabase = props.user.mainDatabase;
  const isDatabaseValid = config.hasOwnProperty(selectedDatabase);
  const navigate = (path) => {
    if (props.user.mainDatabase) {
      history.push(path);
      const disbaleBF = props.user.mainDatabase === "neo4j";
    }
  };

  return (
    <section id="header" className="d-flex align-items-center">
      <div className="container-fluid">
        <div className="row">
          <div className="col-10 mx-auto">
            <div className="row">
              <div className="col-md-6 pt-5 pt-lg-0 order-2 order-lg-1 d-flex justify-content-center flex-column">
                <h1>
                  {props.name}
                  <a
                    style={{
                      textDecoration: "underline",
                      cursor: "pointer",
                      fontWeight: "bold",
                      textUnderlinePosition: "under",
                    }}
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.bpexperts.de"
                    className="brand-name"
                    title="Click here to visit our website"
                  >
                    bpExperts
                  </a>
                </h1>
                <h2 className="my-3">The Business Integrator</h2>
                {/* <div className="mt-4 d-flex align-items-center"> */}
                <div className="mt-4 " style={{ display: "flex", zIndex: 1 }}>
                  <button
                    onClick={() => navigate(props.visitBF)}
                    disabled={!props.user.mainDatabase || disableBF_FromHome}
                    className="btn-get-started"
                    style={
                      !disableBF_FromHome
                        ? { cursor: "pointer", marginRight: "6px" }
                        : { cursor: "not-allowed", marginRight: "6px" }
                    }
                  >
                    {props.btnameBF}
                  </button>
                  <button
                    onClick={() => navigate(props.visitRE)}
                    disabled={!props.user.mainDatabase}
                    className="btn-get-started"
                    style={{ marginRight: "6px" }}
                  >
                    {props.btnameRE}
                  </button>
                  <button
                    onClick={() => navigate("/bf-assistant")} // Updated path for Assistant Chat
                    className="btn-get-started"
                    disabled={!isDatabaseValid}
                    //old logic
                    // disabled={
                    //   !props.user.mainDatabase ||
                    //   !props.user.databases.includes("bpexpertsinternal")
                    // }
                    // style={
                    //   props.user.databases.includes("bpexpertsinternal")
                    //     ? { cursor: "pointer" }
                    //     : { cursor: "not-allowed" }
                    // }
                  >
                    {props.btnamechatBot}
                  </button>
                  <button
                    onClick={() => navigate(props.visitAS)}
                    disabled={!props.user.mainDatabase}
                    className="btn-get-started"
                    style={{ marginLeft: "6px" }}
                  >
                    {props.btnameAdvanceSearch}
                  </button>
                </div>
              </div>
              <div className="col-lg-6 order-1 order-lg-2 header-img">
                <div
                  id="carouselExampleSlidesOnly"
                  className="carousel slide"
                  data-ride="carousel"
                  data-interval="6000"
                >
                  <div className="carousel-inner">
                    <div className="carousel-item active">
                      <img
                        className="d-block w-100"
                        src={props.slider1}
                        alt="First slide"
                      />
                    </div>
                    <div className="carousel-item">
                      <img
                        className="d-block w-100"
                        src={props.slider2}
                        alt="Second slide"
                      />
                    </div>
                    <div className="carousel-item">
                      <img
                        className="d-block w-100"
                        src={props.slider3}
                        alt="Third slide"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
  // return (
  //   <>
  //     <section id="header" className="d-flex align-items-center">
  //       <div className="container-fluid">
  //         <div className="row">
  //           <div className="col-10 mx-auto">
  //             <div className="row">
  //               <div className="col-md-6 pt-5 pt-lg-0 order-2 order-lg-1 d-flex justify-content-center flex-column">
  //                 <h1>
  //                   {props.name}{" "}
  //                   <a
  //                     style={{
  //                       textDecoration: "underline",
  //                       cursor: "pointer",
  //                       fontWeight: "bold",
  //                       textUnderlinePosition: "under",
  //                     }}
  //                     target="_blank"
  //                     rel="noopener noreferrer"
  //                     href="https://www.bpexperts.de"
  //                     className="brand-name"
  //                     title="Click here to visit our website"
  //                   >
  //                     bpExperts
  //                   </a>
  //                 </h1>
  //                 <h2 className="my-3"> The Business Integrator </h2>
  //                 <div className="mt-3">
  //                   <div
  //                     onClick={() => {
  //                       if (!!props.user.mainDatabase) {
  //                         history.push(props.visitBF);
  //                       }
  //                     }}
  //                     className={`btn-get-started ${
  //                       !props.user.mainDatabase ? "disabled" : ""
  //                     }`}
  //                     style={{
  //                       cursor: !props.user.mainDatabase
  //                         ? "not-allowed"
  //                         : "pointer",
  //                       opacity: !props.user.mainDatabase ? 0.5 : 1,
  //                     }}
  //                   >
  //                     {props.btnameBF}
  //                   </div>

  //                   <div
  //                     onClick={() => {
  //                       if (!!props.user.mainDatabase) {
  //                         history.push(props.visitRE);
  //                       }
  //                     }}
  //                     className={`btn-get-started ${
  //                       !props.user.mainDatabase ? "disabled" : ""
  //                     }`}
  //                     style={{
  //                       cursor: !props.user.mainDatabase
  //                         ? "not-allowed"
  //                         : "pointer",
  //                       opacity: !props.user.mainDatabase ? 0.5 : 1,
  //                       marginLeft: "6px",
  //                       marginTop: "5px",
  //                     }}
  //                   >
  //                     {props.btnameRE}
  //                   </div>
  //                   <div
  //                     onClick={() => {
  //                       if (!!props.user.mainDatabase) {
  //                         history.push(props.visitRE);
  //                       }
  //                     }}
  //                     className={`btn-get-started ${
  //                       !props.user.mainDatabase ? "disabled" : ""
  //                     }`}
  //                     style={{
  //                       cursor: !props.user.mainDatabase
  //                         ? "not-allowed"
  //                         : "pointer",
  //                       opacity: !props.user.mainDatabase ? 0.5 : 1,
  //                       marginLeft: "6px",
  //                       marginTop: "5px",
  //                     }}
  //                   >
  //                     {props.btnamechbtnamechatBotatBot}
  //                   </div>
  //                 </div>
  //               </div>
  //               <div className="col-lg-6 order-1 order-lg-2 header-img">
  //                 <div
  //                   id="carouselExampleSlidesOnly"
  //                   className="carousel slide"
  //                   data-ride="carousel"
  //                   data-interval="6000" //6 seconds slider chnage
  //                 >
  //                   <div className="carousel-inner">
  //                     <div className="carousel-item active">
  //                       <img
  //                         className="d-block w-100"
  //                         src={props.slider1}
  //                         alt="First slide"
  //                       />
  //                     </div>
  //                     <div className="carousel-item">
  //                       <img
  //                         className="d-block w-100"
  //                         src={props.slider2}
  //                         alt="Second slide"
  //                       />
  //                     </div>
  //                     <div className="carousel-item">
  //                       <img
  //                         className="d-block w-100"
  //                         src={props.slider3}
  //                         alt="Third slide"
  //                       />
  //                     </div>
  //                   </div>
  //                 </div>
  //               </div>
  //             </div>
  //           </div>
  //         </div>
  //       </div>
  //     </section>
  //   </>
  // );
};

export default Common;
