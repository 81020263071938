import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Form, Button, Card, Alert, Col, Row } from "react-bootstrap";
import axios from "axios";

export default class SPDocService extends Component {
  constructor(props) {
    super(props);
    this.state = {
      docUniqueID: "",
      internalServerError: "",
      sucess: false,
      loading: false,
      tokenVerificationStatus: false,
    };

    this.hnadleSubmit = this.hnadleSubmit.bind(this);

    axios(`neoPublic/verify-token`, {
      method: "GET",
      headers: {
        "auth-token": localStorage.getItem("auth"),
      },
    })
      .then((tokenVerificationRes) => {
        this.setState({
          tokenVerificationStatus: tokenVerificationRes.data.success,
        });
      })
      .catch(function (error) {
        console.error(error.response.status);
      });
  }

  hnadleSubmit(event) {
    const { docUniqueID } = this.state;
    axios(`/sp/getDocIdByUrl/sites/${window.location.search.substring(1)}`, {
      //get thye unique ID of the requested doc
      method: "GET",
      headers: {
        "auth-token": localStorage.getItem("auth"),
      },
      data: {
        docUniqueID: docUniqueID,
      },
    })
      .then((response) => {
        this.setState({
          loading: true,
          docUniqueID: response.data,
        });
        // window.open(
        //   `http://localhost:8080/_api/web/getfilebyid(%27${this.state.docUniqueID}%27)/$value`, //download the requested doc
        //   "_self"
        // );
        let win = window.open();
        win.location.href = `http://localhost:8080/_api/web/getfilebyid(%27${this.state.docUniqueID}%27)/$value`;
      })
      .catch(() => {
        this.setState({
          internalServerError:
            "Internal server problem, please try again later",
        });
      });
    event.preventDefault();
  }
  render() {
    return (
      <>
        {this.state.tokenVerificationStatus ? (
          <Card>
            <Card.Body>
              <h2 className="text-center mb-3">Download Document</h2>
              <Form onSubmit={this.hnadleSubmit}>
                {this.state.sucess ? (
                  <Alert variant="success" align="center">
                    {this.state.msg}
                  </Alert>
                ) : (
                  this.state.internalServerError && (
                    <Alert variant="danger" align="center">
                      {this.state.internalServerError}
                    </Alert>
                  )
                )}
                <Button className="w-100" type="submit">
                  Please click to download
                </Button>
              </Form>
            </Card.Body>
          </Card>
        ) : (
          <div className="w-100 text-center mt-2">
            <Alert variant="danger">
              Sorry you don't have credientials to download the requested
              document!
              <hr />
              <div className="w-100 text-center mt-2">
                <Link to="/login">
                  Please click here to sign in or to create an account and then
                  reopen the URL.
                </Link>
              </div>
            </Alert>
          </div>
        )}
      </>
    );
  }
}
