import React, { useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import Autocomplete from "@mui/material/Autocomplete";
import Chip from "@mui/material/Chip";
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";

const Organisation = () => {
  const databases = ["BpExperts", "Knauf", "Metabo"]; // Replace with actual databases

  const [groups, setGroups] = useState([
    {
      id: 1,
      name: "BpExperts",
      databaseName: "BpExperts",
      databaseURL: "neo4j://85.215.57.154:7687",
      databaseUsername: "neo4j",
      databasePassword: "BpExperts123!",
      assignedUsers: [],
    },
    {
      id: 2,
      name: "Knauf",
      databaseName: "Knauf",
      databaseURL: "neo4j://85.215.57.154:7687",
      databaseUsername: "knauf",
      databasePassword: "systemadmin",
      assignedUsers: [],
    },
    {
      id: 3,
      name: "Metabo",
      databaseName: "Metabo",
      databaseURL: "neo4j://85.215.57.154:7687",
      databaseUsername: "metabo",
      databasePassword: "systemadmin",
      assignedUsers: [],
    },
  ]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const [openDialog, setOpenDialog] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [newGroupName, setNewGroupName] = useState("");
  const [newDatabaseName, setNewDatabaseName] = useState("");
  const [newDatabaseURL, setNewDatabaseURL] = useState("");
  const [newDatabaseUsername, setNewDatabaseUsername] = useState("");
  const [newDatabasePassword, setNewDatabasePassword] = useState("");
  const [newAssignedUsers, setNewAssignedUsers] = useState([]);
  const [allUsers, setAllUsers] = useState([
    "metabo-adm",
    "knauf-adm",
    "ahmed.limame@bpexperts.de",
  ]); // Replace with actual users
  const isValidUrl = (url) => {
    const pattern = new RegExp(
      "^(https?:\\/\\/)?" + // protocol
        "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name and extension
        "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
        "(\\:\\d+)?" + // port
        "(\\/[-a-z\\d%_.~+]*)*" + // path
        "(\\?[;&amp;a-z\\d%_.~+=-]*)?" + // query string
        "(\\#[-a-z\\d_]*)?$",
      "i"
    ); // fragment locator
    return !!pattern.test(url);
  };

  const handleOpenDialog = (group) => {
    if (group) {
      setSelectedGroup(group);
      setNewGroupName(group.name);
      setNewDatabaseName(group.databaseName);
      setNewDatabaseURL(group.databaseURL);
      setNewDatabaseUsername(group.databaseUsername);
      setNewDatabasePassword(group.databasePassword);
      setNewAssignedUsers(group.assignedUsers);
    }
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedGroup(false);
    setNewGroupName("");
    setNewDatabaseName("");
    setNewDatabaseURL("");
    setNewDatabaseUsername("");
    setNewDatabasePassword("");
    setNewAssignedUsers([]);
  };

  const handleEditGroup = () => {
    if (!newGroupName) {
      setSnackbarMessage("Organization Name is required");
      setSnackbarOpen(true);
      return;
    }

    if (
      (selectedGroup &&
        (!newGroupName ||
          !newDatabaseName ||
          !newDatabaseUsername ||
          !newDatabasePassword ||
          !newDatabaseURL)) ||
      (!selectedGroup && !newGroupName)
    ) {
      setSnackbarMessage("All fields are required.");
      setSnackbarOpen(true);
      return;
    }

    if (isValidUrl(newDatabaseURL)) {
      setSnackbarMessage("Please provide a valid URL");
      setSnackbarOpen(true);
      return;
    }
    if (selectedGroup) {
      setGroups(
        groups.map((group) =>
          group.id === selectedGroup.id
            ? {
                ...group,
                name: newGroupName,
                databaseName: newDatabaseName,
                databaseURL: newDatabaseURL,
                databaseUsername: newDatabaseUsername,
                databasePassword: newDatabasePassword,
                assignedUsers: newAssignedUsers,
              }
            : group
        )
      );
    } else {
      // Create new group
      setGroups([
        ...groups,
        {
          id: Math.max(...groups.map((group) => group.id)) + 1,
          name: newGroupName,
          databaseName: newDatabaseName,
          databaseURL: newDatabaseURL,
          databaseUsername: newDatabaseUsername,
          databasePassword: newDatabasePassword,
          assignedUsers: newAssignedUsers,
        },
      ]);
    }
    setOpenDialog(false);
  };

  const columns = [
    { field: "id", headerName: "ID", width: 90 },
    {
      field: "name",
      headerName: "Name",
      width: 150,
      renderCell: (params) => (
        <div
          style={{
            cursor: "pointer",
            //color: "blue",
            textDecoration: "underline",
          }}
          onClick={() => handleOpenDialog(params.row)}
        >
          {params.value}
        </div>
      ),
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 150,
      renderCell: (params) => (
        <IconButton
          onClick={() =>
            setGroups(groups.filter((group) => group.id !== params.id))
          }
          color="error"
        >
          <DeleteIcon />
        </IconButton>
      ),
    },
  ];

  return (
    <Box sx={{ height: 400, width: "100%" }}>
      <DataGrid
        rows={groups}
        columns={columns}
        pageSize={5}
        rowsPerPageOptions={[5]}
      />
      <IconButton
        color="primary"
        aria-label="add organization"
        component="span"
        onClick={() => handleOpenDialog()}
      >
        <AddIcon />
      </IconButton>
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>
          {selectedGroup ? "Edit Organization" : "Add Organization"}
        </DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label={"Organization Name*"}
            type="text"
            fullWidth
            variant="outlined"
            value={newGroupName}
            onChange={(e) => setNewGroupName(e.target.value)}
          />
          <Autocomplete
            id="databaseName"
            options={databases}
            getOptionLabel={(option) => option}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label={`Database Name ${selectedGroup ? "*" : ""}`}
                placeholder={
                  selectedGroup
                    ? "Select database"
                    : "Default DB will be created"
                }
              />
            )}
            onChange={(event, newValue) => {
              setNewDatabaseName(newValue);
            }}
          />
          <TextField
            margin="dense"
            id="databaseURL"
            label={`Database URL ${selectedGroup ? "*" : ""}`}
            placeholder={
              selectedGroup ? "Database URL" : "Default URL will be assigned"
            }
            type="text"
            fullWidth
            variant="outlined"
            value={newDatabaseURL}
            defaultValue={selectedGroup ? newDatabaseURL : "Default"}
            onChange={(e) => setNewDatabaseURL(e.target.value)}
          />
          <TextField
            margin="dense"
            id="databaseUsername"
            label={`Database Username ${selectedGroup ? "*" : ""}`}
            placeholder={
              selectedGroup
                ? "Database Username"
                : "Default Username will be created"
            }
            type="text"
            fullWidth
            variant="outlined"
            value={newDatabaseUsername}
            onChange={(e) => setNewDatabaseUsername(e.target.value)}
          />
          <TextField
            margin="dense"
            id="databasePassword"
            label={`Database Password ${selectedGroup ? "*" : ""}`}
            placeholder={
              selectedGroup
                ? "Database Password"
                : "Default Password will be created"
            }
            type="password"
            fullWidth
            variant="outlined"
            value={newDatabasePassword}
            onChange={(e) => setNewDatabasePassword(e.target.value)}
          />
          <Autocomplete
            multiple
            id="assignedUsers"
            options={allUsers}
            getOptionLabel={(option) => option}
            defaultValue={selectedGroup ? selectedGroup.assignedUsers : []}
            filterSelectedOptions
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label="Assigned Users"
                placeholder="Select users"
              />
            )}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip
                  variant="outlined"
                  label={option}
                  {...getTagProps({ index })}
                />
              ))
            }
            onChange={(event, newValue) => {
              setNewAssignedUsers(newValue);
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Cancel</Button>
          <Button onClick={handleEditGroup}>Save</Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
      >
        <Alert
          onClose={() => setSnackbarOpen(false)}
          severity="error"
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default Organisation;
