import React from "react";
import Tree from "react-d3-tree";
import "./customTree.css";
import { useCenteredTree } from "./useCenteredTree";

// This is a simplified example of an org chart with a depth of 2.
// Note how deeper levels are defined recursively via the `children` property.
const data = {
  name: "ROOT",
  children: [
    {
      name: "E2E-Map",
      children: [
        { name: "Account to Report" },
        { name: "After Sales and Services" },
        { name: "Forecast to Plan" },
        { name: "Hire to Retire" },
        { name: "Inbound to Outbound" },
        { name: "Inspect to Quality" },
        { name: "Lead to Opportunity" },
        { name: "Order to Cash" },
        { name: "Plan to Produce" },
        { name: "Procure to Pay" },
        { name: "Project to Decommission" },
      ],
    },
    {
      name: "Functional Process Map",
      children: [
        { name: "A. Integrated Management System" },
        { name: "B. Design" },
        { name: "C. Plan" },
        { name: "C. Plan" },
        { name: "E. Make" },
        { name: "F. Store" },
        { name: "G. Sell" },
        { name: "H. Service" },
        { name: "I. Enterprise Management" },
        { name: "J. Quality Assurance + Compliance Management" },
      ],
    },
  ],
};
const containerStyles = {
  width: "100vw",
  height: "100vh",
};
const renderForeignObjectNode = ({
  nodeDatum,
  toggleNode,
  foreignObjectProps,
}) => (
  <g>
    <circle onClick={toggleNode} r={10}></circle>
    {/* `foreignObject` requires width & height to be explicitly set. */}
    <foreignObject {...foreignObjectProps}>
      <div >
        <div style={{ textAlign: "left" }}>{nodeDatum.name}</div>
        {nodeDatum.children && (
          <button style={{ width: "100%" }} onClick={toggleNode}>
            {nodeDatum.__rd3t.collapsed ? "Expand" : "Collapse"}
          </button>
        )}
      </div>
    </foreignObject>
  </g>
);

export default function RelationshipExplorer() {
  const [translate, containerRef] = useCenteredTree();
  const nodeSize = { x: 200, y: 200 };

  const foreignObjectProps = { width: nodeSize.x, height: nodeSize.y, x: 20 };

  return (
    // `<Tree />` will fill width/height of its container; in this case `#treeWrapper`.
    <div
      style={{
        borderColor: "black",
        borderWidth: "3px",
        borderBlockColor: "black",
        borderStyle: "solid",
        width: "100vw",
        height: "70vh",
      }}
      ref={containerRef}
    >
      <Tree
        rootNodeClassName="node__root"
        branchNodeClassName="node__branch"
        leafNodeClassName="node__leaf"
        //data={data}
        // collapsible={true}
        zoomable={false}
        shouldCollapseNeighborNodes={true}
        enableLegacyTransitions={true}
        translate={translate}
        separation={{ nonSiblings: 1, siblings: 1.7 }}
        nodeSize={{ x: 40, y: 40 }}
        scaleExtent={{ min: 1.1 }}
        depthFactor={200}
        initialDepth={200}
        // renderCustomNodeElement={(rd3tProps) =>
        //   renderForeignObjectNode({ ...rd3tProps, foreignObjectProps })
        // }
      />
    </div>
  );
}
