import React, { useState, useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import Tooltip from "@mui/material/Tooltip";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const AttributeGrid = ({
  attributes,
  onRowEdit,
  onDeleteAttribute,
  setDeleteChanges,
}) => {
  const [rows, setRows] = useState([]);
  const [newAttributeName, setNewAttributeName] = useState("");

  useEffect(() => {
    const updatedRows = attributes.map((attr) => ({
      ...attr,
      show: attr.whitelist,
    }));
    setRows(updatedRows);
  }, [attributes]);

  const handleShowChange = (id) => {
    const rowIndex = rows.findIndex((attr) => attr.id === id);

    if (rowIndex !== -1) {
      const updatedRows = [...rows];
      updatedRows[rowIndex] = {
        ...updatedRows[rowIndex],
        show: !updatedRows[rowIndex].show,
        whitelist: !updatedRows[rowIndex].show,
      };

      setRows(updatedRows);
      onRowEdit(updatedRows);

      // Show notification based on the checked state
      if (updatedRows[rowIndex].show) {
        toast.info(
          `Attribute '${updatedRows[rowIndex].attribute}' is checked.`
        );
      } else {
        toast.info(
          `Attribute '${updatedRows[rowIndex].attribute}' is unchecked.`
        );
      }
    }
  };

  const handleDeleteAttribute = (id, attributeName) => {
    const confirmDelete = window.confirm(
      `Are you sure you want to delete the attribute '${attributeName}'?`
    );

    if (confirmDelete) {
      const updatedRows = rows.filter((attr) => attr.id !== id);
      setRows(updatedRows);
      onDeleteAttribute(id);
      // Show success notification
      toast.success(`Attribute '${attributeName}' deleted successfully!`);
      setDeleteChanges(true);
    }
  };

  const handleAddNewAttribute = () => {
    if (!newAttributeName.trim()) {
      toast.error("Attribute name cannot be empty or contain only spaces.");
      return;
    }

    const newAttribute = {
      id: `temp-${Date.now()}`,
      attribute: newAttributeName.trim(),
      whitelist: false,
      show: false,
    };

    setRows((prevRows) => [...prevRows, newAttribute]);
    setNewAttributeName("");

    // Show success notification
    toast.success(`Attribute '${newAttributeName}' added successfully!`);
  };

  const columns = [
    {
      field: "show",
      headerName: "Always Display",
      width: 120,
      renderCell: (params) => (
        <Checkbox
          checked={params.row.whitelist}
          onChange={() => handleShowChange(params.row.id)}
        />
      ),
    },
    { field: "attribute", headerName: "Assigned  Attribute", width: 200 },
    {
      field: "actions",
      headerName: "Action",
      width: 150,
      renderCell: (params) => (
        <>
          <Tooltip title="Delete">
            <IconButton
              color="secondary"
              onClick={() =>
                handleDeleteAttribute(params.row.id, params.row.attribute)
              }
            >
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </>
      ),
    },
  ];

  return (
    <>
      <div style={{ height: "50vh", width: "100%" }}>
        <DataGrid
          rows={rows}
          columns={columns}
          pageSize={5}
          rowsPerPageOptions={[5, 10, 20]}
          disableSelectionOnClick
          checkboxSelection={false}
          key={(row) => row.id}
        />
      </div>
      <ToastContainer />
    </>
  );
};

export default AttributeGrid;
