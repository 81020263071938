import "survey-core/defaultV2.min.css";
import { Model } from "survey-core";
import { Survey } from "survey-react-ui";
import { getSurvey } from "../../actions";
import { useEffect, useState } from "react";
import { useLocation } from "react-router";
import { useHistory } from "react-router-dom";
import SplashScreen from "../../screens/SplashScreen";
function SurveyForm(props) {
  const location = useLocation();
  const history = useHistory();
  const [survey, setSurvey] = useState(null);
  const [loading, setLoading] = useState(true);

  const surveyJson = {
    title: "Attitude towards sustainability and policy",
    elements: [
      {
        type: "matrix",
        name: "sustainabilityMatrix",
        title: "Rate your agreement with the following statements",
        columns: [
          { value: "completelyAgree", text: "Completely agree" },
          { value: "agree", text: "Agree" },
          { value: "neutral", text: "Neutral" },
          { value: "disagree", text: "Disagree" },
          { value: "completelyDisagree", text: "Completely disagree" },
        ],
        rows: [
          { value: "emissionsImportant", text: "The amount of emissions of our products is important." },
          { value: "sustainableBusiness", text: "Sustainable business processes are crucial." },
          { value: "sustainabilityPhilosophy", text: "We incorporate sustainability in our fundamental philosophy." },
          { value: "socialGovernance", text: "Our organization has a focus on social and governance responsibility." }
        ]
      }
    ]
  };

  useEffect(() => {

  }, []);
  useEffect(() => {
    const modelGUID =location.pathname.split("/")[3];

    // change the database if needed
     const db = location.pathname.split("/")[1];
     if (db !== localStorage.getItem("db"))
     {     localStorage.setItem("db", db);
      history.push(`/${db}/survey/${modelGUID}`);
      history.go(0);}

    async function fetchSurvey() {
      try {
        const modelGUID = location.pathname.split("/")[3];
        const response = await getSurvey(modelGUID);

        // Step 1: Add double quotes around keys that are unquoted
        let formattedContent = response.content.replace(
          /([{,]\s*)(\w+)(\s*:)/g,
          '$1"$2"$3'
        );
        // Step 2: Remove trailing commas that could break JSON
        formattedContent = formattedContent.replace(/,(\s*[}\]])/g, "$1");
        // Step 3: Parse JSON
        let surveyJson;
        try {
          surveyJson = JSON.parse(formattedContent);
        } catch (jsonError) {
          console.error("JSON parsing error after formatting:", jsonError);
          setLoading(false);
          return;
        }

        const surveyModel = new Model(surveyJson);
        setSurvey(surveyModel);
        console.log(surveyModel.data);
      } catch (error) {
        console.error("Error fetching or parsing survey:", error);
      } finally {
        setLoading(false);
      }
    }

    fetchSurvey();
  }, []);
 
  if (loading) {
    return <SplashScreen />
  }

  if (!survey) {
    return <div>Failed to load survey.</div>;
  }

  return <Survey model={survey} />;
}

export default SurveyForm;

// -------------------------------------------sample json for questions
// const surveyJson = {
//   title: "Order To Cash",
//   description: "Please answer the following questions",
//   pages: [
//     {
//       name: "page1",
//       elements: [
//         {
//           name: "FirstName",
//           title: "Enter your first name:",
//           type: "text",
//         },
//         {
//           name: "LastName",
//           title: "Enter your last name:",
//           type: "text",
//         },
//         {
//           type: "radiogroup",
//           name: "question1",
//           title: "What is your favorite color?",
//           choices: [
//             {
//               value: "red",
//               text: "Red",
//             },
//             {
//               value: "blue",
//               text: "Blue",
//             },
//             {
//               value: "green",
//               text: "Green",
//             },
//             {
//               value: "yellow",
//               text: "Yellow",
//             },
//           ],
//           isRequired: true,
//         },
//         {
//           type: "checkbox",
//           name: "question2",
//           title: "Which programming languages do you know?",
//           choices: [
//             {
//               value: "javascript",
//               text: "JavaScript",
//             },
//             {
//               value: "python",
//               text: "Python",
//             },
//             {
//               value: "java",
//               text: "Java",
//             },
//             {
//               value: "csharp",
//               text: "C#",
//             },
//           ],
//           isRequired: true,
//         },
//         {
//           type: "text",
//           name: "question3",
//           title: "Please provide any additional comments",
//           placeHolder: "Your comments here...",
//           isRequired: false,
//         },
//       ],
//     },
//   ],
// };

//  ----------------------------------------------------------static json for questionare
// const surveyJson = {
//   title: "Accounts Payable",
//   description: "Please answer the following questions",
//   pages: [
//     {
//       name: "page1",
//       elements: [
//         {
//           type: "radiogroup",
//           name: "ProcessEfficiency_Q1",
//           title:
//             "Our process is clearly documented, monitored, and integrated with the organization’s strategic and corporate planning processes.",
//           choices: [
//             { value: "Strongly agree", text: "Strongly agree" },
//             { value: "Agree", text: "Agree" },
//             { value: "Neutral", text: "Neutral" },
//             { value: "Disagree", text: "Disagree" },
//             { value: "Strongly disagree", text: "Strongly disagree" },
//           ],
//           isRequired: true,
//         },
//         {
//           type: "radiogroup",
//           name: "ProcessEfficiency_Q2",
//           title:
//             "We maintain accurate payment records for all vendor accounts.",
//           choices: [
//             { value: "Strongly agree", text: "Strongly agree" },
//             { value: "Agree", text: "Agree" },
//             { value: "Neutral", text: "Neutral" },
//             { value: "Disagree", text: "Disagree" },
//             { value: "Strongly disagree", text: "Strongly disagree" },
//           ],
//           isRequired: true,
//         },
//       ],
//     },
//     {
//       name: "page2",
//       elements: [
//         {
//           type: "radiogroup",
//           name: "SystemsStandardized_Q1",
//           title:
//             "Our Purchasing, Financial and Cash management systems are integrated and standardized across the enterprise.",
//           choices: [
//             { value: "Strongly agree", text: "Strongly agree" },
//             { value: "Agree", text: "Agree" },
//             { value: "Neutral", text: "Neutral" },
//             { value: "Disagree", text: "Disagree" },
//             { value: "Strongly disagree", text: "Strongly disagree" },
//           ],
//           isRequired: true,
//         },
//         {
//           type: "radiogroup",
//           name: "SystemsStandardized_Q2",
//           title:
//             "Our systems produce standardized reports of AP for every entity.",
//           choices: [
//             { value: "Strongly agree", text: "Strongly agree" },
//             { value: "Agree", text: "Agree" },
//             { value: "Neutral", text: "Neutral" },
//             { value: "Disagree", text: "Disagree" },
//             { value: "Strongly disagree", text: "Strongly disagree" },
//           ],
//           isRequired: true,
//         },
//         {
//           type: "radiogroup",
//           name: "SystemsStandardized_Q3",
//           title:
//             "Our transactional processing systems are functional and integrated with the enterprise data repository.",
//           choices: [
//             { value: "Strongly agree", text: "Strongly agree" },
//             { value: "Agree", text: "Agree" },
//             { value: "Neutral", text: "Neutral" },
//             { value: "Disagree", text: "Disagree" },
//             { value: "Strongly disagree", text: "Strongly disagree" },
//           ],
//           isRequired: true,
//         },
//       ],
//     },
//     {
//       name: "page3",
//       elements: [
//         {
//           type: "radiogroup",
//           name: "StrategicObjectives_Q1",
//           title:
//             "Our reports are open, clear and concise for internal and external users.",
//           choices: [
//             { value: "Strongly agree", text: "Strongly agree" },
//             { value: "Agree", text: "Agree" },
//             { value: "Neutral", text: "Neutral" },
//             { value: "Disagree", text: "Disagree" },
//             { value: "Strongly disagree", text: "Strongly disagree" },
//           ],
//           isRequired: true,
//         },
//         {
//           type: "radiogroup",
//           name: "StrategicObjectives_Q2",
//           title:
//             "AP: Appropriate financial and non-financial indicators are covered within the monitoring and forecasting reports such as operational reports, management reports.",
//           choices: [
//             { value: "Strongly agree", text: "Strongly agree" },
//             { value: "Agree", text: "Agree" },
//             { value: "Neutral", text: "Neutral" },
//             { value: "Disagree", text: "Disagree" },
//             { value: "Strongly disagree", text: "Strongly disagree" },
//           ],
//           isRequired: true,
//         },
//         {
//           type: "radiogroup",
//           name: "StrategicObjectives_Q3",
//           title:
//             "Defined performance indicators are established and used to evaluate costs and benefits and to inform key stakeholders.",
//           choices: [
//             { value: "Strongly agree", text: "Strongly agree" },
//             { value: "Agree", text: "Agree" },
//             { value: "Neutral", text: "Neutral" },
//             { value: "Disagree", text: "Disagree" },
//             { value: "Strongly disagree", text: "Strongly disagree" },
//           ],
//           isRequired: true,
//         },
//       ],
//     },
//     {
//       name: "page4",
//       elements: [
//         {
//           type: "radiogroup",
//           name: "DataHighQuality_Q1",
//           title:
//             "Financial managers have access to data that is up to date, readily available and reliable.",
//           choices: [
//             { value: "Strongly agree", text: "Strongly agree" },
//             { value: "Agree", text: "Agree" },
//             { value: "Neutral", text: "Neutral" },
//             { value: "Disagree", text: "Disagree" },
//             { value: "Strongly disagree", text: "Strongly disagree" },
//           ],
//           isRequired: true,
//         },
//         {
//           type: "radiogroup",
//           name: "DataHighQuality_Q2",
//           title: "Master data is current and audited periodically.",
//           choices: [
//             { value: "Strongly agree", text: "Strongly agree" },
//             { value: "Agree", text: "Agree" },
//             { value: "Neutral", text: "Neutral" },
//             { value: "Disagree", text: "Disagree" },
//             { value: "Strongly disagree", text: "Strongly disagree" },
//           ],
//           isRequired: true,
//         },
//       ],
//     },
//     {
//       name: "page5",
//       elements: [
//         {
//           type: "radiogroup",
//           name: "ProcessPerformance_Q1",
//           title: "Compliance with policies is measured and monitored.",
//           choices: [
//             { value: "Strongly agree", text: "Strongly agree" },
//             { value: "Agree", text: "Agree" },
//             { value: "Neutral", text: "Neutral" },
//             { value: "Disagree", text: "Disagree" },
//             { value: "Strongly disagree", text: "Strongly disagree" },
//           ],
//           isRequired: true,
//         },
//         {
//           type: "radiogroup",
//           name: "ProcessPerformance_Q2",
//           title:
//             "Deviations from standards are investigated and corrective measures put in place.",
//           choices: [
//             { value: "Strongly agree", text: "Strongly agree" },
//             { value: "Agree", text: "Agree" },
//             { value: "Neutral", text: "Neutral" },
//             { value: "Disagree", text: "Disagree" },
//             { value: "Strongly disagree", text: "Strongly disagree" },
//           ],
//           isRequired: true,
//         },
//         {
//           type: "radiogroup",
//           name: "ProcessPerformance_Q3",
//           title:
//             "Balances are reported in accordance with international accounting standards for accurate balance sheet reporting.",
//           choices: [
//             { value: "Strongly agree", text: "Strongly agree" },
//             { value: "Agree", text: "Agree" },
//             { value: "Neutral", text: "Neutral" },
//             { value: "Disagree", text: "Disagree" },
//             { value: "Strongly disagree", text: "Strongly disagree" },
//           ],
//           isRequired: true,
//         },
//       ],
//     },
//     {
//       name: "page6",
//       elements: [
//         {
//           type: "radiogroup",
//           name: "StaffEmpowered_Q1",
//           title:
//             "Our staff is provided adequate initial and refresher training.",
//           choices: [
//             { value: "Strongly agree", text: "Strongly agree" },
//             { value: "Agree", text: "Agree" },
//             { value: "Neutral", text: "Neutral" },
//             { value: "Disagree", text: "Disagree" },
//             { value: "Strongly disagree", text: "Strongly disagree" },
//           ],
//           isRequired: true,
//         },
//         {
//           type: "radiogroup",
//           name: "StaffEmpowered_Q2",
//           title:
//             "We empower our staff to behave in accordance with our company values, laws and regulations, and ethical standards and report fraud or errors without repercussions.",
//           choices: [
//             { value: "Strongly agree", text: "Strongly agree" },
//             { value: "Agree", text: "Agree" },
//             { value: "Neutral", text: "Neutral" },
//             { value: "Disagree", text: "Disagree" },
//             { value: "Strongly disagree", text: "Strongly disagree" },
//           ],
//           isRequired: true,
//         },
//         {
//           type: "radiogroup",
//           name: "StaffEmpowered_Q3",
//           title:
//             "Our staff is aware of their role in the End to End process and the integration with other areas (Purchasing).",
//           choices: [
//             { value: "Strongly agree", text: "Strongly agree" },
//             { value: "Agree", text: "Agree" },
//             { value: "Neutral", text: "Neutral" },
//             { value: "Disagree", text: "Disagree" },
//             { value: "Strongly disagree", text: "Strongly disagree" },
//           ],
//           isRequired: true,
//         },
//       ],
//     },
//   ],
// };
