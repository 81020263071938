import { Form, Button, Card, Alert, Col, Row } from "react-bootstrap";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import axios from "axios";

export default class ChangePassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      error: "",
      msg: "",
      sucess: false,
    };

    this.hnadleSubmit = this.hnadleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  hnadleSubmit(event) {
    const { email, oldPassword, newPassword } = this.state;
    const userID = localStorage.getItem("id");
    const authToken = localStorage.getItem("auth");
    axios("neoPublic/change-password", {
      method: "POST",
      headers: {
        "auth-token": authToken,
        userID: userID,
      },
      data: {
        email: email.toLowerCase(), //always convert email into lower case
        oldPassword: oldPassword,
        newPassword: newPassword,
      },
    })
      .then((response) => {
        this.setState({
          email: "",
          msg: response.data.msg,
          sucess: response.data.success,
        });
      })
      .catch((err) => {
        this.setState({
          error: err?.response?.data?.msg || "Internal server problem, please try again later",
        });
      });
    event.preventDefault();
  }

  render() {
    return (
      <>
      <div className="d-flex align-items-center justify-content-center container" style={{minHeight: "80vh"}}>
        <div className="w-100" style={{maxWidth:"600px"}}>
        <Card>
          <Card.Body>
            <h2 className="text-center mb-3">Change Password</h2>
            <Form onSubmit={this.hnadleSubmit}>
              {this.state.sucess ? (
                <Alert variant="success" align="center">
                  {this.state.msg}
                </Alert>
              ) : this.state.error ? (
                <Alert variant="danger" align="center">
                  {this.state.error}
                </Alert>
              ) : (
                this.state.msg && (
                  <Alert variant="danger" align="center">
                    {this.state.msg}
                  </Alert>
                )
              )}
              <Form.Group id="email">
                <Form.Label>Email*</Form.Label>
                <Form.Control
                  type="email"
                  name="email"
                  placeholder="Email"
                  onChange={this.handleChange}
                  required
                ></Form.Control>
                <Form.Text className="text-muted"></Form.Text>
              </Form.Group>
              <Form.Group id="oldPassword">
                <Form.Label>Old Password*</Form.Label>
                <Form.Control
                  type="password"
                  name="oldPassword"
                  placeholder="Your Old Password"
                  onChange={this.handleChange}
                  required
                ></Form.Control>
              </Form.Group>
              <Form.Group id="newPassword">
                <Form.Label>New Password*</Form.Label>
                <Form.Control
                  type="password"
                  name="newPassword"
                  placeholder="Your New Password"
                  onChange={this.handleChange}
                  required
                ></Form.Control>
              </Form.Group>
              <Button className="w-100" type="submit">
                Change Password
              </Button>
            </Form>
          </Card.Body>
        </Card>
        <div className="w-100 text-center mt-2">
          <Link to="/">Back to Home Page</Link>
        </div>
        </div>
      </div>
      </>
    );
  }
}
