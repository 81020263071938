import { Form, Button, Card, Alert, Col, Row } from "react-bootstrap";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { requestAccount } from "../../actions";

export default class Register extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      company: "",
      firstName: "",
      lastName: "",
      phone: "",
      position: "",
      domains: { Aeneis: false, ARIS: false, Signavio: false },
      message: "",
      topics: {
        "Reference Processes (BPMN)": false,
        "E2E Architecture": false,
        "Process Driven SAP S/4HANA Cookbook (SAP Activate)": false,
      },
      error: "",
      successFlag: "",
      successMsg: "",
      sucess: false,

      alertBox: {
        show: false,
        variant: "success",
        msg: "",
      },
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleUltimateEditionCheckbox =
      this.handleUltimateEditionCheckbox.bind(this);
    this.handleTopicOfInterestCheckbox =
      this.handleTopicOfInterestCheckbox.bind(this);
  }

  resetAlertBox = () => {
    this.setState({
      alertBox: {
        show: false,
        variant: "",
        msg: "",
      },
    });
  };

  handleChange(event) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  handleUltimateEditionCheckbox = (e) => {
    let { name, checked } = e.target;
    this.setState((e) => {
      let selectedCheckBox = e.domains;
      return (selectedCheckBox[name] = checked);
    });
  };

  handleTopicOfInterestCheckbox = (e) => {
    let { name, checked } = e.target;
    this.setState((e) => {
      let selectedCheckBox = e.topics;
      return (selectedCheckBox[name] = checked);
    });
  };

  async handleSubmit(event) {
    event.preventDefault();
    window.scrollTo(0, 0);

    const {
      email,
      company,
      firstName,
      lastName,
      phone,
      position,
      domains,
      topics,
    } = this.state;

    let res = await requestAccount({
      email,
      company,
      firstName,
      lastName,
      phone,
      position,
      domains,
      topics,
    });
    if (res.success) {
      //setUser(res.content);
      this.setState({
        alertBox: {
          show: true,
          variant: "success",
          msg: res.msg,
        },
      });
    } else {
      // setApiInfo(res.content);
      this.setState({
        alertBox: {
          show: true,
          variant: "danger",
          msg: res.msg,
        },
      });
    }

    setTimeout(this.resetAlertBox, 4000);
  }

  render() {
    return (
      <>
        <Card>
          <Card.Body>
            <h2 className="text-center mb-2">
              Sign up to free trial of Business Flows{" "}
            </h2>
            <br />
            <p
              className=" mb-3"
              style={{
                textAlign: "justify",
              }}
            >
              Free trial covers the{" "}
              <a
                style={{ textDecoration: "underline" }}
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.bpexperts.de/business-flows"
              >
                Expert Edition
              </a>{" "}
              with access to our process repository. This way you will have our
              reference content at hand to retain confidence and control during
              your transformation.
            </p>
            <br />
            <Form onSubmit={this.handleSubmit}>
              <Alert
                show={this.state.alertBox.show}
                variant={this.state.alertBox.variant}
                align="center"
              >
                {this.state.alertBox.msg}
              </Alert>

              <Form.Group id="email">
                <Form.Label>Business E-Mail*</Form.Label>
                <Form.Control
                  type="email"
                  name="email"
                  placeholder="E-mail"
                  defaultValue={this.state.email}
                  onChange={this.handleChange}
                  required
                ></Form.Control>
              </Form.Group>
              <Form.Group id="company">
                <Form.Label>Company*</Form.Label>
                <Form.Control
                  type="text"
                  name="company"
                  placeholder="Company"
                  defaultValue={this.state.company}
                  onChange={this.handleChange}
                  required
                ></Form.Control>
              </Form.Group>
              <Row>
                <Col>
                  <Form.Group id="firstName">
                    <Form.Label>First Name*</Form.Label>
                    <Form.Control
                      type="text"
                      name="firstName"
                      placeholder="First Name"
                      defaultValue={this.state.firstName}
                      onChange={this.handleChange}
                      required
                    ></Form.Control>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group id="lastName">
                    <Form.Label>Last Name*</Form.Label>
                    <Form.Control
                      type="text"
                      name="lastName"
                      placeholder="Last Name"
                      defaultValue={this.state.lastName}
                      onChange={this.handleChange}
                      required
                    ></Form.Control>
                  </Form.Group>
                </Col>
              </Row>
              <Form.Group id="phone">
                <Form.Label>Phone</Form.Label>
                <Form.Control
                  type="text"
                  name="phone"
                  placeholder="Phone Number"
                  defaultValue={this.state.phone}
                  onChange={this.handleChange}
                ></Form.Control>
                <Form.Text className="text-muted">
                  If you prefer to be contacted via phone, please provide it
                  above
                </Form.Text>
              </Form.Group>
              <Form.Group id="position">
                <Form.Label>Position*</Form.Label>
                <Form.Control
                  type="text"
                  name="position"
                  placeholder="Position"
                  defaultValue={this.state.position}
                  onChange={this.handleChange}
                  required
                ></Form.Control>
              </Form.Group>
              <Form.Label
                style={{
                  textAlign: "justify",
                }}
              >
                {" "}
                <a
                  style={{ textDecoration: "underline" }}
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.bpexperts.de/business-flows"
                >
                  Ultimate Edition
                </a>{" "}
                offers more flexibility in building your own repository and
                identifying processes critical to reach transformation goals. If
                you are interested in this additional content, you will be
                contacted for a personal walkthrough after checking the box
                below.
              </Form.Label>

              <Form.Group controlId="ultimateEditinCheckboxes">
                <Form.Label>
                  I am interested in Ultimate Edition walkthrough
                </Form.Label>
                <Form.Check
                  type="checkbox"
                  name="Aeneis"
                  label="Aeneis"
                  onChange={this.handleUltimateEditionCheckbox}
                />
                <Form.Check
                  type="checkbox"
                  name="ARIS"
                  label="ARIS"
                  onChange={this.handleUltimateEditionCheckbox}
                />
                <Form.Check
                  type="checkbox"
                  name="Signavio"
                  label="
                  Signavio"
                  onChange={this.handleUltimateEditionCheckbox}
                />
              </Form.Group>
              <Form.Group controlId="topicOfInterestCheckboxes">
                <Form.Label>Topics of interest*</Form.Label>
                <Form.Check
                  name="Reference Processes (BPMN)"
                  type="checkbox"
                  label="Reference Processes (BPMN)"
                  onChange={this.handleTopicOfInterestCheckbox}
                />
                <Form.Check
                  name="E2E Architecture"
                  type="checkbox"
                  label="E2E Architecture"
                  onChange={this.handleTopicOfInterestCheckbox}
                />
                <Form.Check
                  name="Process Driven SAP S/4HANA Cookbook (SAP Activate)"
                  type="checkbox"
                  label="
                  Process Driven SAP S/4HANA Cookbook (SAP Activate)"
                  onChange={this.handleTopicOfInterestCheckbox}
                />
              </Form.Group>
              <Form.Group controlId="formBasicCheckbox1">
                <Form.Label
                  style={{
                    fontWeight: "bold",
                    textAlign: "justify",
                    // fontStyle: "italic",
                  }}
                >
                  Consent to process personal data*
                </Form.Label>
                <Form.Text className="text-muted">
                  I grant permission to process my personal data by accepting
                  the Terms and Conditions. I will still retain the right to
                  revoke permission and ask for deletion of my account.
                  <br />
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.bpexperts.de/privacy-statement"
                  >
                    Read more about privacy policy
                  </a>
                </Form.Text>
                <br />
              </Form.Group>
              <Button className="w-100" type="submit">
                Get Your Free Trial
              </Button>
            </Form>
          </Card.Body>
        </Card>
        <div className="w-100 text-center mt-2">
          Already have an account? {""}
          <Link
            style={{
              textAlign: "justify",
              textDecoration: "underline",
              fontWeight: "bold",
              fontStyle: "italic",
              textUnderlinePosition: "under",
            }}
            to="/login"
          >
            Log in
          </Link>
        </div>
      </>
    );
  }
}
