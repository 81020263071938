import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import { FaLink, FaInfoCircle, FaStar, FaRegStar } from "react-icons/fa"; // Import FaStar and FaRegStar
import ShowMoreText from "react-show-more-text";
import e2eMapDump from "../../assets/e2eMap-dump";
import libraryDump from "../../assets/library-dump";
import {
  getAttributesByKey,
  isEntryinData,
  getShortName,
} from "../../utils/accessMapFunctions";
import {
  getProcessAttributesByPartialName,
  getProcessOccurences,
  getProcessRelationships,
  getProcessBPMN,
  updateMyFavorites,
  removeMyFavorites,
} from "../../actions";

class ItemCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      backgroundColor: "#eee",
      previousDivId: props.previousDivID,
      isStarred: props.isStarred || false,
      hasBPMN: false,
      loading: true,
    };
  }

  async componentDidMount() {
    if (this.props.isStarred !== undefined) {
      this.setState({ isStarred: this.props.isStarred });
    }
  }

  async componentDidUpdate(prevProps) {
    if (
      prevProps.isStarred !== this.props.isStarred ||
      prevProps.previousDivID !== this.props.previousDivID ||
      prevProps.name !== this.props.name
    ) {
      this.setState({
        isStarred: this.props.isStarred,
        previousDivId: this.props.previousDivID,
      });
    }
  }

  async onSingleClickModel() {
    const attributes = await getProcessAttributesByPartialName(this.props.guid);
    this.props.setAttributes(attributes);

    const occs = await getProcessOccurences(this.props.guid);
    this.props.setOccurences(occs);

    const rels = await getProcessRelationships(this.props.guid);
    this.props.setRelationships(rels);
  }

  handleDivClick = (id) => {
    let pdi = this.props.previousDivID;
    if (pdi !== null) {
      let previousDiv = document.getElementById(pdi);
      let currentDiv = document.getElementById(id);
      if (previousDiv) {
        previousDiv.style.backgroundColor = "#eee";
        currentDiv.style.backgroundColor = "#fbf043";
        this.setState({ previousDivId: id });
      }
    } else {
      let currentDiv = document.getElementById(id);
      currentDiv.style.backgroundColor = "#fbf043";
      this.setState({ previousDivId: id });
    }
    this.props.setPreviousDivID(id);
    this.onSingleClickModel();
  };

  onStarClick = async () => {
    const newStarredStatus = !this.state.isStarred;
    this.setState({ isStarred: newStarredStatus });

    if (this.props.guid && newStarredStatus) {
      await updateMyFavorites(this.props.guid);


    } else if (this.props.guid && !newStarredStatus) {
      await removeMyFavorites(this.props.guid);
      this.props.setIsStarred(newStarredStatus);
    }
  };

  render() {
    const { loading, hasBPMN, isStarred } = this.state;
    const { description, guid, name, type, url, path } = this.props;

    let isURL = false;
    let pointerEvents = "";
    let isGUID = true;
    if (this.props.description) {
      let url;
      try {
        const urlInstance = new URL(description);
        isURL =
          urlInstance.protocol === "http:" || urlInstance.protocol === "https:";
      } catch (_) {
        isURL = false;
      }
    }

    if (guid) {
      if (!guid.includes("-") && !isURL) {
        pointerEvents = "none";
        isGUID = false;
      } else if (!hasBPMN && !isURL) {
        pointerEvents = "none";
      }
    }

    const backgroundColor =
      this.props?.guid === this.props.previousDivID ? "#fbf043" : "#eee";

    return (
      <div
        id={this.props.guid}
        style={{
          backgroundColor,
          height: "auto",
          boxShadow: "5px 5px 5px rgb(107, 99, 99)",
          alignItems: "flex-start",
          borderRadius: "3px",
          position: "relative",
          padding: 10,
          marginBottom: "1.5vh",
          wordWrap: "break-word",
          minHeight:'12vh'
        }}
      >
        <div
          style={{
            float: "right",
            marginLeft: "0.4em",
            order: 1,
            cursor: "pointer",
            pointerEvents,
          }}
        >
          {url ? (
            <Link
              style={{
                cursor: "pointer",
                pointerEvents: "auto",
              }}
              to={`${path}/external-link/${url}`}
              target="_blank"
            >
              <FaLink />
            </Link>
          ) : null}

          <div
            style={{
              cursor: "pointer",
              pointerEvents: "auto",
            }}
            onClick={() => this.handleDivClick(this.props.guid)}
          >
            <FaInfoCircle />
          </div>
        </div>

        <div>
          <div style={{ display: "flex" }}>
            {!this.props.hasBpmn.success &&
            type !== "E2E-Scenario Cluster" &&
            type !== "Process Group" ? (
              <div
                onClick={() => {
                  const node = {
                    id: guid,
                    guid,
                    shortName: getShortName(name),
                    fullName: name,
                    type,
                    description,
                    children: [],
                    isLink: false,
                    url,
                    style: {
                      background: "#FFFFFF",
                    },
                  };
                  this.props.setNodeDatum(node);
                  this.props.history.push(`${path}/object/${guid}`);
                }}
              >
                <h5
                  className="factSheetFullName disableSelection"
                  style={{
                    margin: "0 0 .5em",
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                >
                  {name}
                </h5>
              </div>
            ) : (
              <Link to={`${path}/model/${guid}`} target="_blank">
                <h5
                  className="factSheetFullName disableSelection"
                  style={{
                    margin: "0 0 .5em",
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                >
                  {name}
                </h5>
              </Link>
            )}
            <div
              onClick={this.onStarClick}
              style={{
                cursor: "pointer",
                marginLeft: "0.5em",
                color: isStarred ? "gold" : "black",
              }}
            >
              {isStarred ? <FaStar /> : <FaRegStar />}
            </div>
          </div>

          <p
            style={{
              fontSize: 15,
              margin: 2,
              color: "rgb(111 111 111)",
              fontWeight: "bold",
            }}
          >
            {type}
          </p>

          <p
            style={{
              fontSize: 16,
              margin: 2,
              color: "#606362",
              textAlign: "justify",
              textJustify: "inter-word",
              pointerEvents: "all",
            }}
          >
            <ShowMoreText
              lines={3}
              more="Show more"
              less="Show less"
              expanded={false}
              truncatedEndingComponent={"... "}
            >
              {description}
            </ShowMoreText>
          </p>
        </div>
      </div>
    );
  }
}

export default withRouter(ItemCard);
