import React, { Component } from "react";
import { Form, Button, Card, Alert } from "react-bootstrap";
import { Link } from "react-router-dom";
import LoadingSpinner from "./admin/LoadingSpinner";

import { LinkedIn } from "react-linkedin-login-oauth2";
import linkedin from "react-linkedin-login-oauth2/assets/linkedin.png";
import {
  authenticate,
  getProfile,
  requestProlongation,
  signInLinkedin,
} from "../../actions";

const auth = localStorage.getItem("auth");
// for testing comment 4
class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      loading: false,
      alertBox: { show: true, type: "danger", message: "" },
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  resetAlertBox = () => {
    this.setState({
      alertBox: {
        show: false,
        variant: "danger",
        msg: "",
      },
    });
  };

  async componentDidMount() {
    /**
     * In case the authentication token exists in the local storage, login will be skipped.
     */

    if (auth) {
      this.setState({
        loading: true,
        alertBox: {
          show: true,
          variant: "info",
          msg: "Proceeding to authentication with token",
        },
      });
      let res = await getProfile(auth);

      if (res.success) {
        this.setState({
          loading: false,

          alertBox: {
            show: true,
            variant: "info",
            msg: "Successfully loggedin",
          },
        });
        this.props.history.push(`/home`);
        this.props.history.go(0);
      } else {
        localStorage.clear();
        this.setState({
          loading: false,

          alertBox: {
            show: true,
            variant: "warning",
            msg: res.msg,
          },
        });
      }
    }
    setTimeout(this.resetAlertBox, 4000);
  }

  handleSignInLinkedinSuccess = async (data) => {
    this.setState({
      alertBox: {
        show: true,
        variant: "info",
        msg: "Proceeding to authentication with linkedin",
      },
    });
    let res = await signInLinkedin(data);
    if (res.success) {
      localStorage.setItem("auth", res.content.token);

      this.props.history.push(`/home`);
      this.props.history.go(0);
    } else {
      this.setState({
        loading: false,

        alertBox: {
          show: true,
          variant: "danger",
          msg: res.msg,
        },
      });
    }
    setTimeout(this.resetAlertBox, 4000);
  };

  handleSignInLinkedinFailure = (error) => {
    this.setState({
      loading: false,

      alertBox: {
        show: true,
        variant: "warning",
        msg: "Failed to authenticate with linkedin",
      },
    });

    setTimeout(this.resetAlertBox, 4000);
  };

  handleChange(event) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  accountExtesnionRequest = async () => {
    if (this.state.email) {
      let res = await requestProlongation(this.state.email);
      if (res.success) {
        this.setState({
          alertBox: {
            show: true,
            variant: "warning",
            msg: res.msg,
          },
        });
      } else {
        this.setState({
          alertBox: {
            show: true,
            variant: "danger",
            msg: res.msg,
          },
        });
      }

      setTimeout(this.resetAlertBox, 4000);
    }
  };

  async handleSubmit(event) {
    const { email, password } = this.state;
    event.preventDefault();

    let res = await authenticate(email, password);
    if (res.success) {
      localStorage.setItem("auth", res.content.token);
      this.props.history.push(`/home`);
      this.props.history.go(0);
    } else {
      this.setState({
        alertBox: {
          show: true,
          variant: "danger",
          msg: res.msg,
        },
      });
    }
    this.setState({ loading: false });
    setTimeout(this.resetAlertBox, 4000);
  }

  render() {
    return (
      <>
        <Alert
          show={this.state.alertBox.show}
          variant={this.state.alertBox.variant}
          align="center"
        >
          {this.state.alertBox.msg}
        </Alert>
        <Card>
          <Card.Body>
            <h2 className="text-center mb-4">Log In</h2>

            <Form onSubmit={this.handleSubmit}>
              {this.state.loading ? (
                <LoadingSpinner />
              ) : (
                <>
                  <Form.Group id="email">
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                      type="email"
                      name="email"
                      placeholder="Email"
                      required
                      defaultValue={this.state.email}
                      onChange={this.handleChange}
                    ></Form.Control>
                  </Form.Group>
                  <Form.Group id="password">
                    <Form.Label>Password</Form.Label>
                    <Form.Control
                      type="password"
                      name="password"
                      placeholder="Password"
                      required
                      defaultValue={this.state.password}
                      onChange={this.handleChange}
                    ></Form.Control>
                  </Form.Group>
                  <Button className="w-100" type="submit">
                    Log In
                  </Button>
                </>
              )}
            </Form>
            <div
              className="w-100 text-center mt-3"
              style={{
                textAlign: "justify",
                textDecoration: "underline",
                fontWeight: "bold",
                fontStyle: "italic",
                textUnderlinePosition: "under",
              }}
            >
              <Link to="/forget-password">Forgot your password?</Link>
            </div>
          </Card.Body>
        </Card>

        <div className="w-100 text-center mt-2">
          Want a free trial of Business Flows? {""}
          <Link
            style={{
              textAlign: "justify",
              textDecoration: "underline",
              fontWeight: "bold",
              fontStyle: "italic",
              textUnderlinePosition: "under",
            }}
            to="/signup"
          >
            Sign Up
          </Link>
          <br />
          <LinkedIn
            scope="r_liteprofile r_emailaddress"
            clientId="78vqcef4okxs2x"
            onFailure={this.handleSignInLinkedinFailure}
            onSuccess={this.handleSignInLinkedinSuccess}
            redirectUri={`${window.location.origin}/linkedin`}
            style={{ marginTop: "25px" }}
            onClick={() => {
              this.setState({
                loading: true,
              });
            }}
          >
            <img
              src={linkedin}
              alt="Sign up with LinkedIn"
              style={{ maxWidth: "180px" }}
            />
          </LinkedIn>
        </div>
      </>
    );
  }
}

export default Login;
