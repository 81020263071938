import React from "react";

const AttributesPanel = ({ attributes }) => {
  if (!attributes || typeof attributes !== "object") {
    return <div>No data to display</div>;
  }

  const { success, ...content } = attributes; // Exclude the 'success' attribute from the content object

  return (
    <div id="attribute" className="tab-pane active">
      <ul className="list-group">
        <li className="list-group-item" style={{ padding: 0, border: "none" }}>
          {/* <table className="table" style={{ marginBottom: 0 }}>
            <thead>
              <tr>
                <th className="attribute-name" scope="col">
                  Attribute
                </th>
                <th className="attribute-value" scope="col">
                  Value
                </th>
              </tr>
            </thead>
          </table> */}

          <div className="table-responsive-xl">
            <table className="table" style={{ tableLayout: "fixed" }}>
              <tbody>
                {Object.entries(content).map(
                  ([attributeName, attributeValue]) => {
                    if (
                      typeof attributeValue === "object" &&
                      !Array.isArray(attributeValue)
                    ) {
                      // If the attributeValue is an object (but not an array), we need to iterate its keys
                      return Object.entries(attributeValue).map(
                        ([nestedKey, nestedValue]) => (
                          <tr key={`${attributeName}.${nestedKey}`}>
                            <td
                              style={{
                                textAlign: "justify",
                                width: "100px",
                                wordWrap: "break-word",
                              }}
                            >
                              {nestedKey === "name" ? (
                                <strong style={{}}>Name: </strong>
                              ) : (
                                <strong style={{}}>{nestedKey}: </strong>
                              )}
                              {"\n"}
                              {nestedValue?.includes("https://") ? (
                                // <Link to={nestedValue} target="_blank">
                                <a
                                  href={nestedValue}
                                  target="_blank"
                                  rel="noreferrer"
                                  style={{
                                    color: "royalblue",
                                    display: "contents",
                                  }}
                                >
                                  {/* {" "} */}
                                  Explore more by clicking here.
                                  {/* {nestedValue} */}
                                </a>
                              ) : (
                                // </Link>
                                nestedValue
                              )}
                            </td>
                            {/* <td>{nestedValue}</td> */}
                          </tr>
                        )
                      );
                    } else {
                      // For regular attributes (non-object or arrays), display in a single row
                      return (
                        <tr key={attributeName}>
                          <td
                            style={{
                              textAlign: "justify",
                              width: "100px",
                              wordWrap: "break-word",
                            }}
                          >
                            <strong>{attributeName}</strong>
                          </td>
                          <td>
                            {typeof attributeValue === "object"
                              ? JSON.stringify(attributeValue)
                              : attributeValue}
                          </td>
                        </tr>
                      );
                    }
                  }
                )}
              </tbody>
            </table>
          </div>
        </li>
      </ul>
    </div>
  );
};

export default AttributesPanel;
