import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import SvgIcon from "@material-ui/core/SvgIcon";
import { fade, makeStyles, withStyles } from "@material-ui/core/styles";
import TreeView from "@material-ui/lab/TreeView";
import TreeItem from "@material-ui/lab/TreeItem";
import Collapse from "@material-ui/core/Collapse";
import { useHistory, useLocation } from "react-router-dom";
import { useSpring, animated } from "react-spring/web.cjs"; // web.cjs is required for IE 11 support
import { verifyAccess } from "../../utils/accessMapFunctions";
import {
  sortByProperty,
  findLibraryParentsGUID,
  getFirstChildArr,
  getRelevantTab,
} from "../../utils/accessMapFunctions";

export default function ProcessHierarchy(props) {
  const history = useHistory();
  const location = useLocation();
  const classes = useStyles();
  const [valueDropDown, setValueDropDown] = useState(props.e2eMap.Type);
  const [labelDropDown, setLablelDropDown] = useState(props.e2eMap.name);

  const [expandedItem, setExpandedItem] = useState(["1"]);
  const [expandedLibraryItem, setExpandedLibraryItem] = useState(["1"]);
  const [expandedBlueprintItem, setExpandedBlueprintItem] = useState(["1"]);
  const [expandedWorkPackageItem, setExpandedWorkPackageItem] = useState(["1"]);
  const [isFirstRender, setIsFirstRender] = useState(true);

  const [selected, setSelected] = useState("1");

  const processTree = [
    props.e2eMap,
    props.processLibrary,
    props.blueprints,
    props.workPackages,
  ];

  const autoExpand = (guid) => {
    const relevantTab = getRelevantTab(
      guid,
      [
        props.e2eMap.Type,
        props.processLibrary.Type,
        props.blueprints.Type,
        props.workPackages.Type,
      ],
      processTree
    );

    switch (relevantTab) {
      case props.e2eMap.Type:
        setExpandedItem([
          "1",
          ...findLibraryParentsGUID([], props.e2eMap, guid),
        ]);
        setSelected(guid);
        setValueDropDown(props.e2eMap.Type);
        setLablelDropDown(props.e2eMap.Type)
        break;
      case props.processLibrary.Type:
        setExpandedLibraryItem([
          "1",
          ...findLibraryParentsGUID([], props.processLibrary, guid),
        ]);
        setValueDropDown(props.processLibrary.Type);
        setLablelDropDown(props.processLibrary.Type)

        setSelected(guid);
        break;
      case props.workPackages.Type:
        setExpandedWorkPackageItem([
          "1",
          ...getFirstChildArr([], props.workPackages, guid),
        ]);
        setValueDropDown(props.workPackages.Type);
        setLablelDropDown(props.workPackages.Type)
        setSelected(guid);
        break;
      case props.blueprints.Type:
        setExpandedBlueprintItem([
          "1",
          ...getFirstChildArr([], props.blueprints, guid),
        ]);
        setValueDropDown(props.blueprints.Type);
        setLablelDropDown(props.blueprints.Type)

        setSelected(guid);
        break;
      default:
        //history.push(`${props.path}/not-found`);
        break;
    }
  };

  useEffect(() => {
    //resolved recursion issue
    //if (true) {
    autoExpand(location.pathname.split("/")[3]);
    setIsFirstRender(false);
    //}

    return () => {};
  }, [isFirstRender, location]);

  return (
    <div className="dropdown" style={{ height: "74vh" }}>
      <button
        className="btn btn-secondary dropdown-toggle"
        style={{ width: "-webkit-fill-available" }}
        type="button"
        id="dropdownMenuButton"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
      >
        {labelDropDown}
      </button>
      <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
        {props?.e2eMap?.GUID && (
          <div
            className="dropdown-item"
            onClick={() => {
              setValueDropDown(props.e2eMap.Type);
              setLablelDropDown(props.e2eMap.name);
              history.push(`${props.path}/model/${props?.e2eMap?.GUID}`);
            }}
          >
           {props.e2eMap.name}
          </div>
        )}

        {props?.processLibrary.GUID && (
          <div
            className="dropdown-item"
            onClick={(k) => {
              setValueDropDown(props.processLibrary.Type);
              setLablelDropDown(props.processLibrary.name);
              history.push(
                `${props.path}/model/${props?.processLibrary?.GUID}`
              );
            }}
          >
           {props.processLibrary.name}
          </div>
        )}

        {props?.blueprints.GUID && (
          <div
            className="dropdown-item"
            onClick={() => {
              setValueDropDown(props.blueprints.Type);
              setLablelDropDown(props.blueprints.name);
              history.push(`${props.path}/model/${props?.blueprints?.GUID}`);
            }}
          >
            {props.blueprints.name}
          </div>
        )}

        {props?.workPackages.GUID && (
          <div
            className="dropdown-item"
            onClick={() => {
              setValueDropDown(props.workPackages.Type);
              setLablelDropDown(props.workPackages.name);
              history.push(`${props.path}/model/${props?.workPackages?.GUID}`);
            }}
          >
            {props.workPackages.name}
          </div>
        )}
      </div>

      {valueDropDown === props.e2eMap.Type ? (
        <TreeView
          key={props.e2eMap.Type}
          className={classes.root}
          expanded={expandedItem}
          selected={selected}
          onNodeToggle={(event, nodeIds) => {
            setExpandedItem(nodeIds);
          }}
          onNodeSelect={(event, nodeIds) => {
            setSelected(nodeIds);
          }}
        >
          <div className="left-side-tree-hierarchy my-5 mx-3">
            <StyledTreeItem
              collapseIcon={<MinusSquare />}
              expandIcon={<PlusSquare />}
              endIcon={<CloseSquare />}
              nodeId="1"
              key="k-e2e"
              label={props.e2eMap.name}
              onClick={() => {
                history.push(`${props.path}/model/${props.e2eMap.GUID}`);
              }}
            >
              {getFirstChildArr(props.e2eMap)
                .sort(sortByProperty("name"))
                .map((domain) =>
                  verifyAccess(
                    getFirstChildArr(props.e2eMap),
                    domain.GUID,
                    props.user.subscription
                  ) ? (
                    <StyledTreeItem
                      collapseIcon={<MinusSquare />}
                      expandIcon={<PlusSquare />}
                      endIcon={<CloseSquare />}
                      key={domain.GUID}
                      nodeId={domain.GUID}
                      label={
                        <div
                          className="noselect"
                          style={{ textDecoration: "underline_" }}
                          onClick={() => {
                            history.push(`${props.path}/model/${domain.GUID}`);
                          }}
                        >
                          {domain.name}{" "}
                        </div>
                      }
                    >
                      {getFirstChildArr(domain)
                        .sort(sortByProperty("name"))
                        .map((scenarioCluster) =>
                          verifyAccess(
                            getFirstChildArr(domain),
                            scenarioCluster.GUID,
                            props.user.subscription
                          ) ? (
                            <StyledTreeItem
                              collapseIcon={<MinusSquare />}
                              expandIcon={<PlusSquare />}
                              endIcon={<CloseSquare />}
                              key={scenarioCluster.GUID}
                              nodeId={scenarioCluster.GUID}
                              label={
                                <div
                                  style={{ textDecoration: "underline_" }}
                                  onClick={() => {
                                    history.push(
                                      `${props.path}/model/${scenarioCluster.GUID}`
                                    );
                                  }}
                                >
                                  {" "}
                                  {scenarioCluster.name}{" "}
                                </div>
                              }
                            >
                              {getFirstChildArr(scenarioCluster)
                                .sort(sortByProperty("name"))
                                .map((scenario) =>
                                  verifyAccess(
                                    getFirstChildArr(scenarioCluster),
                                    scenario.GUID,
                                    props.user.subscription
                                  ) && scenario?.hasAssignment === true ? (
                                    <StyledTreeItem
                                      collapseIcon={<MinusSquare />}
                                      expandIcon={<PlusSquare />}
                                      endIcon={<DefaultSquare />}
                                      key={scenario.GUID}
                                      nodeId={scenario.GUID}
                                      label={
                                        <div
                                          style={{
                                            textDecoration: "underline_",
                                          }}
                                          onClick={() => {
                                            history.push(
                                              `${props.path}/model/${scenario.GUID}`
                                            );
                                          }}
                                        >
                                          {" "}
                                          {scenario.name}
                                        </div>
                                      }
                                    >
                                      {getFirstChildArr(scenario)
                                        .sort(sortByProperty("name"))
                                        .map((process) =>
                                          verifyAccess(
                                            getFirstChildArr(scenario),
                                            process.GUID,
                                            props.user.subscription
                                          ) &&
                                          process?.hasAssignment === true ? (
                                            <StyledTreeItem
                                              collapseIcon={<MinusSquare />}
                                              expandIcon={<PlusSquare />}
                                              endIcon={<DefaultSquare />}
                                              key={process.GUID}
                                              nodeId={process.GUID}
                                              label={
                                                <div
                                                  style={{
                                                    textDecoration:
                                                      "underline_",
                                                  }}
                                                  onClick={() => {
                                                    history.push(
                                                      `${props.path}/model/${process.GUID}`
                                                    );
                                                  }}
                                                >
                                                  {" "}
                                                  {process.name}
                                                </div>
                                              }
                                            />
                                          ) : (
                                            <StyledTreeItem
                                              collapseIcon={<MinusSquare />}
                                              expandIcon={<PlusSquare />}
                                              endIcon={
                                                <DefaultSquare
                                                  className="close"
                                                  style={{ cursor: "default" }}
                                                />
                                              }
                                              key={scenario.GUID}
                                              nodeId={scenario.GUID}
                                              label={
                                                <div
                                                  style={{
                                                    color: "grey",
                                                    cursor: "default",
                                                  }}
                                                >
                                                  {" "}
                                                  {process.name}{" "}
                                                </div>
                                              }
                                            />
                                          )
                                        )}
                                    </StyledTreeItem>
                                  ) : (
                                    <StyledTreeItem
                                      collapseIcon={<MinusSquare />}
                                      expandIcon={<PlusSquare />}
                                      endIcon={
                                        <DefaultSquare
                                          className="close"
                                          style={{ cursor: "default" }}
                                        />
                                      }
                                      key={scenario.GUID}
                                      nodeId={scenario.GUID}
                                      label={
                                        <div
                                          style={{
                                            color: "grey",
                                            cursor: "default",
                                          }}
                                        >
                                          {" "}
                                          {scenario.name}{" "}
                                        </div>
                                      }
                                    />
                                  )
                                )}
                            </StyledTreeItem>
                          ) : (
                            //user cant access scenarioCluster
                            <StyledTreeItem
                              collapseIcon={<MinusSquare />}
                              expandIcon={<PlusSquare />}
                              endIcon={
                                <CloseSquare
                                  className="close"
                                  style={{ cursor: "default" }}
                                />
                              }
                              key={scenarioCluster.GUID}
                              nodeId={scenarioCluster.GUID}
                              label={
                                <div
                                  style={{ color: "grey", cursor: "default" }}
                                >
                                  {" "}
                                  {scenarioCluster.name}{" "}
                                </div>
                              }
                            />
                          )
                        )}
                    </StyledTreeItem>
                  ) : (
                    //User cant access the domain
                    <StyledTreeItem
                      collapseIcon={<MinusSquare />}
                      expandIcon={<PlusSquare />}
                      endIcon={
                        <CloseSquare
                          className="close"
                          style={{ cursor: "default" }}
                        />
                      }
                      key={domain.GUID}
                      nodeId={domain.GUID}
                      label={
                        <div
                          className="noselect"
                          style={{ color: "grey", cursor: "default" }}
                        >
                          {" "}
                          {domain.name}{" "}
                        </div>
                      }
                    />
                  )
                )}
            </StyledTreeItem>
          </div>
        </TreeView>
      ) : valueDropDown === props.processLibrary.Type ? (
        <TreeView
          eventKey={props.processLibrary.Type}
          className={classes.root}
          expanded={expandedLibraryItem}
          selected={selected}
          onNodeToggle={(event, nodeIds) => {
            setExpandedLibraryItem(nodeIds);
          }}
          onNodeSelect={(event, nodeIds) => {
            setSelected(nodeIds);
          }}
        >
          <div className="left-side-tree-hierarchy my-5 mx-3">
            <StyledTreeItem
              collapseIcon={<MinusSquare />}
              expandIcon={<PlusSquare />}
              endIcon={<CloseSquare />}
              nodeId="1"
              key="k-lib"
              label={props.processLibrary.name}
              onClick={() => {
                history.push(
                  `${props.path}/model/${props.processLibrary.GUID}`
                );
              }}
            >
              {
                /**
              Object.values(json).forEach(value => {
                if(Array.isArray(value)) return value
              });
              */
                getFirstChildArr(props.processLibrary)
                  .sort(sortByProperty("name"))
                  .map((level1) => (
                    <StyledTreeItem
                      collapseIcon={<MinusSquare />}
                      expandIcon={<PlusSquare />}
                      endIcon={<CloseSquare />}
                      key={level1.GUID}
                      nodeId={level1.GUID}
                      label={
                        <div
                          className="noselect"
                          style={{ textDecoration: "underline_" }}
                          onClick={() => {
                            history.push(`${props.path}/model/${level1.GUID}`);
                          }}
                        >
                          {level1.name}{" "}
                        </div>
                      }
                    >
                      {getFirstChildArr(level1)
                        .sort(sortByProperty("name"))
                        .map((level2) => (
                          <StyledTreeItem
                            collapseIcon={<MinusSquare />}
                            expandIcon={<PlusSquare />}
                            endIcon={<CloseSquare />}
                            key={level2.GUID}
                            nodeId={level2.GUID}
                            label={
                              <div
                                className="noselect"
                                style={{ textDecoration: "underline_" }}
                                onClick={() => {
                                  history.push(
                                    `${props.path}/model/${level2.GUID}`
                                  );
                                  //history.go(0);
                                }}
                              >
                                {" "}
                                {level2.name}{" "}
                              </div>
                            }
                          >
                            {getFirstChildArr(level2)
                              .sort(sortByProperty("name"))
                              .map((level3) =>
                                verifyAccess(
                                  getFirstChildArr(level2),
                                  level3.GUID,
                                  props.user.subscription
                                ) && level3.hasAssignment === true ? (
                                  <StyledTreeItem
                                    collapseIcon={<MinusSquare />}
                                    expandIcon={<PlusSquare />}
                                    endIcon={<DefaultSquare />}
                                    key={level3.GUID}
                                    nodeId={level3.GUID}
                                    label={
                                      <div
                                        className="noselect"
                                        style={{ textDecoration: "underline_" }}
                                        onClick={() => {
                                          history.push(
                                            `${props.path}/model/${level3.GUID}`
                                          );
                                          //history.go(0);
                                        }}
                                      >
                                        {" "}
                                        {level3.name}{" "}
                                      </div>
                                    }
                                  />
                                ) : (
                                  <StyledTreeItem
                                    collapseIcon={<MinusSquare />}
                                    expandIcon={<PlusSquare />}
                                    endIcon={
                                      <DefaultSquare
                                        className="close"
                                        style={{
                                          cursor: "default",
                                        }}
                                      />
                                    }
                                    key={level3.GUID}
                                    nodeId={level3.GUID}
                                    label={
                                      <div
                                        className="noselect"
                                        style={{
                                          color: "grey",
                                          cursor: "default",
                                        }}
                                      >
                                        {" "}
                                        {level3.name}{" "}
                                      </div>
                                    }
                                  />
                                )
                              )}
                          </StyledTreeItem>
                        ))}
                    </StyledTreeItem>
                  ))
              }
            </StyledTreeItem>
          </div>
        </TreeView>
      ) : valueDropDown === props.blueprints.Type ? (
        <TreeView
          eventKey={props.blueprints.Type}
          className={classes.root}
          expanded={expandedBlueprintItem}
          selected={selected}
          onNodeToggle={(event, nodeIds) => {
            setExpandedBlueprintItem(nodeIds);
          }}
          onNodeSelect={(event, nodeIds) => {
            setSelected(nodeIds);
          }}
        >
          <div className="left-side-tree-hierarchy my-5 mx-3">
            <StyledTreeItem
              collapseIcon={<MinusSquare />}
              expandIcon={<PlusSquare />}
              endIcon={<CloseSquare />}
              nodeId="1"
              key="k-meth"
              label={props.blueprints.Type}
              onClick={() => {
                history.push(`${props.path}/model/${props.blueprints.GUID}`);
              }}
            >
              {getFirstChildArr(props.blueprints)
                .sort(sortByProperty("name"))
                .map((blueprint) => (
                  <StyledTreeItem
                    collapseIcon={<MinusSquare />}
                    expandIcon={<PlusSquare />}
                    endIcon={<DefaultSquare />}
                    key={blueprint.GUID}
                    nodeId={blueprint.GUID}
                    label={
                      <div
                        className="noselect"
                        style={{ textDecoration: "underline_" }}
                        onClick={() => {
                          history.push(`${props.path}/model/${blueprint.GUID}`);
                        }}
                      >
                        {blueprint.name}{" "}
                      </div>
                    }
                  ></StyledTreeItem>
                ))}
            </StyledTreeItem>
          </div>
        </TreeView>
      ) : valueDropDown === props.workPackages.Type ? (
        <TreeView
          eventKey={props.workPackages.Type}
          className={classes.root}
          expanded={expandedWorkPackageItem}
          selected={selected}
          onNodeToggle={(event, nodeIds) => {
            setExpandedWorkPackageItem(nodeIds);
          }}
          onNodeSelect={(event, nodeIds) => {
            setSelected(nodeIds);
          }}
        >
          <div className="left-side-tree-hierarchy my-5 mx-3">
            <StyledTreeItem
              collapseIcon={<MinusSquare />}
              expandIcon={<PlusSquare />}
              endIcon={<CloseSquare />}
              nodeId="1"
              key="k-meth"
              label={props.workPackages.name}
              onClick={() => {
                history.push(`${props.path}/model/${props.workPackages.GUID}`);
              }}
            >
              {getFirstChildArr(props.workPackages)
                .sort(sortByProperty("name"))
                .map((workPackageGroup) => (
                  <StyledTreeItem
                    collapseIcon={<MinusSquare />}
                    expandIcon={<PlusSquare />}
                    endIcon={<DefaultSquare />}
                    key={workPackageGroup.GUID}
                    nodeId={workPackageGroup.GUID}
                    label={
                      <div
                        className="noselect"
                        style={{ textDecoration: "underline_" }}
                        onClick={() => {
                          history.push(
                            `${props.path}/model/${workPackageGroup.GUID}`
                          );
                        }}
                      >
                        {workPackageGroup.name}{" "}
                      </div>
                    }
                  >
                    {getFirstChildArr(workPackageGroup)
                      .sort(sortByProperty("name"))
                      .map((workPackage_) =>
                        verifyAccess(
                          getFirstChildArr(workPackageGroup),
                          workPackage_.GUID,
                          props.user.subscription
                        ) && workPackage_?.hasAssignment === true ? (
                          <StyledTreeItem
                            collapseIcon={<MinusSquare />}
                            expandIcon={<PlusSquare />}
                            endIcon={<DefaultSquare />}
                            key={workPackage_.GUID}
                            nodeId={workPackage_.GUID}
                            label={
                              <div
                                style={{
                                  textDecoration: "underline_",
                                }}
                                onClick={() => {
                                  history.push(
                                    `${props.path}/model/${workPackage_.GUID}`
                                  );
                                }}
                              >
                                {" "}
                                {workPackage_.name}
                              </div>
                            }
                          />
                        ) : (
                          <StyledTreeItem
                            collapseIcon={<MinusSquare />}
                            expandIcon={<PlusSquare />}
                            endIcon={
                              <DefaultSquare
                                className="close"
                                style={{ cursor: "default" }}
                              />
                            }
                            key={workPackage_.GUID}
                            nodeId={workPackage_.GUID}
                            label={
                              <div
                                style={{
                                  color: "grey",
                                  cursor: "default",
                                }}
                              >
                                {" "}
                                {workPackage_.name}{" "}
                              </div>
                            }
                          />
                        )
                      )}
                  </StyledTreeItem>
                ))}
            </StyledTreeItem>
          </div>
        </TreeView>
      ) : (
        <></>
      )}
    </div>
  );
}

function MinusSquare(props) {
  return (
    <SvgIcon fontSize="inherit" style={{ width: 14, height: 14 }} {...props}>
      {/* tslint:disable-next-line: max-line-length */}
      <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 11.023h-11.826q-.375 0-.669.281t-.294.682v0q0 .401.294 .682t.669.281h11.826q.375 0 .669-.281t.294-.682v0q0-.401-.294-.682t-.669-.281z" />
    </SvgIcon>
  );
}

function PlusSquare(props) {
  return (
    <SvgIcon fontSize="inherit" style={{ width: 14, height: 14 }} {...props}>
      {/* tslint:disable-next-line: max-line-length */}
      <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 12.977h-4.923v4.896q0 .401-.281.682t-.682.281v0q-.375 0-.669-.281t-.294-.682v-4.896h-4.923q-.401 0-.682-.294t-.281-.669v0q0-.401.281-.682t.682-.281h4.923v-4.896q0-.401.294-.682t.669-.281v0q.401 0 .682.281t.281.682v4.896h4.923q.401 0 .682.281t.281.682v0q0 .375-.281.669t-.682.294z" />
    </SvgIcon>
  );
}

function DefaultSquare(props) {
  return (
    <SvgIcon fontSize="inherit" style={{ width: 14, height: 14 }} {...props}>
      {/* tslint:disable-next-line: max-line-length */}
      <rect height={14} width={14} fill="#000000" />
    </SvgIcon>
  );
}

function CloseSquare(props) {
  return (
    <SvgIcon
      className={props.className}
      fontSize="inherit"
      style={{ width: 14, height: 14 }}
      {...props}
    >
      {/* tslint:disable-next-line: max-line-length */}
      <path d="M17.485 17.512q-.281.281-.682.281t-.696-.268l-4.12-4.147-4.12 4.147q-.294.268-.696.268t-.682-.281-.281-.682.294-.669l4.12-4.147-4.12-4.147q-.294-.268-.294-.669t.281-.682.682-.281.696 .268l4.12 4.147 4.12-4.147q.294-.268.696-.268t.682.281 .281.669-.294.682l-4.12 4.147 4.12 4.147q.294.268 .294.669t-.281.682zM22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0z" />
    </SvgIcon>
  );
}

function TransitionComponent(props) {
  const style = useSpring({
    from: { opacity: 0, transform: "translate3d(20px,0,0)" },
    to: {
      opacity: props.in ? 1 : 0,
      transform: `translate3d(${props.in ? 0 : 20}px,0,0)`,
    },
  });

  return (
    <animated.div style={style}>
      <Collapse {...props} />
    </animated.div>
  );
}

TransitionComponent.propTypes = {
  /**
   * Show the component; triggers the enter or exit states
   */
  in: PropTypes.bool,
};

const StyledTreeItem = withStyles((theme) => ({
  iconContainer: {
    "& .close": {
      opacity: 0.3,
    },
  },
  group: {
    marginLeft: 7,
    paddingLeft: 18,
    borderLeft: `1px dashed ${fade(theme.palette.text.primary, 0.4)}`,
  },
}))((props) => (
  <TreeItem {...props} TransitionComponent={TransitionComponent} />
));

const useStyles = makeStyles({
  root: {
    height: 264,
    flexGrow: 1,
    maxWidth: 320,
  },
});
