import React from "react";
import "./SplashScreen.scss";

import roundLogo from "../assets/bf_logo_round.png";

export default function SplashScreen() {
  return (
    <div
      style={{
        position: "absolute",
        width: window.innerWidth,
        height: window.innerHeight,
        backgroundColor: "white",
        zIndex: 3000,
      }}
    >
      <div className="splash">
        <div className="splash_logo">
          BFB
          {/* <img
            src={roundLogo}
            style={{ width: 100, height: 100 }}
            alt="fireSpot"
          /> */}
        </div>

        <div className="splash_svg">
          <svg width="100%" height="100%">
            <rect width="100%" height="100%" />
          </svg>
        </div>
        <div className="splash_minimize">
          <svg width="100%" height="100%">
            <rect width="100%" height="100%" />
          </svg>
        </div>
      </div>
      <div className="text">
        <div className="text-center">
          <div className="spinner-border" role="status"></div>
        </div>
      </div>
    </div>
  );
}
